import { FC, useEffect, useState } from "react";

import { Typography } from "antd";

import unSelectedIcon from "../../../../../../../assets/images/form/complete-empty.svg";
import selectedIcon from "../../../../../../../assets/images/form/complete.svg";
import useBaseUrl from "../../../../../../../hooks/helpers/useBaseUrl";

type SelectOneIconsQuestionProps = {
    data: {
        question?: string;
        helpText?: string;
        required?: boolean;
        options?: {
            id: string;
            value: string;
            url?: string;
        }[];
    };
    onAnswer: (_answer: string) => void;
    value?: string;
};

const SelectOneIconsQuestion: FC<SelectOneIconsQuestionProps> = ({
    data,
    onAnswer,
    value = "",
}) => {
    const baseUrl = useBaseUrl();
    const [error, setError] = useState<string>("");
    const [skipValidation, setSkipValidation] = useState<boolean>(true);

    useEffect(() => {
        if (data.required && value === "" && !skipValidation) {
            setError("Required to select an option");
        } else {
            setError("");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, skipValidation]);

    const handleOptionChange = (optionValue: string) => {
        setSkipValidation(false);
        onAnswer(optionValue);
    };

    return (
        <div className="builder__select-many-icons-question">
            <Typography.Text className="builder__form-question">
                {data.question}
            </Typography.Text>
            <div className="builder__icons-option-container">
                {data.options?.map(option => (
                    <div key={option.id} className="builder__icons-option">
                        <div
                            className={`builder__icons-option-content ${
                                value === option.value ? "selected" : ""
                            }`}
                            onClick={() => handleOptionChange(option.value)}
                        >
                            <img
                                className="builder__option-checkbox"
                                src={
                                    value === option.value
                                        ? selectedIcon
                                        : unSelectedIcon
                                }
                                alt="checkbox-icon"
                            />
                            <div className="builder__option-icon-wrapper">
                                <img
                                    className="builder__option-icon"
                                    src={baseUrl + option.url}
                                    alt="option-icon"
                                />
                                <div
                                    className="builder__icons-option-text"
                                    style={{
                                        fontSize: `${
                                            /^\d{1,4}$/.test(option.value)
                                                ? "24px"
                                                : "14px"
                                        }`,
                                    }}
                                >
                                    {option.value}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                }}
            >
                <Typography.Text className="builder__help-text">
                    {data.helpText}
                </Typography.Text>
                {error && (
                    <Typography.Text className="builder__form-error">
                        {error}
                    </Typography.Text>
                )}
            </div>
        </div>
    );
};

export default SelectOneIconsQuestion;
