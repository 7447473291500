import React from "react";

import { DEFAULT_REGIONS_DICT } from "../../../../../constants/regions";
import { IQuestionnaire } from "../../../../../types/questionnaires";

const QuestionnaireItem = ({
    disabled,
    questionnaire,
    selectedQuestionnaireId,
    setSelectedQuestionnaireId,
}: {
    disabled: boolean;
	questionnaire: IQuestionnaire,
	selectedQuestionnaireId?: string;
	setSelectedQuestionnaireId: (_questionnaireId: string) => void;
}) => {
    return <div
        className={`mr20 ${disabled ? "grey-1-background-color" : "pointer"} center ${selectedQuestionnaireId === questionnaire.id ? "selected-right-menu-item" : "right-menu-item"}`}
        onClick={() => {
            if (!disabled) {
                setSelectedQuestionnaireId(questionnaire.id);
            }
        }}
    >
        <div>
            <img
                src={DEFAULT_REGIONS_DICT[questionnaire.region].image}
                width={16}
                height={16}
                alt={"region"}
                style={{
                    marginTop: 6,
                    borderRadius: "100%",
                }}
            />
        </div>
        <div className={"ml4 grey-7-color fs16"}>
            {DEFAULT_REGIONS_DICT[questionnaire.region].shortName}
        </div>
    </div>;
};

export default QuestionnaireItem;
