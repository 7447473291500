import { useContext, useEffect, useMemo, useRef, useState } from "react";

import { Button, Divider, message } from "antd";
import moment from "moment";

import SubmissionDetailsGroup from "./Table/SubmissionDetailsGroup";
import SubmissionTable from "./Table/SubmissionTable";
import { useSessionStorage } from "../../../../../../hooks/helpers/useSessionStorage";
import {
    IUpdateDocumentStatusByIdData, IUpdateDocumentStatusByIdResponse,
    IUpdateSubmissionDetailsData,
} from "../../../../../../types/documents";
import { deepEqual } from "../../../../../../utils/data";
import {
    initializeSubmissionDetails,
} from "../../../../../../utils/documents";
import { CaseContext } from "../../../Case";

const MAIN_COLUMN_WIDTH_PERCENTAGE = 40;

const SubmissionDetails = ({
    years,
    updateSubmissionDetails,
    updateDocumentStatus,
}: {
    years: string[];
    updateSubmissionDetails: (_documentsData: IUpdateSubmissionDetailsData) => void;
    updateDocumentStatus: (_statusData: IUpdateDocumentStatusByIdData) => Promise<IUpdateDocumentStatusByIdResponse>;
}) => {
    const {
        document,
    } = useContext(CaseContext);

    const [hasUnsavedData, setHasUnsavedData] = useState(false);

    const columnWidth = useMemo(() => (100 - MAIN_COLUMN_WIDTH_PERCENTAGE) / years.length, [years]);

    const [documentsSubmissionData, setDocumentsSubmissionData] =
        useSessionStorage<IUpdateSubmissionDetailsData>(
            `${document?._id}-documentsSubmissionData`,
            { submissions: [] }
        );

    const firstRender = useRef(false);

    useEffect(() => {
        if (document) {
            const {
                submissionDetails: {
                    submissions,
                },
            } = document;

            const dbData = initializeSubmissionDetails(submissions, years);

            if (!documentsSubmissionData.submissions.length) {
                setDocumentsSubmissionData({ submissions: dbData });
            } else {
                const currentData = documentsSubmissionData.submissions;

                if (!firstRender.current) {
                    firstRender.current = true;
                    setDocumentsSubmissionData({ submissions: currentData });
                    return;
                }

                const hasUnsavedData = !deepEqual(currentData, dbData);
                setHasUnsavedData(hasUnsavedData);
            }
        }
    }, [years, setDocumentsSubmissionData, document, documentsSubmissionData.submissions]);

    return <div>
        <div
            style={{ height: 50 }}
            className={"horizontal-left"}
        >
            <div className={"h3"}>
	            Submission Details
            </div>
        </div>

        <Divider type={"horizontal"}/>

        <SubmissionTable
            document={document}
            years={years}
            sizes={{
                main: MAIN_COLUMN_WIDTH_PERCENTAGE,
                column: columnWidth,
            }}

        />

        <div className={"mt20"}>
            <SubmissionDetailsGroup
                header={{
                    id: "submissions",
                    title: "Submissions",
                    columns: years,
                }}
                sizes={{
                    main: MAIN_COLUMN_WIDTH_PERCENTAGE,
                    column: columnWidth,
                }}
                rows={[{
                    id: "method",
                    type: "method",
                    title: "Method",
                    columns: documentsSubmissionData.submissions,
                }, {
                    id: "reference",
                    type: "reference",
                    title: "Reference",
                    columns: documentsSubmissionData.submissions,
                }]}
                onChange={(year: string, type: string, value: string) => {
                    setDocumentsSubmissionData(prevState => {
                        return {
                            submissions: prevState.submissions.map(item => {
                                if (item.year !== year) return item;

                                return {
                                    ...item,
                                    [type]: value,
                                };
                            }),
                        };
                    });
                }}
            />
        </div>

        <div className={"mt20 space-between"}>
            <div>
                <Button
                    type={"primary"}
                    className={"danger-button"}
                    style={{ width: 200, height: 36 }}
                    onClick={() => {
                        updateDocumentStatus({
                            statusId: "nothing-to-submit",
                        }).then(async () => {
                            await message.info("Client's status has been successfully changed to 'Nothing to Submit'");
                        }).catch(() => null);
                    }}
                >
                    Nothing to Submit (N/A)
                </Button>
            </div>
            <div className={"horizontal-right"}>
                {hasUnsavedData && <div className={"mh20 red-color"}>
                    Unsaved data
                </div>}
                {document?.submissionDetails?.lastSaved && <div className={"mr20 grey-6-color"}>
                    Last saved {moment(document?.submissionDetails?.lastSaved).format("DD MMM YYYY HH:mm")}
                </div>}
                <div>
                    <Button
                        type={"primary"}
                        className={"primary-button"}
                        style={{ width: 200, height: 36 }}
                        onClick={() => {
                            updateSubmissionDetails(documentsSubmissionData);
                        }}
                    >
                        Save
                    </Button>
                </div>
            </div>
        </div>

    </div>;
};

export default SubmissionDetails;
