import { memo, useCallback, useState } from "react";

import {
    Divider,
    Typography,
    Box,
    TextField,
    Button,
    Modal,
} from "@mui/material";
import { Handle, Position } from "reactflow";

import { useModalOpener } from "../../../../../../../hooks/helpers/useModalOpener";
import { createChangeHandler } from "../../../../../../../utils/constructor";
import useStore from "../store";

const ForbiddenMessageNode = (props: any) => {
    const updateNodeData = useStore(state => state.updateNodeData);

    const {
        isOpened: isEditing,
        open: openEditModal,
        close: closeModal,
    } = useModalOpener();

    const [editedMessage, setEditedMessage] = useState(props.data.message);

    const saveChanges = useCallback(() => {
        updateNodeData(props.id, { message: editedMessage });
        closeModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateNodeData, editedMessage]);

    const handleMessageChange = createChangeHandler(setEditedMessage);

    return (
        <div className="question-node">
            <Handle
                type="target"
                position={Position.Top}
                className="handle-depend"
                id="depend"
            />
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <Typography ml={2} component="div" sx={{ fontSize: "8px" }}>
                    Forbidden Message
                </Typography>
                <Box sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}>
                    {props?.data?.bindTo?.label && (
                        <Typography
                            component="div"
                            color={"#2ebb49"}
                            sx={{ fontSize: "8px", margin: "0px 10px" }}
                        >
                            Affect to: "{props.data.bindTo.label}"
                        </Typography>
                    )}
                </Box>
            </Box>
            <Divider />
            <Box className="question-label-wrapper">
                <Typography
                    ml={1}
                    component="div"
                    sx={{
                        fontSize: "10px",
                        maxWidth: "250px",
                    }}
                >
                    {props.data.message}
                </Typography>
                <Button
                    variant="outlined"
                    onClick={openEditModal}
                    className="button-edit"
                >
                    Edit
                </Button>
            </Box>
            {isEditing && (
                <Modal
                    open={isEditing}
                    onClose={saveChanges}
                    className="modal-wrapper"
                    slotProps={{
                        backdrop: {
                            sx: {
                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                            },
                        },
                    }}
                >
                    <Box className="box-wrapper">
                        <TextField
                            margin="normal"
                            id="text"
                            name="text"
                            value={editedMessage}
                            onChange={handleMessageChange}
                            variant="outlined"
                            fullWidth
                            label="Forbidden Message"
                        />
                        <Box className="button-group">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={saveChanges}
                            >
                                Save
                            </Button>
                        </Box>
                    </Box>
                </Modal>
            )}
        </div>
    );
};

export default memo(ForbiddenMessageNode);
