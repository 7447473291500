const Header = ({
    label,
}: {
	label: string
}) => {
    return <div className={"fs16 purple-color horizontal-left"}>
	    {label}
    </div>;
};

export default Header;
