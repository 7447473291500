import React, { useMemo } from "react";

import { Avatar, Dropdown } from "antd";

import ie from "./../../../assets/images/regions/ireland_24x24.png";
import { useUser } from "../../../hooks/auth/useUser";
import { useModalOpener } from "../../../hooks/helpers/useModalOpener";
import { useAppDispatch } from "../../../redux/hooks";
import { logout } from "../../../redux/reducers/auth";
import { getUserInitials } from "../../../utils/strings";
import CountrySelect from "../../modals/CountrySelect/CountrySelect";

const PageHeader = ({
    menu = [],
    rightMenu = [],
}: {
    menu?: React.ReactNode[];
    rightMenu?: React.ReactNode[];
}) => {
    const dispatch = useAppDispatch();

    const { user } = useUser();

    const {
        isOpened: isCountrySelectorOpened,
        open: handleOpenCountrySelect,
        close: handleCloseCountrySelect,
    } = useModalOpener();

    const userInitials = useMemo(() => {
        return getUserInitials(user.info.name);
    }, [user.info.name]);

    return <div
        data-testid="header"
        className={"page-header"}
    >
        <div
            className={"page-header-block space-between white-background-color"}
        >
            <div className={"horizontal-left"}>
                {menu}
            </div>

            <div className={"horizontal-right"}>
                {rightMenu && <div className={"vertical-center mr20"}>
                    {rightMenu}
                </div>}

                <div>
                    <img
                        className={"flag pointer mt10"}
                        src={ie}
                        alt={"ireland"}
                        width={24}
                        height={24}
                        onClick={handleOpenCountrySelect}
                    />
                </div>

                <div
                    data-testid="top-right-dropdown"
                    className={"pointer mr20 ml20"}
                >
                    <Dropdown
                        overlayStyle={{
                            paddingTop: 6,
                        }}
                        menu={{
                            items: [{
                                label: <div
                                    data-testid={"logout"}
                                    className={"bold"}
                                    onClick={() => {
                                        dispatch(logout());
                                    }}
                                >
                                    Sign out
                                </div>,
                                key: "0",
                            }],
                        }}
                        trigger={["click"]}
                    >
                        <div
                            className={"horizontal-right"}
                        >
                            <div>
                                <Avatar
                                    size="large"
                                >
                                    {userInitials}
                                </Avatar>
                            </div>
                            <div className={"ml20"}>
                                <div
                                    className={"bold horizontal-right"}
                                >
                                    {user.info.name}
                                </div>

                                <div
                                    className={"mt4 fs12 horizontal-right"}
                                >
                                    {user.role.info.name}
                                </div>
                            </div>
                        </div>
                    </Dropdown>
                </div>
            </div>
        </div>

        <CountrySelect
            isCountrySelectorOpened={isCountrySelectorOpened}
            handleClose={handleCloseCountrySelect}
        />
    </div>;
};

export default PageHeader;
