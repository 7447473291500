import { Checkbox } from "antd";

const PasswordGeneratorCheckbox = ({
    generatePassword,
    resetGeneratedPassword,
}: {
    generatePassword: () => void;
    resetGeneratedPassword: () => void;
}) => {
    return <div>
        <Checkbox
            className={"vertical-center"}
            onChange={e => {
                if (e.target.checked) {
                    return generatePassword();
                }

                return resetGeneratedPassword();
            }}>
            <div>
                Generate password
            </div>
        </Checkbox>
    </div>;
};

export default PasswordGeneratorCheckbox;
