import React, { useContext } from "react";

import { Col, Row } from "antd";

import PaymentDetails from "./components/PaymentDetails";
import RebateDetails from "./components/RebateDetails";
import { IUpdateClientPaymentDetailsByIdBody } from "../../../../../types/clients";
import { IUpdateDocumentRefundData } from "../../../../../types/documents";
import Spinner from "../../../../basic/Spinner/Spinner";
import { CaseContext } from "../../Case";

const Payment = ({
    updateClientPaymentDetailsById,
    updateDocumentRefund,
}: {
    updateClientPaymentDetailsById: (_data: IUpdateClientPaymentDetailsByIdBody) => void;
    updateDocumentRefund: (_data: IUpdateDocumentRefundData) => void;
}) => {
    const {
        document,
        isLoading,
    } = useContext(CaseContext);

    return <div>
        <Spinner isLoading={isLoading}>
            <div className={"p20"}>
                {document && <Row wrap={false} gutter={32}>
                    <Col span={12}>
                        <PaymentDetails
                            document={document}
                            updateClientPaymentDetailsById={updateClientPaymentDetailsById}
                        />
                    </Col>
                    <Col span={12}>
                        <RebateDetails
                            document={document}
                            updateDocumentRefund={updateDocumentRefund}
                        />
                    </Col>
                </Row>}
            </div>
        </Spinner>
    </div>;
};

export default Payment;
