import {
    IGetClientDocumentsBody,
    IGetClientDocumentsResponse,
} from "../../types/documents";
import { api } from "../connection/api";

const PARENT_ENDPOINT_PREFIX = "clients";
const ENDPOINT_PREFIX = "documents";

export const clientDocumentsApi = api.injectEndpoints({
    endpoints: build => ({
        getClientDocuments: build.query<IGetClientDocumentsResponse, IGetClientDocumentsBody>({
            query: data => ({
                url: `${PARENT_ENDPOINT_PREFIX}/${data.clientId}/${ENDPOINT_PREFIX}${data.current !== undefined ? `?current=${data.current}` : ""}`,
            }),
            providesTags: ["ClientDocuments"],
        }),
    }),

});

export const {
    useGetClientDocumentsQuery,
} = clientDocumentsApi;
