import { useMemo } from "react";

import { STATUSES_DICT } from "../../../constants/documents";
import { StatusType } from "../../../types/documents";

const Status = ({
    value,
}: {
	value: StatusType;
}) => {
    const statusData = useMemo(() => STATUSES_DICT[value], [value]);

    if (!statusData) return <div>Status not found</div>;

    return <div className={"horizontal-center"}>
        <div
            data-testid={`case-status-${statusData.id}`}
            className={"case-status center"}
            style={{
                color: statusData.color.font,
                backgroundColor: statusData.color.background,
            }}
        >
            {statusData.title}
        </div>
    </div>;
};

export default Status;
