import {
    ICreateClientLogBody,
    ICreateClientLogResponse,
    IGetClientLogsBody,
    IGetClientLogsResponse,
} from "../../types/client-logs";
import { getClientLogsQueryString } from "../../utils/queries";
import { api } from "../connection/api";

const PARENT_ENDPOINT_PREFIX = "clients";
const ENDPOINT_PREFIX = "logs";

export const clientLogsApi = api.injectEndpoints({
    endpoints: build => ({
        getClientLogs: build.query<IGetClientLogsResponse, IGetClientLogsBody>({
            query: data => ({
                url: `${PARENT_ENDPOINT_PREFIX}/${data.clientId}/${ENDPOINT_PREFIX}?${getClientLogsQueryString(data.query)}`,
            }),
            providesTags: ["ClientLogs"],
        }),
        createClientLog: build.mutation<ICreateClientLogResponse, ICreateClientLogBody>({
            query: data => ({
                url: `${PARENT_ENDPOINT_PREFIX}/${data.clientId}/${ENDPOINT_PREFIX}`,
                method: "POST",
                body: data.data,
            }),
            invalidatesTags: ["ClientLogs", "Documents"],
        }),
    }),

});

export const {
    useGetClientLogsQuery,
    useCreateClientLogMutation,
} = clientLogsApi;
