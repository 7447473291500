import { memo, useCallback, useMemo, useState, ChangeEvent } from "react";

import {
    Divider,
    Typography,
    Box,
    TextField,
    Button,
    Modal,
    TextareaAutosize,
    Checkbox,
    ListItemButton,
    Tooltip,
    MenuItem,
} from "@mui/material";
import { Handle, Position } from "reactflow";

import checkboxExample from "../../../../../../../assets/images/form/checkbox-many-example.png";
import tabExample from "../../../../../../../assets/images/form/tab-many-example.png";
import { SELECT_MANY_TYPE } from "../../../../../../../constants/constructor";
import { useModalOpener } from "../../../../../../../hooks/helpers/useModalOpener";
import { Option, QuestionType } from "../../../../../../../types/questionnaires";
import useStore from "../store";

const SelectManyQuestionNode = (props: any) => {
    const updateNodeData = useStore(state => state.updateNodeData);
    const nodes = useStore(state => state.nodes);
    const currentNode = useMemo(() => nodes.find(n => n.id === props.id), [nodes, props.id]);
    const parentType = useMemo(() => (currentNode?.parentNode?.split("_")[0]), [currentNode]);

    const {
        isOpened: isEditing,
        open: openEditModal,
        close: closeModal,
    } = useModalOpener();

    const [editedQuestion, setEditedQuestion] = useState(props.data.question);
    const [editedArrayLimit, setEditedArrayLimit] = useState(props.data.arrayLimit);
    const [editedOptions, setEditedOptions] = useState<Option[]>(
        props.data.options || []
    );
    const [editedRequired, setEditedRequired] = useState(!!props.data.required);
    const [editedType, setEditedType] = useState(props.data.selectType || "");

    const saveChanges = useCallback(() => {
        updateNodeData(props.id, {
            question: editedQuestion,
            options: editedOptions,
            required: editedRequired,
            selectType: editedType,
            arrayLimit: editedArrayLimit,
        });
        closeModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateNodeData, editedQuestion, editedOptions, editedRequired, editedType, editedArrayLimit]);

    const onOptionsChange = useCallback(
        ({ target }: any) => {
            const options = target.value
                .split("\n")
                .map((value: string, index: number) => ({
                    id: `option-${index}`,
                    value,
                }));
            setEditedOptions(options);
        },
        [setEditedOptions]
    );

    const onChange = useCallback(
        ({ target }: any) => {
            setEditedQuestion(target.value);
        },
        [setEditedQuestion]
    );

    const handleRequiredChange = useCallback(
        ({ target }: any) => {
            setEditedRequired(target.checked);
        },
        [setEditedRequired]
    );

    const handleArrayLimitChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const value = event.target.value === "" ? 0 : Number(event.target.value);
            setEditedArrayLimit(value);
        },
        [setEditedArrayLimit]
    );

    return (
        <div className="question-node">
            <Handle
                type="target"
                position={Position.Top}
                className="handle-depend"
                style={{ left: 10 }}
                id="depend"
            />
            <Handle
                type="target"
                position={Position.Top}
                className="handle-depend"
                style={{ left: 30 }}
                id="depend_and"
            />
            <Handle type="target" position={Position.Top} id="to" />
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <Typography ml={2} component="div" sx={{ fontSize: "8px" }}>
                    "Select many" question
                </Typography>
                <Box sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}>
                    {editedType && (
                        <Typography
                            component="div"
                            color={"#2ebb49"}
                            sx={{ fontSize: "6px", margin: "0px 10px" }}
                        >
                            Options type: "{editedType}"
                        </Typography>
                    )}
                    {editedRequired && (
                        <Typography
                            component="div"
                            color={"red"}
                            sx={{ fontSize: "6px", margin: "0px 10px" }}
                        >
                            Required
                        </Typography>
                    )}
                </Box>
            </Box>
            <Divider />
            <Box ml={1} className="question-label-wrapper">
                <Typography
                    component="div"
                    sx={{
                        fontSize: "10px",
                        maxWidth: "1000px",
                    }}
                >
                    {props.data.question}
                </Typography>
                <Button
                    variant="outlined"
                    onClick={openEditModal}
                    className="button-edit"
                >
                    Edit
                </Button>
            </Box>
            <Box ml={1}>
                {editedOptions.map((option: Option, index: number) => (
                    <div key={option.id}>
                        <Divider />
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                gap: "2px",
                            }}
                        >
                            <Typography
                                component="div"
                                sx={{
                                    fontSize: "10px",
                                    maxWidth: "1200px",
                                }}
                            >
                                {option.value}
                            </Typography>
                            <Handle
                                type="source"
                                position={Position.Right}
                                className="handle-select-depend"
                                style={{
                                    top: `${30 + (index + 1) * 16.1}px`,
                                }}
                                id={`dependency_${option.value}`}
                            />
                        </Box>
                    </div>
                ))}
            </Box>

            <Handle type="source" position={Position.Bottom} id="from" />
            {isEditing && (
                <Modal
                    open={isEditing}
                    onClose={saveChanges}
                    className="modal-wrapper"
                    slotProps={{
                        backdrop: {
                            sx: {
                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                            },
                        },
                    }}
                >
                    <Box className="box-wrapper">
                        <Box
                            sx={{
                                display: "flex",
                                width: "100%",
                                marginBottom: "10px",
                            }}
                        >
                            <Typography component="div">Required</Typography>
                            <Checkbox
                                style={{
                                    padding: "0",
                                }}
                                checked={editedRequired}
                                onChange={handleRequiredChange}
                                color="primary"
                                name="requiredCheckbox"
                            />
                        </Box>
                        <TextField
                            margin="normal"
                            id="select-type"
                            select
                            label="Select Type"
                            value={editedType}
                            onChange={(event: any) => setEditedType(event.target.value)}
                            SelectProps={{
                                renderValue: () => editedType.charAt(0).toUpperCase() + editedType.slice(1),
                            }}
                            variant="outlined"
                            fullWidth
                        >
                            <MenuItem value={SELECT_MANY_TYPE.CHECKBOXES} style={{ padding: "0" }}>
                                <Tooltip followCursor arrow title={<img src={checkboxExample} alt="Dropdown Example" style={{ width: "300px", height: "auto" }} />}>
                                    <ListItemButton>
                                        Checkboxes
                                    </ListItemButton>
                                </Tooltip>
                            </MenuItem>
                            <MenuItem value={SELECT_MANY_TYPE.TABS} style={{ padding: "0" }}>
                                <Tooltip followCursor arrow title={<img src={tabExample} alt="Tabs Example" style={{ width: "300px", height: "auto" }} />}>
                                    <ListItemButton>
                                        Tabs
                                    </ListItemButton>
                                </Tooltip>
                            </MenuItem>
                        </TextField>
                        <TextField
                            margin="normal"
                            id="text"
                            name="text"
                            value={editedQuestion}
                            onChange={onChange}
                            variant="outlined"
                            fullWidth
                            label="Question"
                        />
                        <Box sx={{ width: "100%" }}>
                            <Typography
                                component="div"
                                className="select-one-options-label"
                            >
                                Options (one option per line)
                            </Typography>

                            <TextareaAutosize
                                value={editedOptions
                                    .map(option => option.value)
                                    .join("\n")}
                                onChange={onOptionsChange}
                                minRows={10}
                                placeholder="Enter options (one option per line)"
                                className="select-one-textarea"
                            />
                        </Box>
                        {parentType === QuestionType.ArrayGroup &&
                            <Box mt={1} sx={{ width: "100%" }}>
                                <TextField
                                    type="number"
                                    label="Array Option Limit"
                                    value={editedArrayLimit}
                                    onChange={handleArrayLimitChange}
                                    variant="outlined"
                                    fullWidth
                                />
                            </Box>
                        }
                        <Box className="button-group">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={saveChanges}
                            >
                                Save
                            </Button>
                        </Box>
                    </Box>
                </Modal>
            )}
        </div>
    );
};

export default memo(SelectManyQuestionNode);
