import { Form, Select } from "antd";
import { SizeType } from "antd/es/config-provider/SizeContext";

import { LOG_VARIATIONS } from "../../../../constants/client-logs";

const LevelSelector = ({
    size = "large",
    required = false,
    name = "variation",
    errorMessage = "Please set value!",
    placeholder = "Select event level...",
}: {
    size?: SizeType;
    required?: boolean;
    name?: string;
    errorMessage?: string;
    placeholder?: string;
}) => {
    return <div>
        <Form.Item
            name={name}
            rules={[{
                required,
                message: errorMessage,
            }]}
        >
            <Select
                size={size}
                className={"select-content grey-color"}
                style={{ width: "100%" }}
                placeholder={placeholder}
            >
                {LOG_VARIATIONS.map(variation => {
                    return <Select.Option
                        key={variation.id}
                        value={variation.id}
                        className={"select-option"}
                        labelInValue
                    >
                        {variation.title}
                    </Select.Option>;
                })}
            </Select>
        </Form.Item>
    </div>;
};

export default LevelSelector;
