import { api } from "./connection/api";
import {
    ICreateUserBody,
    ICreateUserResponse, IDeleteUserBody, IDeleteUserResponse,
    IGetUsersQuery,
    IGetUsersResponse,
    IUpdateUserBody,
    IUpdateUserResponse,
} from "../types/users";
import { getUsersQueryString } from "../utils/queries";

const ENDPOINT_PREFIX = "users";

export const usersApi = api.injectEndpoints({
    endpoints: build => ({
        getUsers: build.query<IGetUsersResponse, IGetUsersQuery>({
            query: query => ({
                url: `${ENDPOINT_PREFIX}?${getUsersQueryString(query)}`,
            }),
            providesTags: (result, _error, _arg) =>
                (result
                    ? [...result.users.map(({ id }) => ({ type: "Users" as const, id })), "Users"]
                    : ["Users"]),
        }),
        createUser: build.mutation<ICreateUserResponse, ICreateUserBody>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/`,
                method: "POST",
                body: data.data,
            }),
            invalidatesTags: ["Users"],
        }),
        updateUser: build.mutation<IUpdateUserResponse, IUpdateUserBody>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/${data.id}`,
                method: "PUT",
                body: data.data,
            }),
            invalidatesTags: (_result, _error, arg) => [{ type: "Users", id: arg.id }],
        }),
        deleteUser: build.mutation<IDeleteUserResponse, IDeleteUserBody>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/${data.id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Users"],
        }),
    }),

});

export const {
    useGetUsersQuery,
    useCreateUserMutation,
    useUpdateUserMutation,
    useDeleteUserMutation,
} = usersApi;
