const Cell = ({
    value,
}: {
	value: string;
}) => {
    return <div className={"center"}>
        {value || "-"}
    </div>;
};

export default Cell;
