import React from "react";

import "../../../../../../../styles/button-footer.less";
import { useAppSize } from "../../../../../../../hooks/helpers/useAppSize";

interface ButtonFooterProps {
	buttonLabel: string;
	isButtonDisabled?: boolean;
	handleNextButton?: () => void;
}

const ButtonFooter: React.FC<ButtonFooterProps> = ({
	                                                   buttonLabel,
	                                                   isButtonDisabled,
	                                                   handleNextButton,
}) => {
    const isMobile = useAppSize();

    return isMobile ? (
        <div className="button-footer">
            <button
                className="button-footer-start-button"
                style={{ fontWeight: "500", fontSize: "14px" }}
                disabled={isButtonDisabled}
                onClick={handleNextButton}
            >
                {buttonLabel}
            </button>
        </div>
    ) : (
        null
    );
};

export default ButtonFooter;
