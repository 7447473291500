import moment from "moment";

const Date = ({
    value,
}: {
	value: string;
}) => {
    return <div>
        {moment(value).format("DD/MM/YY - HH:mm")}
    </div>;
};

export default Date;
