import { api } from "./connection/api";
import { IGetActualYearsResponse } from "../types/actual-years";

const ENDPOINT_PREFIX = "years";

export const actualYearsApi = api.injectEndpoints({
    endpoints: build => ({
        getActualYears: build.query<IGetActualYearsResponse, void>({
            query: () => ({
                url: `${ENDPOINT_PREFIX}/actual-years-list`,
            }),
            providesTags: ["ActualYears"],
        }),
    }),

});

export const {
    useGetActualYearsQuery,
} = actualYearsApi;
