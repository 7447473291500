export const SELECT_ONE_TYPE = {
    DROPDOWN: "dropdown",
    TABS: "tabs",
};

export const SELECT_MANY_TYPE = {
    CHECKBOXES: "checkboxes",
    TABS: "tabs",
};

export const TEXT_TYPE = {
    INPUT: "input",
    TEXT_AREA: "text-area",
};

export const INFO_BLOCK = {
    TEXT: "text",
    TITLE: "title",
};
