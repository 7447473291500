import React, { ReactNode } from "react";

import { Col, Form, Input, Row } from "antd";
import { Rule } from "rc-field-form/lib/interface";

const InfoItem = ({
    id,
    name,
    isEditing = false,
    title,
    value,
    rules,
}: {
    id?: string;
    name?: string;
    isEditing?: boolean;
	title: string;
	value: string | number | null | ReactNode;
    rules?: Rule[]
}) => {
    return <div className={"horizontal-left"}>
        <Row
	        style={{ width: "100%" }}
            className={"vertical-center"}
        >
            <Col span={12}>
                <div className={"purple-color fs12"} style={{ marginTop: isEditing ? -20 : 0 }}>
                    {title}
                </div>
            </Col>
            <Col span={12}>
                {isEditing ? <div style={{ marginTop: isEditing ? -20 : 0 }}>
                    <Form.Item
                        name={name}
                        rules={rules}
                    >
                        <Input
                            className={"grey-7-color"}
                            size={"small"}
                        />
                    </Form.Item>
                </div> : <div
                    data-testid={id ? `${id}-value` : undefined}
                    className={"horizontal-left grey-7-color fs12"}
                >
                    {value || "-"}
                </div>}
            </Col>
        </Row>
    </div>;
};

export default InfoItem;
