import React, { useMemo } from "react";

import moment from "moment";
import { MdEdit } from "react-icons/md";

import InfoHeader from "./InfoHeader";
import InfoItem from "./InfoItem";
import { useModalOpener } from "../../../../../../hooks/helpers/useModalOpener";
import { IDocument, IUpdateDocumentRefundData } from "../../../../../../types/documents";
import { ITransaction } from "../../../../../../types/transactions";
import EditCustomFeeRateModal from "../../../../../modals/Cases/EditCustomFeeRateModal";

const dateFormat = "DD-MM-YYYY HH:mm:ss";

const RebateDetails = ({
    document,
    updateDocumentRefund,
}: {
	document: IDocument,
	updateDocumentRefund: (_data: IUpdateDocumentRefundData) => void;
}) => {
    const {
        outgoingTransactions,
	    incomingTransactions,
	    lastOutgoing,
    } = useMemo(() => {
        return document.transactions.reduce((acc, transaction) => {
            if (transaction.status !== "completed") return acc;

            if (transaction.variation === "incoming") {
                acc.incomingTransactions.push(transaction);
            } else if (transaction.variation === "outgoing") {
                acc.outgoingTransactions.push(transaction);
                acc.lastOutgoing = transaction;
            }

            return acc;
        }, {
            outgoingTransactions: [],
            incomingTransactions: [],
	        lastOutgoing: undefined,
        } as {
	        outgoingTransactions: ITransaction[],
	        incomingTransactions: ITransaction[],
	        lastOutgoing?: ITransaction,
        });
    }, [document]);

    const {
        isOpened: editCustomFeeRateModalIsOpened,
        open: openEditCustomFeeRateModal,
        close: closeEditCustomFeeRateModal,
    } = useModalOpener();

    return <div>
	    <div
		    style={{ height: 50 }}
		    className={"horizontal-left"}
	    >
            <div className={"h3"}>
	            Rebate Details
            </div>
        </div>

	    <div className={"mt30"}>
		    <InfoHeader title={"Rebate from Revenue"}/>
	    </div>

	    {incomingTransactions.map(transaction => {
            return <div
	            key={transaction._id}
	            className={"mt10"}
            >
	            <InfoItem
		            title={`Transaction (${moment(transaction.completedAt).format(dateFormat)})`}
		            value={`€${transaction.amount || 0}`}
	            />
            </div>;
	    })}

	    <div className={"mt30"}>
		    <InfoHeader
			    title={"Total Rebate from Revenue"}
			    icon={<MdEdit
				    className={"pointer fs18 primary-color"}
			        onClick={() => openEditCustomFeeRateModal()}
			    />}
		    />
	    </div>

	    <div
		    className={"mt10"}
	    >
		    <InfoItem
			    id={"fee"}
			    title={"TaxZap Fee"}
			    value={`€${document.refund?.fee || 0}`}
		    />
	    </div>

	    <div
		    className={"mt10"}
	    >
		    <InfoItem
			    id={"vat"}
			    title={"VAT (23%)"}
			    value={`€${document.refund?.vat || 0}`}
		    />
	    </div>

	    <div className={"mt30"}>
		    <InfoHeader title={"Net Rebate Transferred to Client"}/>
	    </div>

	    {outgoingTransactions.map(transaction => {
		    return <div
			    key={transaction._id}
			    className={"mt10"}
		    >
			    <InfoItem
				    title={`Transaction (${moment(transaction.completedAt).format(dateFormat)})`}
				    value={`€${transaction.amount || 0}`}
			    />
		    </div>;
	    })}

	    {lastOutgoing && <div className={"mt30"}>
		    <InfoHeader
			    title={`Last Outgoing Transfer (${moment(lastOutgoing.completedAt).format(dateFormat)})`}
                value={`€${lastOutgoing.amount || 0}`}
		    />
	    </div>}

	    <div className={"mt30"}>
		    <InfoHeader
			    id={"amount"}
			    title={"Net amount due to Client"}
			    value={`€${document.refund?.amount || 0}`}
		    />
	    </div>

	    {editCustomFeeRateModalIsOpened && <EditCustomFeeRateModal
	        close={closeEditCustomFeeRateModal}
            currentCustomFeeRate={document.refund.customFeeRate}
            updateDocumentRefund={updateDocumentRefund}
	    />}

    </div>;
};

export default RebateDetails;
