import { memo, useCallback, useState } from "react";

import {
    Divider,
    Typography,
    Box,
    TextField,
    Button,
    Modal,
    ListItemButton,
    Tooltip,
    MenuItem,
    TextareaAutosize,
} from "@mui/material";
import { Handle, Position } from "reactflow";

import textInfoExample from "../../../../../../../assets/images/form/info-text-example.png";
import titleInfoExample from "../../../../../../../assets/images/form/info-title-example.png";
import { INFO_BLOCK } from "../../../../../../../constants/constructor";
import { useModalOpener } from "../../../../../../../hooks/helpers/useModalOpener";
import { createChangeHandler } from "../../../../../../../utils/constructor";
import useStore from "../store";

const InfoBlockNode = (props: any) => {
    const updateNodeData = useStore(state => state.updateNodeData);

    const {
        isOpened: isEditing,
        open: openEditModal,
        close: closeModal,
    } = useModalOpener();

    const [editedInfo, setEditedInfo] = useState(props.data.label);
    const [editedType, setEditedType] = useState(props.data.selectType || "");

    const saveChanges = useCallback(() => {
        updateNodeData(props.id, {
            label: editedInfo,
            selectType: editedType,
        });
        closeModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateNodeData, editedInfo, editedType]);

    const handleInfoChange = createChangeHandler(setEditedInfo);
    const handleTypeChange = createChangeHandler(setEditedType);

    return (
        <div className="question-node">
            <Handle
                type="target"
                position={Position.Top}
                className="handle-depend"
                style={{ left: 10 }}
                id="depend"
            />
            <Handle
                type="target"
                position={Position.Top}
                className="handle-depend"
                style={{ left: 30 }}
                id="depend_and"
            />
            <Handle type="target" position={Position.Top} id="to" />
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <Typography ml={2} component="div" sx={{ fontSize: "8px" }}>
                    Info Block
                </Typography>
                {editedType && (
                    <Typography
                        component="div"
                        color={"#2ebb49"}
                        sx={{ fontSize: "6px", margin: "0px 10px" }}
                    >
                            Text type: "{editedType}"
                    </Typography>
                )}
            </Box>
            <Divider />
            <Box className="question-label-wrapper">
                <Typography
                    ml={1}
                    component="div"
                    sx={{
                        fontSize: "10px",
                        maxWidth: "250px",
                    }}
                >
                    {props.data.label}
                </Typography>
                <Button
                    variant="outlined"
                    onClick={openEditModal}
                    className="button-edit"
                >
                    Edit
                </Button>
            </Box>
            <Handle type="source" position={Position.Bottom} id="from" />
            {isEditing && (
                <Modal
                    open={isEditing}
                    onClose={saveChanges}
                    className="modal-wrapper"
                    slotProps={{
                        backdrop: {
                            sx: {
                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                            },
                        },
                    }}
                >
                    <Box className="box-wrapper">
                        <Box
                            sx={{
                                width: "100%",
                            }}
                        >
                            <Typography
                                component="div"
                                className="select-one-options-label"
                            >
                                Info block Text
                            </Typography>
                            <TextField
                                margin="normal"
                                id="select-type"
                                select
                                label="Select Type"
                                value={editedType}
                                onChange={handleTypeChange}
                                SelectProps={{
                                    renderValue: () => editedType.charAt(0).toUpperCase() + editedType.slice(1),
                                }}
                                variant="outlined"
                                fullWidth
                            >
                                <MenuItem value={INFO_BLOCK.TEXT} style={{ padding: "0" }}>
                                    <Tooltip followCursor arrow title={<img src={textInfoExample} alt="Text Input Example" style={{ width: "300px", height: "auto" }} />}>
                                        <ListItemButton>
                                            Text
                                        </ListItemButton>
                                    </Tooltip>
                                </MenuItem>
                                <MenuItem value={INFO_BLOCK.TITLE} style={{ padding: "0" }}>
                                    <Tooltip followCursor arrow title={<img src={titleInfoExample} alt="Text Area Example" style={{ width: "300px", height: "auto" }} />}>
                                        <ListItemButton>
                                            Title
                                        </ListItemButton>
                                    </Tooltip>
                                </MenuItem>
                            </TextField>
                            <TextareaAutosize
                                id="message"
                                value={editedInfo}
                                onChange={handleInfoChange}
                                minRows={10}
                                className="select-one-textarea"
                            />
                        </Box>
                        <Box className="button-group">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={saveChanges}
                            >
                                Save
                            </Button>
                        </Box>
                    </Box>
                </Modal>
            )}
        </div>
    );
};

export default memo(InfoBlockNode);
