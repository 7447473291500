import { memo, useCallback, useState } from "react";

import {
    Divider,
    Typography,
    Box,
    TextField,
    Button,
    Modal,
    TextareaAutosize,
} from "@mui/material";
import { Handle, Position } from "reactflow";

import { useModalOpener } from "../../../../../../../hooks/helpers/useModalOpener";
import { createChangeHandler } from "../../../../../../../utils/constructor";
import useStore from "../store";

const InfoBlockNode = (props: any) => {
    const updateNodeData = useStore(state => state.updateNodeData);

    const {
        isOpened: isEditing,
        open: openEditModal,
        close: closeModal,
    } = useModalOpener();

    const [editedLabel, setEditedLabel] = useState(props.data.label);
    const [editedTitle, setEditedTitle] = useState(props.data.title);
    const [editedMessage, setEditedMessage] = useState(props.data.message);

    const saveChanges = useCallback(() => {
        updateNodeData(props.id, {
            label: editedLabel,
            message: editedMessage,
            title: editedTitle,
        });
        closeModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateNodeData, editedLabel, editedMessage, editedTitle]);

    const handleMessageChange = createChangeHandler(setEditedMessage);
    const handleTitleChange = createChangeHandler(setEditedTitle);
    const handleLabelChange = createChangeHandler(setEditedLabel);

    return (
        <div className="question-node">
            <Handle
                type="target"
                position={Position.Top}
                className="handle-depend"
                style={{ left: 10 }}
                id="depend"
            />
            <Handle
                type="target"
                position={Position.Top}
                className="handle-depend"
                style={{ left: 30 }}
                id="depend_and"
            />
            <Handle type="target" position={Position.Top} id="to" />
            <Typography ml={2} component="div" sx={{ fontSize: "8px" }}>
                Info Click Block
            </Typography>
            <Divider />
            <Box className="question-label-wrapper">
                <Typography
                    ml={1}
                    component="div"
                    sx={{
                        fontSize: "12px",
                    }}
                >
                    Label: {props.data.label}
                </Typography>

                <Button
                    variant="outlined"
                    onClick={openEditModal}
                    className="button-edit"
                >
                    Edit
                </Button>
            </Box>
            <Typography
                ml={1}
                component="div"
                sx={{
                    fontSize: "10px",
                    maxWidth: "350px",
                }}
            >
                Title: {props.data.title}
            </Typography>
            <Typography
                ml={1}
                component="div"
                sx={{
                    fontSize: "10px",
                    maxWidth: "350px",
                }}
            >
                Message: {props.data.message}
            </Typography>
            <Handle type="source" position={Position.Bottom} id="from" />
            {isEditing && (
                <Modal
                    open={isEditing}
                    onClose={saveChanges}
                    className="modal-wrapper"
                    slotProps={{
                        backdrop: {
                            sx: {
                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                            },
                        },
                    }}
                >
                    <Box className="box-wrapper">
                        <TextField
                            margin="normal"
                            id="text"
                            name="text"
                            value={editedLabel}
                            onChange={handleLabelChange}
                            variant="outlined"
                            fullWidth
                            label="Info click block Label"
                        />
                        <TextField
                            margin="normal"
                            id="text"
                            name="text"
                            value={editedTitle}
                            onChange={handleTitleChange}
                            variant="outlined"
                            fullWidth
                            label="Modal title"
                        />
                        <Box
                            sx={{
                                width: "100%",
                            }}
                        >
                            <Typography
                                component="div"
                                className="select-one-options-label"
                            >
                                Modal message
                            </Typography>
                            <TextareaAutosize
                                id="message"
                                value={editedMessage}
                                onChange={handleMessageChange}
                                minRows={10}
                                className="select-one-textarea"
                            />
                        </Box>
                        <Box className="button-group">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={saveChanges}
                            >
                                Save
                            </Button>
                        </Box>
                    </Box>
                </Modal>
            )}
        </div>
    );
};

export default memo(InfoBlockNode);
