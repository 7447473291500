import { FC, useCallback, useEffect, useState } from "react";

import { Typography } from "antd";

import { ValidationType } from "./GroupItem";
import QuestionListItem from "./QuestionItem";
import { ChildrenT } from "../../../../../../../types/questionnaires";
import { getAnsweredRequiredQuestions, getTotalRequiredQuestions } from "../../../../../../../utils/builder";

type QuestionItem = Record<string, any>;

type YearsGroupComponentProps = {
    question: ChildrenT;
    // eslint-disable-next-line no-unused-vars
    onAnswer: (answer: any) => void;
    answers: QuestionItem;
    initialValue?: QuestionItem;
};

const YearsGroupComponent: FC<YearsGroupComponentProps> = ({
    question,
    onAnswer,
    answers,
    initialValue = {},
}) => {
    const { children, data } = question;
    const { yearsBind } = data;

    const [yearsAnswers, setYearsAnswer] = useState<Record<string, any>>(initialValue);
    const [finished, setFinished] = useState<boolean>(false);
    const [requiredGroupQuestions, setRequired] = useState<Record<string, any>>({});

    const handleAnswer = useCallback(
        (questionId: string, answer: any) => {
            const year = yearsBind && Object.keys(yearsBind).find(year => yearsBind[year] === questionId);

            if (year) {
                setYearsAnswer(prevAnswers => ({
                    ...prevAnswers,
                    [year]: answer,
                }));
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [answers, yearsBind]
    );

    const handleQuestionRequired = (questionId: string, required: boolean, validation?: ValidationType) => {
        setRequired(prevRequired => ({
            ...prevRequired,
            [questionId]: {
                required,
                validation,
            },
        }));
    };

    const getMergedAnswers = useCallback(() => {
        if (!yearsBind) return answers;

        const mergedAnswers = { ...answers };
        Object.keys(yearsBind).forEach(year => {
            const questionId = yearsBind[year];
            const yearAnswer = yearsAnswers[year];
            if (yearAnswer !== undefined) {
                mergedAnswers[questionId] = yearAnswer;
            }
        });

        return mergedAnswers;
    }, [answers, yearsBind, yearsAnswers]);

    useEffect(() => {
        const totalRequiredQuestions = getTotalRequiredQuestions(requiredGroupQuestions);

        const mergedAnswersForRequiredQuestions = getMergedAnswers();

        const answeredRequiredQuestions = getAnsweredRequiredQuestions(
            requiredGroupQuestions,
            mergedAnswersForRequiredQuestions
        );

        let progress = 0;

        if (totalRequiredQuestions === 0) {
            progress = 100;
        } else {
            progress = Math.floor(
                (answeredRequiredQuestions / totalRequiredQuestions) * 100
            );
        }

        setFinished(progress === 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requiredGroupQuestions, answers]);

    useEffect(() => {
        onAnswer(finished ? yearsAnswers : undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [yearsAnswers, finished]);

    return (
        <div className="builder__years-question">
            <Typography className="builder__form-question" style={{ marginBottom: "28px" }}>
                {data.question}
            </Typography>
            <div>
                {children?.map(question => {
                    const yearKey = yearsBind ? Object.keys(yearsBind).find(year => yearsBind[year] === question.id) : undefined;
                    const yearAnswer = yearKey ? yearsAnswers[yearKey] : undefined;

                    return (
                        <QuestionListItem
                            setRequired={handleQuestionRequired}
                            key={question.id}
                            question={question}
                            onAnswer={handleAnswer}
                            answers={{
                                ...answers,
                                [question.id]: yearAnswer,
                            }}
                        />
                    );
                })}
            </div>

        </div>
    );
};

export default YearsGroupComponent;
