import { convertArrayOfObjectsToDict } from "../utils/data";

export const BIC_PATTERN = /^[A-Za-z]{4}[A-Za-z]{2}[A-Za-z0-9]{2}([A-Za-z0-9]{3})?$/;

const greyStatus = {
    font: "#555",
    background: "#F6F6F6",
};

const greenStatus = {
    font: "#2EBB49",
    background: "#ECFFEC",
};

const redStatus = {
    font: "#EC0000",
    background: "#FFDFDF",
};

const orangeStatus = {
    font: "#DA6900",
    background: "#FFE6C8",
};

const blueStatus = {
    font: "#1253FA",
    background: "#EDF8FF",
};

export const TRANSACTION_STATUSES = [
    {
        id: "pending",
        title: "Pending",
        color: orangeStatus,
    } as const, {
        id: "completed",
        title: "Completed",
        color: greenStatus,
    } as const, {
        id: "reverted",
        title: "Reverted",
        color: blueStatus,
    } as const, {
        id: "failed",
        title: "Failed",
        color: redStatus,
    } as const, {
        id: "declined",
        title: "Declined",
        color: greyStatus,
    } as const,
] as const;

export const POSSIBLE_TRANSACTION_STATUSES = TRANSACTION_STATUSES.map(status => status.id);
export const TRANSACTION_STATUSES_DICT = convertArrayOfObjectsToDict(POSSIBLE_TRANSACTION_STATUSES, TRANSACTION_STATUSES);

export const TRANSACTION_VARIATIONS = [
    "outgoing",
    "incoming",
] as const;

export const TRANSACTION_TYPES = [{
    value: "all",
    label: "All Transactions",
}, {
    value: "unmatched",
    label: "Unmatched",
}, {
    value: "incoming",
    label: "Revenue (+)",
}, {
    value: "outgoing",
    label: "Taxzap (-)",
}];
