import React, { createContext, useContext, useEffect, useState } from "react";

import { Col, Form, Input, Row, DatePicker, Select } from "antd";
import dayjs from "dayjs";
import { AiOutlineEdit, AiOutlineSave } from "react-icons/ai";

import { SPOUSE_ASSESSMENT_STATUSES, SPOUSE_ASSESSMENT_TYPES } from "../../../constants/clients";
import { IClient, IUpdateClientByIdBody } from "../../../types/clients";
import EditButton from "../../inputs/Fields/EditButton/EditButton";

const InfoItemContext = createContext({
    isEditing: false,
});

const format = "YYYY-MM-DD";

const InfoItem = ({
    editable = true,
    name,
    title,
    value,
    type = "text",
    options = [],
}: {
	editable?: boolean;
	name?: string;
	title: string;
	value?: string | null;
	type: "text" | "date" | "select";
	options?: { value: string | null; label: string }[];
}) => {
    const { isEditing } = useContext(InfoItemContext);

    return <div className={"horizontal-left"}>
	    <Row
		    className={"vertical-center"}
		    style={{ width: 500 }}
	    >
		    <Col span={8}>
			    <div className={"primary-color"}>
				    {title}
			    </div>
		    </Col>
		    <Col span={16}>
			    {(editable && isEditing) ? <div>
				    <Form.Item
					    wrapperCol={{ flex: "0px" }}
					    style={{ marginTop: -8 }}
					    name={name}
				    >
					    {type === "text" && <Input
                            data-testid={name}
						    size={"small"}
						    className={"input-content grey-7-color"}
					    />}

					    {type === "date" && <DatePicker
                            data-testid={name}
						    size={"middle"}
						    style={{ width: "100%" }}
					    />}

					    {type === "select" && <Select
                            data-testid={name}
                            size={"small"}
                            style={{ width: "100%" }}
                            options={options}
                        />}
				    </Form.Item>
			    </div> : <div className={"grey-7-color"}>
				    {["text", "date"].includes(type) && (value || "-")}
				    {["select"].includes(type) && (options.find(option => option.value === value)?.label || "-")}
			    </div>}
		    </Col>
	    </Row>
    </div>;
};

const PersonalDetails = ({
    client,
    updateClientById,
}: {
	client: IClient,
	updateClientById: (_data: IUpdateClientByIdBody) => void;
}) => {
    const [isEditing, setIsEditing] = useState(false);

    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue({
            firstName: client.info.firstName,
            maidenName: client.info.maidenName,
            lastName: client.info.lastName,
            phone: client.info.phone,
            email: client.info.email,
            dateOfBirth: client.info.dateOfBirth ? dayjs(client.info.dateOfBirth, format) : null,
            street: client.address?.street,
            city: client.address?.city,
            county: client.address?.county,
            eircode: client.address?.eircode,
            ppsn: client.ppsn,
            ppsnSpouse: client.spouse.ppsn,
            assessmentType: client.spouse.assessmentType,
            assessmentStatus: client.spouse.assessmentStatus,
            emailSpouse: client.spouse.email,
            firstNameSpouse: client.spouse.firstName,
            lastNameSpouse: client.spouse.lastName,
	        spouseDateOfBirth: client.spouse.dateOfBirth ? dayjs(client.spouse.dateOfBirth, format) : null,
        });
    }, [client, form]);

    return <div>
		    <div
			    style={{ height: 50 }}
			    className={"horizontal-left"}
		    >
            <div className={"h3"}>
	            Personal Details
            </div>
            <div className={"ml20"}>
                <EditButton
                    isEditMode={isEditing}
                    start={{
                        icon: <AiOutlineEdit/>,
                        label: "Edit",
                        action: () => {
                            setIsEditing(true);
                        },
                    }}
                    finish={{
                        icon: <AiOutlineSave/>,
                        label: "Save",
                        action: () => {
	                        form.submit();
		                    setIsEditing(false);
                        },
                    }}
                />
            </div>
	    </div>

	    <div>
		    <Form
			    form={form}
			    layout="vertical"
			    name="userForm"
			    onFinish={values => {
                    updateClientById({
	                    clientId: client.id,
	                    data: {
                            info: {
                                firstName: values.firstName,
                                maidenName: values.maidenName,
                                lastName: values.lastName,
                                phone: values.phone,
                                email: values.email,
	                            dateOfBirth: values.dateOfBirth?.format(format),
                            },
                            address: {
                                street: values.street,
                                city: values.city,
                                county: values.county,
                                eircode: values.eircode,
                            },
		                    ppsn: values.ppsn,
		                    spouse: {
                                ppsn: values.ppsnSpouse,
                                email: values.emailSpouse,
			                    assessmentType: values.assessmentType,
			                    assessmentStatus: values.assessmentStatus,
			                    firstName: values.firstNameSpouse,
			                    lastName: values.lastNameSpouse,
			                    dateOfBirth: values.spouseDateOfBirth?.format(format),
		                    },
	                    },
                    });
			    }}
		    >
			    <InfoItemContext.Provider value={{ isEditing }}>
				    <div className={"mt20"}>
					    <InfoItem
						    name={"firstName"}
						    title={"First Name:"}
						    type={"text"}
						    value={client.info.firstName}
					    />
				    </div>

				    <div className={"mt20"}>
					    <InfoItem
						    name={"maidenName"}
						    title={"Maiden Name:"}
						    type={"text"}
						    value={client.info.maidenName}
					    />
				    </div>

				    <div className={"mt20"}>
					    <InfoItem
						    name={"lastName"}
						    title={"Last Name:"}
						    type={"text"}
						    value={client.info.lastName}
					    />
				    </div>

				    <div className={"mt20"}>
					    <InfoItem
						    name={"ppsn"}
						    title={"PPSN:"}
						    type={"text"}
						    value={client.ppsn}
					    />
				    </div>

				    <div className={"mt20"}>
					    <InfoItem
						    name={"dateOfBirth"}
						    title={"Date of Birth:"}
						    type={"date"}
						    value={client.info.dateOfBirth}
					    />
				    </div>

				    <div className={"mt20"}>
					    <InfoItem
						    name={"email"}
						    title={"Email:"}
						    type={"text"}
						    value={client.info.email}
					    />
				    </div>

				    <div className={"mt20"}>
					    <InfoItem
						    name={"phone"}
						    title={"Phone:"}
						    type={"text"}
						    value={client.info.phone}
					    />
				    </div>

				    <div className={"mt20"}>
					    <InfoItem
						    name={"street"}
						    title={"Street Address:"}
						    type={"text"}
						    value={client.address?.street}
					    />
				    </div>

				    <div className={"mt20"}>
					    <InfoItem
						    name={"city"}
						    title={"City:"}
						    type={"text"}
						    value={client.address?.city}
					    />
				    </div>

				    <div className={"mt20"}>
					    <InfoItem
						    name={"county"}
						    title={"County:"}
						    type={"text"}
						    value={client.address?.county}
					    />
				    </div>

				    <div className={"mt20"}>
					    <InfoItem
						    name={"eircode"}
						    title={"Eircode:"}
						    type={"text"}
						    value={client.address?.eircode}
					    />
				    </div>

				    <div className={"mt20 h3"}>
					    Spouse Info
				    </div>

				    <div className={"mt20"}>
					    <InfoItem
						    name={"ppsnSpouse"}
						    title={"PPSN:"}
						    type={"text"}
						    value={client.spouse.ppsn}
					    />
				    </div>

				    <div className={"mt20"}>
					    <InfoItem
						    name={"firstNameSpouse"}
						    title={"First Name:"}
						    type={"text"}
						    value={client.spouse.firstName}
					    />
				    </div>

				    <div className={"mt20"}>
					    <InfoItem
						    name={"lastNameSpouse"}
						    title={"Last Name:"}
						    type={"text"}
						    value={client.spouse.lastName}
					    />
				    </div>

				    <div className={"mt20"}>
					    <InfoItem
						    name={"emailSpouse"}
						    title={"Email:"}
						    type={"text"}
						    value={client.spouse.email}
					    />
				    </div>

				    <div className={"mt20"}>
					    <InfoItem
						    name={"spouseDateOfBirth"}
						    title={"Date of Birth:"}
						    type={"date"}
						    value={client.spouse.dateOfBirth}
					    />
				    </div>

				    <div className={"mt20"}>
					    <InfoItem
						    name={"assessmentType"}
						    title={"Assessment Type:"}
						    type={"select"}
						    options={SPOUSE_ASSESSMENT_TYPES}
						    value={client.spouse.assessmentType}
					    />
				    </div>

				    <div className={"mt20"}>
					    <InfoItem
						    name={"assessmentStatus"}
						    title={"Assessment Status:"}
						    type={"select"}
						    options={SPOUSE_ASSESSMENT_STATUSES}
						    value={client.spouse.assessmentStatus}
					    />
				    </div>

				    <div className={"mt20"}>
					    <div className={"h3"}>
						    Provided Info
					    </div>

					    <div className={"mt20"}>
						    <InfoItem
							    editable={false}
							    title={"PPSN:"}
							    type={"text"}
							    value={client.additionalInfo.ppsn}
						    />
					    </div>

					    <div className={"mt20"}>
						    <InfoItem
							    editable={false}
							    title={"Name:"}
							    type={"text"}
							    value={client.additionalInfo.name}
						    />
					    </div>

					    <div className={"mt20"}>
						    <InfoItem
							    editable={false}
							    title={"IBAN:"}
							    type={"text"}
							    value={client.additionalInfo.iban}
						    />
					    </div>
				    </div>

			    </InfoItemContext.Provider>
		    </Form>
	    </div>
    </div>;
};

export default PersonalDetails;
