import { DeleteOutlined } from "@ant-design/icons";
import { Dropdown, MenuProps } from "antd";
import { BsThreeDotsVertical } from "react-icons/bs";

import { usePermissionGranter } from "../../../../../../../../hooks/common/usePermissionGranter";
import { IDeleteUserBody, IUser } from "../../../../../../../../types/users";

const Actions = ({
    value,
    deleteUser,
}: {
	value: IUser;
	deleteUser: (_data: IDeleteUserBody) => void;
}) => {
    const agentsDeletePermissionIsGranted = usePermissionGranter("users:people", "delete");

    const onClick: MenuProps["onClick"] = async ({ key }) => {
        if (key === "delete") {
	        deleteUser({
		        id: value.id,
	        });
        }
    };

    const items: MenuProps["items"] = [...agentsDeletePermissionIsGranted ? [{
        key: "delete",
	    icon: <DeleteOutlined className={"red-color"}/>,
	    label: <span
		    data-testid={"delete-case"}
		    className={"red-color"}
	    >
		    Delete Agent
        </span>,
    }] : []];

    return <div>
	    {!!items.length && <Dropdown
		    menu={{ items, onClick }}
		    placement="bottomRight"
		    trigger={["click"]}
	    >
            <BsThreeDotsVertical
		        style={{ marginTop: 6 }}
		        className={"fs22 pointer grey-7-color"}
            />
	    </Dropdown>}
    </div>;
};

export default Actions;
