import { getPreliminaryResultValue } from "./components";
import { IRow, ITableGroup } from "../types/common";
import { IDocument, IIncomeTax, IRevenueDetails, ISubmission, StatusGroupData } from "../types/documents";

export const getEmploymentTableData = (title: string, revenueDetails: IRevenueDetails[], field: keyof IRevenueDetails) => {
    return {
        title,
        rows: revenueDetails.reduce((acc, dataOfYear) => {
            (dataOfYear[field]! as IIncomeTax).incomes.forEach(({ name }) => {
                const rowExists = acc.some(row => row.title === name);
                if (!rowExists) acc = [
                    { id: name, title: name, columns: [] },
                    { id: "incomeTax", parentId: name, title: "- Income Tax Paid", columns: [] },
                    { id: "usc", parentId: name, title: "- USC Paid", columns: [] },
                    ...acc,
                ];
            });

            return acc;
        }, [{
            id: "total",
            title: "Total Income",
            columns: [],
        }] as IRow[]),
    };
};

export const getTaxCreditsAndReliefTableData = (title: string, revenueDetails: IRevenueDetails[], field: keyof IRevenueDetails) => {
    return {
        title,
        rows: revenueDetails.reduce((acc, dataOfYear) => {
            (dataOfYear[field]! as IIncomeTax).taxCreditsAndReliefs.forEach(({ name }) => {
                const rowExists = acc.some(row => row.title === name);
                if (!rowExists) acc = [...acc, { id: name, title: name, columns: [] }];
            });

            return acc;
        }, [] as IRow[]),
    };
};

export const fillEmploymentColumns = (row: IRow, incomeTax: IIncomeTax) => {
    if (row.id === "total") return row.columns.push(String(incomeTax.total || 0));
    const value = incomeTax.incomes.find(item => item.name === row.title || item.name === row.parentId);
    if (!value) return row.columns.push("-");
    if (row.parentId) {
        if (row.id === "incomeTax") return row.columns.push(String(value.incomeTax || "-"));
        if (row.id === "usc") return row.columns.push(String(value.usc || "-"));
    }
    return row.columns.push(String(value.revenue || "-"));
};

export const fillTaxCreditsAndReliefColumns = (row: IRow, incomeTax: IIncomeTax) => {
    const value = incomeTax.taxCreditsAndReliefs.find(item => item.name === row.title);
    if (!value) return row.columns.push("-");
    return row.columns.push(String(value.revenue || "-"));
};

export const fillRevenueTableGroups = (revenueDetails: IRevenueDetails[]): ITableGroup[] => {
    const revenueDocuments = {
        description: {
            id: "description",
            title: "Description",
            columns: [],
        } as IRow,
        previewOfOutcome: {
            id: "previewOfOutcome",
            title: "Preview of Outcome",
            columns: [],
        } as IRow,
        preliminaryIncomeTaxResult: {
            id: "preliminaryIncomeTax",
            title: "Preliminary Income Tax Result",
            columns: [],
        } as IRow,
        preliminaryUSCResult: {
            id: "preliminaryUSC",
            title: "Preliminary USC Result",
            columns: [],
        } as IRow,
        taxableIncome: {
            id: "taxableIncome",
            title: "Taxable Income",
            columns: [],
        } as IRow,
        statementsOfLiability: {
            id: "sol",
            title: "Statements of Liability",
            columns: [],
        } as IRow,
    };

    const spouseEmployment = getEmploymentTableData(
        "Spouse Employment",
        revenueDetails,
        "spouseIncomeTax"
    );

    const employment = getEmploymentTableData(
        "Employment",
        revenueDetails,
        "incomeTax"
    );

    const spouseTaxCreditsAndRelief = getTaxCreditsAndReliefTableData(
        "Spouse Tax Credits & Relief",
        revenueDetails,
        "spouseIncomeTax"
    );

    const taxCreditsAndRelief = getTaxCreditsAndReliefTableData(
        "Tax Credits & Relief",
        revenueDetails,
        "incomeTax"
    );

    revenueDetails.forEach(dataOfYear => {
        const {
            preliminaryResult: {
                value,
                state,
                uscValue,
                uscState,
                incomeTaxValue,
                incomeTaxState,
                income,
                description,
            },
            sol,
            incomeTax,
            spouseIncomeTax,
        } = dataOfYear;

        revenueDocuments.previewOfOutcome.columns.push(getPreliminaryResultValue(state, value));
        revenueDocuments.preliminaryIncomeTaxResult.columns.push(getPreliminaryResultValue(incomeTaxState, incomeTaxValue));
        revenueDocuments.preliminaryUSCResult.columns.push(getPreliminaryResultValue(uscState, uscValue));
        revenueDocuments.taxableIncome.columns.push(income !== null ? `${income}` : "-");
        revenueDocuments.statementsOfLiability.columns.push(sol?.url ? "yes" : "no");
        revenueDocuments.description.columns.push(description ? `${description}` : "-");

        employment.rows.forEach(row => fillEmploymentColumns(row, incomeTax));
        spouseEmployment.rows.forEach(row => fillEmploymentColumns(row, spouseIncomeTax));

        taxCreditsAndRelief.rows.forEach(row => fillTaxCreditsAndReliefColumns(row, incomeTax));
        spouseTaxCreditsAndRelief.rows.forEach(row => fillTaxCreditsAndReliefColumns(row, spouseIncomeTax));
    });

    return [
        {
            title: "Revenue Documents",
            rows: [
                revenueDocuments.previewOfOutcome,
                revenueDocuments.preliminaryIncomeTaxResult,
                revenueDocuments.preliminaryUSCResult,
                revenueDocuments.taxableIncome,
                revenueDocuments.statementsOfLiability,
                revenueDocuments.description,
            ],
        },
        employment,
        taxCreditsAndRelief,
        ...spouseTaxCreditsAndRelief.rows.length > 0 ? [spouseEmployment, spouseTaxCreditsAndRelief] : [],
    ];
};

export const initializeSubmissionDetails = (submissions: ISubmission[], years: string[]) => {
    if (submissions.length === years.length) {
        return submissions.map(item => ({
            year: item.year,
            method: item.method || "n/a",
            reference: item.reference || "",
        }));
    }

    return years.map(year => ({
        year: year,
        method: "n/a",
        reference: "",
    }));
};

export const groupCasesByStatus = (cases: IDocument[]): StatusGroupData[] => {
    return cases.reduce((acc, caseData) => {
        const found = acc.find(group => group.currentStatusId === caseData.status.id);

        if (found) {
            acc = acc.map(group => {
                if (group.currentStatusId !== found.currentStatusId) return group;

                return {
                    ...group,
                    ids: [...group.ids, caseData.id],
                };
            });
        } else {
            acc = [...acc, {
                ids: [caseData.id],
                currentStatusId: caseData.status.id,
                changeToStatusId: undefined,
            }];
        }

        return acc;
    }, [] as StatusGroupData[]);
};
