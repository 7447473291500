import { Popover } from "antd";

import { IUser } from "../../../../../../../../../types/users";
import { getUserInitials } from "../../../../../../../../../utils/strings";

const Agent = ({
    value,
}: {
	value?: IUser | null;
}) => {
    return <div className={"bold"}>
        {value ? <div className={"pointer"}>
	        <Popover
		        placement="bottom"
		        content={<div className={"primary-color"}>
			        {value.info.name}
		        </div>}
		        trigger="click"
		        color={"#EAF8ED"}
	        >
	            {getUserInitials(value.info.name)}
	        </Popover>
        </div> : "-"}
    </div>;
};

export default Agent;
