import { Typography } from "antd";
import { NavLink } from "react-router-dom";

import { IClient } from "../../../../../../../../types/clients";

const TaxNumber = ({
    value,
}: {
	value: IClient;
}) => {
    return <NavLink
		    className={"pointer"}
		    to={{ pathname: `/client/${value.id}/details` }}
	    >
		    <Typography.Text
			    className={"blue-color horizontal-center"}
			    strong
			    copyable
			    underline
		    >
	            {value.ppsn}
		    </Typography.Text>
	    </NavLink>;
};

export default TaxNumber;
