import { Typography } from "antd";

const SubStatus = ({
    value,
}: {
	value: string;
}) => {
    if (value !== "eligible") return <div className={"purple-color horizontal-center"}/>;

    return <Typography.Text
	    className={"purple-color horizontal-center"}
	    strong
    >
        Mortgage
    </Typography.Text>;
};

export default SubStatus;
