import { FC, useCallback, useMemo, useState } from "react";

import { Typography } from "antd";
import { v4 as uuidv4 } from "uuid";

import { useUser } from "../../../../../../../hooks/auth/useUser";
import { useAppDispatch } from "../../../../../../../redux/hooks";
import { getFile, useDeleteFileMutation, useUploadFileMutation } from "../../../../../../../services/files";
import Spinner from "../../../../../../basic/Spinner/Spinner";
import CategoryFiles from "../../../../../../common/Files/CategoryFiles";

type Receipts = {
    originalName: string;
    url: string;
    name: string;
    _id: string;
}[]

type DocumentsUploadProps = {
    data: {
        category?: string;
        helpText?: string;
        allowAdditionalFiles?: boolean;
        uploadDocs?: {
            id: string;
            label: string;
        }[];
    };
    // eslint-disable-next-line no-unused-vars
    onAnswer: (answer: Receipts) => void;
    value?: Receipts;
};

const DocumentsUpload: FC<DocumentsUploadProps> = ({
    data,
    onAnswer,
    value = [],
}) => {
    const dispatch = useAppDispatch();
    const { user } = useUser();
    const [uploadFile, { isLoading: isUploading }] = useUploadFileMutation();
    const [deleteFile, { isLoading: isDeleting }] = useDeleteFileMutation();

    const [currentCategoryReceipts, setCurrentCategoryReceipts] = useState<Receipts>(value);

    const category = useMemo(() => (data.category || "default".replace(" ", "_").toLocaleLowerCase()), [data]);

    const uploadReceipt = useCallback(async (file: File, _category: string, name: string) => {
        const uploadedFileResponse = await uploadFile({
            file: file,
            path: `clients/${user.id}/receipts`,
        }).unwrap();

        if (!uploadedFileResponse?.data?.url) return false;

        const uploadedFileUrl = uploadedFileResponse.data.url;

	    const updatedReceipts = [...currentCategoryReceipts, {
            originalName: file.name,
            url: uploadedFileUrl,
            name: name,
            createdAt: new Date(),
            _id: uuidv4(),
	    }];

        onAnswer(updatedReceipts);
        setCurrentCategoryReceipts(updatedReceipts);

        return true;
    }, [currentCategoryReceipts, onAnswer, uploadFile, user.id]);

    const deleteReceipt = useCallback(async (id: string, _category: string) => {
        const url = currentCategoryReceipts?.find(receipt => receipt?._id === id)?.url;
        if (!url) return false;

        const deleteFileResponse = await deleteFile({ url }).unwrap();
        if (!deleteFileResponse?.success) return false;

        const updatedReceipts = currentCategoryReceipts.filter(receipt => receipt._id !== id);
        onAnswer(updatedReceipts);
        setCurrentCategoryReceipts(updatedReceipts);
        return true;
    }, [currentCategoryReceipts, deleteFile, onAnswer]);

    const getReceiptFile = useCallback(async (url: string, originalName: string) => {
        dispatch(getFile.initiate({
            url,
            fileName: originalName,
            triggerDownload: true,
        }));
    }, [dispatch]);

    return (
        <div className="builder__select-many-icons-question">
            <Typography.Text className="builder__help-text">
                {data.helpText}
            </Typography.Text>
            <div className="builder__icons-option-container">
                <Spinner isLoading={isUploading || isDeleting}>
                    <CategoryFiles
                        requiredDocuments={data?.uploadDocs?.map(doc => ({ name: doc.label }))}
                        files={currentCategoryReceipts}
                        uploadFile={(file, name) => uploadReceipt(file, category, name)}
                        deleteFile={id => deleteReceipt(id, category)}
                        getFile={getReceiptFile}
                        allowAdditionalFiles={data.allowAdditionalFiles}
                    />
                </Spinner>
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                }}
            >
            </div>
        </div>
    );
};

export default DocumentsUpload;
