import { useCallback, useState } from "react";

export const useModalOpener = () => {
    const [isOpened, setIsOpened] = useState(false);

    const open = useCallback(() => {
        setIsOpened(true);
    }, []);

    const close = useCallback(() => {
        setIsOpened(false);
    }, []);

    return {
        isOpened,
        open,
        close,
    };
};
