import React, { createContext } from "react";

import ClientsHeader from "./components/ClientsHeader";
import ClientsTable from "./components/ClientsTable/ClientsTable";
import { getColumns } from "./components/ClientsTable/columns";
import { useClientsTable } from "../../../../../hooks/clients/useClientsTable";
import Spinner from "../../../../basic/Spinner/Spinner";
import Pagination from "../../../../common/Table/components/Pagination";

export const ClientsContext = createContext<{
    isLoading: boolean;
    isRevenueLettersLoading: boolean;
}>({
    isLoading: false,
    isRevenueLettersLoading: false,
});

const ListOfClients = () => {
    const {
        isLoading,
        isRevenueLettersLoading,
        table,
        pagination,
        globalFilter,
        clientsType,
        setClientsType,
    } = useClientsTable(getColumns);

    return <div>
        <ClientsContext.Provider
            value={{
                isLoading,
                isRevenueLettersLoading,
            }}
        >
            <Spinner isLoading={isLoading}>
                <div>
                    <ClientsHeader
                        globalFilter={globalFilter}
                        clientsType={clientsType}
                        setClientsType={setClientsType}
                    />
                </div>

                <div className={"mt10"}>
                    <ClientsTable
                        table={table}
                    />
                </div>

                <div className={"mt10"}>
                    <Pagination
                        pagination={pagination}
                    />
                </div>
            </Spinner>
        </ClientsContext.Provider>
    </div>;
};

export default ListOfClients;
