import { useMemo } from "react";

import { CloseOutlined } from "@ant-design/icons/lib/icons";
import { ResponsivePie } from "@nivo/pie";
import { Col, Modal, Popover, Row } from "antd";

import { DEFAULT_PLANS } from "../../../../../../../../constants/plans";
import {
    IFinancialStats,
    IFinancialStatsMainPrimitiveFields,
} from "../../../../../../../../types/stats";
import { roundToTwoDecimals } from "../../../../../../../../utils/numbers";

const HeaderWithDistributionPopup = ({
    columns,
    field,
    label,
}: {
	columns: IFinancialStats[];
	field: keyof IFinancialStatsMainPrimitiveFields;
	label: string;
}) => {
    const [modal, contextHolder] = Modal.useModal();

    const data = useMemo(() => {
        return DEFAULT_PLANS.map(plan => {
            return {
                "id": plan.name,
                "label": plan.name,
                "value": columns.reduce((acc, item) => {
                    acc += item.stats.plansDistribution[plan.id]?.[field] || 0;
                    return acc;
                }, 0),
            };
        });
    }, [field, columns]);

    const statsContent = useMemo(() => {
	    return <div
		    style={{ width: 400 }}
		    className={"p10"}
	    >
		    <div className={"space-between"}>
			    <div className={"bold"}>
				    {label}
			    </div>
                <div className={"fs12 primary-color bold"}>
					Annual
                </div>
		    </div>
		    <div className={"mt6 fs12 grey-5-color"}>
			    Price breakdown
		    </div>
		    <div>
			    <Row>
				    <Col span={16}>
					    <div style={{ height: 220 }} className={"mt20 center"}>
						    <ResponsivePie
							    data={data}
							    margin={{ top: 20, right: 20, bottom: 10, left: 0 }}
							    enableArcLabels={false}
							    enableArcLinkLabels={false}
							    colors={DEFAULT_PLANS.map(item => item.color)}
						    />
					    </div>
				    </Col>
				    <Col span={8}>
					    {DEFAULT_PLANS.map((plan, i) => {
						    return <div className={"mt10"} key={plan.id}>
							    <div className={"horizontal-left"}>
                                    <div>
                                        <div style={{
                                            backgroundColor: plan.color,
                                            width: 12,
                                            height: 12,
                                            borderRadius: "100%",
                                        }}/>
                                    </div>
                                    <div className={"ml4 grey-7-color"}>
                                        {plan.name}
                                    </div>
                                </div>
							    <div className={"ml10 fs20 bold"}>
								    €{roundToTwoDecimals(Number(data[i].value))}
							    </div>
						    </div>;
					    })}
				    </Col>
			    </Row>
		    </div>
	    </div>;
    }, [data, label]);

    return <div>
	    {contextHolder}
        <Popover
            placement={"bottom"}
            content={statsContent}
        >
            <div
	            className={"pointer stats-header-item"}
	            onClick={() => {
                    modal.info({
	                    content: statsContent,
	                    footer: null,
	                    width: 500,
	                    centered: true,
	                    icon: null,
	                    maskClosable: true,
	                    closable: true,
	                    closeIcon: <CloseOutlined />,
                    });
	            }}
            >
                {label}
            </div>
        </Popover>
    </div>;
};

export default HeaderWithDistributionPopup;
