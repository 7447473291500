import { ColumnDef } from "@tanstack/react-table";

import Actions from "./components/Actions";
import Cell from "./components/Cell";
import Date from "./components/Date";
import Expand from "./components/Expand";
import Header from "./components/Header";
import RevenueLetters from "./components/RevenueLetters";
import TaxNumber from "./components/TaxNumber";
import { IClient, IDeleteClientByIdBody } from "../../../../../../../types/clients";
import IndeterminateCheckbox from "../../../../../../common/Table/components/IndeterminateCheckbox";

export const getColumns = ({
    deleteClientById,
    getRevenueLetterFile,
    uploadRevenueLetter,
    deleteRevenueLetter,
}: {
    deleteClientById: (_data: IDeleteClientByIdBody) => void;
    getRevenueLetterFile: (_url: string, _originalName: string) => Promise<void>;
    uploadRevenueLetter: (_client: IClient, _file: File) => Promise<boolean>;
    deleteRevenueLetter: (_client: IClient, _revenueLetterId: string) => Promise<boolean>;
}): ColumnDef<IClient>[] => [
    {
        id: "select",
        header: ({ table }) => (
            <IndeterminateCheckbox
                {...{
                    checked: table.getIsAllRowsSelected(),
                    indeterminate: table.getIsSomeRowsSelected(),
                    onChange: table.getToggleAllRowsSelectedHandler(),
                }}
                className={"horizontal-left"}
            />
        ),
        cell: ({ row }) => (
            <div className="px-1">
                <IndeterminateCheckbox
                    {...{
                        checked: row.getIsSelected(),
                        disabled: !row.getCanSelect(),
                        indeterminate: row.getIsSomeSelected(),
                        onChange: row.getToggleSelectedHandler(),
                    }}
                />
            </div>
        ),
    },
    {
        id: "name",
        enableSorting: false,
        accessorFn: row => `${row.info.firstName} ${row.info.lastName}`,
        header: () => <Header label={"Name"}/>,
        cell: info => <Cell value={info.getValue() as string}/>,
    },
    {
        id: "email",
        accessorKey: "info.email",
        enableSorting: false,
        header: () => <Header label={"Email"}/>,
        cell: info => <Cell value={info.getValue() as string}/>,
    },
    {
        id: "phone",
        accessorKey: "info.phone",
        enableSorting: false,
        header: () => <Header label={"Phone"}/>,
        cell: info => <Cell value={info.getValue() as string}/>,
    },
    {
        id: "ppsn",
        accessorKey: "ppsn",
        enableSorting: false,
        header: () => <Header label={"PPSN"}/>,
        cell: ({ row }) => <TaxNumber value={row.original}/>,
    },
    {
        id: "createdAt",
        accessorKey: "createdAt",
        header: () => <Header label={"Date Added"}/>,
        cell: ({ row }) => <Date value={row.original.createdAt}/>,
    },
    {
        id: "revenueLetter",
        accessorKey: "revenueLetter.originalName",
        enableSorting: false,
        header: () => <Header label={"Revenue Letter"}/>,
        cell: ({ row }) => <RevenueLetters
            value={row.original}
            getRevenueLetterFile={getRevenueLetterFile}
            uploadRevenueLetter={uploadRevenueLetter}
            deleteRevenueLetter={deleteRevenueLetter}
        />,
    },
    {
        id: "expand",
        header: () => <Header label={"Submission History"}/>,
        cell: ({ row }) => <Expand value={row}/>,
    },
    {
        id: "actions",
        cell: ({ row }) => <Actions
            value={row.original}
            deleteClientById={deleteClientById}
        />,
    },
];
