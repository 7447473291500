import { getMonthName } from "../../../../../../../../utils/date";

const Month = ({
    value,
}: {
	value: string;
}) => {
    return <div className={"primary-color bold fs16"}>
        {getMonthName(value)}
    </div>;
};

export default Month;
