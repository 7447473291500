import React, { useContext } from "react";

import { NavigationContext } from "../contexts/NavigationContext";

const NavigationHeader = ({
    label,
}: {
	label: string;
}) => {
    const {
        navbarIsCollapsed,
    } = useContext(NavigationContext);

    if (navbarIsCollapsed)
        return <div className={"mt30"}/>;

    return <div className={"fs14 grey-7-color"}>
	    {label}
    </div>;
};

export default NavigationHeader;
