import { FC, useEffect, useMemo, useState } from "react";

import { Typography } from "antd";

import unSelectedIcon from "../../../../../../../assets/images/form/complete-empty.svg";
import selectedIcon from "../../../../../../../assets/images/form/complete.svg";
import { SELECT_MANY_TYPE } from "../../../../../../../constants/constructor";

type SelectManyQuestionProps = {
    data: {
        question?: string;
        arrayLimit?: number;
        helpText?: string;
        selectType?: string;
        required?: boolean;
        options?: {
            id: string;
            value: string;
        }[];
    };
    onAnswer: (_answer: string[]) => void;
    setLimitsReached?: (_id: string, _reached: boolean) => void;
    value?: string[];
    arrayItems?: Record<string, any>[]
    questionId: string;
};

const SelectManyQuestion: FC<SelectManyQuestionProps> = ({
    data,
    onAnswer,
    setLimitsReached,
    value = [],
    arrayItems,
    questionId,
}) => {
    const [error, setError] = useState<string>("");
    const [skipValidation, setSkipValidation] = useState<boolean>(true);

    const isLimitReached = useMemo(() => {
        const optionCounts: Record<string, number> = data.options?.reduce((acc, option) => {
            acc[option.value] = 0;
            return acc;
        }, {} as Record<string, number>) || {};

        arrayItems?.forEach(item => {
            item[questionId]?.forEach((selectedValue: string) => {
                if (Object.prototype.hasOwnProperty.call(optionCounts, selectedValue)) {
                    optionCounts[selectedValue]++;
                }
            });
        });

        const limitReachedStatus: Record<string, boolean> = {};
        Object.keys(optionCounts).forEach(value => {
            const limit = data.arrayLimit || 100;
            limitReachedStatus[value] = optionCounts[value] >= limit;
        });

        return limitReachedStatus;
    }, [arrayItems, data.arrayLimit, data.options, questionId]);

    useEffect(() => {
        if (data.required && value.length < 1 && !skipValidation) {
            setError("Required to select at least one option");
        } else {
            setError("");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, skipValidation]);

    const handleOptionChange = (optionValue: string) => {
        if (isLimitReached[optionValue]) return;
        setSkipValidation(false);
        let updatedValues;
        if (value.includes(optionValue)) {
            updatedValues = value.filter(option => option !== optionValue);
        } else {
            updatedValues = [...value, optionValue];
        }
        onAnswer(updatedValues);

        const updatedIsLimitReached = JSON.parse(JSON.stringify(isLimitReached));
        const limit = data.arrayLimit || 100;

        const optionCounts: Record<string, number> = data.options?.reduce((acc, option) => {
            acc[option.value] = 0;
            return acc;
        }, {} as Record<string, number>) || {};

        arrayItems?.forEach(item => {
            item[questionId]?.forEach((selectedValue: string) => {
                if (Object.prototype.hasOwnProperty.call(optionCounts, selectedValue)) {
                    optionCounts[selectedValue]++;
                }
            });
        });

        updatedValues.forEach(value => {
            optionCounts[value]++;
            updatedIsLimitReached[value] = optionCounts[value] >= limit;
        });

        const allLimitsReached = Object.values(updatedIsLimitReached).every(value => value === true);

        setLimitsReached && setLimitsReached(questionId, allLimitsReached);
    };

    switch (data.selectType) {
    case SELECT_MANY_TYPE.TABS:
        return (
            <div className="builder__select-many-question">
                <Typography.Text className="builder__form-question">
                    {data.question}
                </Typography.Text>
                <div className="builder__option-container">
                    {data.options?.map(option => (
                        <div key={option.id} className="builder__tab-option">
                            <div
                                className={`builder__option-content ${
                                    value.includes(option.value) ? "selected" : ""
                                } ${
                                    isLimitReached[option.value] ? "disabled" : ""
                                }`}
                                onClick={() => handleOptionChange(option.value)}
                            >
                                <div
                                    className="builder__option-text"
                                    style={{
                                        fontSize: "14px",
                                    }}
                                >
                                    {option.value}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                    }}
                >
                    <Typography.Text className="builder__help-text">
                        {data.helpText}
                    </Typography.Text>
                    {error && (
                        <Typography.Text className="builder__form-error">
                            {error}
                        </Typography.Text>
                    )}
                </div>
            </div>
        );

    case SELECT_MANY_TYPE.CHECKBOXES:
    default:
        return (
            <div className="builder__select-many-question">
                <Typography.Text className="builder__form-question">
                    {data.question}
                </Typography.Text>
                <div className="builder__option-container">
                    {data.options?.map(option => (
                        <div key={option.id} className="builder__option">
                            <div
                                className={`builder__option-content ${
                                    value.includes(option.value) ? "selected" : ""
                                } ${
                                    isLimitReached[option.value] ? "disabled" : ""
                                }`}
                                onClick={() => handleOptionChange(option.value)}
                            >
                                <img
                                    className="builder__option-image"
                                    src={
                                        value.includes(option.value)
                                            ? selectedIcon
                                            : unSelectedIcon
                                    }
                                    alt="checkbox-icon"
                                />
                                <div
                                    className="builder__option-text"
                                    style={{
                                        fontSize: `${
                                            /^\d{1,4}$/.test(option.value)
                                                ? "24px"
                                                : "14px"
                                        }`,
                                    }}
                                >
                                    {option.value}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                    }}
                >
                    <Typography.Text className="builder__help-text">
                        {data.helpText}
                    </Typography.Text>
                    {error && (
                        <Typography.Text className="builder__form-error">
                            {error}
                        </Typography.Text>
                    )}
                </div>
            </div>
        );
    }
};

export default SelectManyQuestion;
