/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
import { Edge } from "reactflow";
import { v4 as uuidv4 } from "uuid";

import { ChangeEvent, SetterFunction } from "../types/common";
import { ChildrenT, DependT, FlowNode, FlowT, GroupT, NodeType, QuestionnaireData, SubTabT } from "../types/questionnaires";

export const getId = (type: NodeType) => `${type}_${new Date().getTime()}`;

export const flowKey = "questionnaireData";

export const getNodeData = (type: NodeType) => {
    switch (type) {
    case "formTab":
        return { label: "Form Tab" };
    case "formSubTab":
        return { label: "Form Sub Tab" };
    case "resizeGroup":
        return { label: "Questions Group" };
    case "arrayGroup":
        return { label: "Array Group", addItemLabel: "Add Item Button" };
    case "yearsGroup":
        return { question: "Edit question here" };
    case "infoBlock":
        return { label: "Edit info here" };
    case "warningBlock":
        return { label: "Edit warning here" };
    case "infoClickBlock":
        return {
            label: "Edit label here",
            title: "Edit title here",
            message: "Edit message here",
        };
    case "amountQuestion":
        return { question: "Edit question here",
            currency: "€",
            validation: {
                regex: "/^\\d+(\\.\\d{1,2})?$/",
                message: "Please enter a valid amount with up to 2 decimal places",
            } };
    case "textQuestion":
        return { question: "Edit question here" };
    case "formulaNumberQuestion":
        return {
            question: "Edit question here",
            formula: "52 * x - 30",
            label: "days",
            helpText: "per week",
            keyboardType: "number",
        };
    case "dateQuestion":
        return { question: "Edit question here" };
    case "booleanQuestion":
        return { question: "Edit question here" };
    case "addressQuestion":
        return { question: "Edit question here" };
    case "forbiddenMessage":
        return { message: "Edit message here" };
    case "allowMessage":
        return { message: "Edit message here" };
    case "selectOneQuestion":
        return {
            question: "Edit question here",
            selectType: "dropdown",
            options: [
                {
                    id: uuidv4(),
                    value: "First text variant",
                },
                {
                    id: uuidv4(),
                    value: "Second text variant",
                },
                {
                    id: uuidv4(),
                    value: "Third text variant",
                },
            ],
        };
    case "selectOneIconsQuestion":
        return {
            question: "Edit question here",
            options: [
                {
                    id: uuidv4(),
                    value: "First text variant with Icon",
                    url: "",
                },
                {
                    id: uuidv4(),
                    value: "Second text variant with Icon",
                    url: "",
                },
                {
                    id: uuidv4(),
                    value: "Third text variant with Icon",
                    url: "",
                },
            ],
        };
    case "selectManyQuestion":
        return {
            question: "Edit question here",
            selectType: "checkboxes",
            options: [
                {
                    id: uuidv4(),
                    value: "First text variant",
                },
                {
                    id: uuidv4(),
                    value: "Second text variant",
                },
                {
                    id: uuidv4(),
                    value: "Third text variant",
                },
            ],
        };
    case "selectManyIconsQuestion":
        return {
            question: "Edit question here",
            options: [
                {
                    id: uuidv4(),
                    value: "First text variant with Icon",
                    url: "",
                },
                {
                    id: uuidv4(),
                    value: "Second text variant with Icon",
                    url: "",
                },
                {
                    id: uuidv4(),
                    value: "Third text variant with Icon",
                    url: "",
                },
            ],
        };
    case "documentsUpload":
        return {
            category: "Edit Documents Category here",
            helpText: "You can upload your documents later through your Taxzap account as well.",
            uploadDocs: [
                {
                    id: uuidv4(),
                    label: "First Document Label",
                },
                {
                    id: uuidv4(),
                    label: "Second Document Label",
                },
                {
                    id: uuidv4(),
                    label: "Third Document Label",
                },
            ],
        };
    default:
        return {};
    }
};

export const convertDataStructure = (flow: FlowT): QuestionnaireData => {
    const { nodes, edges } = flow;

    const converted: QuestionnaireData = {
        forbiddenMessages: [],
        allowMessages: [],
        tabs: [],
    };

    if (!nodes || !edges) return converted;

    const dependencyEdges: Edge[] = [];
    const andDependencyEdges: Edge[] = [];
    const orderEdges: Edge[] = [];
    const weirdEdges: Edge[] = [];

    edges.forEach((edge: any) => {
        if (edge.sourceHandle === "from" && edge.targetHandle === "to") {
            orderEdges.push(edge);
        } else if (edge.targetHandle === "depend") {
            dependencyEdges.push(edge);
        } else if (edge.targetHandle === "depend_and") {
            andDependencyEdges.push(edge);
        } else {
            weirdEdges.push(edge);
        }
    });

    if (weirdEdges.length) {
        console.warn("Weird Edges during convert Flow Data", weirdEdges);
    }

    for (const { id, data, type } of nodes) {
        if (type === "forbiddenMessage") {
            const message = {
                id,
                text: data.message || "",
                bindTo: data.bindTo || { id: "", label: "" },
                depend: getDepend(dependencyEdges, id),
                andDepend: getDepend(andDependencyEdges, id),
            };
            converted.forbiddenMessages.push(message);
            continue;
        }
        if (type === "allowMessage") {
            const message = {
                id,
                text: data.message || "",
                depend: getDepend(dependencyEdges, id),
                andDepend: getDepend(andDependencyEdges, id),
            };
            converted.allowMessages.push(message);
            continue;
        }

        if (type === "formTab") {
            const subTabs = getTabNodeChildren(nodes, dependencyEdges, andDependencyEdges, id);

            const tab = {
                id,
                type: type as string,
                data,
                children: subTabs,
            };
            converted.tabs.push(tab);
        }
    }

    converted.tabs = sortItemsByEdges(converted.tabs, orderEdges);

    return converted;
};

export function getTabNodeChildren(
    nodes: FlowNode[],
    dependencyEdges: Edge[],
    andDependencyEdges: Edge[],
    id: string
): SubTabT[] {
    return nodes
        .filter(child => child.parentNode === id)
        .map(({ id, parentNode, type, data }) => {
            let children: GroupT[] | undefined;
            if (type === "formSubTab") {
                children = getSubTabNodeChildren(
                    nodes,
                    dependencyEdges,
                    andDependencyEdges,
                    id
                );
            }
            return {
                id,
                parentId: parentNode as string,
                type: type as string,
                data,
                ...(children && { children }),
            };
        });
}
export function getSubTabNodeChildren(
    nodes: FlowNode[],
    dependencyEdges: Edge[],
    andDependencyEdges: Edge[],
    id: string
): (GroupT)[] {
    return nodes
        .filter(child => child.parentNode === id)
        .map(({ id, parentNode, type, data }) => {
            let children: ChildrenT[] | undefined;
            if (type === "resizeGroup") {
                children = getGroupNodeChildren(
                    nodes,
                    dependencyEdges,
                    andDependencyEdges,
                    id
                );
            }
            return {
                id,
                depend: getDepend(dependencyEdges, id),
                andDepend: getDepend(andDependencyEdges, id),
                parentId: parentNode as string,
                type: type as string,
                data,
                ...(children && { children }),
            };
        });
}

export function getGroupNodeChildren(
    nodes: FlowNode[],
    dependencyEdges: Edge[],
    andDependencyEdges: Edge[],
    id: string
): ChildrenT[] {
    return nodes
        .filter(child => child.parentNode === id)
        .map(({ id, parentNode, type, data }) => {
            let children: ChildrenT[] | undefined;
            if (type === "arrayGroup" || type === "yearsGroup") {
                children = getGroupNodeChildren(
                    nodes,
                    dependencyEdges,
                    andDependencyEdges,
                    id
                );
            }
            return {
                id,
                depend: getDepend(dependencyEdges, id),
                andDepend: getDepend(andDependencyEdges, id),
                parentId: parentNode as string,
                type: type as string,
                data: {
                    ...data,
                    question: data.question || "",
                },
                ...(children && { children }),
            };
        });
}

export function convertDependValue(value: string | null | undefined) {
    if (!value) return;
    if (value === "dependency_yes") {
        return true;
    }
    if (value === "dependency_no") {
        return false;
    }
    return value.split("_")[1];
}

export function getDepend(dependencyEdges: Edge[], id: string): DependT[] {
    return dependencyEdges
        .filter(edge => edge.target === id)
        .map(({ source, sourceHandle }) => ({
            id: source,
            value: convertDependValue(sourceHandle),
        }));
}

export function sortItemsByEdges(items: any[], edges: Edge[]): any[] {
    const sortedItems: any[] = [];
    const itemMap = new Map<string, any>();

    items.forEach(item => {
        itemMap.set(item.id, item);
    });

    let sourceId = "";
    edges.forEach(edge => {
        const source = edge.source;
        if (!edges.some(e => e.target === source) && itemMap.has(source)) {
            sourceId = source;
        }
    });

    if (sourceId) {
        while (sourceId) {
            const item = itemMap.get(sourceId);
            if (item) {
                if (item.children) {
                    item.children = sortItemsByEdges(item.children, edges);
                }
                sortedItems.push(item);
                sourceId =
                    // eslint-disable-next-line no-loop-func
                    edges.find(edge => edge.source === sourceId)?.target || "";
            } else {
                break;
            }
        }
    } else {
        for (const item of items) {
            if (item.children) {
                item.children = sortItemsByEdges(item.children, edges);
            }
            sortedItems.push(item);
        }
    }

    return sortedItems;
}

export const createChangeHandler = (
    <T, >(setter: SetterFunction<T>) => {
        return (event: ChangeEvent) => {
            const target = event.target as HTMLInputElement;
            const value = target.type === "checkbox" ? target.checked : target.value;
            setter(value as T);
        };
    }
);
