import React, { useState } from "react";

import { CloseCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { Input, Radio } from "antd";
import { useDebouncedCallback } from "use-debounce";

import { CLIENT_TYPES } from "../../../../../../constants/clients";
import { ClientsType } from "../../../../../../types/clients";
import { IGlobalFilterProps } from "../../../../../../types/common";

const ClientsHeader = ({
    globalFilter,
    clientsType,
    setClientsType,
}: {
    globalFilter: IGlobalFilterProps;
    clientsType: ClientsType;
    setClientsType: (_clientsType: ClientsType) => void;
}) => {
    const [globalFilterValue, setGlobalFilterValue] = useState(globalFilter.globalFilter);

    const handleSearchChange = useDebouncedCallback((value: string) => {
        return globalFilter.setGlobalFilter(value);
    }, 500);

    return <div className={"p10 space-between"}>
        <div className={"horizontal-left"}>
            <div className={"h2"}>
                All Clients
            </div>
        </div>
        <div className={"horizontal-right"}>
            <div>
                <Input
                    value={globalFilterValue}
                    size="large"
                    placeholder="Search"
                    data-testid={"clients-search-input"}
                    allowClear={{
                        clearIcon: <div
                            onClick={() => {
                                setGlobalFilterValue("");
                                globalFilter.setGlobalFilter("");
                            }}
                        >
                            <CloseCircleOutlined />
                        </div>,
                    }}
                    onChange={e => {
                        setGlobalFilterValue(e.target.value);
                        handleSearchChange(e.target.value);
                    }}
                    prefix={<SearchOutlined />}
                />
            </div>
            <div className={"ml20"}>
                <Radio.Group
                    options={CLIENT_TYPES}
                    onChange={({ target: { value } }) => setClientsType(value)}
                    value={clientsType}
                    optionType="button"
                    buttonStyle="solid"
                    size={"large"}
                />
            </div>
        </div>
    </div>;
};

export default ClientsHeader;
