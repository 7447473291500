import React from "react";

import { Table } from "@tanstack/react-table";

import ClientLogsTable from "./../EventLogs/ClientLogsTable/ClientLogsTable";
import { IClientLog, ICreateClientLogData } from "../../../../../../../types/client-logs";
import { IPaginationProps } from "../../../../../../../types/common";
import Pagination from "../../../../../../common/Table/components/Pagination";
import LogsHeader from "../LogsHeader";

const EventLogs = ({
    table,
    pagination,
    createClientLog,
}: {
	table: Table<IClientLog>;
	pagination: IPaginationProps;
    createClientLog: (_data: ICreateClientLogData) => void;
}) => {
    return <div>
        <div>
            <LogsHeader
                createClientLog={createClientLog}
            />
        </div>

        <div className={"mt10"}>
            <ClientLogsTable
                table={table}
            />
        </div>

        <div className={"mt10"}>
            <Pagination
                pagination={pagination}
            />
        </div>
    </div>;
};

export default EventLogs;
