import { Row as TableRow } from "@tanstack/react-table";
import { Col, Row } from "antd";

import Agent from "./components/Agent";
import CaseId from "./components/CaseId";
import Cell from "./components/Cell";
import Date from "./components/Date";
import StatusDot from "./components/StatusDot";
import { getColumns } from "./sub-columns";
import { useClientCasesTable } from "../../../../../../../hooks/clients/useClientCasesTable";
import { IClient } from "../../../../../../../types/clients";
import Spinner from "../../../../../../basic/Spinner/Spinner";
import Status from "../../../../../../common/Status/Status";

const DocumentsSubComponent = ({ row }: { row: TableRow<IClient> }) => {
    const {
        documents,
        isLoading,
    } = useClientCasesTable(getColumns, row.original.id);

    return (
        <div style={{ minHeight: 40 }}>
            <Spinner isLoading={isLoading}>
                {documents.length ? <div>
                    {documents.map(document => {
                        return <div
                            key={document.id}
                            className={"border-top-1px"}
                        >
                            <Row wrap={false} style={{ height: 60 }}>
                                <Col span={2} className={"vertical-center"}>
                                    <StatusDot value={document.status.id}/>
                                </Col>
                                <Col span={4} className={"vertical-center"}>
                                    <Date value={document.createdAt}/>
                                </Col>
                                <Col span={3} className={"vertical-center"}>
                                    <Cell value={`Submission ${document.year}`}/>
                                </Col>
                                <Col span={4} className={"vertical-center"}>
                                    <CaseId value={document}/>
                                </Col>
                                <Col span={6} className={"vertical-center"}>
                                    <Status value={document.status.id}/>
                                </Col>
                                <Col span={3} className={"vertical-center"}>
                                    <Agent value={document.manager}/>
                                </Col>
                                <Col span={2} className={"vertical-center"}>
                                    <Cell value={"-"}/>
                                </Col>
                            </Row>
                        </div>;
                    })}
                </div> : <div className={"center grey-5-color"}>
                    User does not have submissions yet.
                </div>}
            </Spinner>
        </div>
    );
};

export default DocumentsSubComponent;
