import React, { ReactNode } from "react";

import { Col, Row } from "antd";

const InfoHeader = ({
    id,
    title,
    value,
    icon,
}: {
    id?: string;
	title: string;
	value?: string | number | null;
    icon?: ReactNode;
}) => {
    return <div className={"horizontal-left"}>
        <Row
            style={{ width: "100%" }}
            className={"vertical-center"}
        >
            <Col span={12}>
                <div className={"fs16 bold"}>
                    {title}
                </div>
            </Col>
            <Col span={12}>
                {value && <div
                    data-testid={id ? `${id}-value` : undefined}
                    className={"horizontal-left grey-7-color fs12"}
                >
                    {value}
                </div>}

                {icon}
            </Col>
        </Row>
    </div>;
};

export default InfoHeader;
