import { useContext } from "react";

import { DownloadOutlined, InboxOutlined } from "@ant-design/icons";
import { Button } from "antd";

import { useModalOpener } from "../../../../../../hooks/helpers/useModalOpener";
import { IClient } from "../../../../../../types/clients";
import RevenueLettersModal from "../../../../../modals/Files/RevenueLettersModal";
import { CaseContext } from "../../../Case";

const DocumentsHeader = ({
    getInvoiceFile,
    getRevenueLetterFile,
    uploadRevenueLetter,
    deleteRevenueLetter,
}: {
    getInvoiceFile: () => void;
    getRevenueLetterFile: (_url: string, _originalName: string) => Promise<void>;
    uploadRevenueLetter: (_file: File) => Promise<boolean>;
    deleteRevenueLetter: (_id: string) => Promise<boolean>;
}) => {
    const { document, isLoading } = useContext(CaseContext);

    const {
        open: openRevenueLettersModal,
        close: closeRevenueLettersModal,
        isOpened: revenueLettersModalIsOpened,
    } = useModalOpener();

    return <div className={"p10 space-between"}>
        <div>
            <div className={"h2"}>
                Documents
            </div>
        </div>
        <div>
            <div className={"horizontal-right"}>
                <div>
                    <Button
                        type={"primary"}
                        className={"inverted-button"}
                        style={{ width: 200, height: 36 }}
                        icon={<InboxOutlined />}
                        onClick={openRevenueLettersModal}
                    >
                        Revenue Letters
                    </Button>
                </div>

                <div className={"ml20"}>
                    <Button
                        disabled={!document?.invoiceUrl}
                        type={"primary"}
                        className={"inverted-button"}
                        style={{ width: 200, height: 36 }}
                        icon={<DownloadOutlined />}
                        onClick={getInvoiceFile}
                    >
                        Download Invoice
                    </Button>
                </div>
            </div>

            {revenueLettersModalIsOpened && <RevenueLettersModal
                isLoading={isLoading}
                revenueLetters={(document?.client as IClient).revenueLetters || []}
                getRevenueLetterFile={getRevenueLetterFile}
                uploadRevenueLetter={uploadRevenueLetter}
                deleteRevenueLetter={deleteRevenueLetter}
                close={closeRevenueLettersModal}
            />}
        </div>
    </div>;
};

export default DocumentsHeader;
