import { useEffect, useState } from "react";

import { useGetQuestionnairesQuery } from "../../services/questionnaires";

export const useQuestionnairesList = () => {
    const {
        data,
    } = useGetQuestionnairesQuery({
        pageIndex: 0,
        pageSize: 10,
    });

    const [selectedQuestionnaireId, setSelectedQuestionnaireId] = useState<string | undefined>(undefined);

    useEffect(() => {
        setSelectedQuestionnaireId(data?.questionnaires[0].id);
    }, [data?.questionnaires]);

    return {
        selectedQuestionnaireId,
        setSelectedQuestionnaireId,
        questionnaires: data?.questionnaires || [],
    };
};
