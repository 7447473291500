import { useCallback, useEffect, useState } from "react";

import { Col, Row, FormInstance } from "antd";

import PasswordGeneratorCheckbox from "./PasswordGeneratorCheckbox";
import ShouldChangePasswordCheckbox from "./ShouldChangePasswordCheckbox";
import { getPassword } from "../../../../utils/auth";

const PasswordManager = ({
    form,
}: {
    form: FormInstance;
}) => {
    const [passwordIsAutogenerated, setPasswordIsAutogenerated] = useState(false);

    useEffect(() => {
        if (!passwordIsAutogenerated) {
            form.setFieldsValue({
                shouldChangePassword: false,
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [passwordIsAutogenerated]);

    const generatePassword = useCallback(() => {
        const password = getPassword({
            length: 12,
        });

        form.setFieldsValue({
            password: password,
            confirmPassword: password,
        });

        setPasswordIsAutogenerated(true);
    }, [form]);

    const resetGeneratedPassword = useCallback(async () => {
        form.setFieldsValue({
            password: "",
            confirmPassword: "",
        });

        await form.validateFields(["password", "confirmPassword"]);

        setPasswordIsAutogenerated(false);
    }, [form]);

    return <div className={"mb20"}>
        <Row>
            <Col span={24}>
                <PasswordGeneratorCheckbox
                    generatePassword={generatePassword}
                    resetGeneratedPassword={resetGeneratedPassword}
                />
            </Col>
        </Row>

        <Row className={"mt20"}>
            <Col span={24}>
                <ShouldChangePasswordCheckbox
                    disabled={!passwordIsAutogenerated}
                />
            </Col>
        </Row>
    </div>;
};

export default PasswordManager;
