import { Fragment } from "react";

import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";
import { Table, flexRender } from "@tanstack/react-table";

import DocumentsSubComponent from "./DocumentsSubComponent";
import { IClient } from "../../../../../../../types/clients";

const ROW_HEIGHT = 60;

const ClientsTable = ({
    table,
}: {
	table: Table<IClient>;
}) => {
    return <div className={"p10"}>
        <table>
            <thead>
                {table.getHeaderGroups().map(headerGroup => (
                    <tr key={headerGroup.id} style={{ height: ROW_HEIGHT }}>
                        {headerGroup.headers.map(header => {
                            return (
                                <th key={header.id} colSpan={header.colSpan}>
                                    {header.isPlaceholder ? null : (
                                        <>
                                            <div
                                                {...{
                                                    className: header.column.getCanSort()
                                                        ? "pointer horizontal-center"
                                                        : "",
                                                    onClick: header.column.getToggleSortingHandler(),
                                                }}
                                            >
                                                <div>
                                                    {flexRender(
                                                        header.column.columnDef.header,
                                                        header.getContext()
                                                    )}
                                                </div>
                                                <div className={"ml4 primary-color"}>
                                                    {{
                                                        asc: <ArrowUpOutlined />,
                                                        desc: <ArrowDownOutlined />,
                                                    }[header.column.getIsSorted() as string] ?? null}
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </th>
                            );
                        })}
                    </tr>
                ))}
            </thead>
            <tbody>
                {table.getRowModel().rows.map(row => (
                    <Fragment key={row.id}>
                        <tr className={"border-top-1px"} style={{ height: ROW_HEIGHT }}>
                            {row.getVisibleCells().map(cell => (
                                <td
                                    key={cell.id}
                                    className={"fs14 grey-8-color"}
                                >
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </td>
                            ))}
                        </tr>
                        {row.getIsExpanded() && (
                            <tr>
                                <td colSpan={row.getVisibleCells().length}>
                                    <DocumentsSubComponent row={row}/>
                                </td>
                            </tr>
                        )}
                    </Fragment>
                ))}
            </tbody>
        </table>
    </div>;
};

export default ClientsTable;
