import { Select } from "antd";

import { usePermissionGranter } from "../../../../../../../../hooks/common/usePermissionGranter";
import { useUpdateUserMutation } from "../../../../../../../../services/users";

const EnabledSelector = ({
    userId,
    enabled,
}: {
	userId: string;
	enabled: boolean
}) => {
    const [
        updateUser,
	    { isLoading },
    ] = useUpdateUserMutation();

    const agentsUpdatePermissionIsGranted = usePermissionGranter("users:people", "update");

    return <div className={"horizontal-center"}>
	    {agentsUpdatePermissionIsGranted ? <Select
		    loading={isLoading}
            value={enabled}
		    style={{ width: 140 }}
		    onChange={value => {
			    updateUser({
				    id: userId,
				    data: {
					    enabled: value,
				    },
			    });
		    }}
		    options={[{
                value: true,
			    label: "Enabled",
		    }, {
			    value: false,
			    label: "Disabled",
		    }]}
	    /> : <div>
		    {enabled ? "Enabled" : "Disabled"}
	    </div>}
    </div>;
};

export default EnabledSelector;
