import UnassignedCasesHeader from "./components/UnassignedCasesHeader";
import { columns } from "./components/UnassignedCasesTable/columns";
import UnassignedCasesTable from "./components/UnassignedCasesTable/UnassignedCasesTable";
import { useUnassignedCasesTable } from "../../../../../hooks/cases/useUnassignedCasesTable";
import Spinner from "../../../../basic/Spinner/Spinner";
import Pagination from "../../../../common/Table/components/Pagination";

const CaseAssignment = () => {
    const {
        isLoading,
        table,
        pagination,
        selectedCases,
        assignCasesToAgent,
    } = useUnassignedCasesTable(columns);

    return <div>
        <Spinner isLoading={isLoading}>
            <div>
                <UnassignedCasesHeader
                    selectedCases={selectedCases}
                    assignCasesToAgent={assignCasesToAgent}
                    totalUnassignedCases={pagination.totalDocs}
                />
            </div>

            <div className={"mt10"}>
                <UnassignedCasesTable
                    table={table}
                />
            </div>

            <div className={"mt10"}>
                <Pagination
                    pagination={pagination}
                />
            </div>
        </Spinner>
    </div>;
};

export default CaseAssignment;
