import React from "react";

import { IPageFilterItem } from "../../../../../types/common";

const MainStatusFilterItem = ({
    item,
    count = 0,
    mainStatusId,
    setMainStatusId,
}: {
	count?: number;
	item: IPageFilterItem;
	mainStatusId: string | undefined;
	setMainStatusId: (_mainStatusId: string) => void;
}) => {
    return <div
	    className={`ml40 bold ${mainStatusId === item.id ? "primary-color" : "grey-7-color pointer"}`}
	    style={{ zIndex: 11 }}
	    onClick={() => {
            setMainStatusId(item.id);
	    }}
    >
	    {item.title} {!count ? "" : `(${count})`}
    </div>;
};

export default MainStatusFilterItem;
