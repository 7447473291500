import React, { useEffect, useMemo, useState } from "react";

import { CheckOutlined, ClockCircleOutlined, CloseOutlined } from "@ant-design/icons/lib/icons";
import { Form } from "antd";
import IBAN from "iban";
import moment from "moment";
import { AiOutlineEdit, AiOutlineSave } from "react-icons/ai";

import InfoHeader from "./InfoHeader";
import InfoItem from "./InfoItem";
import { BIC_PATTERN } from "../../../../../../constants/transactions";
import { IClient, IUpdateClientPaymentDetailsByIdBody } from "../../../../../../types/clients";
import { IDocument } from "../../../../../../types/documents";
import EditButton from "../../../../../inputs/Fields/EditButton/EditButton";

const PaymentDetails = ({
    document,
    updateClientPaymentDetailsById,
}: {
	document: IDocument,
	updateClientPaymentDetailsById: (_data: IUpdateClientPaymentDetailsByIdBody) => void;
}) => {
    const client = useMemo(() => document.client as IClient, [document.client]);

    const [isEditing, setIsEditing] = useState(false);

    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue({
            fullName: client.paymentDetails?.fullName,
            bic: client.paymentDetails?.bic,
            iban: client.paymentDetails?.iban,
            address: client.paymentDetails?.address,
        });
    }, [client, form]);

    const planUpdated = useMemo(() => {
        if (!document.plan) return "-";

        let updatedBy = "";
	    let date = "";

        if (document.planUpdated) {
            const dict: { [id: string]: string } = {
                "client": "Client",
                "agent": "Agent",
                "platform": "Auto",
            };

            if (!document.planUpdated.by) updatedBy = dict.platform;
            else updatedBy = dict[document.planUpdated.by];

	        if (!document.planUpdated.date) date = "";
            else date = moment(document.planUpdated.date).local().format("DD MMM YYYY [at] HH:mm");
        }

        return `${document.plan.name}${updatedBy ? ` - ${updatedBy}` : ""}${date ? ` - ${date}` : ""}`;
    }, [document.plan, document.planUpdated]);

    return <div>
        <div
            style={{ height: 50 }}
            className={"horizontal-left"}
        >
            <div className={"h3"}>
				Payment Details
            </div>
	        <div className={"ml20"}>
		        <EditButton
			        isEditMode={isEditing}
			        start={{
				        icon: <AiOutlineEdit/>,
				        label: "Edit",
				        action: () => {
					        setIsEditing(true);
				        },
			        }}
			        finish={{
				        icon: <AiOutlineSave/>,
				        label: "Save",
				        action: () => {
					        form.submit();
				        },
			        }}
		        />
	        </div>
        </div>

	    <div>
		    <Form
			    form={form}
			    layout="vertical"
			    name="userForm"
			    onFinish={values => {
				    updateClientPaymentDetailsById({
					    clientId: client.id,
					    data: {
						    fullName: values.fullName,
						    bic: values.bic,
						    iban: values.iban,
						    address: values.address,
					    },
				    });

				    setIsEditing(false);
			    }}
		    >
			    <div className={"mt30"}>
				    <InfoHeader title={"Payment Details"}/>
			    </div>

			    <div
				    className={"mt10"}
			    >
				    <InfoItem
					    isEditing={isEditing}
					    name={"fullName"}
					    title={"Full Name"}
					    value={client.paymentDetails?.fullName}
					    rules={[{
						    required: true,
						    message: "Full name is required",
					    }, {
						    message: "Full name is wrong",
						    pattern: new RegExp(/^([\w]{3,})+\s+([\w\s]{3,})+$/i),
					    }]}
				    />
			    </div>

			    <div
				    className={"mt10"}
			    >
				    <InfoItem
					    isEditing={isEditing}
					    name={"iban"}
					    title={"IBAN"}
					    value={client.paymentDetails?.iban}
					    rules={[{
						    message: "Wrong IBAN",
						    validator: (_, value) => {
							    if (!IBAN.isValid(value)) return Promise.reject();
							    return Promise.resolve();
						    },
					    }]}
				    />
			    </div>

			    <div
				    className={"mt10"}
			    >
				    <InfoItem
					    isEditing={isEditing}
					    name={"bic"}
					    title={"BIC"}
					    value={client.paymentDetails?.bic}
					    rules={[{
						    message: "Wrong BIC",
						    validator: (_, value) => {
							    if (!BIC_PATTERN.test(value)) return Promise.reject();
							    return Promise.resolve();
						    },
					    }]}
				    />
			    </div>

			    <div
				    className={"mt10"}
			    >
				    <InfoItem
					    isEditing={isEditing}
					    name={"address"}
					    title={"Cheque Address"}
					    value={client.paymentDetails?.address}
				    />
			    </div>

			    <div
				    className={"mt10"}
			    >
				    <InfoItem
					    title={"Last Submitted"}
					    value={
						    client.paymentDetails?.lastUpdate
							    ? moment(client.paymentDetails.lastUpdate).local().format("DD MMM YYYY [at] HH:mm")
							    : "-"
					    }
				    />
			    </div>

			    <div className={"mt30"}>
				    <InfoHeader title={"Transaction Details"}/>
			    </div>

			    <div
				    className={"mt10"}
			    >
				    <InfoItem
					    title={"Payment Plan"}
					    value={planUpdated}
				    />
			    </div>

			    <div
				    className={"mt10"}
			    >
				    <InfoItem
					    title={"Bank Transfer Completed"}
					    value={"-"}
				    />
			    </div>

			    <div className={"mt30"}>
				    <InfoHeader title={"Case Details"}/>
			    </div>

			    <div
				    className={"mt10"}
			    >
				    <InfoItem
					    title={"Agent Link Errors"}
					    value={"-"}
				    />
			    </div>

			    <div
				    className={"mt10"}
			    >
				    <InfoItem
					    title={"Referral Counter"}
					    value={"-"}
				    />
			    </div>
		    </Form>

		    <div className={"mt30"}>
			    <InfoHeader title={"ID Verification"}/>
		    </div>

		    <div className={"mt10"}>

			    <InfoItem
				    title={"IDPal Status:"}
				    value={<div className={"grey-7-color"}>
					    {!client.idpalVerified && <div className={"horizontal-left"}>
                            <div>
                                <ClockCircleOutlined className={"grey-7-color"}/>
                            </div>
                            <div className={"ml6"}>
                                Not verified yet
                            </div>
                        </div>}

					    {client.idpalVerified === "success" && <div className={"horizontal-left"}>
                            <div>
                                <CheckOutlined className={"primary-color"}/>
                            </div>
                            <div className={"ml6"}>
                                ID Verified - {moment(client.verificationDate).format("DD MMM YYYY [at] HH:mm")}
                            </div>
                        </div>}

					    {client.idpalVerified === "failed" && <div className={"horizontal-left"}>
                            <div>
                                <CloseOutlined className={"red-color"}/>
                            </div>
                            <div className={"ml6"}>
                                Verification failed
                            </div>
                        </div>}
				    </div>}
			    />
		    </div>
	    </div>
    </div>;
};

export default PaymentDetails;
