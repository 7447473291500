import { useMemo } from "react";

import { GoDotFill } from "react-icons/go";

import { STATUSES_DICT } from "../../../../../../../../constants/documents";
import { StatusType } from "../../../../../../../../types/documents";

const Status = ({
    value,
}: {
	value: StatusType;
}) => {
    const statusData = useMemo(() => STATUSES_DICT[value], [value]);

    if (!statusData) return <div>-</div>;

    return <div className={"ml10 horizontal-center"}>
        <GoDotFill
            style={{
                color: statusData.color.font,
            }}
        />
    </div>;
};

export default Status;
