import { DeleteOutlined, SyncOutlined } from "@ant-design/icons";
import { Dropdown, MenuProps } from "antd";
import { BsThreeDotsVertical } from "react-icons/bs";

import { usePermissionGranter } from "../../../../../../hooks/common/usePermissionGranter";
import { useModalOpener } from "../../../../../../hooks/helpers/useModalOpener";
import { IDeleteClientDocumentByIdBody, IDocument, ISetDocumentsAgentBody } from "../../../../../../types/documents";
import AssignAgentToCaseModal from "../../../../../modals/Cases/AssignAgentToCaseModal";

const Actions = ({
    value,
    deleteClientDocumentById,
	 setDocumentsAgent,
}: {
	value: IDocument;
	deleteClientDocumentById: (_data: IDeleteClientDocumentByIdBody) => void;
	setDocumentsAgent: (_data: ISetDocumentsAgentBody) => void;
}) => {
    const {
        open: openAssignAgentToCaseModal,
        close: closeAssignAgentToCaseModal,
        isOpened: assignAgentToCaseModalIsOpened,
    } = useModalOpener();

    const changeAgentPermissionIsGranted = usePermissionGranter("documents:assignment", "update");

    const onClick: MenuProps["onClick"] = async ({ key }) => {
        if (key === "assign") {
	        openAssignAgentToCaseModal();
        } else if (key === "delete") {
	        deleteClientDocumentById({
		        documentId: value.id,
	        });
        }
    };

    const items: MenuProps["items"] = [...changeAgentPermissionIsGranted ? [{
        key: "assign",
	    icon: <SyncOutlined className={"grey-7-color"}/>,
	    label: <span
		    data-testid={"assign-case"}
		    className={"grey-7-color"}
	    >
		    Change agent
        </span>,
    }] : [], {
        key: "delete",
	    icon: <DeleteOutlined className={"red-color"}/>,
	    label: <span
		    data-testid={"delete-case"}
		    className={"red-color"}
	    >
		    Delete Case Data
        </span>,
    }];

    return <div>
	    <Dropdown
		    menu={{ items, onClick }}
		    placement="bottomRight"
		    trigger={["click"]}
	    >
            <BsThreeDotsVertical
		        style={{ marginTop: 6 }}
		        className={"fs22 pointer grey-7-color"}
            />
	    </Dropdown>

	    {assignAgentToCaseModalIsOpened && <AssignAgentToCaseModal
		    close={closeAssignAgentToCaseModal}
            setDocumentsAgent={setDocumentsAgent}
		    documentId={value.id}
	    />}
    </div>;
};

export default Actions;
