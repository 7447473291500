import React, { useCallback, useEffect, useMemo, useState } from "react";

import { CheckOutlined, DeleteOutlined, DownloadOutlined, FileFilled, UploadOutlined } from "@ant-design/icons";
import { Flex, message, Modal, Upload } from "antd";
import moment from "moment/moment";

import whitePixel from "../../../assets/images/1-white-pixel.png";
import { useModalOpener } from "../../../hooks/helpers/useModalOpener";
import { useAppDispatch } from "../../../redux/hooks";
import * as filesService from "../../../services/files";
import { IFile } from "../../../types/files";
import { addSpaceEveryNthChar } from "../../../utils/strings";
import Spinner from "../../basic/Spinner/Spinner";

const { Dragger } = Upload;

const CategoryFiles = ({
    requiredDocuments = [],
    files,
    uploadFile,
    deleteFile,
    getFile,
    allowAdditionalFiles,
}: {
    requiredDocuments?: { name: string; forAdminOnly?: boolean }[];
    files: IFile[];
    deleteFile?: (_id: string) => Promise<boolean>;
    uploadFile?: (_file: File, _name: string, _forAdminOnly?: boolean) => Promise<boolean>;
    getFile: (_url: string, _originalName: string) => Promise<void>;
    allowAdditionalFiles?: boolean;
    deletable?: boolean;
}) => {
    const dispatch = useAppDispatch();
    const [currentImagePreview, setCurrentImagePreview] = useState<string>("");
    const [currentFile, setCurrentFile] = useState<IFile | null>(null);
    const [loadingFilePreview, setLoadingFilePreview] = useState<Record<string, boolean>>({});

    const {
        isOpened: openImageModal,
        open: handleOpenImageModal,
        close: handleCloseImageModal,
    } = useModalOpener();

    const handleImageClick = useCallback(async (file: IFile) => {
        setCurrentFile(null);
        setCurrentImagePreview(whitePixel); // to overwrite the previous PDF preview
        setLoadingFilePreview(prevState => ({ ...prevState, [file._id!]: true }));

        try {
            const result = await dispatch(filesService.getFile.initiate({
                url: file.url,
                fileName: file.originalName,
                triggerDownload: false,
            }));
            const fetchedFileBlob = result?.data?.data;
            if (fetchedFileBlob) {
                const objectURL = URL.createObjectURL(fetchedFileBlob);
                setCurrentImagePreview(objectURL);
                setCurrentFile(file);
            } else {
                setCurrentImagePreview("");
                message.error("Could not preview the file.");
            }
        } catch (error) {
            console.error("Error fetching file:", error);
            setCurrentImagePreview("");
            message.error("An error occurred while fetching the file.");
        } finally {
            setLoadingFilePreview(prevState => ({ ...prevState, [file._id!]: false }));
        }
    }, [dispatch]);

    const dragComponent = useCallback((name: string, forAdminOnly?: boolean) => {
        return <div style={{ width: 160 }}>
            <div>
                <Dragger
                    name={"file"}
                    multiple={false}
                    showUploadList={false}
                    disabled={!uploadFile}
                    style={{
                        backgroundColor: "white",
                    }}
                    customRequest={async data => {
                        const result = await uploadFile?.(data.file as File, name, forAdminOnly);

                        if (!result) {
                            await message.error("File was not uploaded!");
                        }

                        return true;
                    }}
                >
                    <div
                        style={{ width: 160, height: 150 }}
                        className={"center"}
                    >
                        {uploadFile ? <div>
                            <div>
                                <UploadOutlined className={"blue-color fs16"}/>
                            </div>
                            <div className={"p10 fs12 grey-7-color"}>
                                Drop file to upload or <span className={"blue-color"}>browse</span>.
                            </div>
                        </div> : <div>
                            <div className={"p10 fs12 grey-7-color"}>
                                File is unavailable for upload.
                            </div>
                        </div>}
                    </div>
                </Dragger>
                <div className={"mt10 fs12"}>
                    {name || "File"}
                </div>
            </div>
        </div>;
    }, [uploadFile]);

    const fileComponent = useCallback((file: IFile) => {
        return <div>
            <Spinner isLoading={!!loadingFilePreview[file._id!]}>
                <div
                    className={"vertical-space-around"}
                    style={{
                        flexDirection: "column",
                        width: 160,
                        minHeight: 150,
                        borderRadius: 8,
                        border: "1px #D7D7D7 solid",
                    }}
                >
                    <div className={"horizontal-right mr10"} style={{ height: "25%" }}>
                        <DownloadOutlined
                            className={"blue-color fs16"}
                            onClick={async () => {
                                await getFile(file.url, file.originalName);
                            }}
                        />
                    </div>
                    <div className={"center"} style={{ height: "50%" }}>
                        <div
                            style={{ cursor: "help" }}
                            onClick={() => handleImageClick(file)}
                        >
                            <div className={"center"}>
                                <FileFilled className={"fs20 primary-color"}/>
                            </div>
                            <div className={"mt4 ph4 grey-7-color center"} style={{ textAlign: "center" }}>
                                {addSpaceEveryNthChar(file.originalName, 16)}
                            </div>
                            {file.createdAt && <div className={"grey-3-color center"}>
                                {moment(file.createdAt).format("DD.MM.YYYY HH:mm")}
                            </div>}
                        </div>
                    </div>
                    <div className={"horizontal-right mr10"} style={{ height: "25%" }}>
                        {deleteFile && <DeleteOutlined
                            className={"red-color fs16"}
                            onClick={async () => {
                                const result = await deleteFile?.(file._id!);

                                if (!result) {
                                    await message.error("File was not deleted!");
                                }
                            }}
                        />}
                    </div>
                </div>
                <div className={"mt10 horizontal-left fs12"} style={{ width: 160 }}>
                    <div>
                        {file.name || "File"}
                    </div>
                    <div className={"ml4"}>
                        <CheckOutlined className={"primary-color"}/>
                    </div>
                </div>

            </Spinner>
        </div>;
    }, [deleteFile, getFile, handleImageClick, loadingFilePreview]);

    const previewComponent = useMemo(() => (
        <Modal
            open={openImageModal}
            footer={null}
            centered={true}
            closable={false}
            onCancel={() => {
                URL.revokeObjectURL(currentImagePreview);
                setCurrentImagePreview("");
                setCurrentFile(null);
                handleCloseImageModal();
            }}
            width={"60%"}
            style={{ margin: "30px" }}
        >
            {currentFile && (
                /\.(jpg|jpeg|png|gif|svg)$/i.test(currentFile.originalName) ? (
                    <img src={currentImagePreview} alt="Preview" style={{ display: "block", width: "100%", height: "auto" }} />
                ) : /\.(pdf)$/i.test(currentFile.originalName) ? (
                    <object data={currentImagePreview} type="application/pdf" width="100%" height="900px">
                        <iframe src={currentImagePreview} width="100%" height="800px" title="File Preview">
                            <p>This browser does not support PDFs. Please download the PDF to view it: {" "}
                                <DownloadOutlined
                                    className={"blue-color fs16"}
                                    onClick={async () => {
                                        await getFile(currentFile.url, currentFile.originalName);
                                    }}
                                />
                            </p>
                        </iframe>
                    </object>
                ) : <p>This browser does not support Files Preview. Please download the File to view it: {" "}
                    <DownloadOutlined
                        className={"blue-color fs16"}
                        onClick={async () => {
                            await getFile(currentFile.url, currentFile.originalName);
                        }}
                    />
                </p>
            )}
        </Modal>
    ), [currentFile, currentImagePreview, getFile, handleCloseImageModal, openImageModal]);

    const filterAdditionalFiles = useCallback((files: IFile[], requiredDocuments: {name: string}[]) => {
        return files.filter(file => {
            return !requiredDocuments.some(requiredDocument => {
                return file.name.includes(requiredDocument.name);
            });
        });
    }, []);

    const filterAdminFiles = useCallback(
        (requiredDocuments: { name: string; forAdminOnly?: boolean }[]):
         [{ name: string; forAdminOnly?: boolean }[], { name: string; forAdminOnly?: boolean }[]] => {
            return requiredDocuments.reduce((acc, doc) => {
                const [clientFiles, adminFiles] = acc;
                if (doc.forAdminOnly) {
                    adminFiles.push(doc);
                } else {
                    clientFiles.push(doc);
                }
                return [clientFiles, adminFiles];
            }, [[], []] as [{ name: string; forAdminOnly?: boolean }[], { name: string; forAdminOnly?: boolean }[]]);
        }, []);

    useEffect(() => {
        if (currentImagePreview) {
            handleOpenImageModal();
        }
    }, [currentImagePreview, handleOpenImageModal]);

    if (requiredDocuments.length) {
        const [clientFiles, adminFiles] = filterAdminFiles(requiredDocuments);
        const additionalFiles = filterAdditionalFiles(files, requiredDocuments);
        return <>
            <Flex wrap="wrap" gap="middle" className={"mb30"}>
                {adminFiles.map((requiredDocument, i) => {
                    const found = files.find(file => file.name.includes(requiredDocument.name));

                    if (!found) return <div key={i}>
                        {dragComponent(requiredDocument.name, true)}
                    </div>;

                    return <div key={i}>
                        {fileComponent(found)}
                    </div>;
                })}
            </Flex>
            <Flex wrap="wrap" gap="middle">
                {clientFiles.map((requiredDocument, i) => {
                    const found = files.find(file => file.name.includes(requiredDocument.name));

                    if (!found) return <div key={i}>
                        {dragComponent(requiredDocument.name)}
                    </div>;

                    return <div key={i}>
                        {fileComponent(found)}
                    </div>;
                })}
                {additionalFiles.map(file => {
                    return <div key={file._id}>
                        {fileComponent(file)}
                    </div>;
                })}
                {allowAdditionalFiles && <div>
                    {dragComponent("Additional File")}
                </div>}
            </Flex>
            {previewComponent}
        </>;
    }

    return <Flex wrap="wrap" gap="middle">
        {files.map(file => {
            return <div key={file._id}>
                {fileComponent(file)}
            </div>;
        })}

        <div>
            {dragComponent("File")}
        </div>

        {previewComponent}
    </Flex>;
};

export default CategoryFiles;
