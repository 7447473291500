import { PreliminaryResultState } from "../types/documents";

export const getPreliminaryResultValue = (state: PreliminaryResultState, value: number | null) => {
    if (!state) return "-";

    if (state === "balanced") {
        return "0";
    } else if (state === "overpayment") {
        return <div className={"primary-color"}>{value || 0}</div>;
    } else {
	    return <div className={"red-color"}>-{value || 0}</div>;
    }
};
