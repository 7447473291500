import { Typography } from "antd";

const TaxNumber = ({
    value,
}: {
	value: string;
}) => {
    if (!value) return <div className={"purple-color horizontal-center"}>-</div>;

    return <Typography.Text
	    className={"purple-color horizontal-center"}
	    strong
	    copyable
    >
        {value}
    </Typography.Text>;
};

export default TaxNumber;
