import { flexRender, Table } from "@tanstack/react-table";

import { IFinancialStats } from "../../../../../../../types/stats";

const ROW_HEIGHT = 40;

const FinancialTable = ({
    table,
}: {
	table: Table<IFinancialStats>;
}) => {
    return <div className={"p10 scroll-block"}>
        <table className={"flipped-table"}>
            <tbody>
                {table.getHeaderGroups().map(headerGroup => (
                    <tr key={headerGroup.id} style={{ minWidth: 200 }}>
                        {headerGroup.headers.map(header => {
                            const isMonthColumn = header.id === "month";
                            return <th
                                key={header.id}
                                className={`${isMonthColumn ? "" : "border-top-1px"}`}
                                style={{ height: ROW_HEIGHT }}
                            >
                                {header.isPlaceholder
                                    ? null
                                    : <div className={"center"}>
                                        {flexRender(
                                            header.column.columnDef.header,
                                            header.getContext()
                                        )}
                                    </div>}
                            </th>;
                        })}
                    </tr>
                ))}
                {table.getRowModel().rows.map(row => {
                    return <tr key={row.id} style={{ minWidth: 120 }}>
                        {row.getVisibleCells().map(cell => {
                            const isMonthColumn = cell.column.id === "month";
                            return <td
                                key={cell.id}
                                style={{ height: ROW_HEIGHT }}
                                className={`fs14 grey-7-color ${isMonthColumn ? "" : "border-top-1px"}`}
                            >
                                <div className={"center"}>
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </div>

                            </td>;
                        })}
                    </tr>;
                })}
            </tbody>
        </table>
    </div>;
};

export default FinancialTable;
