import React, { useContext } from "react";

import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";

import RevenueDetails from "./components/RevenueDetails";
import SubmissionDetails from "./components/SubmissionDetails";
import { useSubmissions } from "../../../../../hooks/cases/useSubmissions";
import { IUpdateDocumentStatusByIdData, IUpdateDocumentStatusByIdResponse } from "../../../../../types/documents";
import Spinner from "../../../../basic/Spinner/Spinner";
import { CaseContext } from "../../Case";

import "react-reflex/styles.css";

const Submission = ({
    updateDocumentStatus,
}: {
	updateDocumentStatus: (_statusData: IUpdateDocumentStatusByIdData) => Promise<IUpdateDocumentStatusByIdResponse>;
}) => {
    const {
        document,
        isLoading: isLoadingOutside,
    } = useContext(CaseContext);

    const {
	    years,
        isLoading,
	    updateSubmissionDetails,
    } = useSubmissions(
	    document?._id
    );

    return <div>
	    <Spinner isLoading={isLoadingOutside || isLoading}>
		    {document && <div
			    className={"p20"}
			    style={{ height: "auto" }}
		    >
			    <div style={{ height: "72vh" }}>
				    <ReflexContainer
					    orientation="vertical"
				    >
					    <ReflexElement
						    className="scroll-block"
						    minSize={400}
					    >
						    <div className={"mr20"}>
							    <SubmissionDetails
								    years={years}
								    updateSubmissionDetails={updateSubmissionDetails}
								    updateDocumentStatus={updateDocumentStatus}
							    />
						    </div>
					    </ReflexElement>

					    <ReflexSplitter
						    style={{ width: 1, borderColor: "#eee" }}
					    />

					    <ReflexElement
						    className="scroll-block"
						    minSize={400}
					    >
						    <div className={"ml20"} style={{ paddingRight: "8px" }}>
							    <RevenueDetails
								    document={document}
							    />
						    </div>
					    </ReflexElement>
				    </ReflexContainer>
			    </div>
		    </div>}
	    </Spinner>
    </div>;
};

export default Submission;
