import { Form, Input } from "antd";
import { SizeType } from "antd/es/config-provider/SizeContext";

import Label from "./../Labels/Label";
import Error from "../Labels/Error";

const NameInput = ({
    required = false,
    size = "large",
    name = "name",
    label = "Name",
}: {
    required?: boolean;
    size?: SizeType;
    name?: string;
    label?: string;
}) => {
    return <div>
        <Form.Item
            name={name}
            label={<Label text={label}/>}
            required={required}
            labelAlign={"left"}
            rules={[
                {
                    required,
                    message: <Error text={"Please input name!"}/>,
                },
                {
                    whitespace: true,
                    message: <Error text={"Please input name!"}/>,
                },
            ]}>
            <Input
                placeholder={"Enter name..."}
                data-testid={name}
                className={"input-content dark-grey-color"}
                size={size}
            />
        </Form.Item>
    </div>;
};

export default NameInput;
