import { useState, useRef, useCallback, useEffect, useMemo } from "react";

import { Button } from "antd";
import ReactFlow, {
    ReactFlowProvider,
    Controls,
    MiniMap,
    Panel,
    Edge,
    ReactFlowInstance,
} from "reactflow";
import { shallow } from "zustand/shallow";

import AddressQuestion from "./Items/AddressQuestionNode";
import AllowMessage from "./Items/AllowMessageNode";
import AmountQuestion from "./Items/AmountQuestionNode";
import ArrayGroup from "./Items/ArrayGroupNode";
import BooleanQuestion from "./Items/BooleanQuestionNode";
import DateQuestion from "./Items/DateQuestionNode";
import DocumentsUpload from "./Items/DocumentsUploadNode";
import ForbiddenMessage from "./Items/ForbiddenMessageNode";
import FormSubTab from "./Items/FormSubTabNode";
import FormTab from "./Items/FormTabNode";
import FormulaNumberQuestion from "./Items/FormulaNumberQuestionNode";
import InfoBlock from "./Items/InfoBlockNode";
import InfoClickBlock from "./Items/InfoClickBlockNode";
import ResizeGroup from "./Items/ResizeGroupNode";
import SelectManyIconsQuestion from "./Items/SelectManyIconsQuestionNode";
import SelectManyQuestion from "./Items/SelectManyQuestionNode";
import SelectOneIconsQuestion from "./Items/SelectOneIconsQuestionNode";
import SelectOneQuestion from "./Items/SelectOneQuestionNode";
import TextQuestion from "./Items/TextQuestionNode";
import WarningBlock from "./Items/WarningBlockNode";
import YearsGroup from "./Items/YearsGroupNode";
import Sidebar from "./Sidebar";
import useStore, { selector } from "./store";
import {
    FlowNode,
    FlowT,
    NodeType,
    OnChangeHandler,
    QuestionsDataT,
} from "../../../../../../types/questionnaires";
import {
    convertDataStructure,
    flowKey,
    getId,
    getNodeData,
} from "../../../../../../utils/constructor";

import "../../../../../../styles/flow.less";
import "reactflow/dist/style.css";

const baseGroupChildren = [
    "amountQuestion",
    "textQuestion",
    "formulaNumberQuestion",
    "infoBlock",
    "warningBlock",
    "infoClickBlock",
    "dateQuestion",
    "booleanQuestion",
    "addressQuestion",
    "selectOneQuestion",
    "selectOneIconsQuestion",
    "selectManyQuestion",
    "selectManyIconsQuestion",
    "documentsUpload",
];
const arrayGroupChildren = ["yearsGroup", ...baseGroupChildren];
const resizeGroupChildren = ["arrayGroup", "yearsGroup", ...baseGroupChildren];
const yearsGroupChildren = baseGroupChildren;

const Flow = ({
    onChange,
    value,
    edit = false,
}: {
    onChange?: OnChangeHandler;
    value?: QuestionsDataT;
    edit?: boolean;
}) => {
    const isConnectable = edit;
    const isDraggable = edit;
    const isSelectable = edit;

    const reactFlowWrapper: any = useRef(null);

    const { nodes, edges, onNodesChange, onEdgesChange, onConnect } = useStore(
        selector,
        shallow
    );

    useEffect(() => {
        useStore.setState({
            nodes: value?.nodes || [],
            edges: value?.edges || [],
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const [reactFlowInstance, setReactFlowInstance] =
        useState<ReactFlowInstance | null>(null);

    const nodeTypes = useMemo(
        () => ({
            formTab: FormTab,
            formSubTab: FormSubTab,
            resizeGroup: ResizeGroup,
            arrayGroup: ArrayGroup,
            yearsGroup: YearsGroup,
            amountQuestion: AmountQuestion,
            infoBlock: InfoBlock,
            warningBlock: WarningBlock,
            infoClickBlock: InfoClickBlock,
            textQuestion: TextQuestion,
            formulaNumberQuestion: FormulaNumberQuestion,
            dateQuestion: DateQuestion,
            booleanQuestion: BooleanQuestion,
            addressQuestion: AddressQuestion,
            selectOneQuestion: SelectOneQuestion,
            selectManyQuestion: SelectManyQuestion,
            selectManyIconsQuestion: SelectManyIconsQuestion,
            selectOneIconsQuestion: SelectOneIconsQuestion,
            documentsUpload: DocumentsUpload,
            forbiddenMessage: ForbiddenMessage,
            allowMessage: AllowMessage,
        }),
        []
    );

    const onSave = useCallback(
        (e: any) => {
            e.preventDefault();
            if (reactFlowInstance) {
                const flow: FlowT = reactFlowInstance.toObject();
                localStorage.setItem(flowKey, JSON.stringify(flow));
                const builderData = convertDataStructure(flow);
                console.log("flow", flow);
                console.log("builderData", builderData);

                onChange &&
                    onChange({
                        ...flow,
                        builderData,
                        timeStamp: new Date().getTime(),
                    });
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [reactFlowInstance]
    );

    const onEdgesDelete = useCallback(
        (e: any) => {
            const targetNodeType = e[0].target?.split("_")[0];
            if (targetNodeType === "forbiddenMessage") {
                const targetEdges = edges.filter((edge: Edge) => {
                    return edge.target === e[0].target;
                });

                if (targetEdges.length < 2) {
                    const updatedNodes = nodes.map((node: FlowNode) => {
                        if (node.id === e[0].target) {
                            return {
                                ...node,
                                data: {
                                    ...node.data,
                                    bindTo: {
                                        id: "",
                                        label: "",
                                    },
                                },
                            };
                        }
                        return node;
                    });

                    useStore.setState({
                        nodes: updatedNodes,
                    });
                }
            }
        },
        [edges, nodes]
    );

    const onNodeConnect = useCallback(
        (e: any) => {
            const sourceNodeType = e.source.split("_")[0];
            const sourceHandleType = e.sourceHandle.split("_")[0];
            const targetNodeType = e.target.split("_")[0];

            if (e.source === e.target) {
                return;
            }

            if (targetNodeType === "forbiddenMessage") {
                const targetNode = nodes.find(
                    (node: FlowNode) => node.id === e.target
                );
                const sourceNode = nodes.find(
                    (node: FlowNode) => node.id === e.source
                );
                const sourceParentNode = nodes.find(
                    (node: FlowNode) => node.id === sourceNode?.parentNode
                );

                if (
                    targetNode?.data?.bindTo?.id &&
                    targetNode?.data?.bindTo?.id !== sourceParentNode?.parentNode
                ) {
                    return;
                }

                if (targetNode && targetNode.data) {
                    const updatedNodes = nodes.map((node: FlowNode) => {
                        if (node.id === targetNode.id) {
                            return {
                                ...node,
                                data: {
                                    ...node.data,
                                    bindTo: {
                                        id: sourceParentNode?.parentNode || "",
                                        label:
                                            nodes.find(
                                                (node: FlowNode) =>
                                                    node.id ===
                                                    sourceParentNode?.parentNode
                                            )?.data?.label || "",
                                    },
                                },
                            };
                        }
                        return node;
                    });

                    useStore.setState({
                        nodes: updatedNodes,
                    });
                }
            }

            if (
                sourceHandleType === "dependency" &&
                (e.targetHandle === "depend" || e.targetHandle === "depend_and")
            ) {
                onConnect(e);
            }

            if (e.sourceHandle === "from" && e.targetHandle === "to") {
                const sourceNode = nodes.find(
                    (node: FlowNode) => node.id === e.source
                );
                const targetNode = nodes.find(
                    (node: FlowNode) => node.id === e.target
                );
                const isNodeConnected = edges.some(
                    (edge: Edge) =>
                        (edge.source === e.source ||
                            edge.target === e.target) &&
                        edge.sourceHandle === "from"
                );

                if (isNodeConnected) {
                    return;
                }

                if (
                    sourceNodeType === "formTab" &&
                    sourceNodeType === targetNodeType
                ) {
                    onConnect(e);
                }

                if (
                    sourceNode?.parentNode === targetNode?.parentNode &&
                    sourceNodeType === targetNodeType &&
                    ["formSubTab", "resizeGroup"].includes(sourceNodeType)
                ) {
                    onConnect(e);
                }

                if (
                    sourceNode?.parentNode === targetNode?.parentNode &&
                    resizeGroupChildren.includes(sourceNodeType) &&
                    resizeGroupChildren.includes(targetNodeType)
                ) {
                    onConnect(e);
                }
            }
        },
        [onConnect, nodes, edges]
    );

    const onRestore = useCallback(
        (e: any) => {
            e.preventDefault();
            const restoreFlow = async () => {
                const flowItem = localStorage.getItem(flowKey);
                if (!flowItem) return;

                const flow: FlowT = JSON.parse(flowItem);
                if (flow) {
                    useStore.setState({
                        nodes: flow.nodes || [],
                        edges: flow.edges || [],
                    });
                }
            };

            restoreFlow();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [useStore]
    );

    const onDragOver = useCallback((event: any) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = "move";
    }, []);

    const onDrop = useCallback(
        (event: any) => {
            event.preventDefault();

            const reactFlowBounds =
                reactFlowWrapper.current.getBoundingClientRect();
            const type: NodeType = event.dataTransfer.getData(
                "application/reactflow"
            );

            if (typeof type === "undefined" || !type) {
                return;
            }

            const position = reactFlowInstance!.project({
                x: event.clientX - reactFlowBounds.left,
                y: event.clientY - reactFlowBounds.top,
            });

            type ParentNodeType =
                | "formTab"
                | "formSubTab"
                | "resizeGroup"
                | "arrayGroup"
                | "yearsGroup";

            const parentNode: string | undefined =
                event?.target?.offsetParent?.dataset?.id;
            const parentNodeType = parentNode?.split("_")[0] as ParentNodeType;

            const parentChildMapping = {
                formTab: ["formSubTab"],
                formSubTab: ["resizeGroup"],
                resizeGroup: resizeGroupChildren,
                arrayGroup: arrayGroupChildren,
                yearsGroup: yearsGroupChildren,
            };

            const validChildNodes =
            parentChildMapping[parentNodeType as ParentNodeType] || [];

            if (parentNode && !validChildNodes.includes(type)) {
                return;
            }

            // Allow nodes to be outside the group only if their type is "Form Tab" or "Message"
            if (
                !parentNode &&
                !["formTab", "forbiddenMessage", "allowMessage"].includes(type)
            ) {
                return;
            }

            const newNode: FlowNode = {
                id: getId(type),
                type,
                position,
                ...(!!parentNode && {
                    parentNode,
                    extent: "parent",
                    position: {
                        x: 3,
                        y: 35,
                    },
                }),
                data: getNodeData(type),
            };

            useStore.setState({
                nodes: nodes.concat(newNode),
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [reactFlowInstance, nodes, useStore]
    );

    return (
        <div className="dndflow">
            <ReactFlowProvider>
                {edit && <Sidebar />}
                <div className="reactflow-wrapper" ref={reactFlowWrapper}>
                    <ReactFlow
                        nodes={nodes}
                        edges={edges}
                        onNodesChange={onNodesChange}
                        onEdgesChange={onEdgesChange}
                        onConnect={onNodeConnect}
                        onInit={setReactFlowInstance}
                        onDrop={onDrop}
                        onDragOver={onDragOver}
                        onEdgesDelete={onEdgesDelete}
                        nodesConnectable={isConnectable}
                        nodesDraggable={isDraggable}
                        elementsSelectable={isSelectable}
                        panOnScroll={true}
                        fitView
                        nodeTypes={nodeTypes}
                    >
                        {edit && (
                            <Panel position="top-right">
                                <div className={"horizontal-right"}>
                                    <div>
                                        <Button
                                            className={"default-button"}
                                            onClick={onRestore}
                                        >
                                            Restore Last Saved
                                        </Button>
                                    </div>
                                    <div className={"ml20"}>
                                        <Button
                                            type={"primary"}
                                            className={"primary-button"}
                                            onClick={onSave}
                                        >
                                            Save State
                                        </Button>
                                    </div>
                                </div>
                            </Panel>
                        )}
                        <MiniMap />
                        {edit && <Controls />}
                    </ReactFlow>
                </div>
            </ReactFlowProvider>
        </div>
    );
};

export default Flow;
