import "./styles/index.less";
import { createContext } from "react";

import { Navigate, Route, Routes } from "react-router-dom";

import PrivateRoute from "./components/basic/PrivateRoute/PrivateRoute";
import Agents from "./components/pages/Agents/Agents";
import Auth from "./components/pages/Auth/Auth";
import Case from "./components/pages/Case/Case";
import Cases from "./components/pages/Cases/Cases";
import Client from "./components/pages/Client/Client";
import Clients from "./components/pages/Clients/Clients";
import Dashboard from "./components/pages/Dashboard/Dashboard";
import Profile from "./components/pages/Profile/Profile";
import Questionnaire from "./components/pages/Questionnaire/Questionnaire";
import { useAuth } from "./hooks/auth/useAuth";
import { usePermissionGranter } from "./hooks/common/usePermissionGranter";
import { IAuthData } from "./types/auth";

const navbarSize = 48;
const pageHeight = `calc(100vh - ${navbarSize}px)`;

export const AppContext = createContext<{
    authData: IAuthData | undefined,
    isAuth: boolean,
    navbarSize: number,
    pageHeight: string,
}>({
    authData: undefined,
    isAuth: false,
    navbarSize,
    pageHeight,
});

const App = () => {
    const {
        authData,
        isAuth,
    } = useAuth();

    const questionnairesPermissionIsGranted = usePermissionGranter("questionnaires", "read");
    const agentsPermissionIsGranted = usePermissionGranter("users:people", "read");
    const dashboardPermissionIsGranted = usePermissionGranter("dashboard", "read");

    return (
        <AppContext.Provider
            value={{
                authData,
                isAuth,
                pageHeight,
                navbarSize,
            }}
        >
            <Routes>
                <Route
                    path="home/*"
                    element={<PrivateRoute
                        permissionCheck={dashboardPermissionIsGranted}
                        component={Dashboard}
                    />}
                />

                <Route
                    path="cases/:documentId/*"
                    element={<PrivateRoute component={Case}/>}
                />

                <Route
                    path="cases"
                    element={<PrivateRoute component={Cases}/>}
                />

                <Route
                    path="agents/*"
                    element={<PrivateRoute
                        permissionCheck={agentsPermissionIsGranted}
                        component={Agents}
                    />}
                />

                <Route
                    path="client/:clientId/*"
                    element={<PrivateRoute component={Client}/>}
                />

                <Route
                    path="clients/*"
                    element={<PrivateRoute component={Clients}/>}
                />

                <Route
                    path="questionnaire/*"
                    element={<PrivateRoute
                        permissionCheck={questionnairesPermissionIsGranted}
                        component={Questionnaire}
                    />}
                />

                <Route
                    path="profile"
                    element={<PrivateRoute component={Profile}/>}
                />

                <Route path="auth" element={<Auth/>}/>

                <Route
                    path="*"
                    element={<Navigate
                        to={"/cases"}
                        replace={true}
                    />}
                />
            </Routes>
        </AppContext.Provider>
    );
};

export default App;
