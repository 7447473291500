import { Permission, PermissionScope } from "../../types/roles";
import { useUser } from "../auth/useUser";

export const usePermissionGranter = (scope: PermissionScope, permission: Permission) => {
    const { user } = useUser();

    if (user.role.permissions) {
        return user.role.permissions[scope]?.includes(permission);
    }

    return false;
};
