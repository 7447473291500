import React, { useMemo } from "react";

import { Timeline } from "antd";
import moment from "moment";

import { STATUSES_DICT } from "../../../../../../../constants/documents";
import { IStatusState } from "../../../../../../../types/documents";

const StatusesHistory = ({
    statusesHistory,
}: {
	statusesHistory: IStatusState[];
}) => {
    const items = useMemo(() => {
        return statusesHistory.map(historyItem => {
            const {
                status,
                subStatuses,
                date,
            } = historyItem;

	        const statusData = STATUSES_DICT[status.id];

            return {
	            label: <div className={"grey-6-color fs12"}>
		            {moment(date).format("DD/MM/YYYY - HH:mm")}
	            </div>,
	            children: <div
		            style={{ height: 50 }}
	            >
		            <div
			            className={"case-status center"}
			            style={{
				            color: statusData.color.font,
				            backgroundColor: statusData.color.background,
			            }}
		            >
			            {statusData.title}
		            </div>
		            {subStatuses.mortgage === "eligible" && <div className={"mt4"}>
			            <div className={"ml10 fs12"}>
				            <span className={"grey-5-color"}>Sub-status:</span>
				            <span className={"ml4 purple-color bold"}>Mortgage</span>
			            </div>
		            </div>}
	            </div>,
	            color: statusData.color.font,
            };
        });
    }, [statusesHistory]);

    return <div className={"p10"}>
	    <div className={"horizontal-left"}>
		    <div className={"h2"}>
			    Statuses History
		    </div>
	    </div>

	    <div className={"mt40"}>
		    {items.length ? <div>
			    <div>
				    <Timeline
					    style={{ marginLeft: -120 }}
					    mode="left"
					    items={items}
				    />
			    </div>
		    </div> : <div>
			    <div className={"grey-6-color"}>
				    There is no history of statuses.
			    </div>
		    </div>}
	    </div>
    </div>;
};

export default StatusesHistory;
