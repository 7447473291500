import "../../../../../../../styles/builder.less";

import React, { useEffect, useState } from "react";

import { Button, Typography } from "antd";

type BooleanQuestionProps = {
    data: {
        question?: string;
        helpText?: string;
        required?: boolean;
    };
    onAnswer: (_answer: boolean) => void;
    value?: boolean;
};

const BooleanQuestion: React.FC<BooleanQuestionProps> = ({
    data,
    onAnswer,
    value,
}) => {
    const [error, setError] = useState<string>("");
    const [skipValidation, setSkipValidation] = useState<boolean>(true);

    useEffect(() => {
        if (data.required && value === undefined && !skipValidation) {
            setError("This field is required");
        } else {
            setError("");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, skipValidation]);

    const handleButtonClick = (value: boolean) => {
        onAnswer(value);
    };

    return (
        <div className="builder__boolean-question">
            <Typography.Text className="builder__form-question">
                {data.question}
            </Typography.Text>
            <div className="builder__boolean-question__buttons">
                <Button
                    className={`builder__boolean-button ${
                        value === true ? "builder__boolean-button-selected" : ""
                    }`}
                    onClick={() => {
                        handleButtonClick(true);
                        setSkipValidation(false);
                    }}
                >
                    Yes
                </Button>
                <Button
                    className={`builder__boolean-button ${
                        value === false ? "builder__boolean-button-selected" : ""
                    }`}
                    onClick={() => {
                        handleButtonClick(false);
                        setSkipValidation(false);
                    }}
                >
                    No
                </Button>
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    marginTop: "5px",
                }}
            >
                <Typography.Text className="builder__help-text">
                    {data.helpText}
                </Typography.Text>
                {error && (
                    <Typography.Text className="builder__form-error">
                        {error}
                    </Typography.Text>
                )}
            </div>
        </div>
    );
};

export default BooleanQuestion;
