import React from "react";

import { Tooltip } from "antd";

const NavigationTooltip = ({
    children,
    title,
}: {
    children: React.ReactNode;
    title: string;
}) => {
    return <div style={{ marginTop: 16 }}>
        <Tooltip
            placement="right"
            overlayStyle={{ borderRadius: 4 }}
            title={title}
            mouseEnterDelay={0.3}
        >
            {children}
        </Tooltip>
    </div>;
};

export default NavigationTooltip;
