import "../../../../../../styles/progress-divider.less";
import React from "react";

interface ProgressDividerProps {
    progress: number;
}

const ProgressDivider: React.FC<ProgressDividerProps> = ({ progress }) => {
    return (
        <div className="divider-container">
            <div
                className="progress-bar"
                style={{ width: `${progress}%` }}
            ></div>
        </div>
    );
};

export default ProgressDivider;
