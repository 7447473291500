import React, { useContext } from "react";

import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

import { IClient } from "../../../../types/clients";
import LogPreview from "../../../common/Logs/LogPreview";
import { CaseContext } from "../Case";

const VerticalDivider = () => {
    return <div style={{ height: 30 }} className={"ml20 border-left-1px"}/>;
};

const CaseHeader = () => {
    const {
        document,
        prevDocumentId,
        nextDocumentId,
    } = useContext(CaseContext);

    const navigate = useNavigate();

    return <div
        data-testid="case-page-header"
        className={"page-header"}
    >
        <div
            className={"page-header-block space-between white-background-color"}
        >
            {document && <div className={"horizontal-left"}>
                <div
                    className={"horizontal-left pointer bold"}
                    onClick={() => navigate(localStorage.getItem("prevLocation") || "/cases")}
                >
                    <div className={"ml20 primary-color"}>
                        <LeftOutlined />
                    </div>
                    <div className={"ml10 primary-color"}>
                        Case Detail ID {document.caseId}
                    </div>
                </div>

                <div className={"ml40 horizontal-left"}>
                    <div className={"grey-7-color"}>
                        {(document.client as IClient).info.firstName} {(document.client as IClient).info.lastName}
                    </div>

                    <VerticalDivider/>

                    <div className={"ml20 grey-7-color"}>
                        {document.ppsn}
                    </div>

                    <VerticalDivider/>

                    <div className={"ml20 grey-7-color"}>
                        {(document.client as IClient).info.phone || "-"}
                    </div>

                    <VerticalDivider/>

                    <div className={"ml20 grey-7-color"}>
                        {(document.client as IClient).info.email || "-"}
                    </div>

                    <VerticalDivider/>

                    <div className={"ml20 grey-7-color"}>
                        <LogPreview logs={document.logs}/>
                    </div>
                </div>
            </div>}

            <div className={"horizontal-right mr20"}>
                <div>
                    <div
                        className={`horizontal-left pointer bold ${prevDocumentId ? "primary-color" : "purple-color"}`}
                        onClick={() => {
                            if (prevDocumentId) {
                                navigate(`/cases/${prevDocumentId}/details`);
                            }
                        }}
                    >
                        <div>
                            <LeftOutlined />
                        </div>
                        <div className={"ml10"}>
                            Previous
                        </div>
                    </div>
                </div>
                <div className={"ml20"}>
                    <div
                        className={`horizontal-left pointer bold ${nextDocumentId ? "primary-color" : "purple-color"}`}
                        onClick={() => {
                            if (nextDocumentId) {
                                navigate(`/cases/${nextDocumentId}/details`);
                            }
                        }}
                    >
                        <div>
                            Next
                        </div>
                        <div className={"ml10"}>
                            <RightOutlined />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>;
};

export default CaseHeader;
