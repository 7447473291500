import React, { useState } from "react";

import { CloseCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { VisibilityState } from "@tanstack/react-table";
import { Button, Checkbox, Input, Popover } from "antd";
import { BsDownload } from "react-icons/bs";
import { FiColumns } from "react-icons/fi";
import { useDebouncedCallback } from "use-debounce";

import CasesFilter from "./CasesFilter/CasesFilter";
import { COLUMNS_FOR_VISIBILITY_CHANGE } from "../../../../constants/documents";
import { useModalOpener } from "../../../../hooks/helpers/useModalOpener";
import { IGlobalFilterProps, ISortBy } from "../../../../types/common";
import { IDocument, StatusGroupData, StatusType } from "../../../../types/documents";
import { Plan } from "../../../../types/plans";
import AgentsFilter from "../../../inputs/Fields/AgentsFilter/AgentsFilter";
import GroupedStatusChangeModal from "../../../modals/Cases/GroupedStatusChangeModal";

const CasesHeader = ({
    columnVisibility,
    setColumnVisibility,
    selectedPaidRange,
    setSelectedPaidRange,
    selectedLastSubmittedRange,
    setSelectedLastSubmittedRange,
    mainStatusId,
    selectedPlanIds,
    setSelectedPlanIds,
    selectedStatusIds,
    setSelectedStatusIds,
    exportDocumentsDataToCsv,
    selectedAgentId,
    setSelectedAgentId,
    selectedCases,
    globalFilter,
    setSorting,
    updateDocumentsStatuses,
}: {
    columnVisibility: VisibilityState;
    setColumnVisibility: (_visibilityState: VisibilityState) => void;
    selectedPaidRange: [number, number];
    setSelectedPaidRange: (_paidRange: [number, number]) => void;
    selectedLastSubmittedRange: [string, string];
    setSelectedLastSubmittedRange: (_selectedLastSubmittedRange: [string, string]) => void;
    selectedPlanIds: (Plan | null)[];
    setSelectedPlanIds: (_selectedPlanId: (Plan | null)[]) => void;
    mainStatusId: string;
    exportDocumentsDataToCsv: (_kind: string) => void;
    selectedAgentId: string | undefined;
    setSelectedAgentId: (_agentId: string) => void;
    selectedCases: IDocument[];
    globalFilter: IGlobalFilterProps;
    selectedStatusIds: StatusType[];
    setSelectedStatusIds: (_selectedStatusIds: StatusType[]) => void;
    setSorting: (_sortBy: ISortBy[]) => void;
    updateDocumentsStatuses: (_groups: StatusGroupData[]) => Promise<string[]>;
}) => {
    const [globalFilterValue, setGlobalFilterValue] = useState(globalFilter.globalFilter);
    const [exportCsvOptionsIsOpened, setExportCsvOptionsIsOpened] = useState(false);

    const {
        isOpened: groupedStatusChangeModalIsOpened,
        open: openGroupedStatusChangeModal,
        close: closeGroupedStatusChangeModal,
    } = useModalOpener();

    const handleSearchChange = useDebouncedCallback((value: string) => {
        return globalFilter.setGlobalFilter(value);
    }, 500);

    return <div className={"p10 space-between"}>
        <div className={"horizontal-left"}>
            <div className={"h2"}>
                All cases
            </div>
            <Popover
                overlayInnerStyle={{
                    marginLeft: 10,
                    marginTop: 20,
                }}
                open={exportCsvOptionsIsOpened}
                onOpenChange={(open: boolean) => {
                    if (selectedCases.length) {
                        setExportCsvOptionsIsOpened(open);
                    }
                }}
                placement="right"
                trigger={["click"]}
                content={<div className={"primary-color bold pointer"}>
                    <div
                        onClick={() => {
                            exportDocumentsDataToCsv("cheque");
                            setExportCsvOptionsIsOpened(false);
                        }}
                    >
                        Cheque
                    </div>
                    <div
                        className={"mt4"}
                        onClick={() => {
                            exportDocumentsDataToCsv("one4all");
                            setExportCsvOptionsIsOpened(false);
                        }}
                    >
                        One4All
                    </div>
                </div>}
                arrow={false}
            >
                <div
                    className={`ml20 horizontal-left pointer ${selectedCases.length ? "grey-6-color" : "grey-4-color"}`}
                >
                    <div className={"mt4 fs18"}>
                        <BsDownload/>
                    </div>
                    <div className={"ml10 fs16"}>
                        Export CSV
                    </div>
                </div>
            </Popover>
        </div>
        <div className={"horizontal-right"}>
            <div>
                <Button
                    disabled={!selectedCases.length}
                    type={"primary"}
                    className={"primary-button"}
                    style={{ width: 180, height: 38 }}
                    onClick={openGroupedStatusChangeModal}
                >
                    Change status
                </Button>
            </div>
            <div className={"ml20"}>
                <Input
                    value={globalFilterValue}
                    size="large"
                    placeholder="Search"
                    data-testid={"cases-search-input"}
                    allowClear={{
                        clearIcon: <div
                            onClick={() => {
                                setGlobalFilterValue("");
                                globalFilter.setGlobalFilter("");
                            }}
                        >
                            <CloseCircleOutlined />
                        </div>,
                    }}
                    onChange={e => {
                        setGlobalFilterValue(e.target.value);
                        handleSearchChange(e.target.value);
                    }}
                    prefix={<SearchOutlined />}
                />
            </div>
            <div className={"ml20"}>
                <AgentsFilter
                    selectedAgentId={selectedAgentId}
                    onAgentSelect={(agentId: string) => {
                        setSelectedAgentId(agentId);
                        setSorting([{
                            id: "createdAt",
                            desc: false,
                        }]);
                    }}
                />
            </div>
            <div className={"ml20"}>
                <CasesFilter
                    selectedPlanIds={selectedPlanIds}
                    setSelectedPlanIds={setSelectedPlanIds}
                    selectedPaidRange={selectedPaidRange}
                    setSelectedPaidRange={setSelectedPaidRange}
                    selectedLastSubmittedRange={selectedLastSubmittedRange}
                    setSelectedLastSubmittedRange={setSelectedLastSubmittedRange}
                    mainStatusId={mainStatusId}
                    selectedStatusIds={selectedStatusIds}
                    setSelectedStatusIds={setSelectedStatusIds}
                />
            </div>

            <div className={"ml20"}>
                <Popover
                    trigger={["click"]}
                    arrow={false}
                    destroyTooltipOnHide
                    placement={"bottomRight"}
                    content={<div style={{ width: 150 }}>
                        {COLUMNS_FOR_VISIBILITY_CHANGE
                            .map(column => {
                                return (
                                    <div key={column.id}>
                                        <label>
                                            <Checkbox
                                                checked={columnVisibility[column.id]}
                                                onChange={({ target: { checked } }) => {
                                                    setColumnVisibility({
                                                        ...columnVisibility,
                                                        [column.id]: checked,
                                                    } as VisibilityState);
                                                }}
                                            />
                                            <span className={"ml10"}>{column.title}</span>
                                        </label>
                                    </div>
                                );
                            })}
                    </div>}
                >
                    <Button
                        size={"large"}
                        type={"default"}
                        icon={<FiColumns style={{ marginBottom: -2 }}/>}
                    />
                </Popover>
            </div>
        </div>

        {groupedStatusChangeModalIsOpened && <GroupedStatusChangeModal
            close={closeGroupedStatusChangeModal}
            selectedCases={selectedCases}
            updateDocumentsStatuses={updateDocumentsStatuses}
        />}
    </div>;
};

export default CasesHeader;
