import React, { useMemo } from "react";

import { Route, Routes } from "react-router-dom";

import CaseAssignment from "./tabs/CaseAssignment/CaseAssignment";
import ListOfAgents from "./tabs/ListOfAgents/ListOfAgents";
import { usePermissionGranter } from "../../../hooks/common/usePermissionGranter";
import { IPageTabItem } from "../../../types/common";
import PageHeader from "../../basic/PageHeader/PageHeader";
import PageTab from "../../common/PageTab/PageTab";

const MENU_ITEMS: IPageTabItem[] = [{
    id: "list",
    title: "List of Agents",
}, {
    id: "assignment",
    title: "Case Assignment",
}];

const Agents = () => {
    const assignmentPermissionIsGranted = usePermissionGranter("documents:assignment", "read");

    const menu = useMemo(() =>
	    MENU_ITEMS
		    .filter(item => {
                return !(item.id === "assignment" && !assignmentPermissionIsGranted);
		    })
		    .map(item => {
			    return <PageTab
				    key={item.id}
				    item={item}
			    />;
	        }),
	    [assignmentPermissionIsGranted]
    );

    return <div>
        <div>
            <PageHeader menu={menu}/>
        </div>

        <div className={"common-page scroll-block"}>
            <div className={"common-block"}>
	            <Routes>
		            <Route path={MENU_ITEMS[0].id} element={<ListOfAgents/>}/>

		            {assignmentPermissionIsGranted &&
			            <Route path={MENU_ITEMS[1].id} element={<CaseAssignment/>}/>
                    }
	            </Routes>
            </div>
        </div>
    </div>;
};

export default Agents;
