import {
    ISendErrorNotificationBody,
} from "../../types/notifications";
import { api } from "../connection/api";

const PARENT_ENDPOINT_PREFIX = "clients";
const ENDPOINT_PREFIX = "notifications";

export const notificationsApi = api.injectEndpoints({
    endpoints: build => ({
        sendErrorNotification: build.mutation<void, ISendErrorNotificationBody>({
            query: data => ({
                url: `${PARENT_ENDPOINT_PREFIX}/${data.clientId}/${ENDPOINT_PREFIX}/errors`,
                method: "POST",
                body: data.data,
            }),
            invalidatesTags: ["Document"],
        }),
    }),

});

export const {
    useSendErrorNotificationMutation,
} = notificationsApi;
