import moment from "moment";

import { IAssignedCase } from "../types/users";

export const getTodayCases = (assignedCases?: IAssignedCase[]) => {
    if (!assignedCases) return "0";
    const today = moment().startOf("day");

    let count = 0;
    for (const caseItem of assignedCases) {
        const managerAssignedDate = moment(caseItem.managerAssigned || caseItem.createdAt);

        if (managerAssignedDate.isSame(today, "day")) {
            count++;
        }
    }

    return count.toString();
};

export const getLastWeekClosed = (assignedCases?: IAssignedCase[]) => {
    if (!assignedCases) return "0";
    const today = moment().startOf("day");
    const sevenDaysAgo = today.clone().subtract(7, "days");

    let count = 0;
    for (const caseItem of assignedCases) {
        if (caseItem.status.id === "submitted-to-revenue" || caseItem.status.id === "nothing-to-submit") {
            const statusChangeDate = moment(caseItem.clientStatusHistory.at(-1)?.date);
            if (statusChangeDate.isBetween(sevenDaysAgo, today, "day", "[]")) {
                count++;
            }
        }
    }

    return count.toString();
};

export const getCurrentCases = (assignedCases?: IAssignedCase[]) => {
    if (!assignedCases) return "0";

    const count = assignedCases.filter(caseData => caseData.status.main !== "archived").length;

    return count.toString();
};
