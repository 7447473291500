import OperationsHeader from "./components/OperationsHeader";
import { getColumns } from "./components/OperationsTable/columns";
import OperationsTable from "./components/OperationsTable/OperationsTable";
import { useOperationsStats } from "../../../../../hooks/dashboard/useOperationsStats";
import Spinner from "../../../../basic/Spinner/Spinner";

const Operations = () => {
    const {
        day,
        setDay,
        table,
        newClientsCount,
        isLoading,
    } = useOperationsStats(getColumns);

    return <div>
        <Spinner isLoading={isLoading}>
            <div className={"p10 space-between"}>
                <OperationsHeader
                    newClientsCount={newClientsCount}
                    day={day}
                    setDay={setDay}
                />
            </div>

            <div className={"mt10"}>
                <OperationsTable
                    table={table}
                />
            </div>
        </Spinner>
    </div>;
};

export default Operations;
