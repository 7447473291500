import "../../../../../../../styles/float-input.less";

import { useState, CSSProperties } from "react";

import { DatePicker } from "antd";
import dayjs from "dayjs";

interface FloatDatePickerProps {
    label: string;
    value?: string;
    minDate?: string;
    maxDate?: string;
    error?: any;
    style?: CSSProperties;
    // eslint-disable-next-line no-unused-vars
    onChange?: (date: dayjs.Dayjs | null) => void;
}

const FloatDatePicker = ({
    label,
    value,
    minDate,
    maxDate,
    error,
    style,
    onChange,
}: FloatDatePickerProps) => {
    const [focus, setFocus] = useState(false);

    const isOccupied = focus || (value && value !== "");

    let labelClass = isOccupied ? "label as-label" : "label as-placeholder";

    if (isOccupied && error) labelClass += " text-error";

    const handleBlur = () => {
        setFocus(false);
    };

    const handleFocus = () => {
        setFocus(true);
    };

    const handleDateChange = (date: dayjs.Dayjs | null) => {
        if (onChange) {
            onChange(date);
        }
        setFocus(date !== null);
    };

    return (
        <div
            className="float-label"
            onBlur={handleBlur}
            onFocus={handleFocus}
            style={style}
        >
            <DatePicker
                onClick={() => onChange && onChange(null)}
                className="input-field"
                style={{ width: "100%" }}
                value={value ? dayjs(value) : null}
                onChange={handleDateChange}
                placeholder=""
                minDate={minDate ? dayjs(minDate) : undefined}
                maxDate={maxDate ? dayjs(maxDate) : undefined}
            />
            <label className={labelClass}>{label}</label>
        </div>
    );
};

export default FloatDatePicker;
