import { DragEvent } from "react";

import { NodeType } from "../../../../../../types/questionnaires";

const Sidebar = () => {
    const onDragStart = (
        event: DragEvent<HTMLDivElement>,
        nodeType: NodeType
    ) => {
        event.dataTransfer.setData("application/reactflow", nodeType);
        event.dataTransfer.effectAllowed = "move";
    };

    return (
        <aside style={{ border: "0px" }}>
            <div className="description">
                You can drag these nodes to the pane on the right.
            </div>
            <div
                className="dndnode"
                onDragStart={event => onDragStart(event, "formTab")}
                draggable
            >
                Form Tab
            </div>
            <div
                className="dndnode"
                onDragStart={event => onDragStart(event, "formSubTab")}
                draggable
            >
                Form Sub Tab
            </div>
            <div
                className="dndnode"
                onDragStart={event => onDragStart(event, "resizeGroup")}
                draggable
            >
                Form Group
            </div>
            <div
                className="dndnode"
                onDragStart={event => onDragStart(event, "arrayGroup")}
                draggable
            >
                Array Group
            </div>
            <div
                className="dndnode"
                onDragStart={event => onDragStart(event, "yearsGroup")}
                draggable
            >
                Years Group
            </div>
            <div
                className="dndnode input"
                onDragStart={event => onDragStart(event, "infoBlock")}
                draggable
            >
                Info Block
            </div>
            <div
                className="dndnode input"
                onDragStart={event => onDragStart(event, "infoClickBlock")}
                draggable
            >
                Info Click Block
            </div>
            <div
                className="dndnode output"
                onDragStart={event => onDragStart(event, "warningBlock")}
                draggable
            >
                Warning Block
            </div>
            <div
                className="dndnode input"
                onDragStart={event => onDragStart(event, "amountQuestion")}
                draggable
            >
                Amount Currency Input
            </div>
            <div
                className="dndnode input"
                onDragStart={event => onDragStart(event, "addressQuestion")}
                draggable
            >
                Address Question
            </div>
            <div
                className="dndnode input"
                onDragStart={event => onDragStart(event, "booleanQuestion")}
                draggable
            >
                Boolean Question
            </div>
            <div
                className="dndnode input"
                onDragStart={event => onDragStart(event, "dateQuestion")}
                draggable
            >
                Date Input
            </div>
            <div
                className="dndnode input"
                onDragStart={event => onDragStart(event, "textQuestion")}
                draggable
            >
                Text Input
            </div>
            <div
                className="dndnode input"
                onDragStart={event => onDragStart(event, "formulaNumberQuestion")}
                draggable
            >
                Formula Number Input
            </div>
            <div
                className="dndnode input"
                onDragStart={event => onDragStart(event, "selectOneQuestion")}
                draggable
            >
                "Select One" Question
            </div>
            <div
                className="dndnode input"
                onDragStart={event =>
                    onDragStart(event, "selectOneIconsQuestion")
                }
                draggable
            >
                "Select One" Question with Icons
            </div>
            <div
                className="dndnode input"
                onDragStart={event =>
                    onDragStart(event, "selectManyQuestion")
                }
                draggable
            >
                "Select Many" Question
            </div>
            <div
                className="dndnode input"
                onDragStart={event =>
                    onDragStart(event, "selectManyIconsQuestion")
                }
                draggable
            >
                "Select Many" Question with Icons
            </div>
            <div
                className="dndnode input"
                onDragStart={event =>
                    onDragStart(event, "documentsUpload")
                }
                draggable
            >
                Documents Upload
            </div>
            <div
                className="dndnode output"
                onDragStart={event => onDragStart(event, "forbiddenMessage")}
                draggable
            >
                Forbidden Message
            </div>
            <div
                style={{ borderColor: "green" }}
                className="dndnode"
                onDragStart={event => onDragStart(event, "allowMessage")}
                draggable
            >
                Allow Message
            </div>
        </aside>
    );
};

export default Sidebar;
