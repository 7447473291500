export const DEFAULT_PLANS = [
    {
        id: "one4all",
        name: "One4All",
        color: "#540087",
    },
    {
        id: "express",
        name: "Express",
        color: "#9E00FF",
    },
    {
        id: "standard",
        name: "Standard",
        color: "#D693FF",
    },
    {
        id: "basic",
        name: "Cheque",
        color: "#F3DFFF",
    },
];

export const POSSIBLE_PLANS = DEFAULT_PLANS.map(plan => plan.id);
