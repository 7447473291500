import { memo, useCallback, useState } from "react";

import {
    Divider,
    Typography,
    Box,
    TextField,
    Button,
    Modal,
    Checkbox,
} from "@mui/material";
import { Handle, Position } from "reactflow";

import { useModalOpener } from "../../../../../../../hooks/helpers/useModalOpener";
import { createChangeHandler } from "../../../../../../../utils/constructor";
import FloatDatePicker from "../../../QuestionnairePreview/components/inputs/FloatDatePicker";
import useStore from "../store";

const TextQuestionNode = (props: any) => {
    const updateNodeData = useStore(state => state.updateNodeData);

    const {
        isOpened: isEditing,
        open: openEditModal,
        close: closeModal,
    } = useModalOpener();

    const [editedQuestion, setEditedQuestion] = useState(props.data.question);
    const [editedRequired, setEditedRequired] = useState(!!props.data.required);
    const [editedDefaultDate, setEditedDefaultDate] = useState(props.data.defaultDate);
    const [editedMinDate, setEditedMinDate] = useState(props.data.minDate);
    const [editedMaxDate, setEditedMaxDate] = useState(props.data.maxDate);

    const saveChanges = useCallback(() => {
        updateNodeData(props.id, {
            question: editedQuestion,
            required: editedRequired,
            defaultDate: editedDefaultDate,
            minDate: editedMinDate,
            maxDate: editedMaxDate,
        });
        closeModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateNodeData, editedQuestion, editedRequired, editedDefaultDate, editedMinDate, editedMaxDate]);

    const handleQuestionChange = createChangeHandler(setEditedQuestion);
    const handleRequiredChange = createChangeHandler(setEditedRequired);

    return (
        <div className="question-node">
            <Handle
                type="target"
                position={Position.Top}
                className="handle-depend"
                style={{ left: 10 }}
                id="depend"
            />
            <Handle
                type="target"
                position={Position.Top}
                className="handle-depend"
                style={{ left: 30 }}
                id="depend_and"
            />
            <Handle type="target" position={Position.Top} id="to" />
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <Typography ml={2} component="div" sx={{ fontSize: "8px" }}>
                    Date input
                </Typography>
                {editedRequired && (
                    <Typography
                        component="div"
                        color={"red"}
                        sx={{ fontSize: "6px", margin: "0px 10px" }}
                    >
                        Required
                    </Typography>
                )}
            </Box>

            <Divider />
            <Box className="question-label-wrapper">
                <Typography
                    ml={1}
                    component="div"
                    sx={{
                        fontSize: "10px",
                        maxWidth: "250px",
                    }}
                >
                    {props.data.question}
                </Typography>
                <Button
                    variant="outlined"
                    onClick={openEditModal}
                    className="button-edit"
                >
                    Edit
                </Button>
            </Box>
            {props.data.defaultDate &&
                <Box>
                    <Typography
                        ml={1}
                        component="div"
                        sx={{
                            fontSize: "10px",
                            maxWidth: "250px",
                        }}
                    >
                        Default Date: {props.data.defaultDate}
                    </Typography>
                </Box>
            }
            {props.data.minDate &&
                <Box>
                    <Typography
                        ml={1}
                        component="div"
                        sx={{
                            fontSize: "10px",
                            maxWidth: "250px",
                        }}
                    >
                        Min Available Date: {props.data.minDate}
                    </Typography>
                </Box>
            }
            {props.data.maxDate &&
                <Box>
                    <Typography
                        ml={1}
                        component="div"
                        sx={{
                            fontSize: "10px",
                            maxWidth: "250px",
                        }}
                    >
                        Max Available Date: {props.data.maxDate}
                    </Typography>
                </Box>
            }
            <Handle type="source" position={Position.Bottom} id="from" />
            {isEditing && (
                <Modal
                    open={isEditing}
                    onClose={saveChanges}
                    className="modal-wrapper"
                    slotProps={{
                        root: {
                            style: {
                                zIndex: "100",
                            },
                        },
                        backdrop: {
                            sx: {
                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                            },
                        },
                    }}
                >
                    <Box className="box-wrapper">
                        <Box
                            sx={{
                                display: "flex",
                                width: "100%",
                                marginBottom: "10px",
                            }}
                        >
                            <Typography component="div">Required</Typography>
                            <Checkbox
                                style={{
                                    padding: "0",
                                }}
                                checked={editedRequired}
                                onChange={handleRequiredChange}
                                color="primary"
                                name="requiredCheckbox"
                            />
                        </Box>
                        <TextField
                            margin="normal"
                            id="text"
                            name="text"
                            value={editedQuestion}
                            onChange={handleQuestionChange}
                            variant="outlined"
                            fullWidth
                            label="Date input label"
                        />
                        <Divider/>
                        <Box justifyContent={"start"} width={"100%"} marginTop={1}>
                            <FloatDatePicker
                                style={{
                                    marginBottom: "5px",
                                    width: "290px",
                                }}
                                label="Default Date"
                                value={editedDefaultDate}
                                error={""}
                                onChange={date => {
                                    const formattedDate = date
                                        ? date.format("YYYY-MM-DD")
                                        : "";
                                    setEditedDefaultDate(formattedDate);
                                }}
                            />
                        </Box>
                        <Box justifyContent={"start"} width={"100%"} marginTop={1}>
                            <FloatDatePicker
                                style={{
                                    marginBottom: "5px",
                                    width: "290px",
                                }}
                                label="Min Available Date"
                                value={editedMinDate}
                                error={""}
                                onChange={date => {
                                    const formattedDate = date
                                        ? date.format("YYYY-MM-DD")
                                        : "";
                                    setEditedMinDate(formattedDate);
                                }}
                            />
                        </Box>
                        <Box justifyContent={"start"} width={"100%"} marginTop={1}>
                            <FloatDatePicker
                                style={{
                                    marginBottom: "5px",
                                    width: "290px",
                                }}
                                label="Max Available Date"
                                value={editedMaxDate}
                                error={""}
                                onChange={date => {
                                    const formattedDate = date
                                        ? date.format("YYYY-MM-DD")
                                        : "";
                                    setEditedMaxDate(formattedDate);
                                }}
                            />
                        </Box>
                        <Box className="button-group">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={saveChanges}
                            >
                                Save
                            </Button>
                        </Box>
                    </Box>
                </Modal>
            )}
        </div>
    );
};

export default memo(TextQuestionNode);
