import { Form, Select } from "antd";
import { SizeType } from "antd/es/config-provider/SizeContext";

import { DEFAULT_REGIONS } from "../../../../constants/regions";
import Label from "../Labels/Label";

const RegionsSelector = ({
    size = "large",
    name = "regions",
    label = "Regions",
    multiple = false,
}: {
    size?: SizeType;
    name?: string;
    label?: string;
    multiple?: boolean;
}) => {
    return <div>
        <Form.Item
            label={<Label text={label}/>}
            name={name}
            labelAlign={"left"}
        >
            <Select
                size={size}
                className={"select-content grey-color"}
                style={{ width: "100%" }}
                mode={multiple ? "multiple" : undefined}
            >
                {DEFAULT_REGIONS.map(region => {
                    return <Select.Option
                        key={region.id}
                        value={region.id}
                        className={"select-option"}
                        labelInValue
                    >
                        <div className={"vertical-center"}>
                            <div>
                                <img
                                    src={region.image}
                                    width={16}
                                    height={16}
                                    alt={"region"}
                                    style={{
                                        marginTop: 8,
                                        borderRadius: "100%",
                                    }}
                                />
                            </div>
                            <div className={"ml6 fs14"}>
                                {region.name}
                            </div>
                        </div>
                    </Select.Option>;
                })}
            </Select>
        </Form.Item>
    </div>;
};

export default RegionsSelector;
