import { api } from "./connection/api";
import {
    IDeleteFileQuery,
    IDeleteFileResponse,
    IGetFileQuery,
    IGetFileResponse,
    IUploadFileBody, IUploadFileResponse,
    IUploadStaticFileBody,
    IUploadStaticFileResponse,
} from "../types/files";

const ENDPOINT_PREFIX = "files";

export const filesApi = api.injectEndpoints({
    endpoints: build => ({
        uploadFile: build.mutation<IUploadFileResponse, IUploadFileBody>({
            query: ({ file, path }) => {
                const formData = new FormData();
                formData.append("file", file);
                formData.append("path", path);

                return {
                    url: `${ENDPOINT_PREFIX}`,
                    method: "POST",
                    body: formData,
                };
            },
        }),
        uploadStaticFile: build.mutation<IUploadStaticFileResponse, IUploadStaticFileBody>({
            query: data => {
                const formData = new FormData();
                formData.append("file", data.data.file);

                return {
                    url: `${ENDPOINT_PREFIX}/static`,
                    method: "POST",
                    body: formData,
                    formData: true,
                };
            },
            invalidatesTags: ["File"],
        }),
        getFile: build.query<IGetFileResponse, IGetFileQuery>({
            queryFn: async (data, _api, _extraOptions, baseQuery) => {
                const result = await baseQuery({
                    url: `${process.env.REACT_APP_API_URL}/${ENDPOINT_PREFIX}?url=${data.url}`,
                    responseHandler: ((response: Response) => response.blob()),
                });

                const fileBlob = result.data as Blob;

                if (data.triggerDownload) {
                    const hiddenElement = document.createElement("a");
                    const url = window.URL || window.webkitURL;
                    hiddenElement.href = url.createObjectURL(fileBlob);
                    hiddenElement.target = "_blank";
                    hiddenElement.download = data.fileName;
                    hiddenElement.click();
                }

                return {
                    error: undefined,
                    data: { data: fileBlob },
                };
            },
            providesTags: [],
        }),
        deleteFile: build.mutation<IDeleteFileResponse, IDeleteFileQuery>({
            query: data => {
                return {
                    url: `${process.env.REACT_APP_API_URL}/${ENDPOINT_PREFIX}?url=${data.url}`,
                    method: "DELETE",
                };
            },
            invalidatesTags: ["File"],
        }),
    }),

});

export const {
    useUploadFileMutation,
    useDeleteFileMutation,
    useUploadStaticFileMutation,
    endpoints: {
        getFile,
    },
} = filesApi;
