import { useMemo } from "react";

import { useGetActualYearsQuery } from "../../services/actual-years";

export const useActualYears = () => {
    const {
        data,
    } = useGetActualYearsQuery();

    const years = useMemo(() => data?.data.actualYears.map(item => item.year) || [], [data?.data.actualYears]);

    return {
        years,
    };
};
