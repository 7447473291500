import { NavLink, useLocation } from "react-router-dom";

import { IClient } from "../../../../../../../../types/clients";
import { IDocument } from "../../../../../../../../types/documents";

const CaseId = ({
    value,
}: {
	value: IDocument;
}) => {
    const location = useLocation();

    return <NavLink
	    className={"blue-color pointer underline horizontal-center"}
	    to={{ pathname: `/cases/${value.id}/details` }}
	    state={{ clientId: (value.client as IClient)._id }}
	    onClick={() => localStorage.setItem("prevLocation", location.pathname)}
    >
	    {value.caseId}
    </NavLink>;
};

export default CaseId;
