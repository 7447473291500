import { FC } from "react";

import { Typography } from "antd";

type WarningBlockProps = {
    data: {
        label?: string;
    };
};

const WarningBlock: FC<WarningBlockProps> = ({ data }) => {
    return (
        <div className="builder__warning-block">
            <Typography.Text className="builder__form-question" type="danger">
                {data.label}
            </Typography.Text>
        </div>
    );
};

export default WarningBlock;
