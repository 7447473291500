import { useCallback } from "react";

import { Button, Col, Form, Modal, Row } from "antd";

import { useCreateUserMutation } from "../../../services/users";
import { ICreateUserForm } from "../../../types/users";
import Spinner from "../../basic/Spinner/Spinner";
import ConfirmPasswordInput from "../../inputs/Form/Inputs/ConfirmPasswordInput";
import EmailInput from "../../inputs/Form/Inputs/EmailInput";
import NameInput from "../../inputs/Form/Inputs/NameInput";
import PasswordInput from "../../inputs/Form/Inputs/PasswordInput";
import PermissionsSelector from "../../inputs/Form/Inputs/PermissionsSelector";
import RegionsSelector from "../../inputs/Form/Inputs/RegionsSelector";
import PasswordManager from "../../inputs/Form/PasswordManager/PasswordManager";

const initialValues: ICreateUserForm = {
    email: "",
    name: "",
    regions: ["IE"],
    password: "",
    roleId: "user",
    shouldChangePassword: false,
};

const CreateAgentModal = ({
    close,
}: {
	 close: () => void;
}) => {
    const [form] = Form.useForm();

    const [createUser, {
        isLoading,
    }] = useCreateUserMutation();

    const onFinish = useCallback(async (values: ICreateUserForm) => {
        await createUser({
            data: {
                info: {
                    email: values.email,
                    name: values.name,
                    password: values.password,
                },
                shouldChangePassword: values.shouldChangePassword,
                regions: values.regions,
                roleId: values.roleId,
            },
        });
        close();
    }, [close, createUser]);

    return <Modal
        open
        width={800}
        title={
            <div className={"p20 modal-header grey-7-color"}>
				Add Agent
            </div>
        }
        afterClose={() => form.resetFields()}
        destroyOnClose
        onCancel={() => close()}
        footer={
            <div
                style={{ height: 40 }} className={"space-between"}>
                <Button
                    type={"link"}
                    onClick={() => close()}
                    className={"default-button"}
                >
					Cancel
                </Button>

                <Button
                    htmlType="submit"
                    onClick={() => form.submit()}
                    type={"primary"}
                    className={"primary-button"}
                >
					Add
                </Button>
            </div>
        }
    >
        <div className={"p20"}>
            <Spinner isLoading={isLoading}>
                <Form
                    form={form}
                    layout={"vertical"}
                    initialValues={initialValues}
                    onFinish={onFinish}
                >
                    <Row gutter={16}>
                        <Col span={12}>
                            <NameInput
                                name={"name"}
                                label={"Name"}
                                required
                            />
                        </Col>
                        <Col span={12}>
                            <EmailInput
                                required
                            />
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <PasswordInput/>
                        </Col>

                        <Col span={12}>
                            <ConfirmPasswordInput/>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={24}>
                            <PasswordManager
                                form={form}
                            />
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <PermissionsSelector
                                label={"Role"}
                                name={"roleId"}
                            />
                        </Col>
                        <Col span={12}>
                            <RegionsSelector
                                label={"Regions"}
                                name={"regions"}
                                multiple
                            />
                        </Col>
                    </Row>
                </Form>
            </Spinner>
        </div>
    </Modal>;
};

export default CreateAgentModal;
