import { useContext } from "react";

import { InboxOutlined } from "@ant-design/icons";

import { useModalOpener } from "../../../../../../../../hooks/helpers/useModalOpener";
import { IClient } from "../../../../../../../../types/clients";
import RevenueLettersModal from "../../../../../../../modals/Files/RevenueLettersModal";
import { ClientsContext } from "../../../ListOfClients";

const RevenueLetters = ({
    value,
    getRevenueLetterFile,
    uploadRevenueLetter,
    deleteRevenueLetter,
}: {
	value: IClient;
	getRevenueLetterFile: (_url: string, _originalName: string) => Promise<void>;
	uploadRevenueLetter: (_client: IClient, _file: File) => Promise<boolean>;
	deleteRevenueLetter: (_client: IClient, _revenueLetterId: string) => Promise<boolean>;
}) => {
    const {
        open: openRevenueLettersModal,
        close: closeRevenueLettersModal,
        isOpened: revenueLettersModalIsOpened,
    } = useModalOpener();

    const { isRevenueLettersLoading } = useContext(ClientsContext);

    return <div
	    className={"horizontal-center blue-color pointer"}
    >
	    <div
		    className={"horizontal-left"}
		    onClick={openRevenueLettersModal}
	    >
		    <div>
			    <InboxOutlined />
		    </div>
		    <div className={"ml6"}>
			    Revenue Letters
		    </div>
	    </div>

	    {revenueLettersModalIsOpened && <RevenueLettersModal
            isLoading={isRevenueLettersLoading}
            revenueLetters={value.revenueLetters}
            getRevenueLetterFile={getRevenueLetterFile}
            uploadRevenueLetter={file => uploadRevenueLetter(value, file)}
            deleteRevenueLetter={id => deleteRevenueLetter(value, id)}
            close={closeRevenueLettersModal}
        />}
    </div>;
};

export default RevenueLetters;
