import {
    IDeleteClientByIdBody, IDeleteClientByIdResponse, IDeleteRevenueLetterByIdBody, IDeleteRevenueLetterByIdResponse,
    IGetClientByIdQuery,
    IGetClientByIdResponse,
    IGetClientsQuery,
    IGetClientsResponse,
    IUpdateClientByIdBody,
    IUpdateClientByIdResponse,
    IUpdateClientPaymentDetailsByIdBody,
} from "../../types/clients";
import { getClientsQueryString } from "../../utils/queries";
import { api } from "../connection/api";

const ENDPOINT_PREFIX = "clients";

export const clientsApi = api.injectEndpoints({
    endpoints: build => ({
        getClients: build.query<IGetClientsResponse, IGetClientsQuery>({
            query: query => ({
                url: `${ENDPOINT_PREFIX}?${getClientsQueryString(query)}`,
            }),
            providesTags: (result, _error, _arg) =>
                (result
                    ? [...result.clients.map(({ id }) => ({ type: "Clients" as const, id })), "Clients"]
                    : ["Clients"]),
        }),
        getClientById: build.query<IGetClientByIdResponse, IGetClientByIdQuery>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/${data.clientId}`,
                method: "GET",
            }),
            providesTags: ["Client"],
        }),
        updateClientById: build.mutation<IUpdateClientByIdResponse, IUpdateClientByIdBody>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/${data.clientId}`,
                method: "PUT",
                body: data.data,
            }),
            invalidatesTags: ["Clients", "Client", "Document", "Documents"],
        }),
        updateClientPaymentDetailsById: build.mutation<IUpdateClientByIdResponse, IUpdateClientPaymentDetailsByIdBody>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/${data.clientId}/payment-details`,
                method: "PATCH",
                body: data.data,
            }),
            invalidatesTags: ["Clients", "Client", "Document", "Documents"],
        }),
        deleteRevenueLetterById: build.mutation<IDeleteRevenueLetterByIdResponse, IDeleteRevenueLetterByIdBody>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/${data.clientId}/revenue-letters/${data.revenueLetterId}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Clients", "Client", "Document"],
        }),
        deleteClientById: build.mutation<IDeleteClientByIdResponse, IDeleteClientByIdBody>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/${data.clientId}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Clients"],
        }),
    }),

});

export const {
    useGetClientsQuery,
    useUpdateClientByIdMutation,
    useDeleteClientByIdMutation,
    useDeleteRevenueLetterByIdMutation,
    useUpdateClientPaymentDetailsByIdMutation,
    useGetClientByIdQuery,
} = clientsApi;
