const Label = ({
    text,
    small = false,
    lightColor = false,
}: {
    text: string;
    small?: boolean;
    lightColor?: boolean;
}) => {
    return <div
        className={`input-label ${lightColor ? "grey-5-color" : "grey-7-color"}`}
        style={{
            fontSize: small ? 13 : 15,
        }}
    >
        {text}
    </div>;
};

export default Label;
