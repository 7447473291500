import { DatePicker } from "antd";
import dayjs from "dayjs";

const format = "YYYY";

const FinancialHeader = ({
    year,
    setYear,
}: {
    year: string;
    setYear: (_year: string) => void;
}) => {
    return <div
        style={{ width: "100%" }}
        className={"p10 space-between"}
    >
        <div className={"horizontal-left"}>
            <div className={"h2"}>
				Financial Data
            </div>
        </div>
        <div className={"horizontal-right"}>
            <div className={"grey-5-color bold"}>
                Year:
            </div>
            <div className={"ml10"}>
                <DatePicker
                    allowClear={false}
                    size={"large"}
                    picker="year"
                    style={{ width: 120 }}
                    value={dayjs(year, format)}
                    disabledDate={date => {
                        return dayjs() < date;
                    }}
                    onChange={date => {
                        if (date) {
                            setYear(date.format(format));
                        }
                    }}
                />
            </div>

        </div>
    </div>;
};

export default FinancialHeader;
