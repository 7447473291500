import {
    BaseQueryFn,
    createApi,
    FetchArgs,
    fetchBaseQuery,
    FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { message } from "antd";

import { logout } from "../../redux/reducers/auth";
import { IMessage } from "../../types/common";
import { getAccessToken } from "../../utils/auth";

const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: headers => {
        const token = getAccessToken();

        if (token) {
            headers.set("Authorization", token);
        }

        return headers;
    },
});

const baseQueryWithIntercept: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
    const result = await baseQuery(args, api, extraOptions);

    if (result.error?.status === 401) {
        api.dispatch(logout());
    }

    if (result.error?.data) {
        message.error((result.error.data as IMessage).message);
    }

    return result;
};

export const api = createApi({
    baseQuery: baseQueryWithIntercept,
    tagTypes: [
        "Auth",
        "Users",
        "Documents",
        "Clients",
        "Client",
        "ClientDocuments",
        "ClientTransactions",
        "Transactions",
        "Document",
        "Questionnaires",
        "Questionnaire",
        "File",
        "ClientLogs",
        "Notifications",
        "ActualYears",
    ],
    endpoints: () => ({}),
});
