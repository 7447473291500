import React, { useContext, useMemo } from "react";

import { Navigate } from "react-router-dom";

import { AppContext } from "../../../App";
import MainWrapper from "../MainWrapper/MainWrapper";

const PrivateRoute = ({
    permissionCheck = true,
    component: Component,
    ...props
}: {
    permissionCheck?: boolean,
    component: React.FC<any>;
    props?: any;
}) => {
    const { isAuth, authData } = useContext(AppContext);

    const permitted = useMemo(() => {
        if (!authData) return false;
        return permissionCheck;
    }, [authData, permissionCheck]);

    if (isAuth) {
        return (
            permitted
                ? <MainWrapper>
                    <Component {...props} />
                </MainWrapper>
                : <Navigate replace to={"/"}/>
        );
    }

    return (
        !localStorage.getItem("token") ? <Navigate replace to="/auth"/> : <div></div>
    );
};

export default PrivateRoute;
