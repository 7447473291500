import React, { useEffect, useMemo } from "react";

import AddressQuestion from "./AddressQuestion";
import AmountQuestion from "./AmountQuestion";
import ArrayGroupQuestion from "./ArrayGroupQuestion";
import BooleanQuestion from "./BooleanQuestion";
import DateQuestion from "./DateQuestion";
import DocumentsUpload from "./DocumentsUpload";
import FormulaNumberQuestion from "./FormulaNumberQuestion";
import { ValidationType } from "./GroupItem";
import InfoBlock from "./InfoBlock";
import InfoClickBlock from "./InfoClickBlock";
import SelectManyIconsQuestion from "./SelectManyIconsQuestion";
import SelectManyQuestion from "./SelectManyQuestion";
import SelectOneIconsQuestion from "./SelectOneIconsQuestion";
import SelectOneQuestion from "./SelectOneQuestion";
import TextQuestion from "./TextQuestion";
import WarningBlock from "./WarningBlock";
import YearsGroupQuestion from "./YearsGroupQuestion";
import { ChildrenT, QuestionType } from "../../../../../../../types/questionnaires";
import { checkDepend } from "../../../../../../../utils/builder";

type QuestionListItemProps = {
    question: ChildrenT;
    // eslint-disable-next-line no-unused-vars
    onAnswer: (questionId: string, answer: any) => void;
    // eslint-disable-next-line no-unused-vars
    setRequired: (questionId: string, required: boolean, validation?: ValidationType) => void;
    // eslint-disable-next-line no-unused-vars
    setLimitsReached?: (questionId: string, reached: boolean) => void;
    answers: Record<string, any>;
    arrayItems?: Record<string, any>[];
};

const QuestionListItem: React.FC<QuestionListItemProps> = ({
    question,
    onAnswer,
    setRequired,
    setLimitsReached,
    answers,
    arrayItems,
}) => {
    const showQuestion = useMemo(() => {
        const show1 = checkDepend(question.depend, answers);
        const show2 = checkDepend(question.andDepend, answers);
        return show1 && show2;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [answers]);

    const handleQuestionAnswer = (answer: any) => {
        onAnswer(question.id, answer);
    };

    useEffect(() => {
        if (!showQuestion) {
            onAnswer(question.id, undefined);
            setRequired(question.id, false);
        } else {
            setRequired(
                question.id,
                !!question.data.required,
                (question.type === QuestionType.FormulaNumber
                    ? undefined
                    : question.data.validation
                )
            );
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showQuestion]);

    return showQuestion ? (
        <div className="form-builder__question">
            {question.type === QuestionType.Text && (
                <TextQuestion
                    data={question.data}
                    onAnswer={handleQuestionAnswer}
                    initialValue={answers[question.id]}
                />
            )}
            {question.type === QuestionType.FormulaNumber && (
                <FormulaNumberQuestion
                    data={question.data}
                    onAnswer={handleQuestionAnswer}
                    initialValue={answers[question.id]}
                />
            )}
            {question.type === QuestionType.Amount && (
                <AmountQuestion
                    data={question.data}
                    onAnswer={handleQuestionAnswer}
                    initialValue={answers[question.id]}
                />
            )}
            {question.type === QuestionType.Info && (
                <InfoBlock data={question.data} />
            )}
            {question.type === QuestionType.Warning && (
                <WarningBlock data={question.data} />
            )}
            {question.type === QuestionType.InfoClick && (
                <InfoClickBlock data={question.data} />
            )}
            {question.type === QuestionType.Date && (
                <DateQuestion
                    data={question.data}
                    onAnswer={handleQuestionAnswer}
                    value={answers[question.id]}
                />
            )}
            {question.type === QuestionType.Boolean && (
                <BooleanQuestion
                    data={question.data}
                    onAnswer={handleQuestionAnswer}
                    value={answers[question.id]}
                />
            )}
            {question.type === QuestionType.Address && (
                <AddressQuestion
                    data={question.data}
                    onAnswer={handleQuestionAnswer}
                    value={answers[question.id]}
                />
            )}
            {question.type === QuestionType.SelectOne && (
                <SelectOneQuestion
                    data={question.data}
                    onAnswer={handleQuestionAnswer}
                    value={answers[question.id]}
                />
            )}
            {question.type === QuestionType.SelectOneIcons && (
                <SelectOneIconsQuestion
                    data={question.data}
                    onAnswer={handleQuestionAnswer}
                    value={answers[question.id]}
                />
            )}
            {question.type === QuestionType.SelectMany && (
                <SelectManyQuestion
                    data={question.data}
                    onAnswer={handleQuestionAnswer}
                    value={answers[question.id]}
                    arrayItems={arrayItems}
                    questionId={question.id}
                    setLimitsReached={setLimitsReached}
                />
            )}
            {question.type === QuestionType.SelectManyIcons && (
                <SelectManyIconsQuestion
                    data={question.data}
                    onAnswer={handleQuestionAnswer}
                    value={answers[question.id]}
                    arrayItems={arrayItems}
                    questionId={question.id}
                    setLimitsReached={setLimitsReached}
                />
            )}
            {question.type === QuestionType.DocumentsUpload && (
                <DocumentsUpload
                    data={question.data}
                    onAnswer={handleQuestionAnswer}
                    value={answers[question.id]}
                />
            )}
            {question.type === QuestionType.ArrayGroup && (
                <ArrayGroupQuestion
                    arrayData={question}
                    onAnswer={handleQuestionAnswer}
                    answers={answers}
                    initialValue={answers[question.id]}
                />
            )}
            {question.type === QuestionType.YearsGroup && (
                <YearsGroupQuestion
                    question={question}
                    onAnswer={handleQuestionAnswer}
                    answers={answers}
                    initialValue={answers[question.id]}
                />
            )}
        </div>
    ) : null;
};

export default QuestionListItem;
