import UsersHeader from "./components/UsersHeader";
import { getColumns } from "./components/UsersTable/columns";
import UsersTable from "./components/UsersTable/UsersTable";
import { useAgentsTable } from "../../../../../hooks/agents/useAgentsTable";
import Spinner from "../../../../basic/Spinner/Spinner";
import Pagination from "../../../../common/Table/components/Pagination";

const ListOfAgents = () => {
    const {
        isLoading,
        table,
        pagination,
        globalFilter,
    } = useAgentsTable(getColumns);

    return <div>
        <Spinner isLoading={isLoading}>
            <div>
                <UsersHeader
                    globalFilter={globalFilter}
                />
            </div>

            <div className={"mt10"}>
                <UsersTable
                    table={table}
                />
            </div>

            <div className={"mt10"}>
                <Pagination
                    pagination={pagination}
                />
            </div>
        </Spinner>
    </div>;
};

export default ListOfAgents;
