import { useCallback, useEffect, useMemo, useState } from "react";

import {
    ColumnDef,
    getCoreRowModel, getFilteredRowModel,
    getSortedRowModel,
    PaginationState, SortingState,
    useReactTable, VisibilityState,
} from "@tanstack/react-table";
import { saveAs } from "file-saver";
import Papa from "papaparse";

import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { documentsSlice } from "../../redux/reducers/documents";
import {
    useDeleteDocumentByIdMutation,
    useGetDocumentsQuery,
    useGetSelectedDocumentsForCsvMutation,
    useSetDocumentsAgentMutation, useUpdateDocumentsStatusesMutation,
} from "../../services/documents";
import { ISortBy } from "../../types/common";
import {
    IDeleteClientDocumentByIdBody,
    IDocument,
    ISetDocumentsAgentBody,
    StatusGroupData,
    StatusType,
} from "../../types/documents";
import { Plan } from "../../types/plans";
import { getGlobalFilterProps, getPaginationProps, getSelectedRows } from "../../utils/table";

export const useCasesTable = (getColumns: ({
    // eslint-disable-next-line no-unused-vars
    deleteClientDocumentById,
    // eslint-disable-next-line no-unused-vars
    setDocumentsAgent,
}: {
    deleteClientDocumentById: (_data: IDeleteClientDocumentByIdBody) => void;
    setDocumentsAgent: (_data: ISetDocumentsAgentBody) => void;
}) => ColumnDef<IDocument>[]) => {
    const dispatch = useAppDispatch();

    const globalFilter = useAppSelector(state => state.documents.filters.globalFilter);
    const mainStatusId = useAppSelector(state => state.documents.filters.mainStatusId);
    const statusIds = useAppSelector(state => state.documents.filters.statusIds);
    const paidRange = useAppSelector(state => state.documents.filters.paidRange);
    const lastSubmittedRange = useAppSelector(state => state.documents.filters.lastSubmittedRange);
    const agentId = useAppSelector(state => state.documents.filters.agentId);
    const planIds = useAppSelector(state => state.documents.filters.planIds);
    const sorting = useAppSelector(state => state.documents.filters.sorting);
    const paginationState = useAppSelector(state => state.documents.paginationState);
    const pagination = useAppSelector(state => state.documents.pagination);
    const columnVisibility = useAppSelector(state => state.documents.columnVisibility);

    const setGlobalFilter = useCallback((value: string) => {
        dispatch(documentsSlice.actions.setGlobalFilter(value));
    }, [dispatch]);

    const setMainStatusId = useCallback((value: string) => {
        dispatch(documentsSlice.actions.setMainStatusId(value));
    }, [dispatch]);

    const setStatusIds = useCallback((value: StatusType[]) => {
        dispatch(documentsSlice.actions.setStatusIds(value));
    }, [dispatch]);

    const setAgentId = useCallback((value: string) => {
        dispatch(documentsSlice.actions.setAgentId(value));
    }, [dispatch]);

    const setSorting = useCallback((value: SortingState) => {
        dispatch(documentsSlice.actions.setSorting(value));
    }, [dispatch]);

    const setPaidRange = useCallback((value: [number, number]) => {
        dispatch(documentsSlice.actions.setPaidRange(value));
    }, [dispatch]);

    const setLastSubmittedRange = useCallback((value: [string, string]) => {
        dispatch(documentsSlice.actions.setLastSubmittedRange(value));
    }, [dispatch]);

    const setPlanIds = useCallback((value: (Plan | null)[]) => {
        dispatch(documentsSlice.actions.setPlanIds(value));
    }, [dispatch]);

    const setPagination = useCallback((value: PaginationState) => {
        dispatch(documentsSlice.actions.setPagination(value));
    }, [dispatch]);

    const setColumnVisibility = useCallback((value: VisibilityState) => {
        dispatch(documentsSlice.actions.setColumnVisibility(value));
    }, [dispatch]);

    const [rowSelection, setRowSelection] = useState({});

    const paginationData = useMemo(
        () => ({
            pageIndex: paginationState.pageIndex,
            pageSize: paginationState.pageSize,
        }),
        [paginationState]
    );

    const {
        data,
        currentData,
        isLoading,
        isFetching,
    } = useGetDocumentsQuery({
        pageIndex: paginationState.pageIndex,
        pageSize: paginationState.pageSize,
        sortBy: sorting as ISortBy[],
        globalFilter,
        managerId: agentId,
        mainStatusId,
        statusIds,
        paidRange,
        lastSubmittedRange,
        planIds,
    });

    const [getSelectedDocumentsForCsv, {
        isLoading: isLoadingDocumentsDataForCsv,
    }] = useGetSelectedDocumentsForCsvMutation();

    const [deleteClientDocumentById, {
        isLoading: isLoadingDocumentDelete,
    }] = useDeleteDocumentByIdMutation();

    const [setDocumentsAgent, {
        isLoading: isLoadingSetDocumentsAgent,
    }] = useSetDocumentsAgentMutation();

    const [updateDocumentsStatusesAsync, {
        isLoading: isLoadingUpdateDocumentsStatuses,
    }] = useUpdateDocumentsStatusesMutation();

    const table = useReactTable(
        {
            columns: useMemo(() => getColumns({
                deleteClientDocumentById,
                setDocumentsAgent,
            }), [getColumns, deleteClientDocumentById, setDocumentsAgent]),
            data: useMemo(() => currentData?.documents || [], [currentData?.documents]),
            pageCount: currentData?.pagination.totalPages,
            state: {
                pagination: paginationData,
                sorting,
                rowSelection,
                globalFilter,
                columnVisibility,
            },
            onColumnVisibilityChange: updater => {
                if (typeof updater === "function") {
                    const nextState = updater(columnVisibility);
                    setColumnVisibility(nextState);
                }
            },
            onGlobalFilterChange: setGlobalFilter,
            onRowSelectionChange: setRowSelection,
            onSortingChange: updater => {
                if (typeof updater === "function") {
                    const nextState = updater(sorting);
                    setSorting(nextState);
                }
            },
            onPaginationChange: updater => {
                if (typeof updater === "function") {
                    const nextState = updater(paginationState);
                    setPagination(nextState);
                }
            },
            getCoreRowModel: getCoreRowModel(),
            getSortedRowModel: getSortedRowModel(),
            getFilteredRowModel: getFilteredRowModel(),
            enableRowSelection: true,
            manualPagination: true,
            manualSorting: true,
            manualFiltering: true,
        }
    );

    useEffect(() => {
        if (pagination.totalDocs < paginationState.pageSize) {
            setPagination({
                pageSize: paginationState.pageSize,
                pageIndex: 0,
            });
        }
    }, [pagination.totalDocs, paginationState.pageSize, setPagination]);

    const selectedCases = useMemo(() => {
        return getSelectedRows(table);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rowSelection]);

    const isDataLoading = useMemo(() => {
        return isLoading ||
            isFetching ||
            isLoadingDocumentsDataForCsv ||
            isLoadingDocumentDelete ||
            isLoadingSetDocumentsAgent ||
            isLoadingUpdateDocumentsStatuses;
    }, [
        isLoading,
        isFetching,
        isLoadingDocumentsDataForCsv,
        isLoadingDocumentDelete,
        isLoadingSetDocumentsAgent,
        isLoadingUpdateDocumentsStatuses,
    ]);

    const exportDocumentsDataToCsv = useCallback(async (kind: string) => {
        const documentsData = await getSelectedDocumentsForCsv({
            data: {
                isOne4All: kind === "one4all",
                documentIds: selectedCases.map(selectedCase => selectedCase.id),
            },
        }).unwrap();

        if (documentsData) {
            const csv = Papa.unparse(documentsData.data.data);
            const blob = new Blob(["\ufeff", csv], { type: "text/csv; charset=UTF-8;" });
            saveAs(blob, "exported_cases.csv");
        }
    }, [getSelectedDocumentsForCsv, selectedCases]);

    const updateDocumentsStatuses = useCallback(async (groups: StatusGroupData[]): Promise<string[]> => {
        const documentsData = await updateDocumentsStatusesAsync({
            data: {
                groups,
            },
        }).unwrap();

        setRowSelection({});

        return documentsData?.data.warnings;
    }, [updateDocumentsStatusesAsync]);

    return {
        isLoading: isDataLoading,
        table,
        count: data?.count,
        selectedCases,
        agentId,
        setAgentId,
        mainStatusId,
        setMainStatusId,
        statusIds,
        setStatusIds,
        paidRange,
        setPaidRange,
        lastSubmittedRange,
        setLastSubmittedRange,
        planIds,
        setPlanIds,
        exportDocumentsDataToCsv,
        updateDocumentsStatuses,
        setSorting,
        columnVisibility,
        setColumnVisibility,
        globalFilter: useMemo(() => {
            return getGlobalFilterProps(table);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [globalFilter]),
        pagination: useMemo(() => {
            return getPaginationProps(table, currentData?.pagination, "Cases");
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [paginationData, currentData?.pagination]),
    };
};
