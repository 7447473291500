import { useContext, useMemo, useState } from "react";

import { Collapse } from "antd";

import DocumentsHeader from "./components/DocumentsHeader";
import { RECEIPTS_TABS } from "../../../../../constants/documents";
import { useActualYears } from "../../../../../hooks/cases/useActualYears";
import { IReceipts } from "../../../../../types/documents";
import Spinner from "../../../../basic/Spinner/Spinner";
import CategoryFiles from "../../../../common/Files/CategoryFiles";
import { CaseContext } from "../../Case";

const Documents = ({
    getInvoiceFile,
    uploadReceipt,
    deleteReceipt,
    uploadQFile,
    deleteQFile,
    getReceiptFile,
    getRevenueLetterFile,
    uploadRevenueLetter,
    deleteRevenueLetter,
}: {
	getInvoiceFile: () => void;
	uploadReceipt: (_file: File, _category: keyof IReceipts, _name: string, _forAdminOnly?: boolean) => Promise<boolean>;
	deleteReceipt: (_id: string, _category: keyof IReceipts) => Promise<boolean>;
	uploadQFile: (_file: File, _category: string, _name: string) => Promise<boolean>;
	deleteQFile: (_id: string, _category: string) => Promise<boolean>;
	getReceiptFile: (_url: string, _originalName: string) => Promise<void>;
	getRevenueLetterFile: (_url: string, _originalName: string) => Promise<void>;
	uploadRevenueLetter: (_file: File) => Promise<boolean>;
	deleteRevenueLetter: (_id: string) => Promise<boolean>;
}) => {
    const {
        isLoading,
        document,
    } = useContext(CaseContext);

    const {
        years,
    } = useActualYears();

    const [activeKeys, setActiveKeys] = useState<string[]>([]);

    const collapseItems = useMemo(() => {
        if (!document) return [];

        const questionnaireFiles = Object.keys(document?.files || {}).map(categoryKey => {
            const categoryData = document?.files[categoryKey];

            if (categoryData?.docs?.length) setActiveKeys(prevState => [...prevState, categoryKey]);

            return {
                key: categoryKey,
                label: <div className={"bold"}>{categoryKey}</div>,
                children: <CategoryFiles
                    requiredDocuments={categoryData?.requiredDocuments}
                    files={categoryData?.docs || []}
                    uploadFile={(file, name) => uploadQFile(file, categoryKey, name)}
                    deleteFile={id => deleteQFile(id, categoryKey)}
                    getFile={getReceiptFile}
                    allowAdditionalFiles={categoryData?.allowAdditionalFiles}
                />,
            };
        });

        const receipts = RECEIPTS_TABS.map(tab => {
            const category = tab.id as keyof IReceipts;
            const files = document?.receipts[category] || [];

            if (files.length) setActiveKeys(prevState => [...prevState, tab.id]);

            let requiredDocuments;

            if (!Array.isArray(tab.required)) {
	            requiredDocuments = tab.required(years);
            } else {
	            requiredDocuments = tab.required;
            }

            return {
                key: tab.id,
                label: <div className={"bold"}>{tab.name}</div>,
                children: <CategoryFiles
                    requiredDocuments={requiredDocuments}
                    files={files}
                    uploadFile={(file, name, forAdminOnly) => uploadReceipt(file, category, name, forAdminOnly)}
                    deleteFile={id => deleteReceipt(id, category)}
                    getFile={getReceiptFile}
                />,
            };
        });

        return [...questionnaireFiles, ...receipts];
    }, [document, getReceiptFile, uploadQFile, deleteQFile, years, uploadReceipt, deleteReceipt]);

    return <div>
	    <Spinner isLoading={isLoading}>
		    <div>
			    <DocumentsHeader
				    getRevenueLetterFile={getRevenueLetterFile}
				    uploadRevenueLetter={uploadRevenueLetter}
				    deleteRevenueLetter={deleteRevenueLetter}
				    getInvoiceFile={getInvoiceFile}
			    />
		    </div>
		    <div className={"mt10"}>
			    <Collapse
				    ghost
				    bordered
				    activeKey={activeKeys}
				    onChange={(key: string[] | string) => setActiveKeys(key as string[])}
				    items={collapseItems}
			    />
		    </div>
	    </Spinner>
    </div>;
};

export default Documents;
