import React from "react";

import { Col, Row } from "antd";

import TransactionsHeader from "./components/TransactionsHeader";
import { getColumns } from "./components/TransactionsTable/columns";
import TransactionsTable from "./components/TransactionsTable/TransactionsTable";
import { useTransactionsTable } from "../../../../../hooks/transactions/useTransactionsTable";
import Spinner from "../../../../basic/Spinner/Spinner";
import Pagination from "../../../../common/Table/components/Pagination";

const Transactions = () => {
    const {
        table,
	    isLoading,
	    pagination,
	    globalFilter,
	    variation,
	    setVariation,
    } = useTransactionsTable(getColumns);

    return <div>
	    <Spinner isLoading={isLoading}>
		    <div className={"p20"}>
			    <Row>
				    <Col span={24}>
                        <div>
                            <div>
                                <TransactionsHeader
                                    globalFilter={globalFilter}
                                    variation={variation}
                                    setVariation={setVariation}
                                />
                            </div>

                            <div className={"mt10"}>
                                <TransactionsTable
                                    table={table}
                                />
                            </div>

	                        <div className={"mt10"}>
		                        <Pagination
			                        pagination={pagination}
		                        />
	                        </div>
                        </div>
				    </Col>
			    </Row>
		    </div>
	    </Spinner>
    </div>;
};

export default Transactions;
