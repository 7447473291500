import { memo, useCallback, useState } from "react";

import {
    Divider,
    Typography,
    Box,
    TextField,
    Button,
    Modal,
    Checkbox,
    ListItemButton,
    Tooltip,
    MenuItem,
} from "@mui/material";
import { Handle, Position } from "reactflow";

import textAreaExample from "../../../../../../../assets/images/form/text-area-example.png";
import textInputExample from "../../../../../../../assets/images/form/text-input-example.png";
import { TEXT_TYPE } from "../../../../../../../constants/constructor";
import { useModalOpener } from "../../../../../../../hooks/helpers/useModalOpener";
import { createChangeHandler } from "../../../../../../../utils/constructor";
import useStore from "../store";

const TextQuestionNode = (props: any) => {
    const updateNodeData = useStore(state => state.updateNodeData);

    const {
        isOpened: isEditing,
        open: openEditModal,
        close: closeModal,
    } = useModalOpener();

    const [editedValidationRegex, setEditedValidationRegex] = useState(props.data.validation?.regex || "");
    const [editedValidationMessage, setEditedValidationMessage] = useState(props.data.validation?.message || "");
    const [editedWarningRegex, setEditedWarningRegex] = useState(props.data.warning?.regex || "");
    const [editedWarningMessage, setEditedWarningMessage] = useState(props.data.warning?.message || "");
    const [editedQuestion, setEditedQuestion] = useState(props.data.question);
    const [editedRequired, setEditedRequired] = useState(!!props.data.required);
    const [editedType, setEditedType] = useState(props.data.selectType || "");
    const [editedKeyboardType, setEditedKeyboardType] = useState(props.data.keyboardType || "");

    const saveChanges = useCallback(() => {
        updateNodeData(props.id, {
            question: editedQuestion,
            required: editedRequired,
            selectType: editedType,
            keyboardType: editedKeyboardType,
            validation: {
                regex: editedValidationRegex,
                message: editedValidationMessage,
            },
            warning: {
                regex: editedWarningRegex,
                message: editedWarningMessage,
            },
        });
        closeModal();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        updateNodeData,
        editedQuestion,
        editedRequired,
        editedValidationRegex,
        editedValidationMessage,
        editedType,
        editedKeyboardType,
        editedWarningRegex,
        editedWarningMessage,
    ]);

    const handleQuestionChange = createChangeHandler(setEditedQuestion);
    const handleRequiredChange = createChangeHandler(setEditedRequired);
    const handleTypeChange = createChangeHandler(setEditedType);
    const handleKeyboardTypeChange = createChangeHandler(setEditedKeyboardType);
    const handleValidationRegexChange = createChangeHandler(setEditedValidationRegex);
    const handleValidationMessageChange = createChangeHandler(setEditedValidationMessage);
    const handleWarningRegexChange = createChangeHandler(setEditedWarningRegex);
    const handleWarningMessageChange = createChangeHandler(setEditedWarningMessage);

    return (
        <div className="question-node">
            <Handle
                type="target"
                position={Position.Top}
                className="handle-depend"
                style={{ left: 10 }}
                id="depend"
            />
            <Handle
                type="target"
                position={Position.Top}
                className="handle-depend"
                style={{ left: 30 }}
                id="depend_and"
            />
            <Handle type="target" position={Position.Top} id="to" />
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <Typography ml={2} component="div" sx={{ fontSize: "8px" }}>
                    Text input
                </Typography>
                {editedType && (
                    <Typography
                        component="div"
                        color={"#2ebb49"}
                        sx={{ fontSize: "6px", margin: "0px 10px" }}
                    >
                        Input type: "{editedType}"
                    </Typography>
                )}
                {editedRequired && (
                    <Typography
                        component="div"
                        color={"red"}
                        sx={{ fontSize: "6px", margin: "0px 10px" }}
                    >
                        Required
                    </Typography>
                )}
            </Box>
            <Divider />
            <Box className="question-label-wrapper">
                <Typography
                    ml={1}
                    component="div"
                    sx={{
                        fontSize: "10px",
                        maxWidth: "250px",
                    }}
                >
                    {props.data.question}
                </Typography>
                <Button
                    variant="outlined"
                    onClick={openEditModal}
                    className="button-edit"
                >
                    Edit
                </Button>
            </Box>
            <Handle type="source" position={Position.Bottom} id="from" />
            {isEditing && (
                <Modal
                    open={isEditing}
                    onClose={saveChanges}
                    className="modal-wrapper"
                    slotProps={{
                        backdrop: {
                            sx: {
                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                            },
                        },
                    }}
                >
                    <Box className="box-wrapper">
                        <Box
                            sx={{
                                display: "flex",
                                width: "100%",
                                marginBottom: "10px",
                            }}
                        >
                            <Typography component="div">Required</Typography>
                            <Checkbox
                                style={{
                                    padding: "0",
                                }}
                                checked={editedRequired}
                                onChange={handleRequiredChange}
                                color="primary"
                                name="requiredCheckbox"
                            />
                        </Box>
                        <TextField
                            margin="normal"
                            id="select-type"
                            select
                            label="Select Type"
                            value={editedType}
                            onChange={handleTypeChange}
                            SelectProps={{
                                renderValue: () => editedType.charAt(0).toUpperCase() + editedType.slice(1),
                            }}
                            variant="outlined"
                            fullWidth
                        >
                            <MenuItem value={TEXT_TYPE.INPUT} style={{ padding: "0" }}>
                                <Tooltip followCursor arrow title={<img src={textInputExample} alt="Text Input Example" style={{ width: "300px", height: "auto" }} />}>
                                    <ListItemButton>
                                        Input
                                    </ListItemButton>
                                </Tooltip>
                            </MenuItem>
                            <MenuItem value={TEXT_TYPE.TEXT_AREA} style={{ padding: "0" }}>
                                <Tooltip followCursor arrow title={<img src={textAreaExample} alt="Text Area Example" style={{ width: "300px", height: "auto" }} />}>
                                    <ListItemButton>
                                        Text Area
                                    </ListItemButton>
                                </Tooltip>
                            </MenuItem>
                        </TextField>
                        <TextField
                            margin="normal"
                            id="select-type"
                            select
                            label="Select Keyboard Type"
                            value={editedKeyboardType}
                            onChange={handleKeyboardTypeChange}
                            variant="outlined"
                            fullWidth
                        >
                            <MenuItem value={"tel"} style={{ padding: "0" }}>
                                <ListItemButton>
                                    Tel
                                </ListItemButton>
                            </MenuItem>
                            <MenuItem value={"number"} style={{ padding: "0" }}>
                                <ListItemButton>
                                    Number
                                </ListItemButton>
                            </MenuItem>
                            <MenuItem value={"text"} style={{ padding: "0" }}>
                                <ListItemButton>
                                    Text
                                </ListItemButton>
                            </MenuItem>
                        </TextField>
                        <TextField
                            margin="normal"
                            id="text"
                            name="text"
                            value={editedQuestion}
                            onChange={handleQuestionChange}
                            variant="outlined"
                            fullWidth
                            label="Text input label"
                        />
                        <Box
                            style={{
                                marginTop: "20px",
                                width: "100%",
                            }}
                        >
                            <Typography component="div" mt={1}>
                                Input Validation:
                            </Typography>
                            <TextField
                                margin="normal"
                                id="validationRegex"
                                name="validationRegex"
                                value={editedValidationRegex}
                                onChange={handleValidationRegexChange}
                                variant="outlined"
                                fullWidth
                                label="Validation RegExp"
                                placeholder="/^[0-9]{7}[A-Za-z]{1,2}$/"
                            />
                            <TextField
                                margin="normal"
                                id="validationMessage"
                                name="validationMessage"
                                value={editedValidationMessage}
                                onChange={handleValidationMessageChange}
                                variant="outlined"
                                fullWidth
                                label="Validation Massage"
                                placeholder="Please enter a valid PPS Number"
                            />
                            <Typography component="div" mt={4}>
                                Warning Validation:
                            </Typography>
                            <TextField
                                margin="normal"
                                id="warningRegex"
                                name="warningRegex"
                                value={editedWarningRegex}
                                onChange={handleWarningRegexChange}
                                variant="outlined"
                                fullWidth
                                label="Warning RegExp"
                                placeholder="Warning RegExp"
                            />
                            <TextField
                                margin="normal"
                                id="warningMessage"
                                name="warningMessage"
                                value={editedWarningMessage}
                                onChange={handleWarningMessageChange}
                                variant="outlined"
                                fullWidth
                                label="Warning Massage"
                                placeholder="Please enter a warning message"
                            />
                        </Box>
                        <Box className="button-group">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={saveChanges}
                            >
                                Save
                            </Button>
                        </Box>
                    </Box>
                </Modal>
            )}
        </div>
    );
};

export default memo(TextQuestionNode);
