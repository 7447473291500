import React from "react";

import Flow from "./components/flow";
import { QuestionnaireFlowDataT } from "../../../../../types/questionnaires";
import Spinner from "../../../../basic/Spinner/Spinner";

const QuestionnaireFlow = ({
    isEditMode,
    isLoadingOutside,
    questionnaireFlowData,
    setQuestionnaireFlowData,
}: {
	isEditMode: boolean;
	isLoadingOutside: boolean;
	questionnaireFlowData?: QuestionnaireFlowDataT;
	setQuestionnaireFlowData: (_data: QuestionnaireFlowDataT) => void;
}) => {
    return <div>
        <Spinner isLoading={isLoadingOutside}>
	        {questionnaireFlowData && <div>
                <div
                    style={{
				        width: "100%",
				        height: "calc(100dvh - 220px)",
			        }}
                >
                    <Flow
                        value={questionnaireFlowData}
                        edit={isEditMode}
                        onChange={setQuestionnaireFlowData}
                    />
                </div>
            </div>}
        </Spinner>
    </div>;
};

export default QuestionnaireFlow;
