import { useState } from "react";

import { Button, Checkbox, message, Modal, Select } from "antd";

import { DEFAULT_PLANS } from "../../../constants/plans";
import { IConfirmPlanForDocumentByIdData } from "../../../types/documents";
import { Plan } from "../../../types/plans";

const SetCasePlanModal = ({
    availablePlans,
    close,
    currentPlanId,
    confirmPlanForDocument,
}: {
    availablePlans: Plan[];
    close: () => void;
    currentPlanId?: Plan;
    confirmPlanForDocument: (_documentData: IConfirmPlanForDocumentByIdData) => void;
}) => {
    const [planId, setPlanId] = useState<Plan | undefined>(currentPlanId);
    const [shouldUpdateStatus, setShouldUpdateStatus] = useState<boolean>(false);

    return <Modal
        open
        width={500}
        title={
            <div className={"p20 modal-header grey-7-color"}>
                Change Case Plan
            </div>
        }
        destroyOnClose
        onCancel={() => close()}
        footer={
            <div
                style={{ height: 40 }} className={"space-between"}>
                <Button
                    type={"link"}
                    onClick={() => close()}
                    className={"default-button"}
                >
                    Cancel
                </Button>

                <Button
                    disabled={!planId}
                    htmlType="submit"
                    onClick={async () => {
                        if (planId === currentPlanId) {
                            return message.error("You cannot set current plan.");
                        }

                        if (planId) {
                            confirmPlanForDocument({
                                plan: planId,
                                shouldUpdateStatus,
                            });
                        }

                        close();
                    }}
                    type={"primary"}
                    className={"primary-button"}
                >
                    Save
                </Button>
            </div>
        }
    >
        <div className={"p20"}>
            <div>
                <div className={"grey-7-color"}>
                    Plan
                </div>

                <div className={"mt10"}>
                    <Select
                        value={planId}
                        onSelect={(value: string) => setPlanId(value as Plan)}
                        size={"large"}
                        className={"select-content grey-color"}
                        style={{ width: "100%" }}
                        placeholder={<span className={"fs14"}>Select plan...</span>}
                    >
                        {DEFAULT_PLANS
                            .filter(plan => availablePlans.includes(plan.id))
                            .map(plan => {
                                return (
                                    <Select.Option
                                        key={plan.id}
                                        value={plan.id}
                                        className={"select-option"}
                                    >
                                        {plan.name}
                                    </Select.Option>
                                );
                            })}
                    </Select>
                </div>

            </div>

            <div className={"mt20"}>
                <div className={"mt10"}>
                    <Checkbox
                        checked={shouldUpdateStatus}
                        onChange={e => setShouldUpdateStatus(e.target.checked)}
                    >
                        Should update status
                    </Checkbox>
                </div>
            </div>
        </div>
    </Modal>;
};

export default SetCasePlanModal;
