import "../../../../../../../styles/form-wrapper.less";
import "dayjs/locale/en-gb";

import React, { ReactNode } from "react";

import { Col, Divider, Layout, Row, Typography } from "antd";

import FormButtonFooter from "./ButtonFooter";
import backIcon from "../../../../../../../assets/images/arrow-back-main.svg";
import { useAppSize } from "../../../../../../../hooks/helpers/useAppSize";
import ProgressDivider from "../ProgressDivider";

const { Content } = Layout;

interface FormPageWrapperProps {
    title: string;
    stepTitle?: string;
    complete: number;
    completeTitle?: string;
    progressMenu: ReactNode;
    formInputs: ReactNode;
    sectionIcon?: any;
    isButtonDisabled?: boolean;
    handleNextButton?: () => void;
    handleBackButton: () => void;
    buttonLabel: string;
}

const FormPageWrapper: React.FC<FormPageWrapperProps> = ({
    title,
    stepTitle,
    complete,
    completeTitle,
    progressMenu,
    formInputs,
    sectionIcon,
    isButtonDisabled,
    handleNextButton,
    handleBackButton,
    buttonLabel,
}) => {
    const isMobile = useAppSize();
    return (
        <>{isMobile
            ? <Layout className="form-wrapper-layout">
                <Row className="form-content">
                    <Col className="form-inputs-column">
                        <Row className="form-input-title-row">
                            <img
                                src={backIcon}
                                alt="back"
                                onClick={handleBackButton}
                                width={28}
                                height={28}
                                style={{ cursor: "pointer" }}
                            />
                            {sectionIcon && (
                                <img
                                    className="section-icon"
                                    src={sectionIcon}
                                    alt="section icon"
                                    width={64}
                                    height={64}
                                    style={{ marginRight: "8px" }}
                                />
                            )}
                            <Col style={{ width: "225px" }}>
                                <Typography className="form-title">
                                    {stepTitle}
                                </Typography>
                                <Typography className="form-complete">
                                    {completeTitle || `${complete || 0}% Complete`}
                                </Typography>
                            </Col>
                        </Row>
                        <ProgressDivider progress={complete} />
                        <Row className="form-content-row">
                            <Content>{formInputs}</Content>
                        </Row>
                    </Col>
                </Row>
                <FormButtonFooter
                    buttonLabel={buttonLabel}
                    isButtonDisabled={isButtonDisabled}
                    handleNextButton={handleNextButton}
                />
            </Layout>
            : <Layout className="form-wrapper-layout">
                <Row className="form-content">
                    <Col className="form-menu-column">
                        <Row className="form-title-row">
                            <img
                                src={backIcon}
                                alt="back"
                                onClick={handleBackButton}
                                width={28}
                                height={28}
                                style={{ cursor: "pointer" }}
                            />
                            <div style={{ margin: "0 auto" }}>
                                <Typography className="form-title">
                                    {title}
                                </Typography>
                            </div>
                        </Row>
                        <Divider className="divider" style={{ margin: "0" }} />
                        <Row className="form-content-row">
                            <Content>{progressMenu}</Content>
                        </Row>
                    </Col>
                    <Col className="form-inputs-column">
                        <Row className="form-input-title-row">
                            {sectionIcon && (
                                <img
                                    className="section-icon"
                                    src={sectionIcon}
                                    alt="section icon"
                                    width={64}
                                    height={64}
                                />
                            )}
                            <Col>
                                <Typography className="form-title">
                                    {stepTitle}
                                </Typography>
                                <Typography className="form-complete">
                                    {completeTitle || `${complete || 0}% Complete`}
                                </Typography>
                            </Col>
                        </Row>
                        <ProgressDivider progress={complete} />
                        <Row className="form-content-row">
                            <Content>{formInputs}</Content>
                        </Row>
                        <button
                            className="next-button"
                            disabled={isButtonDisabled}
                            onClick={handleNextButton}
                        >
                            {buttonLabel}
                        </button>
                    </Col>
                </Row>
            </Layout>
        }</>
    );
};
export default FormPageWrapper;
