import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";

import {
    Typography,
    Box,
    TextField,
    Button,
    Modal,
    Divider,
    Checkbox,
    ListItemButton,
    MenuItem,
} from "@mui/material";
import { Handle, Position, NodeResizer } from "reactflow";

import { useModalOpener } from "../../../../../../../hooks/helpers/useModalOpener";
import { useGetActualYearsQuery } from "../../../../../../../services/actual-years";
import { createChangeHandler } from "../../../../../../../utils/constructor";
import useStore from "../store";

const YearsGroupNode = (props: any) => {
    const {
        data,
    } = useGetActualYearsQuery();

    const years = useMemo(() => data?.data.actualYears.map(item => item.year) || [], [data?.data.actualYears]);

    const { nodes, updateNodeData } = useStore();

    const childrenNodes = useMemo(
        () => nodes.filter(n => n.parentNode === props.id),
        [nodes, props.id]
    );

    const childrenQuestions = useMemo(
        () =>
            childrenNodes.filter(n => {
                const nodeType = n.id?.split("_")[0];
                return [
                    "textQuestion",
                    "formulaNumberQuestion",
                    "dateQuestion",
                    "selectOneQuestion",
                    "amountQuestion",
                    "booleanQuestion",
                    "addressQuestion",
                    "selectOneIconsQuestion",
                    "selectManyQuestion",
                    "selectManyIconsQuestion",
                ].includes(nodeType);
            }),
        [childrenNodes]
    );

    const {
        isOpened: isEditing,
        open: openEditModal,
        close: closeModal,
    } = useModalOpener();

    const [editedQuestion, setEditedQuestion] = useState(props.data.question);
    const [editedYearsBind, setEditedYearsBind] = useState<Record<string, string>>({});
    const [editedRequired, setEditedRequired] = useState(!!props.data.required);

    const initialBindRef = useRef(false);
    useEffect(() => {
        if (years.length > 0 && !initialBindRef.current) {
            setEditedYearsBind(
                Object.keys(props.data.yearsBind || {})
                    .filter(year => years.includes(year))
                    .reduce((acc, year) => {
                        acc[year] = props.data.yearsBind[year];
                        return acc;
                    }, {} as Record<string, string>)
            );
            initialBindRef.current = true;
        }
    }, [years, props.data.yearsBind]);

    const saveChanges = useCallback(() => {
        updateNodeData(props.id, {
            question: editedQuestion,
            yearsBind: editedYearsBind,
            required: editedRequired,
        });
        closeModal();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        updateNodeData,
        editedQuestion,
        editedYearsBind,
        editedRequired,
    ]);

    const handleQuestionChange = createChangeHandler(setEditedQuestion);
    const handleRequiredChange = createChangeHandler(setEditedRequired);

    const handleYearsBindChange = useCallback(
        (value: string, year: string) => {
            if (years.includes(year)) {
                setEditedYearsBind(prev => ({
                    ...prev,
                    [year]: value,
                }));
            }
        },
        [setEditedYearsBind, years]
    );

    const getQuestionTextById = (id: string) => {
        const foundQuestion = childrenQuestions.find(q => q.id === id);
        return foundQuestion ? foundQuestion.data.question : "";
    };

    return (
        <div className="years-group-node">
            <NodeResizer
                color="#ff0071"
                isVisible={props.selected}
                minWidth={300}
                minHeight={200}
            />
            <Handle
                type="target"
                position={Position.Top}
                className="handle-depend"
                style={{ left: "15%" }}
                id="depend"
            />
            <Handle
                type="target"
                position={Position.Top}
                className="handle-depend"
                style={{ left: "20%" }}
                id="depend_and"
            />
            <Handle type="target" position={Position.Top} id="to" />
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <Typography ml={2} component="div" sx={{ fontSize: "8px" }}>
                    Years Group question
                </Typography>
                {editedRequired && (
                    <Typography
                        component="div"
                        color={"red"}
                        sx={{ fontSize: "6px", margin: "0px 10px" }}
                    >
                        Required
                    </Typography>
                )}
            </Box>
            <Divider />
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "8px",
                }}
            >
                <Typography
                    ml={1}
                    variant="subtitle1"
                    component="div"
                    sx={{
                        fontSize: "12px",
                    }}
                >
                    {props.data.label}
                </Typography>
                <Button
                    variant="outlined"
                    onClick={openEditModal}
                    className="button-edit"
                >
                    Edit
                </Button>
            </Box>
            <Box>
                <Typography
                    ml={1}
                    variant="subtitle1"
                    component="div"
                    sx={{
                        fontSize: "10px",
                    }}
                >
                    {`Question:  "${props.data.question}"`}
                </Typography>
            </Box>
            <Box>
                {years.map(year => (
                    <Typography
                        key={year}
                        ml={1}
                        component="div"
                        sx={{ fontSize: "10px" }}
                    >
                        {`Bind ${year}: ${getQuestionTextById(editedYearsBind[year]) || ""}`}
                    </Typography>
                ))}
            </Box>
            <Handle type="source" position={Position.Bottom} id="from" />
            {isEditing && (
                <Modal
                    open={isEditing}
                    onClose={saveChanges}
                    className="modal-wrapper"
                    slotProps={{
                        backdrop: {
                            sx: {
                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                            },
                        },
                    }}
                >
                    <Box className="box-wrapper">
                        <Box
                            sx={{
                                display: "flex",
                                width: "100%",
                                marginBottom: "10px",
                            }}
                        >
                            <Typography component="div">Required</Typography>
                            <Checkbox
                                style={{
                                    padding: "0",
                                }}
                                checked={editedRequired}
                                onChange={handleRequiredChange}
                                color="primary"
                                name="requiredCheckbox"
                            />
                        </Box>
                        <TextField
                            margin="normal"
                            id="question"
                            name="question"
                            value={editedQuestion}
                            onChange={handleQuestionChange}
                            variant="outlined"
                            fullWidth
                            label="Years Group Question"
                        />
                        {years.map(year => (
                            <TextField
                                key={`select-${year}-bind`}
                                margin="normal"
                                id={`select-${year}-bind`}
                                select
                                label={`Select Bind Question for ${year} year`}
                                value={editedYearsBind[year] || ""}
                                onChange={(event: any) =>
                                    handleYearsBindChange(event.target.value, year)
                                }
                                variant="outlined"
                                fullWidth
                            >
                                {childrenQuestions.map(q => (
                                    <MenuItem
                                        key={q.id + "subtitle"}
                                        value={q.id}
                                        style={{ padding: "0" }}
                                    >
                                        <ListItemButton>
                                            {q.data.question}
                                        </ListItemButton>
                                    </MenuItem>
                                ))}
                                <MenuItem
                                    key="empty"
                                    value=""
                                    style={{ padding: "0" }}
                                >
                                    <ListItemButton>Empty</ListItemButton>
                                </MenuItem>
                            </TextField>
                        ))}

                        <Box className="button-group">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={saveChanges}
                            >
                                Save
                            </Button>
                        </Box>
                    </Box>
                </Modal>
            )}
        </div>
    );
};

export default memo(YearsGroupNode);
