import { TRANSACTION_STATUSES_DICT } from "../../../../../../../../constants/transactions";
import { TransactionStatus } from "../../../../../../../../types/transactions";

const Amount = ({
    value,
}: {
	value: TransactionStatus;
}) => {
    return <div style={{ color: TRANSACTION_STATUSES_DICT[value].color.font }} className={"horizontal-center"}>
        {TRANSACTION_STATUSES_DICT[value].title}
    </div>;
};

export default Amount;
