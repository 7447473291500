import { useState, FC, useEffect } from "react";

import useDebounce from "../../../../../../../hooks/helpers/useDebounce";
import { isAnswerValid } from "../../../../../../../utils/builder";
import CustomFormItem from "../FormWrapper/FormItemWrapper";
import FloatInput from "../inputs/FloatInput";

type AmountQuestionProps = {
    data: {
        question?: string;
        currency?: string;
        helpText?: string;
        required?: boolean;
        validation?: {
            regex: string;
            message: string;
        };
        warning?: {
            regex: string;
            message: string;
        };
    };
    onAnswer: (_answer: string) => void;
    initialValue?: string;
};

const AmountQuestion: FC<AmountQuestionProps> = ({
    data,
    onAnswer,
    initialValue = "",
}) => {
    const [answer, setAnswer] = useState<string>(initialValue);
    const [error, setError] = useState<string>("");
    const [warning, setWarning] = useState<string>("");
    const [skipValidation, setSkipValidation] = useState<boolean>(true);

    useEffect(() => {
        if (data.required && answer === "" && !skipValidation) {
            setError("This field is required");
        } else if (data.validation?.regex && !skipValidation) {
            const valid = isAnswerValid(answer, data.validation?.regex);
            !valid ? setError(data.validation.message) : setError("");
        } else {
            setError("");
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [answer, skipValidation]);

    const debouncedAnswer = useDebounce(answer, 300);

    const handleAnswerChange = (value: string) => {
        setSkipValidation(false);
        setAnswer(value);
    };

    useEffect(() => {
        if (initialValue === debouncedAnswer) return;
        onAnswer(debouncedAnswer);
        const valid = isAnswerValid(answer, data.warning?.regex);
        !valid ? setWarning(data.warning?.message || "") : setWarning("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedAnswer]);

    return (
        <div className="builder__amount-question">
            <CustomFormItem
                key={data.question}
                helpText={data.helpText}
                error={error || warning}
            >
                <FloatInput
                    style={{
                        marginBottom: "5px",
                    }}
                    label={data.question || ""}
                    placeholder={data.currency}
                    value={answer}
                    error={error || warning}
                    type={"tel"}
                    setSkipValidation={setSkipValidation}
                    onChange={(event: any) => {
                        handleAnswerChange(event.target.value);
                    }}
                />
            </CustomFormItem>
        </div>
    );
};

export default AmountQuestion;
