import { useMemo } from "react";

import { Route, Routes } from "react-router-dom";

import QuestionnaireHeader from "./components/QuestionnaireHeader/QuestionnaireHeader";
import QuestionnaireItem from "./components/QuestionnaireItem/QuestionnaireItem";
import QuestionnaireFlow from "./tabs/QuestionnaireFlow/QuestionnaireFlow";
import QuestionnairePreview from "./tabs/QuestionnairePreview/QuestionnairePreview";
import { useQuestionnaire } from "../../../hooks/questionnaires/useQuestionnaire";
import { useQuestionnairesList } from "../../../hooks/questionnaires/useQuestionnairesList";
import { IPageTabItem } from "../../../types/common";
import PageHeader from "../../basic/PageHeader/PageHeader";
import PageTab from "../../common/PageTab/PageTab";

const MENU_ITEMS: IPageTabItem[] = [{
    id: "constructor",
    title: "Questionnaire Flow",
}, {
    id: "preview",
    title: "Questionnaire Preview",
}];

const Questionnaire = () => {
    const {
        selectedQuestionnaireId,
        setSelectedQuestionnaireId,
        questionnaires,
    } = useQuestionnairesList();

    const {
        isLoading,
        questionnaire,
        isEditMode,
        setIsEditMode,
        questionnaireFlowData,
        setQuestionnaireFlowData,
        updateQuestionnaire,
        refetchQuestionnaire,
    } = useQuestionnaire(selectedQuestionnaireId);

    const menu = useMemo(() =>
        MENU_ITEMS.map(item =>
            <PageTab
                key={item.id}
                item={item}
            />),
    []);

    const rightMenu = useMemo(() => {
        return questionnaires.map(questionnaire => {
            return <QuestionnaireItem
                key={questionnaire.id}
                questionnaire={questionnaire}
                disabled={isEditMode}
                selectedQuestionnaireId={selectedQuestionnaireId}
                setSelectedQuestionnaireId={setSelectedQuestionnaireId}
            />;
        });
    }, [
        isEditMode,
        questionnaires,
        selectedQuestionnaireId,
        setSelectedQuestionnaireId,
    ]);

    return <div>
        <div>
            <PageHeader
                menu={menu}
                rightMenu={rightMenu}
            />
        </div>

        <div className={"common-page scroll-block"}>
            <div className={"common-block-header"}>
                <QuestionnaireHeader
                    questionnaireInfo={questionnaire?.info}
                    isEditMode={isEditMode}
                    startEditing={() => {
                        setIsEditMode(true);
                    }}
                    cancelEditing={() => {
                        refetchQuestionnaire();
                        setIsEditMode(false);
                    }}
                    saveChanges={() => {
                        updateQuestionnaire()
                            .then(() => {
                                setIsEditMode(false);
                            });
                    }}
                />
            </div>
            <div className={"common-block"}>
                <Routes>
                    <Route
                        path={MENU_ITEMS[0].id}
                        element={<QuestionnaireFlow
                            isEditMode={isEditMode}
                            questionnaireFlowData={questionnaireFlowData}
                            isLoadingOutside={isLoading}
                            setQuestionnaireFlowData={setQuestionnaireFlowData}
                        />}
                    />

                    <Route
                        path={MENU_ITEMS[1].id}
                        element={<QuestionnairePreview
                            questionnaireFlowData={questionnaireFlowData}
                            isLoadingOutside={isLoading}
                        />}
                    />
                </Routes>
            </div>
        </div>
    </div>;
};

export default Questionnaire;
