import React, { useMemo } from "react";

import { DownOutlined } from "@ant-design/icons";
import { Dropdown } from "antd";
import moment from "moment";
import { NavLink, useLocation } from "react-router-dom";

import { useSubmissionsSelector } from "../../../../../../../../hooks/cases/useSubmissionsSelector";
import { IDocument } from "../../../../../../../../types/documents";
import {
    IAssignCaseToTransactionData,
    ITransaction,
} from "../../../../../../../../types/transactions";

const CaseId = ({
    assignCaseToTransaction,
    value,
}: {
	assignCaseToTransaction: (_transactionId: string, _data: IAssignCaseToTransactionData) => void;
	value: ITransaction;
}) => {
    const location = useLocation();

    const clientId = useMemo(() => value.client as string, [value.client]);
    const transactionDocument = useMemo(() => value.document as IDocument, [value.document]);

    const {
	    documents,
    } = useSubmissionsSelector(clientId);

    const items = useMemo(() => {
        return documents.map(document => {
            const disabled = transactionDocument?._id === document._id;

            return {
                key: document._id,
	            label: <div className={disabled ? "grey-6-color" : "blue-color"}>
		            <span className={"underline"}>{document.caseId}</span> - {moment(document.createdAt).format("DD/MM/YYYY")}
                </div>,
	            disabled,
            };
        });
    }, [documents, transactionDocument?._id]);

    return <div>
	    <Dropdown.Button
		    className={"horizontal-center"}
		    menu={{
                items,
			    onClick: e => {
                    assignCaseToTransaction(value._id, {
                        documentId: e.key,
                    });
			    },
		    }}
		    type={"link"}
		    icon={<DownOutlined/>}
		    onClick={() => null}
        >
		    {transactionDocument ? <NavLink
			    className={"blue-color pointer underline"}
			    to={{ pathname: `/cases/${transactionDocument._id}/details` }}
			    state={{ clientId }}
			    onClick={() => localStorage.setItem("prevLocation", location.pathname)}
		    >
			    {transactionDocument.caseId}
		    </NavLink> : <div className={"blue-color pointer underline"}>
			    Select case...
		    </div>}
        </Dropdown.Button>
    </div>;
};

export default CaseId;
