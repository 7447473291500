import React, { useContext } from "react";

import { Col, Row } from "antd";

import CasesTable from "./components/CasesTable/CasesTable";
import { getColumns } from "./components/CasesTable/columns";
import { useClientCasesTable } from "../../../../../hooks/clients/useClientCasesTable";
import Spinner from "../../../../basic/Spinner/Spinner";
import { ClientContext } from "../../Client";

const Cases = () => {
    const {
        client,
	    isLoading: isLoadingOutside,
    } = useContext(ClientContext);

    const {
        table,
	    isLoading,
    } = useClientCasesTable(
	    getColumns,
	    client?._id
    );

    return <div>
	    <Spinner isLoading={isLoading || isLoadingOutside}>
		    <div className={"p20"}>
			    <Row>
				    <Col span={24}>
                        <div>
                            <div
                                style={{ height: 50 }}
                                className={"horizontal-left"}
                            >
                                <div className={"h3"}>
                                    Case Details
                                </div>
                            </div>

                            <div className={"mt10"}>
                                <CasesTable
                                    table={table}
                                />
                            </div>
                        </div>
				    </Col>
			    </Row>
		    </div>
	    </Spinner>
    </div>;
};

export default Cases;
