import { Button, Modal } from "antd";

import planet from "./../../../assets/images/regions/planet.svg";

const CountrySelect = ({
    isCountrySelectorOpened,
    handleClose,
}: {
    isCountrySelectorOpened: boolean;
	handleClose: () => void
}) => {
    const handleSelect = (value: string) => {
        window.location.href = `${process.env.REACT_APP_BASE_URL}/${value}`;
    };

    return (
        <div className="">
            <Modal
                open={isCountrySelectorOpened}
                width={350}
                destroyOnClose
                onCancel={handleClose}
                footer={null}
            >
                <div className={"p10 horizontal-center"}>
                    <div>
                        <div className={"horizontal-center"}>
                            <img
                                src={planet}
                                alt="planet"
                                height={80}
                            />
                        </div>

                        <div className={"mt20 horizontal-center bold grey-7-color"}>
                            Choose the region
                        </div>

                        <div className="horizontal-center">
                            <Button
                                size={"large"}
                                className={"m10"}
                                onClick={() => handleSelect("uk")}
                            >
                                UK
                            </Button>

                            <Button
                                disabled
                                size={"large"}
                                className={"m10"}
                                onClick={() => handleSelect("ie")}
                            >
                                Ireland
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default CountrySelect;
