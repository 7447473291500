import { Form, Input } from "antd";
import { SizeType } from "antd/es/config-provider/SizeContext";

const CommonTextArea = ({
    required = false,
    name = "name",
    errorMessage = "Please set value!",
    size = "large",
    value = undefined,
    onChange = () => null,
    placeholder = "Set value...",
}: {
    required?: boolean;
    name?: string;
    errorMessage?: string;
    placeholder?: string;
    size?: SizeType;
    value?: string | undefined;
    onChange?: () => void;
}) => {
    return <div>
        <Form.Item
            name={name}
            rules={[{
                required,
                message: errorMessage,
            }]}
        >
            <Input.TextArea
                data-testid={name}
                className={"input-content grey-color"}
                size={size}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
            />
        </Form.Item>
    </div>;
};

export default CommonTextArea;
