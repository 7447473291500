import React, { useContext, useMemo, useState } from "react";

import { Col, Row } from "antd";

import { AppContext } from "../../../App";
import { NARROW_NAVBAR_SIZE, NAVBAR_SIZE } from "../../../constants/navigation";
import { useAutoLogout } from "../../../hooks/helpers/useAutoLogout";
import SetNewPasswordModal from "../../modals/SetNewPasswordModal/SetNewPasswordModal";
import Navigation from "../Navigation/Navigation";

const MainWrapper = ({
    children,
}: {
    children: React.ReactNode
}) => {
    const {
        authData: {
            shouldChangePassword,
        } = {
            shouldChangePassword: false,
        },
    } = useContext(AppContext);

    const [navbarIsCollapsed, setNavbarIsCollapsed] = useState(localStorage.getItem("navbarIsCollapsed") === "true" || false);

    const navbarSize = useMemo(() => {
        return navbarIsCollapsed ? NARROW_NAVBAR_SIZE : NAVBAR_SIZE;
    }, [navbarIsCollapsed]);

    useAutoLogout({
        interval: 10_000,
        timeout: 3_600_000,
    });

    return <div style={{ minWidth: 1280 }}>
        <Row>
            <Col
                flex={`${navbarSize}px`}
                className={"white-background-color"}
                style={{
                    height: "100vh",
                    position: "fixed",
                    zIndex: 11,
                }}
            >
                <Navigation
                    navbarIsCollapsed={navbarIsCollapsed}
                    setNavbarIsCollapsed={setNavbarIsCollapsed}
                    navbarWidth={navbarSize}
                />
            </Col>
            <Col
                flex={"auto"}
                className={"grey-1-background-color"}
                style={{
                    height: "100vh",
                    width: "100%",
                    paddingLeft: navbarSize,
                }}
            >
                {children}
            </Col>
        </Row>

        <SetNewPasswordModal
            modalIsOpened={shouldChangePassword}
        />
    </div>;
};

export default MainWrapper;
