import { Button, Col, Form, Row } from "antd";

import { useModalOpener } from "../../../../hooks/helpers/useModalOpener";
import { useAppDispatch } from "../../../../redux/hooks";
import { login } from "../../../../redux/reducers/auth";
import { useSignInMutation } from "../../../../services/auth";
import EmailInput from "../../../inputs/Form/Inputs/EmailInput";
import PasswordInput from "../../../inputs/Form/Inputs/PasswordInput";
import ForgotPasswordModal from "../../../modals/ForgotPasswordModal/ForgotPasswordModal";

const initialValues = {
    email: "",
    password: "",
};

const SignIn = () => {
    const dispatch = useAppDispatch();

    const [form] = Form.useForm();

    const {
        isOpened: forgotPasswordModalIsOpened,
        open: openForgotPasswordModal,
        close: closeForgotPasswordModal,
    } = useModalOpener();

    const [signIn] = useSignInMutation();

    return <div style={{ width: 426 }}>
        <div
            style={{ fontWeight: 700, fontSize: 36 }}
            className={"horizontal-center dark-grey-color"}
        >
            Login Account
        </div>

        <div className={"mt40"}>
            <Form
                form={form}
                requiredMark={false}
                onFinish={data => {
                    signIn(data)
                        .unwrap()
                        .then(data => {
                            dispatch(login(data.token));
                        }).catch(() => null);
                }}
                initialValues={initialValues}
                layout={"vertical"}
            >

                <Row>
                    <Col span={24}>
                        <EmailInput
                            required
                        />
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <PasswordInput
                            required
                        />
                    </Col>
                </Row>

                <Row>
                    <Col span={24} className={"horizontal-right"}>
                        <div>
                            <span>
                               Forgot your password?
                            </span>

                            <span
                                className={"ml4 primary-color bold"}
                                style={{ cursor: "pointer" }}
                                onClick={openForgotPasswordModal}
                            >
                                Reset
                            </span>
                        </div>
                    </Col>
                </Row>

                <Row className={"mt30"}>
                    <Col span={12}>
                        <div className={"horizontal-center"}>
                            <Form.Item>
                                <Button
                                    data-testid="sign-in-submit-button"
                                    htmlType="submit"
                                    shape="round"
                                    type={"primary"}
                                    className={"primary-button button-label"}
                                    style={{ width: 200 }}
                                >
                                    Login to Account
                                </Button>
                            </Form.Item>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className={"horizontal-center"}>
                            <Form.Item>
                                <Button
                                    disabled
                                    data-testid="request-access-button"
                                    htmlType="submit"
                                    shape="round"
                                    type={"primary"}
                                    className={"primary-button button-label"}
                                    style={{ width: 200 }}
                                >
                                     Request Access
                                </Button>
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
            </Form>
        </div>

        {forgotPasswordModalIsOpened &&
            <ForgotPasswordModal
                close={closeForgotPasswordModal}
            />}

    </div>;
};

export default SignIn;
