import { Popover } from "antd";

import { IClientLog } from "../../../../../../../../../types/client-logs";

const Description = ({
    log,
}: {
    log: IClientLog;
}) => {
    return <div className={"horizontal-left"}>
        <div>
            {log.description}
        </div>
        {log.email && <div className={"ml4"}>
            <Popover
                trigger={["click"]}
                content={<iframe
                    srcDoc={log.email.text}
                    title={"email"}
                    style={{ border: 0, minWidth: 600, minHeight: 500 }}
                />}
            >
                <span className={"pointer primary-color bold"}>Preview</span>
            </Popover>
        </div>}
    </div>;
};

export default Description;
