import { DatePicker } from "antd";
import dayjs from "dayjs";

const format = "DD-MM-YYYY";

const OperationsHeader = ({
    day,
    setDay,
    newClientsCount,
}: {
    day: string;
    setDay: (_day: string) => void;
    newClientsCount: number;
}) => {
    return <div
        style={{ width: "100%" }}
        className={"p10 space-between"}
    >
        <div className={"horizontal-left"}>
            <div className={"h2"}>
				Operations
            </div>
        </div>
        <div className={"horizontal-right"}>
            <div>
                <div
                    className={"grey-1-background-color"}
                    style={{
                        borderRadius: 40,
                        padding: "8px 20px",
                    }}
                >
                    <span>New clients:</span> <span className={"purple-color bold"}>{newClientsCount}</span>
                </div>
            </div>
            <div className={"ml30"}>
                <DatePicker
                    allowClear={false}
                    size={"large"}
                    value={dayjs(day, format)}
                    disabledDate={date => {
                        return dayjs() < date;
                    }}
                    onChange={date => {
                        if (date) {
                            setDay(date.format(format));
                        }
                    }}
                />
            </div>
        </div>
    </div>;
};

export default OperationsHeader;
