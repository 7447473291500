import { useState, FC, useEffect } from "react";

import useDebounce from "../../../../../../../hooks/helpers/useDebounce";
import { isAnswerValid } from "../../../../../../../utils/builder";
import CustomFormItem from "../FormWrapper/FormItemWrapper";
import FloatInput from "../inputs/FloatInput";

type FormulaNumberQuestionProps = {
    data: {
        question: string;
        formula?: string;
        label?: string;
        helpText?: string;
        selectType?: string;
        keyboardType?: string;
        validation?: {
            regex: string;
            message: string;
        };
        warning?: {
            regex: string;
            message: string;
        };
        required?: boolean;
    };
    // eslint-disable-next-line no-unused-vars
    onAnswer: (answer: number | string | undefined) => void;
    initialValue?: string;
};

const FormulaNumberQuestion: FC<FormulaNumberQuestionProps> = ({
    data,
    onAnswer,
    initialValue = "",
}) => {
    const [answer, setAnswer] = useState<string>(initialValue);
    const [error, setError] = useState<string>("");
    const [warning, setWarning] = useState<string>("");
    const [skipValidation, setSkipValidation] = useState<boolean>(true);

    useEffect(() => {
        if (data.required && answer === "" && !skipValidation) {
            setError("This field is required");
        } else if (data.validation?.regex && !skipValidation) {
            const valid = isAnswerValid(answer, data.validation?.regex);
            !valid ? setError(data.validation.message) : setError("");
        } else {
            setError("");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [answer, skipValidation]);

    const debouncedAnswer = useDebounce(answer, 500);

    const handleAnswerChange = (value: string) => {
        setSkipValidation(false);
        setAnswer(value);
    };

    useEffect(() => {
        if (initialValue === debouncedAnswer) return;

        const numberInput = Number(debouncedAnswer);

        let result;

        if (!Number.isNaN(numberInput)) {
            try {
                // eslint-disable-next-line no-eval
                result = (data?.formula && numberInput !== 0) ? eval(data?.formula.replace(/x/g, numberInput.toString())) : debouncedAnswer;
            } catch (error) {
                result = debouncedAnswer;
            }
        } else {
            result = debouncedAnswer;
        }

        const calculatedAnswer = error
            ? undefined
            : result.toString();

        onAnswer(calculatedAnswer);

        const valid = isAnswerValid(answer, data.warning?.regex);
        !valid ? setWarning(data.warning?.message || "") : setWarning("");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedAnswer, error]);

    useEffect(() => {
        console.log("Formula Question Value", initialValue);
    }, [initialValue]);

    return (
        <div className="builder__formula-question">
            <CustomFormItem
                key={data.question}
                error={error || warning}
            >
                <FloatInput
                    style={{
                        marginBottom: "5px",
                    }}
                    label={data.question}
                    value={answer}
                    error={error || warning}
                    staticPlaceholder={data.label}
                    {...(data?.keyboardType ? { type: data.keyboardType } : {})}
                    setSkipValidation={setSkipValidation}
                    onChange={(event: any) => {
                        handleAnswerChange(event.target.value);
                    }}
                />
            </CustomFormItem>
            {data.helpText &&
                <div className="builder__formula-question-info-text">
                    {data.helpText}
                </div>
            }
        </div>
    );
};

export default FormulaNumberQuestion;
