import { convertArrayOfObjectsToDict } from "../utils/data";

export const LOG_VARIATIONS = [{
    id: "success",
    title: "Success",
    colorClassName: "primary-color",
}, {
    id: "failure",
    title: "Failure",
    colorClassName: "red-color",
}, {
    id: "warning",
    title: "Warning",
    colorClassName: "orange-color",
}, {
    id: "info",
    title: "Info",
    colorClassName: "blue-color",
}] as const;

export const LOG_VARIATION_KEYS = LOG_VARIATIONS.map(variation => variation.id);

export const LOG_VARIATIONS_DICT = convertArrayOfObjectsToDict(LOG_VARIATION_KEYS, LOG_VARIATIONS);

export const INITIATORS = [{
    id: "platform",
    title: "Platform",
}, {
    id: "agent",
    title: "Agent",
}, {
    id: "client",
    title: "Client",
}, {
    id: "revolut",
    title: "Revolut",
}] as const;

export const INITIATOR_KEYS = INITIATORS.map(initiator => initiator.id);

export const INITIATORS_DICT = convertArrayOfObjectsToDict(INITIATOR_KEYS, INITIATORS);
