import { ColumnDef } from "@tanstack/react-table";

import Amount from "./components/Amount";
import Cell from "./components/Cell";
import Date from "./components/Date";
import Header from "./components/Header";
import Status from "./components/Status";
import TaxNumber from "./components/TaxNumber";
import { ITransaction } from "../../../../../../../types/transactions";

export const getColumns = ({
    assignClientToTransaction,
} : {
    assignClientToTransaction: (_transactionId: string, _ppsn: string) => void;
}): ColumnDef<ITransaction>[] => [
    {
        id: "createdAt",
        accessorKey: "createdAt",
        header: () => <Header label={"Transaction Date"}/>,
        cell: ({ row }) => <Date value={row.original.completedAt}/>,
    },
    {
        id: "amount",
        accessorKey: "amount",
        enableSorting: false,
        header: () => <Header label={"Amount"}/>,
        cell: ({ row }) => <Amount value={row.original}/>,
    },
    {
        id: "reference",
        accessorKey: "reference",
        enableSorting: false,
        header: () => <Header label={"Reference"}/>,
        cell: ({ row }) => <Cell value={row.original.reference}/>,
    },
    {
        id: "status",
        accessorKey: "status",
        enableSorting: false,
        header: () => <Header label={"Status"}/>,
        cell: ({ row }) => <Status value={row.original.status}/>,
    },
    {
        id: "document",
        accessorKey: "document",
        enableSorting: false,
        header: () => <Header label={"Linked Client"}/>,
        cell: ({ row }) => <TaxNumber
            value={row.original}
            assignClientToTransaction={assignClientToTransaction}
        />,
    },
    {
        id: "updatedAt",
        accessorKey: "updatedAt",
        header: () => <Header label={"Last Updated"}/>,
        cell: ({ row }) => <Date value={row.original.updatedAt}/>,
    },
];
