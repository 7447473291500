import { IClientLog } from "../../../../../../types/client-logs";
import LogPreview from "../../../../../common/Logs/LogPreview";

const Log = ({
    logs,
}: {
    logs?: IClientLog[];
}) => {
    return <div className={"horizontal-center"}>
        <LogPreview logs={logs}/>
    </div>;
};

export default Log;
