import { useState } from "react";

import { Button, Modal } from "antd";

import { ISetDocumentsAgentBody } from "../../../types/documents";
import AgentsSelector from "../../inputs/Fields/AgentSelector/AgentSelector";

const AssignAgentToCaseModal = ({
    close,
    documentId,
    setDocumentsAgent,
}: {
	close: () => void;
	setDocumentsAgent: (_data: ISetDocumentsAgentBody) => void;
	documentId: string;
}) => {
    const [agentId, setAgentId] = useState<string | undefined>(undefined);

    return <Modal
        open
        width={500}
        title={
            <div className={"p20 modal-header grey-7-color"}>
				Assign Agent to Case
            </div>
        }
        afterClose={() => setAgentId(undefined)}
        destroyOnClose
        onCancel={() => close()}
        footer={
            <div
                style={{ height: 40 }} className={"space-between"}>
                <Button
                    type={"link"}
                    onClick={() => close()}
                    className={"default-button"}
                >
					Cancel
                </Button>

                <Button
	                disabled={!agentId}
                    htmlType="submit"
                    onClick={async () => {
                        if (agentId) {
                            setDocumentsAgent({
                                data: {
                                    managerId: agentId,
                                    documentIds: [documentId],
                                },
                            });
                        }

	                    close();
                    }}
                    type={"primary"}
                    className={"primary-button"}
                >
					Assign
                </Button>
            </div>
        }
    >
        <div className={"p20"}>
	            <div className={"grey-7-color"}>
		            Agent
	            </div>

	            <div className={"mt10"}>
		            <AgentsSelector
			            width={"100%"}
			            selectedAgentId={agentId}
			            onAgentSelect={(agentId: string) => {
				            setAgentId(agentId);
			            }}
		            />
	            </div>
        </div>
    </Modal>;
};

export default AssignAgentToCaseModal;
