import { memo, useCallback, useMemo, useState } from "react";

import {
    Typography,
    Box,
    TextField,
    Button,
    Modal,
    Divider,
    Checkbox,
    ListItemButton,
    MenuItem,
} from "@mui/material";
import { Handle, Position, NodeResizer } from "reactflow";

import { useModalOpener } from "../../../../../../../hooks/helpers/useModalOpener";
import { createChangeHandler } from "../../../../../../../utils/constructor";
import useStore from "../store";

const ArrayGroupNode = (props: any) => {
    const { nodes, updateNodeData } = useStore();

    const childrenNodes = useMemo(
        () => nodes.filter(n => n.parentNode === props.id),
        [nodes, props.id]
    );

    const childrenQuestions = useMemo(
        () =>
            childrenNodes.filter(n => {
                const nodeType = n.id?.split("_")[0];
                return [
                    "textQuestion",
                    "formulaNumberQuestion",
                    "dateQuestion",
                    "selectOneQuestion",
                    "amountQuestion",
                    "booleanQuestion",
                    "addressQuestion",
                    "selectOneIconsQuestion",
                    "selectManyQuestion",
                    "selectManyIconsQuestion",
                ].includes(nodeType);
            }),
        [childrenNodes]
    );

    const {
        isOpened: isEditing,
        open: openEditModal,
        close: closeModal,
    } = useModalOpener();

    const [editedLabel, setEditedLabel] = useState(props.data.label);
    const [editedItemTitle, setEditedItemTitle] = useState(props.data.itemTitle);
    const [editedItemSubTitle, setEditedItemSubTitle] = useState(props.data.itemSubTitle);
    const [editedAddItemLabel, setEditedAddItemLabel] = useState(
        props.data.addItemLabel
    );
    const [editedRequired, setEditedRequired] = useState(!!props.data.required);

    const saveChanges = useCallback(() => {
        updateNodeData(props.id, {
            label: editedLabel,
            addItemLabel: editedAddItemLabel,
            required: editedRequired,
            itemTitle: editedItemTitle,
            itemSubTitle: editedItemSubTitle,
        });
        closeModal();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateNodeData, editedLabel, editedAddItemLabel, editedRequired, editedItemTitle, editedItemSubTitle]);

    const handleLabelChange = createChangeHandler(setEditedLabel);
    const handleAddItemLabelChange = createChangeHandler(setEditedAddItemLabel);
    const handleRequiredChange = createChangeHandler(setEditedRequired);
    const handleEditedItemTitleChange = createChangeHandler(setEditedItemTitle);
    const handleEditedItemSubTitleChange = createChangeHandler(setEditedItemSubTitle);

    return (
        <div className="array-group-node">
            <NodeResizer
                color="#ff0071"
                isVisible={props.selected}
                minWidth={300}
                minHeight={200}
            />
            <Handle
                type="target"
                position={Position.Top}
                className="handle-depend"
                style={{ left: "15%" }}
                id="depend"
            />
            <Handle
                type="target"
                position={Position.Top}
                className="handle-depend"
                style={{ left: "20%" }}
                id="depend_and"
            />
            <Handle type="target" position={Position.Top} id="to" />
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <Typography ml={2} component="div" sx={{ fontSize: "8px" }}>
                    Array Group question
                </Typography>
                {editedRequired && (
                    <Typography
                        component="div"
                        color={"red"}
                        sx={{ fontSize: "6px", margin: "0px 10px" }}
                    >
                        Required
                    </Typography>
                )}
            </Box>
            <Divider />
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "8px",
                }}
            >
                <Typography
                    ml={1}
                    variant="subtitle1"
                    component="div"
                    sx={{
                        fontSize: "12px",
                    }}
                >
                    {props.data.label}
                </Typography>
                <Button
                    variant="outlined"
                    onClick={openEditModal}
                    className="button-edit"
                >
                    Edit
                </Button>
            </Box>
            <Box>
                <Typography
                    ml={1}
                    variant="subtitle1"
                    component="div"
                    sx={{
                        fontSize: "10px",
                    }}
                >
                    {`Add Item Button Label:  "${props.data.addItemLabel}"`}
                </Typography>
            </Box>
            <Handle type="source" position={Position.Bottom} id="from" />
            {isEditing && (
                <Modal
                    open={isEditing}
                    onClose={saveChanges}
                    className="modal-wrapper"
                    slotProps={{
                        backdrop: {
                            sx: {
                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                            },
                        },
                    }}
                >
                    <Box className="box-wrapper">
                        <Box
                            sx={{
                                display: "flex",
                                width: "100%",
                                marginBottom: "10px",
                            }}
                        >
                            <Typography component="div">Required</Typography>
                            <Checkbox
                                style={{
                                    padding: "0",
                                }}
                                checked={editedRequired}
                                onChange={handleRequiredChange}
                                color="primary"
                                name="requiredCheckbox"
                            />
                        </Box>
                        <TextField
                            margin="normal"
                            id="select-title"
                            select
                            label="Select Item Title"
                            value={editedItemTitle}
                            onChange={handleEditedItemTitleChange}
                            variant="outlined"
                            fullWidth
                        >
                            {childrenQuestions.map(q => (
                                <MenuItem key={q.id + "title"} value={q.id} style={{ padding: "0" }}>
                                    <ListItemButton>
                                        {q.data.question}
                                    </ListItemButton>
                                </MenuItem>
                            ))}
                            <MenuItem key="empty" value="" style={{ padding: "0" }}>
                                <ListItemButton>
                                    Empty
                                </ListItemButton>
                            </MenuItem>
                        </TextField>
                        <TextField
                            margin="normal"
                            id="select-sub-title"
                            select
                            label="Select Item Sub Title"
                            value={editedItemSubTitle}
                            onChange={handleEditedItemSubTitleChange}
                            variant="outlined"
                            fullWidth
                        >
                            {childrenQuestions.map(q => (
                                <MenuItem key={q.id + "subtitle"} value={q.id} style={{ padding: "0" }}>
                                    <ListItemButton>
                                        {q.data.question}
                                    </ListItemButton>
                                </MenuItem>
                            ))}
                            <MenuItem key="empty" value="" style={{ padding: "0" }}>
                                <ListItemButton>
                                    Empty
                                </ListItemButton>
                            </MenuItem>
                        </TextField>
                        <TextField
                            margin="normal"
                            id="title"
                            name="title"
                            value={editedLabel}
                            onChange={handleLabelChange}
                            variant="outlined"
                            fullWidth
                            label="Array Label"
                        />
                        <TextField
                            margin="normal"
                            id="text"
                            name="text"
                            value={editedAddItemLabel}
                            onChange={handleAddItemLabelChange}
                            variant="outlined"
                            fullWidth
                            label="Add Item Label"
                        />
                        <Box className="button-group">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={saveChanges}
                            >
                                Save
                            </Button>
                        </Box>
                    </Box>
                </Modal>
            )}
        </div>
    );
};

export default memo(ArrayGroupNode);
