import { ChangeEvent, memo, useCallback, useState } from "react";

import { Typography, Box, TextField, Button, Modal, InputAdornment } from "@mui/material";
import { Handle, Position, NodeResizer } from "reactflow";

import useBaseUrl from "../../../../../../../hooks/helpers/useBaseUrl";
import { useModalOpener } from "../../../../../../../hooks/helpers/useModalOpener";
import { useUploadStaticFileMutation } from "../../../../../../../services/files";
import { createChangeHandler } from "../../../../../../../utils/constructor";
import useStore from "../store";

const FormTabNode = (props: any) => {
    const updateNodeData = useStore(state => state.updateNodeData);
    const {
        isOpened: isEditing,
        open: openEditModal,
        close: closeModal,
    } = useModalOpener();

    const baseUrl = useBaseUrl();

    const [uploadStaticIcon] = useUploadStaticFileMutation();

    const [editedLabel, setEditedLabel] = useState(props.data.label);
    const [groupIcon, setGroupIcon] = useState(props.data.iconUrl);

    const saveChanges = useCallback(() => {
        updateNodeData(props.id, { label: editedLabel, iconUrl: groupIcon });
        closeModal();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateNodeData, editedLabel, groupIcon]);

    const handleLabelChange = createChangeHandler(setEditedLabel);

    const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];

            try {
                const response = await uploadStaticIcon({
                    data: { file },
                }).unwrap();

                if (response && response.url) {
                    setGroupIcon(response.url);
                } else {
                    console.error("Upload failed");
                }
            } catch (error) {
                console.error("Error uploading the file:", error);
            }
        }
    };

    return (
        <div className="form-tab-node">
            <NodeResizer
                color="#ff0071"
                isVisible={props.selected}
                minWidth={300}
                minHeight={200}
            />
            <Handle type="target" position={Position.Left} id="to" />
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "8px",
                }}
            >
                <div style={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
                    {groupIcon && (
                        <img
                            src={baseUrl + groupIcon}
                            alt="tab icon"
                            height={35}
                            style={{ border: "1px solid #ccc", borderRadius: "5px" }}
                        />
                    )}
                    <Typography ml={1} variant="subtitle1" component="div">
                        {props.data.label}
                    </Typography>
                </div>
                <Button
                    variant="outlined"
                    onClick={openEditModal}
                    className="button-edit"
                >
                    Edit
                </Button>
            </Box>
            <Handle type="source" position={Position.Right} id="from" />
            {isEditing && (
                <Modal
                    open={isEditing}
                    onClose={saveChanges}
                    className="modal-wrapper"
                    slotProps={{
                        backdrop: {
                            sx: {
                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                            },
                        },
                    }}
                >
                    <Box className="box-wrapper">
                        <div style={{ display: "flex", alignItems: "center", flexDirection: "row", width: "100%" }}>
                            {groupIcon && (
                                <img
                                    src={baseUrl + groupIcon}
                                    alt="tab icon"
                                    height={64}
                                    style={{ marginRight: "10px", border: "1px solid #ccc", borderRadius: "5px" }}/>
                            )}
                            <TextField
                                margin="normal"
                                id="text"
                                name="text"
                                value={editedLabel}
                                onChange={handleLabelChange}
                                variant="outlined"
                                fullWidth
                                label="Group title"
                            />
                        </div>
                        <Box className="button-group">
                            <InputAdornment position="end" style={{ display: "block" }}>
                                <input
                                    type="file"
                                    accept=".png, .svg"
                                    style={{ display: "none" }}
                                    id={"group-upload-icon"}
                                    onChange={handleFileChange}
                                />
                                <label htmlFor={"group-upload-icon"}>
                                    <Button component="span">
                                            Upload Tab Icon
                                    </Button>
                                </label>
                            </InputAdornment>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={saveChanges}
                            >
                                Save
                            </Button>
                        </Box>
                    </Box>
                </Modal>
            )}
        </div>
    );
};

export default memo(FormTabNode);
