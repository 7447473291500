import React from "react";

import { HiOutlineCursorClick, HiOutlineLockClosed } from "react-icons/hi";

import { usePermissionGranter } from "../../../../../hooks/common/usePermissionGranter";
import { IQuestionnaireInfo } from "../../../../../types/questionnaires";
import EditButton from "../../../../inputs/Fields/EditButton/EditButton";

const QuestionnaireHeader = ({
    questionnaireInfo,
    isEditMode,
    startEditing,
    cancelEditing,
    saveChanges,
}: {
    questionnaireInfo?: IQuestionnaireInfo;
    isEditMode: boolean;
    startEditing: () => void;
    cancelEditing: () => void;
    saveChanges: () => void;
}) => {
    const questionnairesPermissionIsGranted = usePermissionGranter("questionnaires", "update");

    return <div className={"space-between"}>
        <div className={"ml6 h3"}>
            {questionnaireInfo?.name}
        </div>
        <div>
            <EditButton
                isEditMode={isEditMode}
                permissionIsGranted={questionnairesPermissionIsGranted}
                start={{
                    icon: <HiOutlineLockClosed/>,
                    label: "Unlock to Edit",
                    action: () => {
                        startEditing();
                    },
                }}
                finish={{
                    icon: <HiOutlineCursorClick/>,
                    label: "Finish Editing & Store Last Saved State",
                    action: () => {
                        saveChanges();
                    },
                }}
                cancel={{
                    icon: <HiOutlineCursorClick/>,
                    label: "Cancel Editing & Restore Original",
                    action: () => {
                        cancelEditing();
                    },
                }}
            />
        </div>
    </div>;
};

export default QuestionnaireHeader;
