/* eslint-disable no-unused-vars */
import { Edge, Node, OnConnect, OnEdgesChange, OnNodesChange } from "reactflow";

import { Region } from "./users";

export type Option = {
	id: string;
	value: string;
	url?: string;
};

export type UploadDoc = {
	id: string;
	label: string;
};

export type NodeData = {
	required?: boolean;
	label?: string;
	formula?: string;
	category?: string;
	selectType?: string;
	keyboardType?: string;
	iconUrl?: string;
	addItemLabel?: string;
	itemTitle?: string;
	itemSubTitle?: string;
	yearsBind?: Record<string, string>
	question?: string;
	defaultDate?: string;
	minDate?: string;
	maxDate?: string;
	bindTo?: {
		id: string,
        label: string,
	};
	currency?: string;
	message?: string;
	helpText?: string;
	title?: string;
	validation?: {
		regex: string;
		message: string;
	};
	warning?: {
		regex: string;
		message: string;
	};
	value?: boolean;
	allowAdditionalFiles?: boolean;
	options?: Option[];
	uploadDocs?: UploadDoc[];
	arrayLimit?: number;
};

export type FlowNode = Node<NodeData>;

export type RFState = {
	nodes: FlowNode[];
	edges: Edge[];
	onNodesChange: OnNodesChange;
	onEdgesChange: OnEdgesChange;
	onConnect: OnConnect;
	// eslint-disable-next-line no-unused-vars
	updateNodeData: (nodeId: string, data: Partial<NodeData>) => void;
};

export type NodeType =
	| "formTab"
	| "formSubTab"
	| "resizeGroup"
	| "arrayGroup"
	| "yearsGroup"
	| "amountQuestion"
	| "textQuestion"
	| "formulaNumberQuestion"
	| "infoBlock"
	| "warningBlock"
	| "infoClickBlock"
	| "dateQuestion"
	| "booleanQuestion"
	| "addressQuestion"
	| "forbiddenMessage"
	| "allowMessage"
	| "selectOneQuestion"
	| "selectOneIconsQuestion"
	| "selectManyQuestion"
	| "selectManyIconsQuestion"
	| "documentsUpload"

export enum QuestionType {
	ArrayGroup = "arrayGroup",
	YearsGroup = "yearsGroup",
	Amount = "amountQuestion",
	Text = "textQuestion",
	FormulaNumber = "formulaNumberQuestion",
	Info = "infoBlock",
	Warning = "warningBlock",
	InfoClick = "infoClickBlock",
	Date = "dateQuestion",
	Boolean = "booleanQuestion",
	Address = "addressQuestion",
	SelectOne = "selectOneQuestion",
	SelectOneIcons = "selectOneIconsQuestion",
	SelectMany = "selectManyQuestion",
	SelectManyIcons = "selectManyIconsQuestion",
	DocumentsUpload = "documentsUpload",
}

export type DependT = {
	id: string;
	value?: string | null | boolean;
};

export type ChildrenT = {
	id: string;
	depend: DependT[];
	andDepend: DependT[];
	parentId: string;
	type: string;
	label?: string;
	children?: ChildrenT[];
	data: {
		required?: boolean;
		helpText?: string;
		label?: string;
		selectType?: string;
		keyboardType?: string;
		yearsBind?: Record<string, string>
		message?: string;
		addItemLabel?: string;
		itemTitle?: string;
		itemSubTitle?: string;
		question: string;
		validation?: {
			regex: string;
			message: string;
		};
		options?: {
			id: string;
			value: string;
			url?: string;
		}[];
	};
};

export type GroupT = {
	id: string;
	parentId: string;
	depend: DependT[];
	andDepend: DependT[];
	type: string;
	data: {
		label?: string;
		iconUrl?: string;
	};
	children?: ChildrenT[];
}

export type SubTabT = {
	id: string;
	parentId: string;
	type: string;
	data: {
		label?: string;
		iconUrl?: string;
	};
	children?: GroupT[];
}

export type TabT = {
	id: string;
	type: string;
	data: {
		label?: string;
		iconUrl?: string;
	};
	children?: SubTabT[];
}

export type QuestionnaireData = {
	allowMessages: {
		depend: DependT[];
		andDepend: DependT[];
		bindTo?: {
			id: string;
			label: string;
		};
		id: string;
		text: string;
	}[];
	forbiddenMessages: {
		depend: DependT[];
		andDepend: DependT[];
		bindTo: {
			id: string;
			label: string;
		};
		id: string;
		text: string;
	}[];
	tabs: TabT[]
};

export type FlowT = {
	nodes: FlowNode[];
	edges: Edge[];
	viewport: {
		x: number;
		y: number;
		zoom: number;
	};
};

export type QuestionsDataT = FlowT & {
	builderData: QuestionnaireData;
};

export type QuestionnaireFlowDataT = QuestionsDataT & {
	timeStamp: number;
};

export type OnChangeHandler = (flow: QuestionnaireFlowDataT) => void;

export interface IQuestionnaireInfo {
	name: string;
	description: string;
}

export interface IQuestionnaire {
	createdAt: Date;
	isMain: boolean;
	info: IQuestionnaireInfo;
	authors: string[];
	questions?: QuestionsDataT;
	_id: string;
	id: string;
	region: string;
}

export interface IGetQuestionnairesResponse {
	questionnaires: IQuestionnaire[];
}

export interface IGetQuestionnaireByIdResponse {
	questionnaire: IQuestionnaire;
}

export interface IUpdateQuestionnaireByIdResponse {
	questionnaire: IQuestionnaire;
}

export interface IGetQuestionnairesQuery {
	pageIndex?: number;
	pageSize?: number;
}

export interface IGetQuestionnaireByIdQuery {
	questionnaireId: string;
}

export interface IUpdateQuestionnaireByIdBody {
	questionnaireId: string;
	data: {
		region?: Region;
		isMain?: boolean;
		questions?: QuestionsDataT;
		info?: IQuestionnaireInfo;
	}
}
