import React, { useEffect, useMemo, useState } from "react";

import { CloseOutlined, DeleteOutlined, FilterOutlined } from "@ant-design/icons";
import { Button, DatePicker, Divider, InputNumber, Popover, Slider } from "antd";
import dayjs from "dayjs";

import { ALL_STATUSES, DEFAULT_LAST_SUBMITTED_RANGE, DEFAULT_PAID_RANGE } from "../../../../../constants/documents";
import { DEFAULT_PLANS } from "../../../../../constants/plans";
import { StatusType } from "../../../../../types/documents";
import { Plan } from "../../../../../types/plans";

const { RangePicker } = DatePicker;

const DATE_FORMAT = "DD-MM-YYYY";

const CasesFilter = ({
    selectedPlanIds,
    setSelectedPlanIds,
    selectedPaidRange,
    setSelectedPaidRange,
    selectedLastSubmittedRange,
    setSelectedLastSubmittedRange,
    mainStatusId,
    selectedStatusIds,
    setSelectedStatusIds,
}: {
    selectedPlanIds: (Plan | null)[];
    setSelectedPlanIds: (_selectedPlanIds: (Plan | null)[]) => void;
    selectedPaidRange: [number, number];
    setSelectedPaidRange: (_selectedPaidRange: [number, number]) => void;
    selectedLastSubmittedRange: [string, string];
    setSelectedLastSubmittedRange: (_selectedLastSubmittedRange: [string, string]) => void;
    mainStatusId: string;
    selectedStatusIds: StatusType[];
    setSelectedStatusIds: (_selectedStatusIds: StatusType[]) => void;
}) => {
    const [filterTooltipIsOpened, setFilterTooltipIsOpened] = useState<boolean>(false);
    const [selectedStatusIdsTemp, setSelectedStatusIdsTemp] = useState<StatusType[]>(selectedStatusIds);
    const [selectedPaidRangeTemp, setSelectedPaidRangeTemp] = useState<[number, number]>(selectedPaidRange);
    const [selectedLastSubmittedRangeTemp, setSelectedLastSubmittedRangeTemp] = useState<[string, string]>(selectedLastSubmittedRange);
    const [selectedPlanIdsTemp, setSelectedPlanIdsTemp] = useState<(Plan | null)[]>(selectedPlanIds);

    useEffect(() => {
        setSelectedStatusIdsTemp(selectedStatusIds);
    }, [selectedStatusIds]);

    useEffect(() => {
        setSelectedPaidRangeTemp(selectedPaidRange);
    }, [selectedPaidRange]);

    useEffect(() => {
        setSelectedLastSubmittedRangeTemp(selectedLastSubmittedRange);
    }, [selectedLastSubmittedRange]);

    useEffect(() => {
        setSelectedPlanIdsTemp(selectedPlanIds);
    }, [selectedPlanIds]);

    const hasFilteredValue = useMemo(() => {
        return selectedPaidRange[0] !== DEFAULT_PAID_RANGE[0] ||
            selectedPaidRange[1] !== DEFAULT_PAID_RANGE[1] ||
            selectedLastSubmittedRange[1] !== DEFAULT_LAST_SUBMITTED_RANGE[1] ||
            selectedLastSubmittedRange[0] !== DEFAULT_LAST_SUBMITTED_RANGE[0] ||
            !!selectedStatusIds.length || !!selectedPlanIds.length;
    }, [selectedLastSubmittedRange, selectedPaidRange, selectedPlanIds.length, selectedStatusIds.length]);

    return <Popover
        arrow={false}
        open={filterTooltipIsOpened}
        align={{
            targetOffset: [0, 46],
        }}
        destroyTooltipOnHide
        placement={"bottomRight"}
        content={<div style={{ width: 850 }} className={"p10"}>
            <div className={"space-between"}>
                <div>
                    <CloseOutlined
                        className={"fs20 grey-6-color"}
                        onClick={() => {
                            setSelectedStatusIds(selectedStatusIdsTemp);
                            setSelectedPaidRange(selectedPaidRangeTemp);
                            setSelectedLastSubmittedRange(selectedLastSubmittedRangeTemp);
                            setSelectedPlanIds(selectedPlanIdsTemp);
                            setFilterTooltipIsOpened(false);
                        }}
                    />
                </div>
                <div className={"horizontal-right"}>
                    <div>
                        <Button
                            size={"large"}
                            type={"text"}
                            className={"primary-color"}
                            icon={<DeleteOutlined/>}
                            disabled={!hasFilteredValue}
                            onClick={() => {
                                setSelectedStatusIds([]);
                                setSelectedPaidRange(DEFAULT_PAID_RANGE);
                                setSelectedLastSubmittedRange(DEFAULT_LAST_SUBMITTED_RANGE);
                                setSelectedPlanIds([]);
                                setFilterTooltipIsOpened(false);
                            }}
                        >
							Clear all
                        </Button>
                    </div>
                </div>

            </div>

            <div className={"mt20"}>
                <div className={"h3"}>
                    Statuses
                </div>

                <Divider style={{ marginTop: 8 }}/>

                <div>
                    {ALL_STATUSES
                        .filter(status => {
                            if (mainStatusId === "all") return true;
                            return status.mainStatusId === mainStatusId;
                        })
                        .map(status => {
                            return <div
                                key={status.id}
                                style={{
                                    marginRight: 10,
                                    marginBottom: 10,
                                    borderRadius: 50,
                                }}
                                className={`pv6-ph12 pointer inline ${selectedStatusIdsTemp.includes(status.id) ? "primary-border-2px" : "border-1px"}`}
                                onClick={() => {
                                    if (selectedStatusIdsTemp.includes(status.id)) setSelectedStatusIdsTemp(selectedStatusIdsTemp.filter(statusId => statusId !== status.id));
                                    else setSelectedStatusIdsTemp([...selectedStatusIdsTemp, status.id]);
                                }}
                            >
                                <div className={"horizontal-left"}>
                                    <div style={{
                                        width: 14,
                                        height: 14,
                                        backgroundColor: status.color.font,
                                        borderRadius: "100%",
                                    }}/>
                                    <div className={"ml6 fs13 grey-7-color"}>{status.title}</div>
                                </div>
                            </div>;
                        })}
                </div>

                <div className={"mt20"}>
                    <div className={"h3"}>
                        Payment Plan
                    </div>

                    <Divider style={{ marginTop: 8 }}/>

                    <div>
                        {[...DEFAULT_PLANS, { id: null, name: "-" }]
                            .map(plan => {
                                return <div
                                    key={plan.id}
                                    style={{
                                        marginRight: 10,
                                        marginBottom: 10,
                                        borderRadius: 50,
                                    }}
                                    className={`pv6-ph12 pointer inline ${selectedPlanIdsTemp.includes(plan.id) ? "primary-border-2px" : "border-1px"}`}
                                    onClick={() => {
                                        if (selectedPlanIdsTemp.includes(plan.id)) setSelectedPlanIdsTemp(selectedPlanIdsTemp.filter(planId => planId !== plan.id));
                                        else setSelectedPlanIdsTemp([...selectedPlanIdsTemp, plan.id]);
                                    }}
                                >
                                    <div className={"horizontal-left"}>
                                        <div className={"fs13 grey-7-color"}>{plan.name}</div>
                                    </div>
                                </div>;
                            })}
                    </div>
                </div>

                <div className={"mt20"}>
                    <div className={"h3"}>
                        Net Rebate transferred to Client
                    </div>

                    <Divider style={{ marginTop: 8 }}/>

                    <div style={{ width: "60%" }}>
                        <Slider
                            value={selectedPaidRangeTemp}
                            onChange={value => setSelectedPaidRangeTemp(value as [number, number])}
                            min={DEFAULT_PAID_RANGE[0]}
                            max={DEFAULT_PAID_RANGE[1]}
                            range
                        />

                        <div className={"space-between"}>
                            <div>
                                <InputNumber
                                    prefix={"€"}
                                    size={"large"}
                                    min={DEFAULT_PAID_RANGE[0]}
                                    max={selectedPaidRangeTemp[1]}
                                    step={1}
                                    value={selectedPaidRangeTemp[0]}
                                    onChange={(value: number | null) => setSelectedPaidRangeTemp([value || DEFAULT_PAID_RANGE[0], selectedPaidRangeTemp[1]])}
                                />
                            </div>
                            <div>
                                -
                            </div>
                            <div>
                                <InputNumber
                                    prefix={"€"}
                                    size={"large"}
                                    min={selectedPaidRangeTemp[0]}
                                    max={DEFAULT_PAID_RANGE[1]}
                                    step={1}
                                    value={selectedPaidRangeTemp[1]}
                                    onChange={(value: number | null) => setSelectedPaidRangeTemp([selectedPaidRangeTemp[0], value || DEFAULT_PAID_RANGE[1]])}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"mt20"}>
                    <div className={"h3"}>
                        Calendar (Last submitted)
                    </div>

                    <Divider style={{ marginTop: 8 }}/>

                    <div>
                        <RangePicker
                            size={"large"}
                            disabledDate={current => {
                                return current > dayjs();
                            }}
                            value={[
                                selectedLastSubmittedRangeTemp[0] ? dayjs(selectedLastSubmittedRangeTemp[0], DATE_FORMAT) : null,
                                selectedLastSubmittedRangeTemp[1] ? dayjs(selectedLastSubmittedRangeTemp[1], DATE_FORMAT) : null,
                            ]}
                            presets={[
                                {
                                    label: "Today",
                                    value: [dayjs(), dayjs()],
                                }, {
                                    label: "Yesterday",
                                    value: [dayjs().add(-1, "days"), dayjs().add(-1, "days")],
                                }, {
                                    label: "Last 3 Days",
                                    value: [dayjs().subtract(3, "days"), dayjs()],
                                }, {
                                    label: "Last 7 Days",
                                    value: [dayjs().subtract(7, "days"), dayjs()],
                                }, {
                                    label: "Last 14 Days",
                                    value: [dayjs().subtract(14, "days"), dayjs()],
                                }, {
                                    label: "Last 30 Days",
                                    value: [dayjs().subtract(30, "days"), dayjs()],
                                }, {
                                    label: "Last 90 Days",
                                    value: [dayjs().subtract(90, "days"), dayjs()],
                                }, {
                                    label: "This Month",
                                    value: [dayjs().startOf("month"), dayjs()],
                                }, {
                                    label: "This Year",
                                    value: [dayjs().startOf("year"), dayjs()],
                                },
                            ]}
                            onChange={dates => {
                                if (dates) {
                                    const [from, to] = dates;
                                    setSelectedLastSubmittedRangeTemp([from?.format(DATE_FORMAT) || "", to?.format(DATE_FORMAT) || ""]);
                                } else {
                                    setSelectedLastSubmittedRangeTemp(["", ""]);
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>}
    >
        <Button
            size={"large"}
            type={!hasFilteredValue ? "default" : "primary"}
            ghost={hasFilteredValue}
            icon={<FilterOutlined/>}
            onClick={() => setFilterTooltipIsOpened(true)}
        >
            Filter
        </Button>
    </Popover>;
};

export default CasesFilter;
