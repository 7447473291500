import { convertArrayOfObjectsToDict } from "../utils/data";

export const DEFAULT_ROLES = [
    {
        id: "admin",
        name: "Admin",
    },
    {
        id: "user-moderator",
        name: "Moderator",
    },
    {
        id: "user",
        name: "Agent",
    },
] as const;

export const POSSIBLE_ROLE_IDS = DEFAULT_ROLES.map(role => role.id);

export const DEFAULT_ROLES_DICT = convertArrayOfObjectsToDict(POSSIBLE_ROLE_IDS, DEFAULT_ROLES);

export const PERMISSION_SCOPES = [
    "users:people",
    "users:roles",
    "clients:people",
    "clients:documents",
    "clients:notifications",
    "documents:assignment",
    "dashboard",
    "questionnaires",
    "transactions",
] as const;

export const PERMISSIONS = [
    "create",
    "read",
    "update",
    "delete",
] as const;
