import { ColumnDef } from "@tanstack/react-table";

import Actions from "./components/Actions";
import Cell from "./components/Cell";
import EnabledSelector from "./components/EnabledSelector";
import Header from "./components/Header";
import RoleSelector from "./components/RoleSelector";
import { Role } from "../../../../../../../types/roles";
import { IDeleteUserBody, IUser } from "../../../../../../../types/users";
import { getCurrentCases, getLastWeekClosed, getTodayCases } from "../../../../../../../utils/listOfAgents";

export const getColumns = ({
    deleteUser,
}: {
    deleteUser: (_data: IDeleteUserBody) => void
}): ColumnDef<IUser>[] => [
    {
        id: "email",
        accessorKey: "info.email",
        header: () => <Header label={"Agent"}/>,
        cell: info => <Cell value={info.getValue() as string}/>,
    },
    {
        id: "permission",
        accessorKey: "role.info.id",
        header: () => <Header label={"Permission"}/>,
        cell: ({ row }) => {
            return <RoleSelector
                userId={row.original.id}
                role={row.original.role.info.id as Role}
            />;
        },
    },
    {
        id: "average",
        header: () => <Header label={"Weekly Average"}/>,
        cell: ({ row }) => {
            return <Cell value={getLastWeekClosed(row.original.assignedCases)}/>;
        },
    },
    {
        id: "target",
        header: () => <Header label={"Daily Target"}/>,
        cell: ({ row }) => {
            return <Cell value={getTodayCases(row.original.assignedCases)}/>;
        },
    },
    {
        id: "cases",
        header: () => <Header label={"Current Cases"}/>,
        cell: ({ row }) => <Cell value={getCurrentCases(row.original.assignedCases)}/>,
    },
    {
        id: "enabled",
        accessorKey: "enabled",
        header: () => <Header label={"Enabled"}/>,
        cell: ({ row }) => {
            return <EnabledSelector
                userId={row.original.id}
                enabled={row.original.enabled}
            />;
        },
    },
    {
        id: "actions",
        cell: ({ row }) => <Actions
            value={row.original}
            deleteUser={deleteUser}
        />,
    },
];
