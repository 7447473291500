import { Button, Form, Modal } from "antd";

import { ICreateClientLogData } from "../../../types/client-logs";
import CommonInput from "../../inputs/Form/Inputs/CommonInput";
import CommonTextArea from "../../inputs/Form/Inputs/CommonTextArea";
import LevelSelector from "../../inputs/Form/Inputs/LevelSelector";

const CreateClientLogModal = ({
    close,
    onCreate,
}: {
	close: () => void;
	onCreate: (_data: ICreateClientLogData) => void;
}) => {
    const [form] = Form.useForm();

    return <Modal
        open
        width={600}
        title={
            <div className={"p20 modal-header grey-7-color"}>
				Add Event Log
            </div>
        }
        destroyOnClose
        onCancel={() => close()}
        footer={
            <div
                style={{ height: 40 }} className={"space-between"}>
                <Button
                    type={"link"}
                    onClick={() => close()}
                    className={"default-button"}
                >
					Cancel
                </Button>

                <Button
                    htmlType="submit"
                    onClick={() => {
                        form.submit();
                    }}
                    type={"primary"}
                    className={"primary-button"}
                >
					Create
                </Button>
            </div>
        }
    >
        <div className={"p20"}>
            <div>
	            <Form
		            form={form}
		            layout="vertical"
		            name="userForm"
		            onFinish={values => {
                        onCreate({
                            title: values.title,
	                        description: values.description,
	                        variation: values.variation,
                        });

			            close();
		            }}
	            >
                    <div>
						Event
                    </div>
                    <div className={"mt10"}>
                        <CommonInput
	                        required
                            name={"title"}
	                        placeholder={"Input event title..."}
                        />
                    </div>

		            <div>
			            Comment
		            </div>
		            <div className={"mt10"}>
			            <CommonTextArea
				            required
				            name={"description"}
				            placeholder={"Input comment..."}
			            />
		            </div>

		            <div>
			            Level
		            </div>
		            <div className={"mt10"}>
			            <LevelSelector
				            required
				            name={"variation"}
			            />
		            </div>
	            </Form>
            </div>
        </div>
    </Modal>;
};

export default CreateClientLogModal;
