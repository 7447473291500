import { useMemo } from "react";

import { Popover } from "antd";
import { GoDotFill } from "react-icons/go";

import { LOG_VARIATIONS_DICT } from "../../../constants/client-logs";
import { IClientLog } from "../../../types/client-logs";

const LogPreview = ({
    logs,
}: {
    logs?: IClientLog[];
}) => {
    const data = useMemo(() => {
        if (!logs || !logs.length) return;

        const lastLog = logs[logs.length - 1];

        return {
            lastLog,
            levelData: LOG_VARIATIONS_DICT[lastLog.variation],
        };
    }, [logs]);

    if (!data) return <div>-</div>;

    return <Popover
        content={`${data.lastLog.title}: ${data.lastLog.description} (${data.lastLog.variation})`}
        placement={"bottomRight"}
        color={"#EBEDEF"}
    >
        <div
            className={data.levelData.colorClassName}
            style={{ paddingTop: 8 }}
        >
            <GoDotFill className={"fs24"}/>
        </div>
    </Popover>;
};

export default LogPreview;
