import "../../../../../../../styles/builder.less";

import React, { useEffect, useState } from "react";

import { Button, Typography } from "antd";

import addressMark from "../../../../../../../assets/images/form/address-mark.svg";

type AddressQuestionProps = {
    data: {
        question?: string;
        helpText?: string;
        required?: boolean;
    };
    onAnswer: (_answer: boolean | string) => void;
    value?: boolean;
};

const clientsAddress = {
    street: "default street",
    city: "default city",
    county: "Ireland",
    eircode: "default code",
};

const AddressQuestion: React.FC<AddressQuestionProps> = ({
    data,
    onAnswer,
    value,
}) => {
    const [error, setError] = useState<string>("");
    const [skipValidation, setSkipValidation] = useState<boolean>(true);

    useEffect(() => {
        if (data.required && value === undefined && !skipValidation) {
            setError("This field is required");
        } else {
            setError("");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, skipValidation]);

    const handleButtonClick = (value: boolean) => {
        if (value) {
            onAnswer(clientsAddress.street + ", " + clientsAddress.city + ", " + clientsAddress.county + ", " + clientsAddress.eircode);
        } else {
            onAnswer(value);
        }
    };

    return (
        <div className="builder__boolean-question">
            <div className="builder__form-address-question__address-wrapper">
                <div className="builder__form-address-question__address">
                    <img src={addressMark} alt="address" width={20} height={20}/>
                    <Typography.Text className="builder__form-address-question__address-text">
                        Address
                    </Typography.Text>
                </div>
                <Typography.Text className="builder__form-question">
                    {clientsAddress.street}
                </Typography.Text>
                <Typography.Text className="builder__form-question">
                    {clientsAddress.city}
                </Typography.Text>
                <Typography.Text className="builder__form-question">
                    {clientsAddress.county}
                </Typography.Text>
                <Typography.Text className="builder__form-question">
                    {clientsAddress.eircode}
                </Typography.Text>
            </div>
            <Typography.Text className="builder__form-question">
                {data.question}
            </Typography.Text>
            <div className="builder__boolean-question__buttons">
                <Button
                    className={`builder__boolean-button ${
                        value ? "builder__boolean-button-selected" : ""
                    }`}
                    onClick={() => {
                        handleButtonClick(true);
                        setSkipValidation(false);
                    }}
                >
                    Yes
                </Button>
                <Button
                    className={`builder__boolean-button ${
                        value === false ? "builder__boolean-button-selected" : ""
                    }`}
                    onClick={() => {
                        handleButtonClick(false);
                        setSkipValidation(false);
                    }}
                >
                    No
                </Button>
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    marginTop: "5px",
                }}
            >
                <Typography.Text className="builder__help-text">
                    {data.helpText}
                </Typography.Text>
                {error && (
                    <Typography.Text className="builder__form-error">
                        {error}
                    </Typography.Text>
                )}
            </div>
        </div>
    );
};

export default AddressQuestion;
