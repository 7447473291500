import React from "react";

import { NavLink } from "react-router-dom";

import { IPageTabItem } from "../../../types/common";

const PageTab = ({
    item,
}: {
	item: IPageTabItem;
}) => {
    return <NavLink
	        style={{ zIndex: 11 }}
		    to={item.id}
		    className={({ isActive }) =>
			    "ml30 pointer bold" + (isActive ? " primary-color" : " grey-7-color")
		    }
	    >
		    {item.title}
	    </NavLink>;
};

export default PageTab;
