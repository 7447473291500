import { ColumnDef } from "@tanstack/react-table";

import CellWithDistributionPopup from "./components/CellWithDistributionPopup";
import Header from "./components/Header";
import HeaderWithDistributionPopup from "./components/HeaderWithDistributionPopup";
import Month from "./components/Month";
import { IFinancialStats } from "../../../../../../../types/stats";
import { roundToTwoDecimals } from "../../../../../../../utils/numbers";

export const getColumns = (): ColumnDef<IFinancialStats>[] => [
    {
        id: "month",
        accessorKey: "month",
        header: () => null,
        cell: ({ row }) => <Month value={row.original.month}/>,
    },
    {
        id: "monthlyRevenue",
        accessorKey: "stats.monthlyRevenue",
        header: ({ table }) => {
            const columns = table.getCenterRows().map(row => row.original);

            return <HeaderWithDistributionPopup
                columns={columns}
                label={"Monthly Revenue"}
                field={"monthlyRevenue"}
            />;
        },
        cell: ({ row }) => <CellWithDistributionPopup
            value={row.original}
            label={"Monthly Revenue"}
            field={"monthlyRevenue"}
        />,
    },
    {
        id: "vat",
        accessorKey: "stats.vat",
        header: ({ table }) => {
            const columns = table.getCenterRows().map(row => row.original);

            return <HeaderWithDistributionPopup
                columns={columns}
                label={"VAT"}
                field={"vat"}
            />;
        },
        cell: ({ row }) => <CellWithDistributionPopup
            value={row.original}
            label={"VAT"}
            field={"vat"}
        />,
    },
    {
        id: "totalRevenue",
        accessorKey: "stats.totalRevenue",
        header: () => <Header label={"Total Revenue"}/>,
        cell: ({ row }) => <CellWithDistributionPopup
            value={row.original}
            label={"Total Revenue"}
            field={"totalRevenue"}
        />,
    },
    {
        id: "newClient",
        accessorKey: "stats.newClient",
        header: () => <Header label={"New Client"}/>,
        cell: info => roundToTwoDecimals(Number(info.getValue())),
    },
    {
        id: "totalClient",
        accessorKey: "stats.totalClient",
        header: () => <Header label={"Total Client"}/>,
        cell: info => roundToTwoDecimals(Number(info.getValue())),
    },
    {
        id: "autoclosed",
        accessorKey: "stats.autoclosed",
        header: () => <Header label={"Autoclosed"}/>,
        cell: info => roundToTwoDecimals(Number(info.getValue())),
    },
    {
        id: "totalAutoclosed",
        accessorKey: "stats.totalAutoclosed",
        header: () => <Header label={"Total Autoclosed"}/>,
        cell: info => roundToTwoDecimals(Number(info.getValue())),
    },
    {
        id: "arpc",
        accessorKey: "stats.arpc",
        header: () => <Header label={"ARPC"}/>,
        cell: info => roundToTwoDecimals(Number(info.getValue())),
    },
];
