import React, { useMemo } from "react";

import { Button, message } from "antd";

import TableGroup from "./Table/TableGroup";
import { useInitiateDocumentScrapingMutation } from "../../../../../../services/documents";
import { IDocument } from "../../../../../../types/documents";
import { fillRevenueTableGroups } from "../../../../../../utils/documents";

const MAIN_COLUMN_WIDTH_PERCENTAGE = 30;

const RevenueDetails = ({
    document,
}: {
    document?: IDocument;
}) => {
    const [initiateDocumentScraping, {
        isLoading: isLoadingInitiateDocumentScraping,
    }] = useInitiateDocumentScrapingMutation();

    const {
        tableGroups,
        columnWidth,
        years,
    } = useMemo(() => {
        if (!document) {
            return {
                tableGroups: [],
                columnWidth: 0,
                years: [],
            };
        }

        const revenueDetails = [...document.revenueDetails]
            .sort((a, b) => (a.year > b.year ? -1 : 1));

        return {
            tableGroups: fillRevenueTableGroups(revenueDetails),
            columnWidth: (100 - MAIN_COLUMN_WIDTH_PERCENTAGE) / revenueDetails.length,
            years: revenueDetails.map(item => item.year),
        };
    }, [document]);

    return <div>
        <div
            style={{ height: 50 }}
            className={"space-between"}
        >
            <div className={"h3"}>
	            Revenue Details
            </div>

            <div>
                <Button
                    type={"primary"}
                    className={"primary-button"}
                    style={{ width: 180, height: 36 }}
                    loading={isLoadingInitiateDocumentScraping}
                    onClick={() => {
                        initiateDocumentScraping({
                            documentId: document!._id,
                        })
                            .unwrap()
                            .then(() => {
                                message.success("Case data scraping was successfully initiated. Please wait 3 minutes");
                            }).catch(() => null);
                    }}
                >
                    Rescrape Data
                </Button>
            </div>
        </div>

        <div>
            {tableGroups.map(group => {
                return <div
                    key={group.title}
                    className={"mt20"}
                >
                    <TableGroup
                        sizes={{
                            main: MAIN_COLUMN_WIDTH_PERCENTAGE,
                            column: columnWidth,
                        }}
                        header={{
                            id: group.title,
                            title: group.title,
                            columns: years,
                        }}
                        rows={group.rows}
                    />
                </div>;
            })}
        </div>
    </div>;
};

export default RevenueDetails;
