import { ColumnDef } from "@tanstack/react-table";

import Agent from "./components/Agent";
import CaseId from "./components/CaseId";
import Date from "./components/Date";
import Header from "./components/Header";
import { IDocument } from "../../../../../../../types/documents";
import Status from "../../../../../../common/Status/Status";

export const getColumns = (): ColumnDef<IDocument>[] => [
    {
        id: "createdAt",
        accessorKey: "createdAt",
        header: () => <Header label={"Date Added"}/>,
        cell: ({ row }) => <Date value={row.original.createdAt}/>,
    },
    {
        id: "id",
        accessorKey: "id",
        enableSorting: false,
        header: () => <Header label={"Case ID"}/>,
        cell: ({ row }) => <CaseId value={row.original}/>,
    },
    {
        id: "status",
        accessorKey: "status.id",
        enableSorting: false,
        header: () => <Header label={"Status"}/>,
        cell: ({ row }) => <Status value={row.original.status.id}/>,
    },
    {
        id: "updatedAt",
        accessorKey: "updatedAt",
        header: () => <Header label={"Last Updated"}/>,
        cell: ({ row }) => <Date value={row.original.updatedAt}/>,
    },
    {
        id: "manager",
        accessorKey: "manager",
        enableSorting: false,
        header: () => <Header label={"Agent"}/>,
        cell: ({ row }) => <Agent value={row.original.manager}/>,
    },
];
