import { Select } from "antd";
import { SizeType } from "antd/es/config-provider/SizeContext";

import { GROUPED_STATUSES, GROUPS_DICT } from "../../../../constants/documents";
import { StatusType } from "../../../../types/documents";

export const StatusSelector = ({
    disabled = false,
    selectedStatusId,
    onStatusSelect,
    width = 240,
    size = "large",
    name = "statuses",
}: {
	disabled?: boolean;
	selectedStatusId?: string;
	onStatusSelect: (_statusId: StatusType) => void;
	width?: number | string;
	size?: SizeType;
	name?: string;
}) => {
    return <div>
        <Select
            value={selectedStatusId}
            onSelect={(value: string) => onStatusSelect(value as StatusType)}
            size={size}
            className={"select-content grey-color"}
            style={{ width }}
            disabled={disabled}
            placeholder={<span className={"fs14"}>Select status...</span>}
            data-testid={name}
        >
            {Object.entries(GROUPED_STATUSES).map(([groupId, statuses]) => {
                const groupLabel = GROUPS_DICT[groupId as keyof typeof GROUPS_DICT] || groupId;

                return (
                    <Select.OptGroup key={groupId} label={<div className={"center status-category fw400"}>{groupLabel}</div>}>
                        {statuses.map((status, index) => (
                            <Select.Option
                                key={status.id}
                                value={status.id}
                                className={"center status-option"}
                                style={index < statuses.length - 1 ? { borderBottom: "1px solid #EEE" } : {}}
                            >
                                <div
                                    className={"fs14 text-center fw500"}
                                    data-testid={`select-option-${name}-${status.id}`}
                                    style={{ color: status.color.font }}
                                >
                                    {status.title}
                                </div>
                            </Select.Option>
                        ))}
                    </Select.OptGroup>
                );
            })}
        </Select>
    </div>;
};

export default StatusSelector;
