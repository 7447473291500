import { ColumnDef } from "@tanstack/react-table";

import CaseId from "./components/CaseId";
import Date from "./components/Date";
import Header from "./components/Header";
import TaxNumber from "./components/TaxNumber";
import { IClient } from "../../../../../../../types/clients";
import { IDocument } from "../../../../../../../types/documents";
import Status from "../../../../../../common/Status/Status";
import IndeterminateCheckbox from "../../../../../../common/Table/components/IndeterminateCheckbox";
import Cell from "../../../../../Cases/components/CasesTable/components/Cell";

export const columns: ColumnDef<IDocument>[] = [
    {
        id: "select",
        header: ({ table }) => (
            <IndeterminateCheckbox
                {...{
                    checked: table.getIsAllRowsSelected(),
                    indeterminate: table.getIsSomeRowsSelected(),
                    onChange: table.getToggleAllRowsSelectedHandler(),
                }}
                className={"horizontal-left"}
            />
        ),
        cell: ({ row }) => (
            <div className="px-1">
                <IndeterminateCheckbox
                    {...{
                        checked: row.getIsSelected(),
                        disabled: !row.getCanSelect(),
                        indeterminate: row.getIsSomeSelected(),
                        onChange: row.getToggleSelectedHandler(),
                    }}
                />
            </div>
        ),
    },
    {
        id: "name",
        enableSorting: false,
        accessorFn: row => {
            const client = row.client as IClient;
            return `${client.info.firstName} ${client.info.lastName}`;
        },
        header: () => <Header label={"Name"}/>,
        cell: info => <Cell value={info.getValue() as string}/>,
    },
    {
        id: "email",
        accessorKey: "client.info.email",
        enableSorting: false,
        header: () => <Header label={"Email"}/>,
        cell: info => <Cell value={info.getValue() as string}/>,
    },
    {
        id: "ppsn",
        accessorKey: "ppsn",
        enableSorting: false,
        header: () => <Header label={"PPSN"}/>,
        cell: ({ row }) => <TaxNumber value={row.original.ppsn}/>,
    },
    {
        id: "id",
        accessorKey: "id",
        enableSorting: false,
        header: () => <Header label={"Case ID"}/>,
        cell: ({ row }) => <CaseId value={row.original}/>,
    },
    {
        id: "status",
        accessorKey: "status.id",
        enableSorting: false,
        header: () => <Header label={"Status"}/>,
        cell: ({ row }) => <Status value={row.original.status.id}/>,
    },
    {
        id: "updatedAt",
        accessorKey: "updatedAt",
        header: () => <Header label={"Last Updated"}/>,
        cell: ({ row }) => <Date value={row.original.updatedAt}/>,
    },
    {
        id: "createdAt",
        accessorKey: "createdAt",
        header: () => <Header label={"Date Added"}/>,
        cell: ({ row }) => <Date value={row.original.createdAt}/>,
    },
];
