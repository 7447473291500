import "../../../../../../../styles/selectable-menu.less";

import React, { useEffect, useState } from "react";

import { Menu } from "antd";

import complete from "../../../../../../../assets/images/form/complete.svg";
import incomplete from "../../../../../../../assets/images/form/incomplete.svg";
import useBaseUrl from "../../../../../../../hooks/helpers/useBaseUrl";
import { SubTabT, TabT } from "../../../../../../../types/questionnaires";
import { getStepsCount } from "../../../../../../../utils/builder";

type SelectableMenuProps = {
    currentSubTab: string;
    setCurrentSubTab: React.Dispatch<React.SetStateAction<string>>;
    setCurrentGroupId: React.Dispatch<React.SetStateAction<string>>;
    setCurrentIcon: React.Dispatch<React.SetStateAction<string>>;
    completedSubTabs: { [key: string]: boolean };
    tabs: TabT[];
    isQuestionnaire?: boolean;
    answers?: Record<string, any>;
    disabled?: boolean;
};

const SelectableMenu: React.FC<SelectableMenuProps> = ({
    currentSubTab,
    setCurrentSubTab,
    setCurrentIcon,
    setCurrentGroupId,
    completedSubTabs,
    tabs,
    isQuestionnaire,
    disabled,
}) => {
    const baseUrl = useBaseUrl();
    const [openKeys, setOpenKeys] = useState<string[]>([
        tabs[0]?.data.label || "",
    ]);

    const handleMenuClick = (e: any) => {
        setCurrentSubTab(e.key);
        const parentTab = tabs.find(tab =>
            tab.children?.some(subTab => subTab.data.label === e.key)
        );
        const subTab = parentTab?.children?.find(subTab => subTab.data.label === e.key);
        if (subTab?.children && subTab.children.length > 0) {
            setCurrentGroupId(subTab.children[0].id);
        }
    };

    const onOpenChange = (keys: string[]) => {
        setOpenKeys([keys[keys.length - 1]]);
    };

    useEffect(() => {
        const parentTab = tabs.find(tab =>
            tab.children?.some(subTab => subTab.data.label === currentSubTab)
        );

        if (parentTab?.data?.label) {
            setOpenKeys([`Menu ${parentTab.data.label}`]);
        }
    }, [currentSubTab, tabs]);

    return (
        <div className="custom-menu">
            <Menu
                selectedKeys={[currentSubTab]}
                openKeys={openKeys}
                onClick={handleMenuClick}
                onOpenChange={onOpenChange}
                defaultOpenKeys={[tabs[0]?.data.label || ""]}
                mode="inline"
                style={{
                    height: "100%",
                    borderRight: 0,
                }}
            >
                {tabs.map((tab: TabT) => {
                    const tabCompleted =
                        tab.children?.every(
                            subTab =>
                                completedSubTabs &&
                                subTab.data.label &&
                                completedSubTabs[subTab.data.label]
                        ) || false;
                    return (
                        <Menu.SubMenu
                            key={`Menu ${tab.data.label}`}
                            title={
                                <div className="custom-submenu-title">
                                    <p className="custom-submenu-title-label">
                                        {tab.data.label}
                                    </p>
                                    {tabCompleted ? (
                                        <p className="custom-submenu-title-text complete">
                                            Complete
                                        </p>
                                    ) : (
                                        <p className="custom-submenu-title-text">
                                            {getStepsCount(tab, completedSubTabs)}
                                        </p>
                                    )}
                                </div>
                            }
                            icon={
                                <div className="menu-icon-wrapper">
                                    <img
                                        src={
                                            isQuestionnaire
                                                ? baseUrl + tab.data.iconUrl
                                                : tab.data.iconUrl
                                        }
                                        height={50}
                                        width={50}
                                        alt="Icon"
                                    />
                                    {tabCompleted &&
                                    <div className="menu-icon-complete-mark">
                                        <div className="complete-icon-wrapper-complete" style={{ marginRight: "0px" }}>
                                            <img
                                                src={complete}
                                                alt="complete icon"
                                            />
                                        </div>
                                    </div>
                                    }
                                </div>
                            }
                            className="custom-submenu"
                        >
                            {tab.children?.map((subTab: SubTabT) => {
                                const subTabCompleted =
                                    completedSubTabs &&
                                    subTab.data.label &&
                                    completedSubTabs[subTab.data.label];
                                return (
                                    <Menu.Item
                                        key={subTab.data.label}
                                        onClick={() => {
                                            setCurrentSubTab(
                                                subTab.data.label || ""
                                            );
                                            setCurrentIcon(
                                                tab.data.iconUrl
                                                    ? isQuestionnaire
                                                        ? baseUrl +
                                                          tab.data.iconUrl
                                                        : tab.data.iconUrl
                                                    : ""
                                            );
                                        }}
                                        className={`custom-menu-item ${
                                            currentSubTab === tab.data.label
                                                ? "selected"
                                                : ""
                                        } ${disabled ? "disabled" : ""}`}
                                    >
                                        <div className="custom-menu-item-content">
                                            {subTabCompleted ? (
                                                <div className="complete-icon-wrapper-complete">
                                                    <img
                                                        src={complete}
                                                        alt="complete icon"
                                                    />
                                                </div>
                                            ) : (
                                                <div className="complete-icon-wrapper">
                                                    <img
                                                        src={incomplete}
                                                        alt="incomplete icon"
                                                    />
                                                </div>
                                            )}
                                            <p>{subTab.data.label}</p>
                                        </div>
                                    </Menu.Item>
                                );
                            })}
                        </Menu.SubMenu>
                    );
                })}
            </Menu>
        </div>
    );
};

export default SelectableMenu;
