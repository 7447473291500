import { FC, useEffect, useState } from "react";

import { List } from "antd";

import QuestionListItem from "./QuestionItem";
import { useTopScroller } from "../../../../../../../hooks/helpers/useTopScroller";
import { GroupT } from "../../../../../../../types/questionnaires";
import {
    getAnsweredRequiredQuestions,
    getTotalRequiredQuestions,
} from "../../../../../../../utils/builder";

export type ValidationType = {
    regex: string;
    message: string;
};

type QuestionRequirement = {
    required: boolean;
    validation?: ValidationType;
};

export type Questions = Record<string, QuestionRequirement>;

type GroupItemComponentProps = {
    group: GroupT;
    // eslint-disable-next-line no-unused-vars
    onAnswer: (questionId: string, answer: any) => void;
    returnForbidden: Record<string, string>;
    answers: Record<string, any>;
    // eslint-disable-next-line no-unused-vars
    onGroupProgressUpdate: (groupId: string, progress: number) => void;
};

const GroupItemComponent: FC<GroupItemComponentProps> = ({
    group,
    onAnswer,
    answers,
    returnForbidden,
    onGroupProgressUpdate,
}) => {
    useTopScroller();
    const { id, children } = group;

    const [requiredGroupQuestions, setRequired] = useState<Record<string, any>>({});

    const handleQuestionRequired = (questionId: string, required: boolean, validation?: ValidationType) => {
        setRequired(prevRequired => ({
            ...prevRequired,
            [questionId]: {
                required,
                validation,
            },
        }));
    };

    useEffect(() => {
        let totalRequiredQuestions = getTotalRequiredQuestions(
            requiredGroupQuestions
        );
        let answeredRequiredQuestions = getAnsweredRequiredQuestions(
            requiredGroupQuestions,
            answers
        );

        if (Object.values(returnForbidden).includes(id)) {
            totalRequiredQuestions = 0;
            answeredRequiredQuestions = 0;
        }

        let progress = 0;

        if (totalRequiredQuestions === 0) {
            progress = 100;
        } else {
            progress = Math.floor(
                (answeredRequiredQuestions / totalRequiredQuestions) * 100
            );
        }

        onGroupProgressUpdate(id, progress);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requiredGroupQuestions, answers, returnForbidden]);

    return (
        <div className="form-builder__tabpanel">
            <List className="form-builder__questions">
                {children && children.map(question => (
                    <QuestionListItem
                        setRequired={handleQuestionRequired}
                        key={question.id}
                        question={question}
                        onAnswer={onAnswer}
                        answers={answers}
                    />
                ))}
            </List>
        </div>
    );
};

export default GroupItemComponent;
