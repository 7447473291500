import { useMemo } from "react";

import {
    ColumnDef,
    getCoreRowModel, getFilteredRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";

import { useGetClientDocumentsQuery } from "../../services/clients/documents";
import { IDocument } from "../../types/documents";

export const useClientCasesTable = (getColumns: () => ColumnDef<IDocument>[], clientId?: string) => {
    const {
        data,
        isLoading: isLoadingGetClientDocuments,
    } = useGetClientDocumentsQuery({
        clientId: clientId!,
    }, {
        skip: !clientId,
    });

    const documents = useMemo(() => data?.documents || [], [data?.documents]);

    const isLoading = useMemo(() => {
        return isLoadingGetClientDocuments;
    }, [
        isLoadingGetClientDocuments,
    ]);

    const table = useReactTable(
        {
            columns: useMemo(() => getColumns(), [getColumns]),
            data: useMemo(() => documents || [], [documents]),
            getCoreRowModel: getCoreRowModel(),
            getSortedRowModel: getSortedRowModel(),
            getFilteredRowModel: getFilteredRowModel(),
        }
    );

    return {
        documents,
        table,
        isLoading,
    };
};
