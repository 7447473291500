import { useMemo } from "react";

import { Col, Row, Select } from "antd";
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";

import { PAGE_SIZES } from "../../../../constants/pagination";
import { IPaginationProps } from "../../../../types/common";

const Pagination = ({
    pagination,
}: {
	pagination: IPaginationProps;
}) => {
    const {
	    itemsName = "Items",
	    totalDocs = 0,
	    setPageSize,
	    pageSize = 1,
	    pageIndex = 0,
	    totalPages = 0,
	    previousPage,
	    nextPage,
	    canPreviousPage,
	    canNextPage,
	    setCurrentPage,
    } = useMemo(() => pagination, [pagination]);

    return <Row>
        <Col span={8} className={"vertical-center"}>
            <div className={"p20 horizontal-left"}>
                <div className={"h5"}>
	                Total: <span data-testid="pagination-total" className={"bold"}>{totalDocs}</span> {itemsName}
                </div>
            </div>
        </Col>
        <Col span={16}>
            <div className="pagination">
                {setPageSize &&
                    <div className={"mr20"}>
                        <Select
                            data-testid="pagination-show"
                            value={pageSize}
                            onChange={value => setPageSize(value)}
                            size={"middle"}
                            className={"select-content grey-color"}
                            style={{ width: 150 }}
                        >
                            {PAGE_SIZES.map(size => <Select.Option
                                data-testid={`pagination-show-${size}`}
                                key={size}
                                value={size}
                                className={"select-option grey-color"}
                            >
								Show {size}
                            </Select.Option>)}
                        </Select>
                    </div>}

                <div
                    data-testid="pagination-prev"
                    className={"pagination-item center"}
                    onClick={() => canPreviousPage && previousPage()}
                    style={{ cursor: "pointer" }}
                >
                    <RiArrowLeftSLine style={{ fontSize: 20, verticalAlign: "middle" }}
					                  className={pageIndex > 1 ? "dark-grey-color" : "light-grey-color"}/>
                </div>

                {setCurrentPage && (pageIndex > 3) && <div
                    className={"pagination-item center"}
                    onClick={() => setCurrentPage(1)}
                >
                    <span>1</span>
                </div>}

                {setCurrentPage && (pageIndex > 3) && <div
                    className={"pagination-item center"}>
                    <span>...</span>
                </div>}

                {pageIndex > 1 && <div
                    className={"pagination-item center"}
                    onClick={() => canPreviousPage && previousPage()}
                >
                    <span style={{ verticalAlign: "middle" }}>{pageIndex - 1}</span>
                </div>}

                <div
                    style={{ color: "white" }}
                    className={"selected-pagination-item center"}
                >
                    <span style={{ verticalAlign: "middle" }}>{pageIndex}</span>
                </div>

                {(pageIndex < totalPages) && <div
                    className={"pagination-item center"}
                    onClick={() => canNextPage && nextPage()}>
                    <span>{pageIndex + 1}</span>
                </div>}

                {setCurrentPage && (pageIndex < totalPages - 2) && <div
                    className={"pagination-item center"}>
                    <span>...</span>
                </div>}

                {setCurrentPage && (pageIndex < totalPages - 2) && <div
                    className={"pagination-item center"}
                    onClick={() => setCurrentPage(totalPages)}
                >
                    <span>{totalPages}</span>
                </div>}

                <div
                    data-testid="pagination-next"
                    className={"pagination-item center"}
                    onClick={() => canNextPage && nextPage()}
                    style={{ marginLeft: 10, marginRight: 10, cursor: "pointer" }}
                >
                    <RiArrowRightSLine
	                    style={{
                            fontSize: 20,
                            verticalAlign: "middle",
                        }}
	                    className={(pageIndex < totalPages) ? "dark-grey-color" : "light-grey-color"}
                    />
                </div>

            </div>
        </Col>
    </Row>;
};

export default Pagination;
