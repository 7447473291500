import React, { useMemo } from "react";

import CasesHeader from "./components/CasesHeader";
import CasesTable from "./components/CasesTable/CasesTable";
import { getColumns } from "./components/CasesTable/columns";
import MainStatusFilterItem from "./components/MainStatusFilterItem/MainStatusFilterItem";
import { MAIN_STATUSES } from "../../../constants/documents";
import { useCasesTable } from "../../../hooks/cases/useCasesTable";
import { IPageFilterItem } from "../../../types/common";
import PageHeader from "../../basic/PageHeader/PageHeader";
import Spinner from "../../basic/Spinner/Spinner";
import Pagination from "../../common/Table/components/Pagination";

const Cases = () => {
    const {
        isLoading,
        table,
        pagination,
        globalFilter,
        count,
        selectedCases,
        agentId,
        setAgentId,
        mainStatusId,
        setMainStatusId,
        statusIds,
        setStatusIds,
        paidRange,
        setPaidRange,
        planIds,
        lastSubmittedRange,
        setLastSubmittedRange,
        setPlanIds,
        exportDocumentsDataToCsv,
        updateDocumentsStatuses,
        setSorting,
        columnVisibility,
        setColumnVisibility,
    } = useCasesTable(getColumns);

    const menu = useMemo(() => {
        return (MAIN_STATUSES as readonly IPageFilterItem[]).map(item => {
            return <MainStatusFilterItem
                key={item.id}
                count={count?.[item.id]?.[0]?.count}
                item={item}
                mainStatusId={mainStatusId}
                setMainStatusId={setMainStatusId}
            />;
        });
    }, [count, mainStatusId, setMainStatusId]);

    return <div>
        <div>
            <PageHeader menu={menu}/>
        </div>

        <div className={"common-page scroll-block"}>
            <div className={"common-block"}>
                <Spinner isLoading={isLoading}>
                    <div>
                        <CasesHeader
                            columnVisibility={columnVisibility}
                            setColumnVisibility={setColumnVisibility}
                            selectedPlanIds={planIds}
                            setSelectedPlanIds={setPlanIds}
                            selectedPaidRange={paidRange}
                            setSelectedPaidRange={setPaidRange}
                            selectedLastSubmittedRange={lastSubmittedRange}
                            setSelectedLastSubmittedRange={setLastSubmittedRange}
                            mainStatusId={mainStatusId}
                            selectedStatusIds={statusIds}
                            setSelectedStatusIds={setStatusIds}
                            exportDocumentsDataToCsv={exportDocumentsDataToCsv}
                            selectedAgentId={agentId}
                            setSelectedAgentId={setAgentId}
                            globalFilter={globalFilter}
                            selectedCases={selectedCases}
                            updateDocumentsStatuses={updateDocumentsStatuses}
                            setSorting={setSorting}

                        />
                    </div>

                    <div className={"mt10"}>
                        <CasesTable
                            table={table}
                        />
                    </div>

                    <div className={"mt10"}>
                        <Pagination
                            pagination={pagination}
                        />
                    </div>
                </Spinner>
            </div>
        </div>
    </div>;
};

export default Cases;
