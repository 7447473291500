import { Form, Input } from "antd";

import Error from "./../Labels/Error";
import Label from "./../Labels/Label";

const EmailInput = ({
    required = false,
    name = "email",
}: {
    required?: boolean;
    name?: string;
}) => {
    return <div>
        <Form.Item
            hasFeedback
            name={name}
            label={<Label text={"Email"}/>}
            required={required}
            labelAlign={"left"}
            validateFirst
            rules={[{
                required,
                message: <Error text={"Please input the email!"}/>,
            }, {
                type: "email",
                message: <Error text={"The input is not valid E-mail!"}/>,
            }]}>
            <Input
                placeholder="Enter email..."
                data-testid={name}
                className={"input-content dark-grey-color"}
                size="large"
            />
        </Form.Item>

    </div>;
};

export default EmailInput;
