import { useState } from "react";

import { Button } from "antd";

import { IDocument } from "../../../../../../types/documents";
import AgentSelector from "../../../../../inputs/Fields/AgentSelector/AgentSelector";

const UnassignedCasesHeader = ({
    selectedCases,
    totalUnassignedCases,
    assignCasesToAgent,
}: {
    selectedCases: IDocument[];
    assignCasesToAgent: (_agentId: string) => void;
    totalUnassignedCases: number;
}) => {
    const [selectedAgentId, setSelectedAgentId] = useState<string | undefined>(undefined);

    return <div className={"p10 space-between"}>
        <div className={"h2"}>
            Unassigned Cases ({totalUnassignedCases})
        </div>
        <div className={"horizontal-right"}>
            <div>
                <AgentSelector
                    width={240}
                    disabled={!selectedCases.length}
                    selectedAgentId={selectedAgentId}
                    onAgentSelect={(agentId: string) => {
                        setSelectedAgentId(agentId);
                    }}
                />
            </div>
            <div className={"ml20"}>
                <Button
                    disabled={!selectedCases.length || !selectedAgentId}
                    type={"primary"}
                    className={"primary-button"}
                    style={{ width: 120, height: 38 }}
                    onClick={() => {
                        if (selectedAgentId) {
                            assignCasesToAgent(selectedAgentId);
                        }
                    }}
                >
                    Assign
                </Button>
            </div>
        </div>
    </div>;
};

export default UnassignedCasesHeader;
