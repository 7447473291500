export const CLIENT_TYPES = [{
    value: "all",
    label: "All Clients",
}, {
    value: "current",
    label: "Current Clients",
}, {
    value: "previous",
    label: "Previous Clients",
}];

export const POSSIBLE_CLIENT_TYPES = CLIENT_TYPES.map(type => type.value);

export const SPOUSE_ASSESSMENT_TYPES = [{
    value: "joint",
    label: "Joint Assessment",
}, {
    value: "single",
    label: "Single Assessment",
}, {
    value: null,
    label: "-",
}];

export const POSSIBLE_SPOUSE_ASSESSMENT_TYPES = SPOUSE_ASSESSMENT_TYPES.map(type => type.value);

export const SPOUSE_ASSESSMENT_STATUSES = [{
    value: "assessable-spouse",
    label: "Assessable Spouse",
}, {
    value: "non-assessable-spouse",
    label: "Not Assessable Spouse",
}, {
    value: "n/a",
    label: "N/A",
}, {
    value: null,
    label: "-",
}];

export const POSSIBLE_SPOUSE_ASSESSMENT_STATUSES = SPOUSE_ASSESSMENT_STATUSES.map(type => type.value);
