import React, { createContext } from "react";

import { DownloadOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { NavLink, Route, Routes, useParams } from "react-router-dom";

import ClientHeader from "./components/ClientHeader";
import Cases from "./tabs/Cases/Cases";
import Details from "./tabs/Details/Details";
import Transactions from "./tabs/Transactions/Transactions";
import { useClient } from "../../../hooks/clients/useClient";
import { IClient } from "../../../types/clients";

export const ClientContext = createContext<{
    clientId: string | undefined;
    client: IClient | undefined;
    isLoading: boolean;
}>({
    clientId: undefined,
    client: undefined,
    isLoading: false,
});

const getTabClassName = ({ isActive }: { isActive: boolean }) => "tab grey-color" + (isActive ? " tab-active" : "");

const Client = () => {
    const {
        clientId,
    } = useParams() as {
        clientId: string
    };

    const {
        client,
        isLoading,
        updateClientById,
        getAgreementFile,
    } = useClient(clientId);

    return <div>
        <ClientContext.Provider
            value={{
                clientId,
                isLoading,
                client,
            }}
        >
            <div>
                <ClientHeader/>
            </div>

            <div className={"common-page scroll-block"}>
                <div className={"common-block-header"}>
                    <div className={"space-between"}>
                        <div
                            className={"case-tabs"}
                        >
                            <NavLink
                                data-testid={"case-overview-tab"}
                                to={`/client/${clientId}/details`}
                                className={getTabClassName}
                            >
                                Personal Details
                            </NavLink>
                            <NavLink
                                data-testid={"case-people-tab"}
                                to={`/client/${clientId}/cases`}
                                className={getTabClassName}
                            >
                                Case Details
                            </NavLink>
                            <NavLink
                                data-testid={"case-mails-tab"}
                                to={`/client/${clientId}/transactions`}
                                className={getTabClassName}
                            >
                                Transaction Details
                            </NavLink>
                        </div>
                        <div className={"horizontal-right"}>
                            <div className={"mr10"}>
                                <Button
                                    type={"primary"}
                                    className={"primary-button"}
                                    style={{ width: 280, height: 36 }}
                                    icon={<DownloadOutlined />}
                                    onClick={getAgreementFile}
                                >
                                    Download Client Agreement
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"common-block"}>
                    <Routes>
                        <Route
                            path={"details"}
                            element={<Details
                                updateClientById={updateClientById}
                            />}
                        />

                        <Route
                            path={"cases"}
                            element={<Cases/>}
                        />

                        <Route
                            path={"transactions"}
                            element={<Transactions/>}
                        />

                    </Routes>
                </div>
            </div>
        </ClientContext.Provider>
    </div>;
};

export default Client;
