import { ColumnDef } from "@tanstack/react-table";

import { IUser } from "../../../../types/users";

export const columns: ColumnDef<IUser>[] = [
    {
        id: "name",
        accessorKey: "info.name",
        cell: info => info.getValue(),
    },
];
