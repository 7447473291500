import { FC, useEffect, useState } from "react";

import { Typography } from "antd";

import { SELECT_ONE_TYPE } from "../../../../../../../constants/constructor";
import Dropdown from "../inputs/Dropdown";

type SelectOneQuestionProps = {
    data: {
        question?: string;
        helpText?: string;
        selectType?: string;
        required?: boolean;
        options?: {
            id: string;
            value: string;
        }[];
    };
    onAnswer: (_answer: string) => void;
    value?: string;
};

const SelectOneQuestion: FC<SelectOneQuestionProps> = ({
    data,
    onAnswer,
    value = "",
}) => {
    const [error, setError] = useState<string>("");
    const [skipValidation, setSkipValidation] = useState<boolean>(true);

    useEffect(() => {
        if (data.required && value === "" && !skipValidation) {
            setError("Required to select an option");
        } else {
            setError("");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, skipValidation]);

    const handleOptionChange = (id: string) => {
        const selectedOption = data.options?.find(option => option.id === id);
        if (selectedOption) {
            onAnswer(selectedOption.value);
        }
    };

    const selectedId = data.options?.find(
        option => option.value === value
    )?.id;

    switch (data.selectType) {
    case SELECT_ONE_TYPE.TABS:
        return (
            <div className="builder__select-many-question">
                <Typography.Text className="builder__form-question">
                    {data.question}
                </Typography.Text>
                <div className="builder__option-container">
                    {data.options?.map(option => (
                        <div key={option.id} className="builder__tab-option">
                            <div
                                className={`builder__option-content ${
                                    value === option.value ? "selected" : ""
                                }`}
                                onClick={() => handleOptionChange(option.id)}
                            >
                                <div
                                    className="builder__option-text"
                                    style={{
                                        fontSize: `${
                                            /^\d{1,4}$/.test(option.value)
                                                ? "24px"
                                                : "14px"
                                        }`,
                                    }}
                                >
                                    {option.value}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                    }}
                >
                    <Typography.Text className="builder__help-text">
                        {data.helpText}
                    </Typography.Text>
                    {error && (
                        <Typography.Text className="builder__form-error">
                            {error}
                        </Typography.Text>
                    )}
                </div>
            </div>
        );

    case SELECT_ONE_TYPE.DROPDOWN:
    default:
        return (
            <div className="builder__select-one-question">
                <Typography.Text
                    className="builder__form-question"
                    style={{ display: "block", marginBottom: "10px" }}
                >
                    {data.question}
                </Typography.Text>
                <Dropdown
                    options={data.options || []}
                    onSelect={handleOptionChange}
                    setSkipValidation={setSkipValidation}
                    selectedId={selectedId}
                />
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        marginTop: "5px",
                    }}
                >
                    <Typography.Text className="builder__help-text">
                        {data.helpText}
                    </Typography.Text>
                    {error && (
                        <Typography.Text className="builder__form-error">
                            {error}
                        </Typography.Text>
                    )}
                </div>
            </div>
        );
    }
};

export default SelectOneQuestion;
