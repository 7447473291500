import { ReactNode } from "react";

import { Form, Input } from "antd";
import { SizeType } from "antd/es/config-provider/SizeContext";

const CommonInput = ({
    required = false,
    name = "name",
    errorMessage = "Please set value!",
    size = "large",
    addonBefore = undefined,
    value = undefined,
    onChange = () => null,
    placeholder = "Set value...",
}: {
    required?: boolean;
    name?: string;
    errorMessage?: string;
    placeholder?: string;
    size?: SizeType;
    addonBefore?: ReactNode | undefined;
    value?: string | undefined;
    onChange?: () => void;
}) => {
    return <div>
        <Form.Item
            name={name}
            rules={[{
                required,
                message: errorMessage,
            }]}
        >
            <Input
                data-testid={name}
                className={"input-content grey-color"}
                size={size}
                value={value}
                onChange={onChange}
                addonBefore={addonBefore}
                placeholder={placeholder}
            />
        </Form.Item>
    </div>;
};

export default CommonInput;
