import { useEffect, useState } from "react";

import { useIdleTimer } from "react-idle-timer";

import { useAppDispatch } from "../../redux/hooks";
import { logout } from "../../redux/reducers/auth";

export const useAutoLogout = ({
    interval,
    timeout,
}: {
    interval: number;
    timeout: number;
}) => {
    const dispatch = useAppDispatch();

    const [state, setState] = useState<string>("Active");
    const [count, setCount] = useState<number>(0);
    const [remaining, setRemaining] = useState<number>(0);

    const onIdle = () => {
        setState("Idle");
    };

    const onActive = () => {
        setState("Active");
    };

    const onAction = () => {
        setCount(count + 1);
    };

    const { getRemainingTime } = useIdleTimer({
        onIdle,
        onActive,
        onAction,
        timeout,
    });

    useEffect(() => {
        const intervalInstance = setInterval(() => {
            setRemaining(Math.ceil(getRemainingTime() / 1000));
        }, interval);

        return () => {
            clearInterval(intervalInstance);
        };
    });

    useEffect(() => {
        if (state === "Idle") {
            dispatch(logout());
        }
    }, [dispatch, state]);

    return {
        remaining,
        state,
    };
};
