import { Col, Row } from "antd";

import { IRow } from "../../../../../../../types/common";

const TableGroup = ({
    sizes,
    header,
    rows,
}: {
	header: IRow;
	rows: IRow[];
	sizes: {
		main: number;
		column: number;
	}
}) => {
    return <div>
        <Row gutter={16}>
            <Col flex={`${sizes.main}%`}>
	            <div className={"fs16 bold"}>
		            {header.title}
	            </div>
            </Col>

	        {header.columns.map(column => {
                return <Col
	                key={column}
	                flex={`${sizes.column}%`}
                >
	                <div className={"fs16 primary-color bold horizontal-right"}>
		                {column}
                    </div>
                </Col>;
	        })}
        </Row>

	    {rows.map((row, i) => {
		    return <Row
			    key={i}
			    gutter={16}
			    className={"mt10"}
		    >
			    <Col flex={`${sizes.main}%`}>
				    <div className={`purple-color ${row.parentId ? "fs11" : "fs12"}`}>
					    {row.title}
				    </div>
			    </Col>

			    {row.columns.map((column, j) => {
				    return <Col
					    key={j}
					    flex={`${sizes.column}%`}
				    >
					    <div className={"fs12 grey-7-color bold horizontal-right"}>
						    {column}
					    </div>
				    </Col>;
			    })}
		    </Row>;
	    })}
    </div>;
};

export default TableGroup;
