import { Select } from "antd";

import { DEFAULT_ROLES, DEFAULT_ROLES_DICT } from "../../../../../../../../constants/roles";
import { useUser } from "../../../../../../../../hooks/auth/useUser";
import { usePermissionGranter } from "../../../../../../../../hooks/common/usePermissionGranter";
import { useUpdateUserMutation } from "../../../../../../../../services/users";
import { Role } from "../../../../../../../../types/roles";

const RoleSelector = ({
    userId,
    role,
}: {
	userId: string;
	role: Role
}) => {
    const [
        updateUser,
	    { isLoading },
    ] = useUpdateUserMutation();

    const { user } = useUser();

    const agentsUpdatePermissionIsGranted = usePermissionGranter("users:people", "update");

    return <div className={"horizontal-center"}>
	    {agentsUpdatePermissionIsGranted ? <Select
		    loading={isLoading}
		    disabled={user.id === userId}
            value={role}
		    style={{ width: 140 }}
		    onChange={value => {
			    updateUser({
				    id: userId,
				    data: {
					    roleId: value,
				    },
			    });
		    }}
		    options={DEFAULT_ROLES.map(role => ({
			    value: role.id,
			    label: role.name,
		    }))}
	    /> : <div>
		    {DEFAULT_ROLES_DICT[role].name}
	    </div>}
    </div>;
};

export default RoleSelector;
