import { useMemo, useState } from "react";

import { ColumnDef, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import moment from "moment/moment";

import { useGetFinancialStatsQuery } from "../../services/stats";
import { IFinancialStats } from "../../types/stats";

export const useFinancialStats = (getColumns: () => ColumnDef<IFinancialStats>[]) => {
    const [year, setYear] = useState<string>(moment().format("YYYY"));

    const {
        data,
        isLoading,
    } = useGetFinancialStatsQuery({
        year,
    });

    const table = useReactTable(
        {
            columns: useMemo(() => getColumns(), [getColumns]),
            data: useMemo(() => data?.data.stats || [], [data]),
            getCoreRowModel: getCoreRowModel(),
        }
    );

    return {
        table,
        year,
        setYear,
        isLoading,
    };
};
