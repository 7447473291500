import { useMemo } from "react";

import { useGetClientDocumentsQuery } from "../../services/clients/documents";

export const useSubmissionsSelector = (clientId?: string) => {
    const {
        data,
        isLoading: isLoadingGetClientDocuments,
    } = useGetClientDocumentsQuery({
        clientId: clientId!,
    }, {
        skip: !clientId,
    });

    const documents = useMemo(() => data?.documents || [], [data?.documents]);

    const isLoading = useMemo(() => {
        return isLoadingGetClientDocuments;
    }, [
        isLoadingGetClientDocuments,
    ]);

    return {
        isLoading,
        documents,
    };
};
