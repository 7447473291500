import React, { useContext } from "react";

import { Col, Row } from "antd";

import { columns } from "./components/EventLogs/ClientLogsTable/columns";
import EventLogs from "./components/EventLogs/EventLogs";
import StatusesHistory from "./components/StatusesHistory/StatusesHistory";
import { useClientLogsTable } from "../../../../../hooks/clients/useClientLogsTable";
import { IClient } from "../../../../../types/clients";
import Spinner from "../../../../basic/Spinner/Spinner";
import { CaseContext } from "../../Case";

const Logs = () => {
    const {
        document,
    } = useContext(CaseContext);

    const {
	    isLoading,
	    table,
	    pagination,
	    createClientLog,
    } = useClientLogsTable(
        columns,
	    document && (document.client as IClient).id
    );

    return <div>
	    <Spinner isLoading={isLoading}>
		    <div>
			    <Row gutter={32}>
				    <Col span={16}>
					    <EventLogs
						    table={table}
						    pagination={pagination}
						    createClientLog={createClientLog}
					    />
				    </Col>
				    <Col span={8} className={"border-left-1px"}>
					    <div>
						    <StatusesHistory
							    statusesHistory={document?.statusesHistory || []}
						    />
					    </div>
				    </Col>
			    </Row>
		    </div>
	    </Spinner>
    </div>;
};

export default Logs;
