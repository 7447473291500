import { useCallback, useEffect, useMemo, useState } from "react";

import {
    ColumnDef,
    getCoreRowModel, getFilteredRowModel,
    getSortedRowModel,
    PaginationState, SortingState,
    useReactTable,
} from "@tanstack/react-table";

import { PAGE_SIZES } from "../../constants/pagination";
import { useAssignCaseToTransactionMutation, useGetClientTransactionsQuery } from "../../services/clients/transactions";
import { ISortBy } from "../../types/common";
import {
    IAssignCaseToTransactionData,
    ITransaction,
    TransactionVariation,
} from "../../types/transactions";
import { getGlobalFilterProps, getPaginationProps } from "../../utils/table";

export const useClientTransactionsTable = (getColumns: ({
    // eslint-disable-next-line no-unused-vars
    assignCaseToTransaction,
} : {
    assignCaseToTransaction: (_transactionId: string, _data: IAssignCaseToTransactionData) => void;
}) => ColumnDef<ITransaction>[], clientId?: string) => {
    const [variation, setVariation] = useState<TransactionVariation>("outgoing");
    const [globalFilter, setGlobalFilter] = useState<string>("");
    const [sorting, setSorting] = useState<SortingState>([]);

    const [{
        pageIndex,
        pageSize,
    }, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: PAGE_SIZES[1],
    });

    const paginationData = useMemo(
        () => ({ pageIndex, pageSize }),
        [pageIndex, pageSize]
    );

    const {
        data,
        isLoading,
        isFetching,
    } = useGetClientTransactionsQuery({
        clientId: clientId!,
        query: {
            variation,
            pageIndex,
            pageSize,
            sortBy: sorting as ISortBy[],
        },
    }, {
        skip: !clientId,
    });

    const [assignCaseToTransactionAsync, {
        isLoading: isLoadingAssignCaseToTransaction,
    }] = useAssignCaseToTransactionMutation();

    const assignCaseToTransaction = useCallback(async (transactionId: string, data: IAssignCaseToTransactionData) => {
        await assignCaseToTransactionAsync({
            clientId: clientId!,
            transactionId,
            data,
        });
    }, [assignCaseToTransactionAsync, clientId]);

    useEffect(() => {
        setPagination(prevState => ({
            ...prevState,
            pageIndex: 0,
        }));
    }, [
        globalFilter,
        sorting,
        pageSize,
    ]);

    const table = useReactTable(
        {
            columns: useMemo(() => getColumns({
                assignCaseToTransaction,
            }), [assignCaseToTransaction, getColumns]),
            data: useMemo(() => data?.data.transactions || [], [data?.data.transactions]),
            pageCount: data?.data.pagination.totalPages,
            state: {
                pagination: paginationData,
                sorting,
                globalFilter,
            },
            onGlobalFilterChange: setGlobalFilter,
            onSortingChange: setSorting,
            onPaginationChange: setPagination,
            getCoreRowModel: getCoreRowModel(),
            getSortedRowModel: getSortedRowModel(),
            getFilteredRowModel: getFilteredRowModel(),
            enableRowSelection: true,
            manualPagination: true,
            manualSorting: true,
            manualFiltering: true,
        }
    );

    const isDataLoading = useMemo(() => {
        return isLoading ||
            isFetching ||
            isLoadingAssignCaseToTransaction;
    }, [
        isLoading,
        isFetching,
        isLoadingAssignCaseToTransaction,
    ]);

    return {
        isLoading: isDataLoading,
        table,
        variation,
        setVariation,
        globalFilter: useMemo(() => {
            return getGlobalFilterProps(table);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [globalFilter]),
        pagination: useMemo(() => {
            return getPaginationProps(table, data?.data.pagination, "Transactions");
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [paginationData, data?.data.pagination]),
    };
};
