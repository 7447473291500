import { Row } from "@tanstack/react-table";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";

import { IClient } from "../../../../../../../../types/clients";

const Expand = ({
    value,
}: {
	value: Row<IClient>;
}) => {
    return <div
	    className={"horizontal-center blue-color pointer"}
	    onClick={value.getToggleExpandedHandler()}
    >
	    <div>
		    See details
	    </div>
	    <div className={"fs16 ml6"}>
		    {value.getIsExpanded() ? <BsChevronUp/> : <BsChevronDown/>}
	    </div>
    </div>;
};

export default Expand;
