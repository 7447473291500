import { api } from "./connection/api";
import {
    IGetQuestionnairesResponse,
    IGetQuestionnairesQuery,
    IGetQuestionnaireByIdResponse,
    IGetQuestionnaireByIdQuery,
    IUpdateQuestionnaireByIdResponse,
    IUpdateQuestionnaireByIdBody,
} from "../types/questionnaires";
import { getQuestionnairesQueryString } from "../utils/queries";

const ENDPOINT_PREFIX = "questionnaires";

export const questionnairesApi = api.injectEndpoints({
    endpoints: build => ({
        getQuestionnaires: build.query<IGetQuestionnairesResponse, IGetQuestionnairesQuery>({
            query: query => ({
                url: `${ENDPOINT_PREFIX}?${getQuestionnairesQueryString(query)}`,
            }),
            providesTags: (result, _error, _arg) =>
                (result
                    ? [...result.questionnaires.map(({ id }) => ({ type: "Questionnaires" as const, id })), "Questionnaires"]
                    : ["Questionnaires"]),
        }),
        getQuestionnaireById: build.query<IGetQuestionnaireByIdResponse, IGetQuestionnaireByIdQuery>({
            query: query => ({
                url: `${ENDPOINT_PREFIX}/${query.questionnaireId}`,
            }),
            providesTags: ["Questionnaire"],
        }),
        updateQuestionnaireById: build.mutation<IUpdateQuestionnaireByIdResponse, IUpdateQuestionnaireByIdBody>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/${data.questionnaireId}`,
                method: "PUT",
                body: data.data,
            }),
            invalidatesTags: ["Questionnaire"],
        }),
    }),

});

export const {
    useGetQuestionnairesQuery,
    useGetQuestionnaireByIdQuery,
    useUpdateQuestionnaireByIdMutation,
} = questionnairesApi;
