import { useCallback, useMemo } from "react";

import { useActualYears } from "./useActualYears";
import { useUpdateSubmissionDetailsMutation } from "../../services/documents";
import { IUpdateSubmissionDetailsData } from "../../types/documents";

export const useSubmissions = (documentId?: string) => {
    const {
        years,
    } = useActualYears();

    const [updateSubmissionDetailsAsync, {
        isLoading: isLoadingUpdateSubmissionDetails,
    }] = useUpdateSubmissionDetailsMutation();

    const isLoading = useMemo(() => {
        return isLoadingUpdateSubmissionDetails;
    }, [
        isLoadingUpdateSubmissionDetails,
    ]);

    const updateSubmissionDetails = useCallback((documentsData: IUpdateSubmissionDetailsData) => {
        updateSubmissionDetailsAsync({
            documentId: documentId!,
            data: documentsData,
        });
    }, [documentId, updateSubmissionDetailsAsync]);

    return {
        isLoading,
        years,
        updateSubmissionDetails,
    };
};
