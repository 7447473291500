const Agent = ({
    value,
}: {
	value: string;
}) => {
    return <div className={"primary-color bold fs14 horizontal-center"}>
	    {value.split(" ")[0]}
    </div>;
};

export default Agent;
