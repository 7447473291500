import React, { useContext } from "react";

import { Col, Row } from "antd";

import CaseDetails from "./components/CaseDetails";
import { IClient, IUpdateClientByIdBody } from "../../../../../types/clients";
import {
    IConfirmPlanForDocumentByIdData,
    IUpdateDocumentStatusByIdData, IUpdateDocumentStatusByIdResponse, IUpdateDocumentSubStatusByIdData,
} from "../../../../../types/documents";
import Spinner from "../../../../basic/Spinner/Spinner";
import PersonalDetails from "../../../../common/Clients/PersonalDetails";
import { CaseContext } from "../../Case";

const Details = ({
    updateDocumentStatus,
    updateClientById,
    confirmPlanForDocument,
    updateDocumentSubStatus,
}: {
	updateDocumentStatus: (_statusData: IUpdateDocumentStatusByIdData) => Promise<IUpdateDocumentStatusByIdResponse>;
	updateClientById: (_data: IUpdateClientByIdBody) => void;
    updateDocumentSubStatus: (_data: IUpdateDocumentSubStatusByIdData) => void;
	confirmPlanForDocument: (_documentData: IConfirmPlanForDocumentByIdData) => void;
}) => {
    const {
        document,
	    isLoading,
    } = useContext(CaseContext);

    return <div>
	    <Spinner isLoading={isLoading}>
		    <div className={"p20"}>
			    {document && <Row>
				    <Col span={12}>
                        <PersonalDetails
                            client={document.client as IClient}
                            updateClientById={updateClientById}
                        />
				    </Col>
				    <Col span={12}>
                        <CaseDetails
                            document={document}
                            updateDocumentStatus={updateDocumentStatus}
                            confirmPlanForDocument={confirmPlanForDocument}
                            updateDocumentSubStatus={updateDocumentSubStatus}
                        />
				    </Col>
			    </Row>}
		    </div>
	    </Spinner>
    </div>;
};

export default Details;
