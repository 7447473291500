import React from "react";

import FormPreview from "./components/builder/FormBuilder";
import { QuestionsDataT } from "../../../../../types/questionnaires";
import Spinner from "../../../../basic/Spinner/Spinner";

const QuestionnairePreview = ({
    isLoadingOutside,
    questionnaireFlowData,
}: {
	isLoadingOutside: boolean;
	questionnaireFlowData?: QuestionsDataT
}) => {
    return <div>
        <Spinner isLoading={isLoadingOutside}>
	        {questionnaireFlowData && <div>
                <FormPreview
                    builderData={questionnaireFlowData!.builderData}
                    title="Preview"
                />
	        </div>}
        </Spinner>
    </div>;
};

export default QuestionnairePreview;
