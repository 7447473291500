import { useMemo, useState } from "react";

import { Button, Checkbox, Input, Modal } from "antd";

import { IUpdateDocumentRefundData } from "../../../types/documents";

const defaultCustomRate = 5;

const EditCustomFeeRateModal = ({
    close,
    currentCustomFeeRate,
    updateDocumentRefund,
}: {
	close: () => void;
	currentCustomFeeRate: number | null;
	updateDocumentRefund: (_data: IUpdateDocumentRefundData) => void;
}) => {
    const initialCustomFeeRate = useMemo(() => {
        return currentCustomFeeRate ? (currentCustomFeeRate * 100) : defaultCustomRate;
    }, [currentCustomFeeRate]);

    const [customFeeRate, setCustomFeeRate] = useState<number>(initialCustomFeeRate);
    const [useDefaultFeeRate, setUseDefaultFeeRate] = useState<boolean>(false);

    return <Modal
        open
        width={500}
        title={
            <div className={"p20 modal-header grey-7-color"}>
				Set Custom Fee Rate
            </div>
        }
        afterClose={() => setCustomFeeRate(initialCustomFeeRate)}
        destroyOnClose
        onCancel={() => close()}
        footer={
            <div
                style={{ height: 40 }} className={"space-between"}>
                <Button
                    type={"link"}
                    onClick={() => close()}
                    className={"default-button"}
                >
					Cancel
                </Button>

                <Button
                    htmlType="submit"
                    onClick={async () => {
                        updateDocumentRefund({
	                        customFeeRate: useDefaultFeeRate ? null : (customFeeRate / 100),
                        });

	                    close();
                    }}
                    type={"primary"}
                    className={"primary-button"}
                >
					Save
                </Button>
            </div>
        }
    >
        <div className={"p20"}>
            <div className={"grey-7-color"}>
	            Custom Fee Rate, %
            </div>

	        <div className={"mt20"}>
		        <Input
			        value={customFeeRate}
			        type={"number"}
			        min={0}
			        max={100}
			        style={{ width: 200 }}
			        onChange={e => {
                        setCustomFeeRate(parseInt(e.target.value));
			        }}
		        />
	        </div>

	        <div className={"mt20"}>
		        <Checkbox
			        checked={useDefaultFeeRate}
			        onChange={e => setUseDefaultFeeRate(e.target.checked)}
		        >
			        Use Fee Rate from selected plan
		        </Checkbox>
	        </div>
        </div>
    </Modal>;
};

export default EditCustomFeeRateModal;
