import { FC } from "react";

import { Typography } from "antd";

import { INFO_BLOCK } from "../../../../../../../constants/constructor";

type InfoBlockProps = {
    data: {
        label?: string;
        selectType?: string;
    };
};

const InfoBlock: FC<InfoBlockProps> = ({ data }) => {
    switch (data.selectType) {
    case INFO_BLOCK.TITLE:
        return (<div className="builder__info-title-block">
            <div className="builder__info-block">
                <Typography className="builder__form-info-title">
                    {data.label}
                </Typography>
            </div>
        </div>
        );
    case INFO_BLOCK.TEXT:
    default:
        return (
            <div className="builder__info-block">
                <Typography className="builder__form-question">
                    {data.label}
                </Typography>
            </div>
        );
    }
};

export default InfoBlock;
