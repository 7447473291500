import { useCallback, useEffect, useMemo, useState } from "react";

import {
    ColumnDef,
    getCoreRowModel,
    getSortedRowModel,
    PaginationState, SortingState,
    useReactTable,
} from "@tanstack/react-table";

import { PAGE_SIZES } from "../../constants/pagination";
import { useCreateClientLogMutation, useGetClientLogsQuery } from "../../services/clients/logs";
import { IClientLog, ICreateClientLogData } from "../../types/client-logs";
import { ISortBy } from "../../types/common";
import { getPaginationProps } from "../../utils/table";

export const useClientLogsTable = (columns: ColumnDef<IClientLog>[], clientId?: string) => {
    const [sorting, setSorting] = useState<SortingState>([]);

    const [{
        pageIndex,
        pageSize,
    }, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: PAGE_SIZES[1],
    });

    const paginationData = useMemo(
        () => ({ pageIndex, pageSize }),
        [pageIndex, pageSize]
    );

    const {
        data,
        isLoading,
        isFetching,
    } = useGetClientLogsQuery({
        clientId: clientId!,
        query: {
            pageIndex,
            pageSize,
            sortBy: sorting as ISortBy[],
        },
    }, {
        skip: !clientId,
    });

    const [createClientLogAsync, {
        isLoading: isLoadingCreateClientLog,
    }] = useCreateClientLogMutation();

    useEffect(() => {
        setPagination(prevState => ({
            ...prevState,
            pageIndex: 0,
        }));
    }, [
        sorting,
        pageSize,
    ]);

    const table = useReactTable(
        {
            columns: useMemo(() => columns, [columns]),
            data: useMemo(() => data?.data.logs || [], [data?.data.logs]),
            pageCount: data?.data.pagination.totalPages,
            state: {
                pagination: paginationData,
                sorting,
            },
            onSortingChange: setSorting,
            onPaginationChange: setPagination,
            getCoreRowModel: getCoreRowModel(),
            getSortedRowModel: getSortedRowModel(),
            manualPagination: true,
            manualSorting: true,
        }
    );

    const isDataLoading = useMemo(() => {
        return isLoading ||
            isFetching ||
            isLoadingCreateClientLog;
    }, [
        isLoading,
        isFetching,
        isLoadingCreateClientLog,
    ]);

    const createClientLog = useCallback(async (data: ICreateClientLogData) => {
        await createClientLogAsync({
            clientId: clientId!,
            data,
        });
    }, [clientId, createClientLogAsync]);

    return {
        isLoading: isDataLoading,
        table,
        pagination: useMemo(() => {
            return getPaginationProps(table, data?.data.pagination, "Client Logs");
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [paginationData, data?.data.pagination]),
        createClientLog,
    };
};
