import { List } from "antd";

import { getAnswerString } from "../../../../../../../utils/builder";

const AnswerItemPreview = ({ item }: { item: any }) => {
    if (item?.type === "arrayGroup") {
        return (
            <List
                key={item.id}
                style={{
                    display: "block",
                    padding: "4px",
                    border: "1px solid #ccc",
                    background: "rgb(254, 244, 244)",
                }}
            >
                <List.Item.Meta title={item.label} />
                {item.questions.map((question: any, index: number) => (
                    <List
                        key={index}
                        style={{
                            padding: "16px",
                            border: "1px solid #ccc",
                            background: "white",
                            width: "100%",
                            minWidth: "400px",
                        }}
                    >
                        {question.map((item: any, innerIndex: number) => (
                            <List.Item
                                key={`${item.id}_${innerIndex}`}
                                style={{
                                    padding: "0px",
                                    marginBottom: "15px",
                                }}
                            >
                                <List.Item.Meta
                                    title={item?.question}
                                    description={getAnswerString(
                                        item?.answer
                                    )}
                                />
                            </List.Item>
                        ))}
                    </List>
                ))}
            </List>
        );
    }

    return (
        <List.Item
            key={item.id}
            style={{
                padding: "0px",
                marginBottom: "15px",
            }}
        >
            <List.Item.Meta
                title={item?.question}
                description={getAnswerString(item?.answer)}
            />
        </List.Item>
    );
};

export default AnswerItemPreview;
