import { useEffect, useState } from "react";

import { Button, Input, message, Modal } from "antd";

import { IUpdateDocumentStatusByIdData, IUpdateDocumentStatusByIdResponse, StatusType } from "../../../types/documents";
import StatusSelector from "../../inputs/Fields/StatusSelector/StatusSelector";

const SetCaseStatusModal = ({
    close,
    currentStatusId,
    setDocumentStatus,
}: {
    close: () => void;
    currentStatusId: StatusType;
    setDocumentStatus: (_statusData: IUpdateDocumentStatusByIdData) => Promise<IUpdateDocumentStatusByIdResponse>;
}) => {
    const [statusId, setStatusId] = useState<StatusType>(currentStatusId);
    const [description, setDescription] = useState<string | undefined>(undefined);

    useEffect(() => {
        setDescription(undefined);
    }, [statusId]);

    return <Modal
        open
        width={500}
        title={
            <div className={"p20 modal-header grey-7-color"}>
                Change Case Status
            </div>
        }
        destroyOnClose
        onCancel={() => close()}
        footer={
            <div
                style={{ height: 40 }} className={"space-between"}>
                <Button
                    type={"link"}
                    onClick={() => close()}
                    className={"default-button"}
                >
                    Cancel
                </Button>

                <Button
                    disabled={!statusId}
                    htmlType="submit"
                    onClick={async () => {
                        if (statusId === currentStatusId) {
                            return message.error("You cannot set current status.");
                        }

                        if (statusId) {
                            setDocumentStatus({
                                statusId,
                                description,
                            }).then(async () => {
                                await message.info("Status has been successfully changed");
                            }).catch(() => null);
                        }

                        close();
                    }}
                    type={"primary"}
                    className={"primary-button"}
                    data-testid={"save-status"}
                >
                    Save
                </Button>
            </div>
        }
    >
        <div className={"p20"}>
            <div className="horizontal-left">
                <div className={"grey-7-color"}>
                    Status
                </div>
                <div className={"ml20"}>
                    <StatusSelector
                        size={"middle"}
                        selectedStatusId={statusId}
                        onStatusSelect={(statusId: StatusType) => setStatusId(statusId)}
                        width={"318px"}
                    />
                </div>

            </div>

            {statusId === "info-requested" && <div>
                <div className={"mt20"}>
                    Description
                </div>

                <div className={"mt10"}>
                    <Input.TextArea
                        value={description}
                        onChange={e => setDescription(e.target.value)}
                        rows={4}
                        placeholder={"Input description..."}
                    />
                </div>
            </div>}
        </div>
    </Modal>;
};

export default SetCaseStatusModal;
