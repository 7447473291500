import { memo, useCallback, useState } from "react";

import {
    Divider,
    Typography,
    Box,
    TextField,
    Button,
    Modal,
    Checkbox,
} from "@mui/material";
import { Handle, Position } from "reactflow";
import { v4 as uuidv4 } from "uuid";

import { useModalOpener } from "../../../../../../../hooks/helpers/useModalOpener";
import { UploadDoc } from "../../../../../../../types/questionnaires";
import { createChangeHandler } from "../../../../../../../utils/constructor";
import useStore from "../store";

const DocumentsUploadNode = (props: any) => {
    const updateNodeData = useStore(state => state.updateNodeData);

    const {
        isOpened: isEditing,
        open: openEditModal,
        close: closeModal,
    } = useModalOpener();

    const [editedCategory, setEditedCategory] = useState(props.data.category);
    const [editedHelpText, setEditedHelpText] = useState(props.data.helpText);
    const [editedAllowAdditionalFiles, setEditedAllowAdditionalFiles] = useState(!!props.data.allowAdditionalFiles);
    const [editedUploadDocs, setUploadDocs] = useState<UploadDoc[]>(
        props.data.uploadDocs || []
    );

    const addNewUploadDoc = () => {
        setUploadDocs([...editedUploadDocs, { id: uuidv4(), label: "" }]);
    };

    const deleteUploadDoc = (id: string) => {
        const filteredUploadDocs = editedUploadDocs.filter(uploadDoc => uploadDoc.id !== id);
        setUploadDocs(filteredUploadDocs);
    };

    const updateUploadDocLabel = (id: string, label: string) => {
        const updatedUploadDocs = editedUploadDocs.map(uploadDoc =>
            (uploadDoc.id === id ? { ...uploadDoc, label } : uploadDoc)
        );
        setUploadDocs(updatedUploadDocs);
    };

    const saveChanges = useCallback(() => {
        updateNodeData(props.id, {
            category: editedCategory,
            helpText: editedHelpText,
            uploadDocs: editedUploadDocs,
            allowAdditionalFiles: editedAllowAdditionalFiles,
        });
        closeModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateNodeData, editedCategory, editedUploadDocs, editedHelpText, editedAllowAdditionalFiles]);

    const handleCategory = createChangeHandler(setEditedCategory);
    const onHelpTextChange = createChangeHandler(setEditedHelpText);
    const handleAllowAdditionalFiles = createChangeHandler(setEditedAllowAdditionalFiles);

    return (
        <div className="question-node">
            <Handle
                type="target"
                position={Position.Top}
                className="handle-depend"
                style={{ left: 10 }}
                id="depend"
            />
            <Handle
                type="target"
                position={Position.Top}
                className="handle-depend"
                style={{ left: 30 }}
                id="depend_and"
            />
            <Handle type="target" position={Position.Top} id="to" />
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <Typography ml={2} component="div" sx={{ fontSize: "8px" }}>
                    Documents Upload
                </Typography>
            </Box>
            <Divider />
            <Box ml={1} className="question-label-wrapper">
                <Typography
                    component="div"
                    sx={{
                        fontSize: "10px",
                        maxWidth: "1000px",
                    }}
                >
                    Category: {props.data.category}
                </Typography>
                <Button
                    variant="outlined"
                    onClick={openEditModal}
                    className="button-edit"
                >
                    Edit
                </Button>
            </Box>
            <Box ml={1}>
                <Typography
                    component="div"
                    sx={{
                        fontSize: "10px",
                        maxWidth: "1000px",
                    }}
                >
                    Upload Documents:
                </Typography>
                {editedUploadDocs.map((uploadDoc: UploadDoc, index: number) => (
                    <div key={uploadDoc.id}>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                gap: "2px",
                            }}
                        >
                            <Typography
                                component="div"
                                sx={{
                                    fontSize: "10px",
                                    maxWidth: "1200px",
                                }}
                            >
                                {`${index + 1} - ${uploadDoc.label}`}
                            </Typography>
                        </Box>
                    </div>
                ))}
                <Divider />
                <Typography
                    component="div"
                    sx={{
                        fontSize: "10px",
                        maxWidth: "1000px",
                    }}
                >
                    Help text: {props.data.helpText}
                </Typography>
            </Box>

            <Handle type="source" position={Position.Bottom} id="from" />
            {isEditing && (
                <Modal
                    open={isEditing}
                    onClose={saveChanges}
                    className="modal-wrapper"
                    slotProps={{
                        backdrop: {
                            sx: {
                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                            },
                        },
                    }}
                >
                    <Box className="box-wrapper">
                        <TextField
                            margin="normal"
                            id="text"
                            name="text"
                            value={editedCategory}
                            onChange={handleCategory}
                            variant="outlined"
                            fullWidth
                            label="Category"
                        />
                        <TextField
                            margin="normal"
                            id="text"
                            name="text"
                            value={editedHelpText}
                            onChange={onHelpTextChange}
                            variant="outlined"
                            fullWidth
                            label="Help Text"
                        />
                        <Box
                            sx={{
                                width: "100%",
                            }}
                        >
                            {editedUploadDocs.map((uploadDoc, index) => (
                                <Box key={uploadDoc.id} display="flex" gap={0} alignItems="center" justifyContent={"center"} mt={1}>
                                    <TextField
                                        margin="normal"
                                        fullWidth
                                        variant="outlined"
                                        value={uploadDoc.label}
                                        onChange={e => updateUploadDocLabel(uploadDoc.id, e.target.value)}
                                        label={`Document Label ${index + 1}`}
                                    />
                                    <Button
                                        variant="text"
                                        color="secondary"
                                        onClick={() => deleteUploadDoc(uploadDoc.id)}
                                    >
                                        Delete
                                    </Button>
                                </Box>
                            ))}
                            <Box display="flex" flexDirection="row" justifyContent="flex-end">
                                <Button variant="contained" onClick={addNewUploadDoc}>Add new document input</Button>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                width: "100%",
                                marginBottom: "10px",
                            }}
                        >
                            <Typography component="div">Allow Additional File Inputs</Typography>
                            <Checkbox
                                style={{
                                    padding: "0",
                                }}
                                checked={editedAllowAdditionalFiles}
                                onChange={handleAllowAdditionalFiles}
                                color="primary"
                                name="requiredCheckbox"
                            />
                        </Box>
                        <Box className="button-group">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={saveChanges}
                            >
                                Save
                            </Button>
                        </Box>
                    </Box>
                </Modal>
            )}
        </div>
    );
};

export default memo(DocumentsUploadNode);
