import { api } from "./connection/api";
import {
    IGetTransactionsResponse,
    IGetTransactionsBody,
    IAssignClientToTransactionResponse,
    IAssignClientToTransactionBody,
} from "../types/transactions";
import { getTransactionsQueryString } from "../utils/queries";

const PARENT_ENDPOINT_PREFIX = "transactions";

export const transactionsApi = api.injectEndpoints({
    endpoints: build => ({
        getTransactions: build.query<IGetTransactionsResponse, IGetTransactionsBody>({
            query: data => ({
                url: `${PARENT_ENDPOINT_PREFIX}?${getTransactionsQueryString(data.query)}`,
            }),
            providesTags: ["Transactions"],
        }),
        assignClientToTransaction: build.mutation<IAssignClientToTransactionResponse, IAssignClientToTransactionBody>({
            query: data => ({
                url: `${PARENT_ENDPOINT_PREFIX}/${data.transactionId}/assign`,
                method: "PATCH",
                body: data.data,
            }),
            invalidatesTags: ["ClientTransactions", "Transactions", "Document"],
        }),
    }),

});

export const {
    useGetTransactionsQuery,
    useAssignClientToTransactionMutation,
} = transactionsApi;
