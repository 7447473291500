import { Table } from "@tanstack/react-table";

import { IGlobalFilterProps, IPagination, IPaginationProps } from "../types/common";
import { IDocument } from "../types/documents";

export const getPaginationProps = (table: Table<any>, pagination?: IPagination, itemsName?: string): IPaginationProps => {
    return {
        itemsName: itemsName || "Documents",
        totalDocs: pagination?.totalDocs || 0,
        setPageSize: table.setPageSize,
        pageSize: table.getState().pagination.pageSize,
        pageIndex: table.getState().pagination.pageIndex + 1,
        totalPages: table.getPageCount(),
        previousPage: table.previousPage,
        nextPage: table.nextPage,
        canPreviousPage: table.getCanPreviousPage(),
        canNextPage: table.getCanNextPage(),
        setCurrentPage: table.setPageIndex,
    };
};

export const getGlobalFilterProps = (table: Table<any>): IGlobalFilterProps => {
    return {
        setGlobalFilter: table.setGlobalFilter,
        globalFilter: table.getState().globalFilter,
        resetGlobalFilter: table.resetGlobalFilter,
    };
};

export const getSelectedRows = (table: Table<any>): IDocument[] => {
    return table.getSelectedRowModel().flatRows.map(row => row.original);
};
