import React, { useMemo } from "react";

import { Route, Routes } from "react-router-dom";

import ListOfClients from "./tabs/ListOfClients/ListOfClients";
import Transactions from "./tabs/Transactions/Transactions";
import { usePermissionGranter } from "../../../hooks/common/usePermissionGranter";
import { IPageTabItem } from "../../../types/common";
import PageHeader from "../../basic/PageHeader/PageHeader";
import PageTab from "../../common/PageTab/PageTab";

const MENU_ITEMS: IPageTabItem[] = [{
    id: "list",
    title: "List of Clients",
}, {
    id: "transactions",
    title: "Transactions",
}];

const Clients = () => {
    const transactionsPermissionIsGranted = usePermissionGranter("transactions", "read");

    const menu = useMemo(() => {
        return MENU_ITEMS
            .filter(item => {
                return !(item.id === "transactions" && !transactionsPermissionIsGranted);
            })
            .map(item => {
                return <PageTab
                    key={item.id}
                    item={item}
                />;
            });
    }, [transactionsPermissionIsGranted]);

    return <div>
        <div>
            <PageHeader
                menu={menu}
            />
        </div>

        <div className={"common-page scroll-block"}>
            <div className={"common-block"}>
                <Routes>
                    <Route path={MENU_ITEMS[0].id} element={<ListOfClients/>}/>
                    <Route path={MENU_ITEMS[1].id} element={<Transactions/>}/>
                </Routes>
            </div>
        </div>
    </div>;
};

export default Clients;
