import { useMemo } from "react";

import { GROUPED_SUB_STATUSES } from "../../../constants/documents";
import { SubStatusType } from "../../../types/documents";

const SubStatus = ({
    subStatusId,
    value,
}: {
    subStatusId: SubStatusType;
    value: string;
}) => {
    const statusData = useMemo(() => {
        return GROUPED_SUB_STATUSES[subStatusId].find(subStatusData => subStatusData.id === value);
    }, [subStatusId, value]);

    if (!statusData) return <div>Status not found</div>;

    return <div className={"horizontal-center"}>
        <div
            data-testid={`case-sub-status-${statusData.id}`}
            className={"case-status center"}
            style={{
                color: statusData.color.font,
                backgroundColor: statusData.color.background,
            }}
        >
            {statusData.title}
        </div>
    </div>;
};

export default SubStatus;
