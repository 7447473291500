const Title = ({
    value,
}: {
	value: string;
}) => {
    return <div data-testid={`client-log-title-${value}`}>
        {value}
    </div>;
};

export default Title;
