import { FC, useState, useEffect, useRef } from "react";

import arrowDown from "../../../../../../../assets/images/form/arrow-drop-down.svg";
import arrowUp from "../../../../../../../assets/images/form/arrow-drop-up.svg";

type DropdownProps = {
    options: {
        id: string;
        value: string;
    }[];
    onSelect: (_id: string) => void;
    setSkipValidation: (_value: boolean) => void;
    selectedId?: string;
};

const Dropdown: FC<DropdownProps> = ({ options, onSelect, selectedId, setSkipValidation }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const handleClick = (id: string) => {
        onSelect(id);
        setIsOpen(false);
    };

    const handleOutsideClick = (event: MouseEvent) => {
        if (
            dropdownRef.current &&
            !dropdownRef.current.contains(event.target as Node)
        ) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        if (isOpen) {
            document.addEventListener("mousedown", handleOutsideClick);
        } else {
            document.removeEventListener("mousedown", handleOutsideClick);
        }

        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, [isOpen]);

    const selectedOption = options.find(option => option.id === selectedId);

    return (
        <div className="custom-dropdown" ref={dropdownRef}>
            <div
                className="custom-dropdown__selected-option"
                onClick={() => {
                    setSkipValidation(false);
                    setIsOpen(!isOpen);
                }}
            >
                {selectedOption?.value || "Select..."}
                <img
                    src={isOpen ? arrowUp : arrowDown}
                    alt="arrow"
                    className="custom-dropdown__arrow"
                />
            </div>
            {isOpen && (
                <div className="custom-dropdown__options">
                    {options.map(option => (
                        <div
                            key={option.id}
                            className={`custom-dropdown__option ${
                                option.id === selectedId
                                    ? "custom-dropdown__option--selected"
                                    : ""
                            }`}
                            onClick={() => handleClick(option.id)}
                        >
                            {option.value}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Dropdown;
