import { useState, FC, useEffect } from "react";
import "dayjs/locale/en-gb";

import CustomFormItem from "../FormWrapper/FormItemWrapper";
import FloatDatePicker from "../inputs/FloatDatePicker";

type DateQuestionProps = {
    data: {
        question: string;
        helpText?: string;
        defaultDate?: string;
        minDate?: string;
        maxDate?: string;
        required?: boolean;
    };
    onAnswer: (_answer: string) => void;
    value?: string;
};

const DateQuestion: FC<DateQuestionProps> = ({
    data,
    onAnswer,
    value = "",
}) => {
    const [error, setError] = useState<string>("");
    const [skipValidation, setSkipValidation] = useState<boolean>(true);

    useEffect(() => {
        if (data.required && value === "" && !skipValidation) {
            setError("This field is required");
        } else {
            setError("");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, skipValidation]);

    const handleAnswerChange = (newValue: any) => {
        setSkipValidation(false);
        onAnswer(newValue);
    };

    return (
        <div className="builder__date-question">
            <CustomFormItem
                key={data.question}
                // labelLength={data.question?.length || 1}
                helpText={data.helpText}
                error={error}
            >
                <FloatDatePicker
                    style={{
                        marginBottom: "5px",
                    }}
                    label={data.question}
                    value={value || data.defaultDate || ""}
                    minDate={data.minDate}
                    maxDate={data.maxDate}
                    error={error}
                    onChange={date => {
                        const formattedDate = date
                            ? date.format("YYYY-MM-DD")
                            : "";
                        handleAnswerChange(formattedDate);
                    }}
                />
            </CustomFormItem>
        </div>
    );
};

export default DateQuestion;
