import Logo from "../../../../assets/icons/Logo";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import authImage from "../../../../assets/images/auth-taxzap.png";

const AuthPicture = () => {
    return <div className={"center"}>
	    <div>
		    <div className={"horizontal-center"}>
			    <Logo
				    width={40}
				    height={50}
				    withLabel={false}
			    />
		    </div>

		    <div className={"mt30"}>
			    <img src={authImage} alt={"auth"}/>
		    </div>
	    </div>

    </div>;
};

export default AuthPicture;
