const Header = ({
    label,
}: {
	label: string
}) => {
    return <div className={"fs16 primary-color horizontal-center"}>
	    {label}
    </div>;
};

export default Header;
