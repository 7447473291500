import { ColumnDef } from "@tanstack/react-table";

import Agent from "./components/Agent";
import Date from "./components/Date";
import Description from "./components/Description";
import Header from "./components/Header";
import Level from "./components/Level";
import Title from "./components/Title";
import { INITIATORS_DICT } from "../../../../../../../../constants/client-logs";
import { IClientLog } from "../../../../../../../../types/client-logs";

export const columns: ColumnDef<IClientLog>[] = [
    {
        id: "title",
        accessorKey: "title",
        enableSorting: false,
        header: () => <Header label={"Event"}/>,
        cell: ({ row }) => <Title value={row.original.title}/>,
    },
    {
        id: "level",
        accessorKey: "variation",
        header: () => <Header label={"Level"}/>,
        cell: ({ row }) => <Level value={row.original.variation}/>,
    },
    {
        id: "initiator",
        accessorKey: "initiator",
        enableSorting: false,
        header: () => <Header label={"Initiator"}/>,
        cell: ({ row }) => INITIATORS_DICT[row.original.initiator].title,
    },
    {
        id: "author",
        accessorKey: "author",
        header: () => <Header label={"Name"}/>,
        cell: ({ row }) => <Agent value={row.original.author}/>,
    },
    {
        id: "createdAt",
        accessorKey: "createdAt",
        header: () => <Header label={"Date"}/>,
        cell: ({ row }) => <Date value={row.original.createdAt}/>,
    },
    {
        id: "description",
        accessorKey: "description",
        enableSorting: false,
        header: () => <Header label={"Comment"}/>,
        cell: ({ row }) => <Description log={row.original}/>,
    },
];
