import { useState } from "react";

import { Button, Col, message, Modal, Row } from "antd";

import {
    IDocument, StatusGroupData,
    StatusType,
} from "../../../types/documents";
import { groupCasesByStatus } from "../../../utils/documents";
import Status from "../../common/Status/Status";
import StatusSelector from "../../inputs/Fields/StatusSelector/StatusSelector";

const GroupedStatusChangeModal = ({
    close,
    selectedCases,
    updateDocumentsStatuses,
}: {
    close: () => void;
    selectedCases: IDocument[];
    updateDocumentsStatuses: (_groups: StatusGroupData[]) => Promise<string[]>;
}) => {
    const [isConfirmation, setIsConfirmation] = useState<boolean>(false);

    const [groupsData, setGroupsData] = useState<StatusGroupData[]>(groupCasesByStatus(selectedCases));

    return <Modal
        open
        width={700}
        title={
            !isConfirmation ? <div className={"p20"}>
                <div className={"modal-header grey-7-color"}>
                    Status Change
                </div>
                <div className={"grey-5-color fs12"}>
                    {selectedCases.length} case(s) selected
                </div>
            </div> : <div className={"p20"}>
                <div className={"fs22 bold grey-7-color horizontal-center"}>
                    Are you sure?
                </div>
                <div className={"mt10 grey-5-color fs14 horizontal-center"}>
                    {selectedCases.length} case(s) will be changed
                </div>
            </div>
        }
        destroyOnClose
        onCancel={() => close()}
        footer={
            !isConfirmation ? <div style={{ height: 40 }} className={"space-between"}>
                <Button
                    type={"link"}
                    onClick={() => close()}
                    className={"default-button"}
                >
                    Cancel
                </Button>

                <Button
                    htmlType="submit"
                    disabled={groupsData.some(item => !item.changeToStatusId)}
                    onClick={async () => {
                        setIsConfirmation(true);
                    }}
                    type={"primary"}
                    className={"primary-button"}
                    data-testid={"save-status"}
                >
                    Change status
                </Button>
            </div> : <div className={"horizontal-center"}>
                <Button
                    type={"link"}
                    onClick={() => setIsConfirmation(false)}
                    className={"default-button"}
                >
                    Cancel
                </Button>

                <Button
                    htmlType="submit"
                    onClick={async () => {
                        const warnings = await updateDocumentsStatuses(groupsData);

                        if (warnings?.length) {
                            message.warning(<div>
                                <div>
                                    Some cases were not updated:
                                </div>
                                {warnings.map(warning => <div>
                                    {warning}
                                </div>)}
                            </div>, 5, () => null);
                        } else {
                            message.success("Cases have been successfully updated");
                        }

                        close();
                    }}
                    type={"primary"}
                    className={"primary-button"}
                    data-testid={"save-status"}
                >
                    Yes, change status
                </Button>
            </div>
        }
    >
        <div className={"p20"}>
            {groupsData.map((groupData, i) => {
                return <Row className={!i ? undefined : "mt20"} key={groupData.currentStatusId}>
                    <Col span={10}>
                        <div className={"bold grey-7-color"}>
                            Current status:
                        </div>
                        <div className={"mt10 horizontal-left"}>
                            <Status value={groupData.currentStatusId}/>
                        </div>
                    </Col>
                    <Col span={14}>
                        <div className={"bold grey-7-color"}>
                            Change to:
                        </div>
                        <div className={"mt10"}>
                            <StatusSelector
                                size={"middle"}
                                disabled={isConfirmation}
                                selectedStatusId={groupData.changeToStatusId}
                                onStatusSelect={(statusId: StatusType) => setGroupsData(prevState => {
                                    return prevState.map(item => {
                                        if (item.currentStatusId !== groupData.currentStatusId) return item;

                                        return {
                                            ...item,
                                            changeToStatusId: statusId,
                                        };
                                    });
                                })}
                                width={"318px"}
                            />
                        </div>
                    </Col>
                </Row>;
            })}
        </div>
    </Modal>;
};

export default GroupedStatusChangeModal;
