import React, { ChangeEvent, memo, useCallback, useMemo, useState } from "react";

import {
    Divider,
    Typography,
    Box,
    TextField,
    Button,
    Modal,
    InputAdornment,
    Checkbox,
} from "@mui/material";
import { Handle, Position } from "reactflow";
import { v4 as uuidv4 } from "uuid";

import { useModalOpener } from "../../../../../../../hooks/helpers/useModalOpener";
import { useUploadStaticFileMutation } from "../../../../../../../services/files";
import { Option, QuestionType } from "../../../../../../../types/questionnaires";
import useStore from "../store";

const SelectManyIconsQuestionNode = (props: any) => {
    const updateNodeData = useStore(state => state.updateNodeData);
    const nodes = useStore(state => state.nodes);
    const currentNode = useMemo(() => nodes.find(n => n.id === props.id), [nodes, props.id]);
    const parentType = useMemo(() => (currentNode?.parentNode?.split("_")[0]), [currentNode]);

    const {
        isOpened: isEditing,
        open: openEditModal,
        close: closeModal,
    } = useModalOpener();

    const [uploadStaticIcon] = useUploadStaticFileMutation();

    const [editedQuestion, setEditedQuestion] = useState(props.data.question);
    const [editedArrayLimit, setEditedArrayLimit] = useState(props.data.arrayOptionLimit);
    const [editedOptions, setEditedOptions] = useState<Option[]>(
        props.data.options || []
    );
    const [editedRequired, setEditedRequired] = useState(!!props.data.required);

    const addNewOption = () => {
        setEditedOptions([...editedOptions, { id: uuidv4(), value: "", url: "" }]);
    };

    const deleteOption = (id: string) => {
        const filteredOptions = editedOptions.filter(option => option.id !== id);
        setEditedOptions(filteredOptions);
    };

    const updateOptionValue = (id: string, value: string) => {
        const updatedOptions = editedOptions.map(option =>
            (option.id === id ? { ...option, value } : option)
        );
        setEditedOptions(updatedOptions);
    };

    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>, optionId: string) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];

            try {
                const response = await uploadStaticIcon({
                    data: { file },
                }).unwrap();

                if (response && response.url) {
                    setEditedOptions(prevOptions =>
                        prevOptions.map(option =>
                            (option.id === optionId
                                ? { ...option, url: response.url }
                                : option)
                        )
                    );
                } else {
                    console.error("Upload failed");
                }
            } catch (error) {
                console.error("Error uploading the file:", error);
            }
        }
    };

    const saveChanges = useCallback(() => {
        updateNodeData(props.id, {
            question: editedQuestion,
            options: editedOptions,
            required: editedRequired,
        });
        closeModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateNodeData, editedQuestion, editedOptions, editedRequired]);

    const onChange = useCallback(
        ({ target }: any) => {
            setEditedQuestion(target.value);
        },
        [setEditedQuestion]
    );

    const handleRequiredChange = useCallback(
        ({ target }: any) => {
            setEditedRequired(target.checked);
        },
        [setEditedRequired]
    );

    const handleArrayLimitChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const value = event.target.value === "" ? 0 : Number(event.target.value);
            setEditedArrayLimit(value);
        },
        [setEditedArrayLimit]
    );

    return (
        <div className="question-node">
            <Handle
                type="target"
                position={Position.Top}
                className="handle-depend"
                style={{ left: 10 }}
                id="depend"
            />
            <Handle
                type="target"
                position={Position.Top}
                className="handle-depend"
                style={{ left: 30 }}
                id="depend_and"
            />
            <Handle type="target" position={Position.Top} id="to" />
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <Typography ml={2} component="div" sx={{ fontSize: "8px" }}>
                    "Select many" question with Icons
                </Typography>
                {editedRequired && (
                    <Typography
                        component="div"
                        color={"red"}
                        sx={{ fontSize: "6px", margin: "0px 10px" }}
                    >
                        Required
                    </Typography>
                )}
            </Box>
            <Divider />
            <Box ml={1} className="question-label-wrapper">
                <Typography
                    component="div"
                    sx={{
                        fontSize: "10px",
                        maxWidth: "1000px",
                    }}
                >
                    {props.data.question}
                </Typography>
                <Button
                    variant="outlined"
                    onClick={openEditModal}
                    className="button-edit"
                >
                    Edit
                </Button>
            </Box>
            <Box ml={1}>
                {editedOptions.map((option: Option, index: number) => (
                    <div key={option.id}>
                        <Divider />
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                gap: "2px",
                            }}
                        >
                            {option.url && (
                                <img src={process.env.REACT_APP_API_URL + option.url} alt="Uploaded icon" height={15} />
                            )}
                            <Typography
                                component="div"
                                sx={{
                                    fontSize: "10px",
                                    maxWidth: "1200px",
                                }}
                            >
                                {option.value}
                            </Typography>
                            <Handle
                                type="source"
                                position={Position.Right}
                                className="handle-select-depend"
                                style={{
                                    top: `${30 + (index + 1) * 16.1}px`,
                                }}
                                id={`dependency_${option.value}`}
                            />
                        </Box>
                    </div>
                ))}
            </Box>

            <Handle type="source" position={Position.Bottom} id="from" />
            {isEditing && (
                <Modal
                    open={isEditing}
                    onClose={saveChanges}
                    className="modal-wrapper"
                    slotProps={{
                        backdrop: {
                            sx: {
                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                            },
                        },
                    }}
                >
                    <Box className="box-wrapper">
                        <Box
                            sx={{
                                display: "flex",
                                width: "100%",
                                marginBottom: "10px",
                            }}
                        >
                            <Typography component="div">Required</Typography>
                            <Checkbox
                                style={{
                                    padding: "0",
                                }}
                                checked={editedRequired}
                                onChange={handleRequiredChange}
                                color="primary"
                                name="requiredCheckbox"
                            />
                        </Box>
                        <TextField
                            margin="normal"
                            id="text"
                            name="text"
                            value={editedQuestion}
                            onChange={onChange}
                            variant="outlined"
                            fullWidth
                            label="Question"
                        />
                        <Box
                            sx={{
                                width: "100%",
                            }}
                        >
                            {editedOptions.map((option, index) => (
                                <Box key={option.id} display="flex" gap={0} alignItems="center" justifyContent={"center"} mt={1}>
                                    {option.url && (
                                        <img
                                            src={process.env.REACT_APP_API_URL + option.url}
                                            alt="Uploaded icon"
                                            height={100}
                                            style={{
                                                border: "1px solid #ccc",
                                                borderRadius: "5px",
                                                marginRight: "10px",
                                            }}
                                        />
                                    )}
                                    <TextField
                                        margin="normal"
                                        fullWidth
                                        variant="outlined"
                                        value={option.value}
                                        onChange={e => updateOptionValue(option.id, e.target.value)}
                                        label={`Option ${index + 1}`}
                                    />
                                    <InputAdornment position="end">
                                        <>
                                            <input
                                                type="file"
                                                accept=".png, .svg"
                                                style={{ display: "none" }}
                                                id={`upload-icon-${option.id}`}
                                                onChange={e => handleFileChange(e, option.id)}
                                            />
                                            <label htmlFor={`upload-icon-${option.id}`}>
                                                <Button component="span">
                                                    Upload Icon
                                                </Button>
                                            </label>
                                        </>
                                    </InputAdornment>
                                    <Button
                                        variant="text"
                                        color="secondary"
                                        onClick={() => deleteOption(option.id)}
                                    >
                                        Delete
                                    </Button>
                                </Box>
                            ))}
                            {parentType === QuestionType.ArrayGroup &&
                            <Box mt={1} sx={{ width: "100%" }}>
                                <TextField
                                    type="number"
                                    label="Array Option Limit"
                                    value={editedArrayLimit}
                                    onChange={handleArrayLimitChange}
                                    variant="outlined"
                                    fullWidth
                                />
                            </Box>
                            }
                            <Box display="flex" flexDirection="row" justifyContent="flex-end">
                                <Button variant="contained" onClick={addNewOption}>Add new option</Button>
                            </Box>
                        </Box>
                        <Box className="button-group">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={saveChanges}
                            >
                                Save
                            </Button>
                        </Box>
                    </Box>
                </Modal>
            )}
        </div>
    );
};

export default memo(SelectManyIconsQuestionNode);
