import FinancialHeader from "./components/FinancialHeader";
import { getColumns } from "./components/FinancialTable/columns";
import FinancialTable from "./components/FinancialTable/FinancialTable";
import { useFinancialStats } from "../../../../../hooks/dashboard/useFinancialStats";
import Spinner from "../../../../basic/Spinner/Spinner";

const Financial = () => {
    const {
        table,
        year,
        setYear,
        isLoading,
    } = useFinancialStats(getColumns);

    return <div>
        <Spinner isLoading={isLoading}>
            <div className={"p10 space-between"}>
                <FinancialHeader
                    year={year}
                    setYear={setYear}
                />
            </div>

            <div className={"mt10"}>
                <FinancialTable
                    table={table}
                />
            </div>
        </Spinner>
    </div>;
};

export default Financial;
