import { Button, Col, Form, Modal, Row } from "antd";

import { useAppDispatch } from "../../../redux/hooks";
import { getCurrentUser } from "../../../redux/reducers/auth";
import { useResetPasswordMutation } from "../../../services/auth";
import Spinner from "../../basic/Spinner/Spinner";
import ConfirmPasswordInput from "../../inputs/Form/Inputs/ConfirmPasswordInput";
import PasswordInput from "../../inputs/Form/Inputs/PasswordInput";

const SetNewPasswordModal = ({
    modalIsOpened,
}: {
    modalIsOpened: boolean;
}) => {
    const dispatch = useAppDispatch();

    const [form] = Form.useForm();

    const [resetPassword, {
        isLoading,
    }] = useResetPasswordMutation();

    return <Modal
        open={modalIsOpened}
        width={500}
        title={
            <div style={{ color: "white" }} className={"modal-header"}>
                Set new password
            </div>
        }
        afterClose={() => form.resetFields()}
        destroyOnClose
        footer={
            <div style={{ height: 40 }} className={"space-between"}>
                <Button
                    shape="round"
                    htmlType="submit"
                    onClick={() => form.submit()}
                    className={"primary-button button-label"}
                >
                    Save password
                </Button>
            </div>
        }
        closable={false}
    >
        <div className={"p30"}>
            <Spinner isLoading={isLoading}>
                <Form
                    form={form}
                    layout={"vertical"}
                    onFinish={async values => {
                        resetPassword({
                            newPassword: values.password,
                        })
                            .unwrap()
                            .then(() => {
                                dispatch(getCurrentUser());
                            }).catch(() => null);
                    }}
                >
                    <Row gutter={16}>
                        <Col span={24}>
                            <PasswordInput required/>
                        </Col>

                        <Col span={24}>
                            <ConfirmPasswordInput required/>
                        </Col>
                    </Row>

                </Form>
            </Spinner>
        </div>
    </Modal>;
};

export default SetNewPasswordModal;
