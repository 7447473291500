import { useContext, useEffect, useState } from "react";

import { Button, Checkbox, Input, Modal, Select } from "antd";

import { ERROR_STATUSES } from "../../../constants/documents";
import { ErrorStatusType } from "../../../types/documents";
import { ISendErrorNotificationData } from "../../../types/notifications";
import { CaseContext } from "../../pages/Case/Case";

const ErrorHandlingModal = ({
	                            close,
	                            sendErrorNotification,
}: {
	close: () => void;
	sendErrorNotification: (_data: ISendErrorNotificationData) => void;
}) => {
    const {
        documentId,
    } = useContext(CaseContext);

    const [errorId, setErrorId] = useState<ErrorStatusType | undefined>(undefined);
    const [putOnHold, setPutOnHold] = useState<boolean>(false);
    const [daysOnHold, setDaysOnHold] = useState<number | undefined>(undefined);

    const [description, setDescription] = useState<string | undefined>(undefined);

    useEffect(() => {
        setDescription(undefined);
	    setDaysOnHold(undefined);
        setPutOnHold(false);
    }, [errorId]);

    return <Modal
        open
        width={500}
        title={
            <div className={"p20 modal-header red-color"}>
				Error Handling
            </div>
        }
        destroyOnClose
        onCancel={() => close()}
        footer={
            <div
                style={{ height: 40 }} className={"space-between"}>
                <Button
                    type={"link"}
                    onClick={() => close()}
                    className={"default-button"}
                >
					Cancel
                </Button>

                <Button
                    disabled={!errorId}
                    htmlType="submit"
                    onClick={() => {
                        if (errorId) {
                            sendErrorNotification({
                                documentId: documentId!,
                                errorId,
                                putOnHold,
                                daysOnHold,
                                description,
                            });

                            close();
                        }
                    }}
                    type={"primary"}
                    className={"primary-button"}
                >
					Save
                </Button>
            </div>
        }
    >
        <div className={"p20"}>
            <div className={"grey-7-color"}>
				Error
            </div>

            <div className={"mt10"}>
                <Select
                    value={errorId}
                    onSelect={(value: string) => setErrorId(value as ErrorStatusType)}
                    size={"large"}
                    className={"select-content grey-color"}
                    style={{ width: "100%" }}
                    placeholder={<span className={"fs14"}>Select status...</span>}
                >
                    {ERROR_STATUSES.map(status => {
                        return <Select.Option
                            key={status.id}
                            value={status.id}
                            className={"select-option"}
                        >
                            <div
                                className={"vertical-center"}
                            >
                                {status.title}
                            </div>
                        </Select.Option>;
                    })}
                </Select>
            </div>

            {errorId === "info-requested" && <div>
                <div className={"mt20"}>
                    Description
                </div>

                <div className={"mt10"}>
                    <Input.TextArea
                        value={description}
                        onChange={e => setDescription(e.target.value)}
                        rows={4}
                        placeholder={"Input description..."}
                    />
                </div>
            </div>}

            <div className={"ml4 mt4 grey-5-color fs12"}>
				The client will receive an email requesting the information
            </div>

	        {["ppsn-error", "name-error"].includes(errorId as string) && <div>
		        <div className={"mt20"}>
			        <Checkbox
				        checked={putOnHold}
				        onChange={e => setPutOnHold(e.target.checked)}
			        >
				        Automatic Archive
			        </Checkbox>
		        </div>

		        <div className={"mt10"}>
			        <Select
				        value={daysOnHold}
				        onSelect={(value: number) => setDaysOnHold(value)}
				        size={"large"}
				        className={"select-content grey-color"}
				        disabled={!putOnHold}
				        style={{ width: "100%" }}
				        placeholder={<span className={"fs14"}>Select...</span>}
			        >
				        {[{
					        value: 7,
					        title: "1 week",
				        }, {
					        value: 14,
					        title: "2 weeks",
				        }].map((option, i) => {
					        return <Select.Option
						        key={i}
						        value={option.value}
						        className={"select-option"}
					        >
						        <div className={"vertical-center"}>
							        {option.title}
						        </div>
					        </Select.Option>;
				        })}
			        </Select>
		        </div>
	        </div>}
        </div>
    </Modal>;
};

export default ErrorHandlingModal;
