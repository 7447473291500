import { useCallback, useEffect, useMemo, useState } from "react";

import {
    ColumnDef,
    getCoreRowModel, getExpandedRowModel, getFilteredRowModel,
    getSortedRowModel,
    PaginationState, SortingState,
    useReactTable,
} from "@tanstack/react-table";

import { PAGE_SIZES } from "../../constants/pagination";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { clientsSlice } from "../../redux/reducers/clients";
import {
    useDeleteClientByIdMutation,
    useDeleteRevenueLetterByIdMutation,
    useGetClientsQuery,
    useUpdateClientByIdMutation,
} from "../../services/clients";
import { getFile, useUploadFileMutation } from "../../services/files";
import { ClientsType, IClient, IDeleteClientByIdBody } from "../../types/clients";
import { ISortBy } from "../../types/common";
import { getGlobalFilterProps, getPaginationProps, getSelectedRows } from "../../utils/table";

export const useClientsTable = (
    getColumns: ({
        // eslint-disable-next-line no-unused-vars
        deleteClientById,
        // eslint-disable-next-line no-unused-vars
        getRevenueLetterFile,
        // eslint-disable-next-line no-unused-vars
        uploadRevenueLetter,
        // eslint-disable-next-line no-unused-vars
        deleteRevenueLetter,
    }: {
        deleteClientById: (_data: IDeleteClientByIdBody) => void;
        getRevenueLetterFile: (_url: string, _originalName: string) => Promise<void>;
        uploadRevenueLetter: (_client: IClient, _file: File) => Promise<boolean>;
        deleteRevenueLetter: (_client: IClient, _revenueLetterId: string) => Promise<boolean>;
    }) => ColumnDef<IClient>[]
) => {
    const dispatch = useAppDispatch();

    const [clientsType, setClientsType] = useState<ClientsType>("all");

    const globalFilter = useAppSelector(state => state.clients.filters.globalFilter);
    const sorting = useAppSelector(state => state.clients.filters.sorting);

    const setGlobalFilter = useCallback((value: string) => {
        dispatch(clientsSlice.actions.setGlobalFilter(value));
    }, [dispatch]);

    const setSorting = useCallback((value: SortingState) => {
        dispatch(clientsSlice.actions.setSorting(value));
    }, [dispatch]);

    const [rowSelection, setRowSelection] = useState({});

    const [{
        pageIndex,
        pageSize,
    }, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: PAGE_SIZES[1],
    });

    const paginationData = useMemo(
        () => ({ pageIndex, pageSize }),
        [pageIndex, pageSize]
    );

    const [updateClientById, {
        isLoading: isLoadingClientUpdate,
    }] = useUpdateClientByIdMutation();

    const [deleteClientById, {
        isLoading: isLoadingClientDelete,
    }] = useDeleteClientByIdMutation();

    const [deleteRevenueLetterById, {
        isLoading: isLoadingRevenueLetterDelete,
    }] = useDeleteRevenueLetterByIdMutation();

    const [uploadFile, {
        isLoading: isFileLoading,
    }] = useUploadFileMutation();

    const getRevenueLetterFile = useCallback(async (url: string, originalName: string) => {
        dispatch(getFile.initiate({
            url,
            fileName: originalName,
            triggerDownload: true,
        }));
    }, [dispatch]);

    const uploadRevenueLetter = useCallback(async (client: IClient, file: File) => {
        const uploadedFileResponse = await uploadFile({
            file: file,
            path: `clients/${client._id}/revenue`,
        }).unwrap();

        if (!uploadedFileResponse?.data?.url) return false;

        const uploadedFileUrl = uploadedFileResponse.data.url;

        await updateClientById({
            clientId: client._id,
            data: {
                revenueLetters: [...client.revenueLetters, {
                    name: "Revenue Letter",
                    url: uploadedFileUrl,
                    originalName: file.name,
                }],
            },
        });

        return true;
    }, [updateClientById, uploadFile]);

    const deleteRevenueLetter = useCallback(async (client: IClient, revenueLetterId: string) => {
        await deleteRevenueLetterById({
            clientId: client._id,
            revenueLetterId,
        });

        return true;
    }, [deleteRevenueLetterById]);

    const {
        currentData,
        isLoading,
        isFetching,
    } = useGetClientsQuery({
        pageIndex,
        pageSize,
        sortBy: sorting as ISortBy[],
        globalFilter,
        ...clientsType !== "all" && {
            shouldUpdateAgreement: clientsType === "previous",
        },
    });

    useEffect(() => {
        setPagination(prevState => ({
            ...prevState,
            pageIndex: 0,
        }));
    }, [
        globalFilter,
        sorting,
        pageSize,
    ]);

    const isDataLoading = useMemo(() => {
        return isLoading ||
            isFetching ||
            isLoadingClientDelete;
    }, [
        isLoading,
        isFetching,
        isLoadingClientDelete,
    ]);

    const isRevenueLettersLoading = useMemo(() => {
        return isLoadingRevenueLetterDelete || isLoadingClientUpdate || isFileLoading;
    }, [isLoadingRevenueLetterDelete, isLoadingClientUpdate, isFileLoading]);

    const table = useReactTable(
        {
            columns: useMemo(() => getColumns({
                deleteClientById,
                getRevenueLetterFile,
                uploadRevenueLetter,
                deleteRevenueLetter,
            }), [getColumns, deleteClientById, getRevenueLetterFile, uploadRevenueLetter, deleteRevenueLetter]),
            data: useMemo(() => currentData?.clients || [], [currentData?.clients]),
            getRowCanExpand: () => true,
            pageCount: currentData?.pagination.totalPages,
            state: {
                pagination: paginationData,
                sorting,
                rowSelection,
                globalFilter,
            },
            onGlobalFilterChange: setGlobalFilter,
            onRowSelectionChange: setRowSelection,
            onSortingChange: updater => {
                if (typeof updater === "function") {
                    const nextState = updater(sorting);
                    setSorting(nextState);
                }
            },
            onPaginationChange: setPagination,
            getCoreRowModel: getCoreRowModel(),
            getSortedRowModel: getSortedRowModel(),
            getFilteredRowModel: getFilteredRowModel(),
            getExpandedRowModel: getExpandedRowModel(),
            enableRowSelection: true,
            manualPagination: true,
            manualSorting: true,
            manualFiltering: true,
        }
    );

    const selectedClients = useMemo(() => {
        return getSelectedRows(table);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rowSelection]);

    return {
        isRevenueLettersLoading,
        isLoading: isDataLoading,
        table,
        selectedClients,
        clientsType,
        setClientsType,
        globalFilter: useMemo(() => {
            return getGlobalFilterProps(table);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [globalFilter]),
        pagination: useMemo(() => {
            return getPaginationProps(table, currentData?.pagination, "Users");
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [paginationData, currentData?.pagination]),
    };
};
