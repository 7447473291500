import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PaginationState, SortingState, VisibilityState } from "@tanstack/react-table";

import {
    COLUMNS_FOR_VISIBILITY_CHANGE,
    DEFAULT_LAST_SUBMITTED_RANGE,
    DEFAULT_PAID_RANGE,
    MAIN_STATUSES,
} from "../../constants/documents";
import { PAGE_SIZES } from "../../constants/pagination";
import { documentsApi } from "../../services/documents";
import { IPagination } from "../../types/common";
import { IDocument, StatusType } from "../../types/documents";
import { Plan } from "../../types/plans";

const globalFilter = localStorage.getItem("cases-globalFilter") || "";
const mainStatusId = localStorage.getItem("cases-mainStatusId") || MAIN_STATUSES[0].id;
const agentId = localStorage.getItem("cases-agentId") || undefined;
const sorting = JSON.parse(localStorage.getItem("cases-sorting") || JSON.stringify([]));
const statusIds = JSON.parse(localStorage.getItem("cases-statusIds") || JSON.stringify([]));
const planIds = JSON.parse(localStorage.getItem("cases-planIds") || JSON.stringify([]));
const paidRange = JSON.parse(localStorage.getItem("cases-paidRange") || JSON.stringify(DEFAULT_PAID_RANGE));
const lastSubmittedRange = JSON.parse(localStorage.getItem("cases-lastSubmittedRange") || JSON.stringify(DEFAULT_LAST_SUBMITTED_RANGE));
const columnVisibility = JSON.parse(localStorage.getItem("cases-columnVisibility") || JSON.stringify(COLUMNS_FOR_VISIBILITY_CHANGE.reduce((acc, column) => {
    acc[column.id] = true;
    return acc;
}, {} as VisibilityState)));

const initialState: {
    filters: {
        globalFilter: string;
        mainStatusId: string;
        statusIds: StatusType[];
        agentId: string | undefined;
        planIds: (Plan | null)[];
        sorting: SortingState;
        paidRange: [number, number];
        lastSubmittedRange: [string, string];
    },
    paginationState: PaginationState;
    columnVisibility: VisibilityState;
    documents: IDocument[],
    pagination: IPagination
} = {
    filters: {
        globalFilter,
        mainStatusId,
        agentId,
        sorting,
        statusIds,
        planIds,
        paidRange,
        lastSubmittedRange,
    },
    columnVisibility,
    paginationState: {
        pageIndex: 0,
        pageSize: PAGE_SIZES[1],
    },
    documents: [],
    pagination: {
        totalPages: 0,
        totalDocs: 0,
    },
};

export const documentsSlice = createSlice({
    name: "documents",
    initialState,
    reducers: {
        setGlobalFilter: (state, action: PayloadAction<string>) => {
            state.filters.globalFilter = action.payload;
            localStorage.setItem("cases-globalFilter", action.payload);
        },
        setMainStatusId: (state, action: PayloadAction<string>) => {
            state.filters.mainStatusId = action.payload;
            localStorage.setItem("cases-mainStatusId", action.payload);
        },
        setStatusIds: (state, action: PayloadAction<StatusType[]>) => {
            state.filters.statusIds = action.payload;
            localStorage.setItem("cases-statusIds", JSON.stringify(action.payload));
        },
        setAgentId: (state, action: PayloadAction<string>) => {
            state.filters.agentId = action.payload;
            localStorage.setItem("cases-agentId", action.payload);
        },
        setSorting: (state, action: PayloadAction<SortingState>) => {
            state.filters.sorting = action.payload;
            localStorage.setItem("cases-sorting", JSON.stringify(action.payload));
        },
        setPaidRange: (state, action: PayloadAction<[number, number]>) => {
            state.filters.paidRange = action.payload;
            localStorage.setItem("cases-paidRange", JSON.stringify(action.payload));
        },
        setLastSubmittedRange: (state, action: PayloadAction<[string, string]>) => {
            state.filters.lastSubmittedRange = action.payload;
            localStorage.setItem("cases-lastSubmittedRange", JSON.stringify(action.payload));
        },
        setPlanIds: (state, action: PayloadAction<(Plan | null)[]>) => {
            state.filters.planIds = action.payload;
            localStorage.setItem("cases-planIds", JSON.stringify(action.payload));
        },
        setPagination: (state, action: PayloadAction<PaginationState>) => {
            state.paginationState = action.payload;
        },
        setColumnVisibility: (state, action: PayloadAction<VisibilityState>) => {
            state.columnVisibility = action.payload;
            localStorage.setItem("cases-columnVisibility", JSON.stringify(action.payload));
        },
    },
    extraReducers: builder => {
        builder.addMatcher(documentsApi.endpoints.getDocuments.matchFulfilled, (state, action) => {
            state.pagination = action.payload.pagination;
        });
    },
});

export default documentsSlice.reducer;
