import { useCallback, useMemo } from "react";

import { v4 as uuidv4 } from "uuid";

import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
    useDeleteRevenueLetterByIdMutation,
    useUpdateClientByIdMutation,
    useUpdateClientPaymentDetailsByIdMutation,
} from "../../services/clients";
import { useSendErrorNotificationMutation } from "../../services/clients/notifications";
import {
    useConfirmPlanForDocumentMutation, useDeleteDocumentReceiptMutation, useDeleteDocumentFileMutation,
    useGetDocumentByIdQuery,
    useUpdateDocumentByIdMutation, useUpdateDocumentRefundMutation,
    useUpdateDocumentStatusByIdMutation, useUpdateDocumentSubStatusByIdMutation,
} from "../../services/documents";
import { getFile, useUploadFileMutation } from "../../services/files";
import { IClient } from "../../types/clients";
import { ISortBy } from "../../types/common";
import {
    IConfirmPlanForDocumentByIdData, IDocument, IReceipts,
    IUpdateDocumentByIdData, IUpdateDocumentRefundData,
    IUpdateDocumentStatusByIdData, IUpdateDocumentSubStatusByIdData,
} from "../../types/documents";
import { ISendErrorNotificationData } from "../../types/notifications";

export const useCase = (documentId: string) => {
    const dispatch = useAppDispatch();

    const globalFilter = useAppSelector(state => state.documents.filters.globalFilter);
    const mainStatusId = useAppSelector(state => state.documents.filters.mainStatusId);
    const agentId = useAppSelector(state => state.documents.filters.agentId);
    const statusIds = useAppSelector(state => state.documents.filters.statusIds);
    const paidRange = useAppSelector(state => state.documents.filters.paidRange);
    const planIds = useAppSelector(state => state.documents.filters.planIds);
    const sorting = useAppSelector(state => state.documents.filters.sorting);

    const {
        data,
        isLoading: isLoadingDocument,
        isFetching: isFetchingDocument,
    } = useGetDocumentByIdQuery({
        documentId,
        mainStatusId,
        sortBy: sorting as ISortBy[],
        globalFilter,
        managerId: agentId,
        statusIds,
        paidRange,
        planIds,
    });

    const [confirmPlanForDocumentAsync, {
        isLoading: isLoadingConfirmPlanForDocument,
    }] = useConfirmPlanForDocumentMutation();

    const [updateDocumentById, {
        isLoading: isLoadingDocumentUpdate,
    }] = useUpdateDocumentByIdMutation();

    const [updateDocumentStatusById, {
        isLoading: isLoadingDocumentStatusUpdate,
    }] = useUpdateDocumentStatusByIdMutation();

    const [updateDocumentSubStatusById, {
        isLoading: isLoadingDocumentSubStatusUpdate,
    }] = useUpdateDocumentSubStatusByIdMutation();

    const [updateDocumentRefundAsync, {
        isLoading: isLoadingUpdateDocumentRefund,
    }] = useUpdateDocumentRefundMutation();

    const [updateClientById, {
        isLoading: isLoadingClientUpdate,
    }] = useUpdateClientByIdMutation();

    const [updateClientPaymentDetailsById, {
        isLoading: isLoadingClientPaymentUpdate,
    }] = useUpdateClientPaymentDetailsByIdMutation();

    const [sendErrorNotificationAsync, {
        isLoading: isLoadingSendErrorNotification,
    }] = useSendErrorNotificationMutation();

    const [deleteDocumentReceiptAsync, {
        isLoading: isLoadingDeleteDocumentReceipt,
    }] = useDeleteDocumentReceiptMutation();

    const [deleteDocumentFileAsync, {
        isLoading: isLoadingDeleteDocumentFile,
    }] = useDeleteDocumentFileMutation();

    const [uploadFile, {
        isLoading: isFileLoading,
    }] = useUploadFileMutation();

    const [deleteRevenueLetterById, {
        isLoading: isLoadingRevenueLetterDelete,
    }] = useDeleteRevenueLetterByIdMutation();

    const getAgreementFile = useCallback(async () => {
        const client = data?.data.document.client as IClient;

        dispatch(getFile.initiate({
            url: client.agreementUrl,
            fileName: `${client.id}_agreement.pdf`,
            triggerDownload: true,
        }));
    }, [data?.data.document.client, dispatch]);

    const getInvoiceFile = useCallback(async () => {
        const document = data?.data.document as IDocument;

        dispatch(getFile.initiate({
            url: document.invoiceUrl!,
            fileName: `${document.id}_invoice.pdf`,
            triggerDownload: true,
        }));
    }, [data?.data.document, dispatch]);

    const getReceiptFile = useCallback(async (url: string, originalName: string) => {
        dispatch(getFile.initiate({
            url,
            fileName: originalName,
            triggerDownload: true,
        }));
    }, [dispatch]);

    const uploadReceipt = useCallback(async (file: File, category: keyof IReceipts, name: string, forAdminOnly?: boolean) => {
        const document = data?.data.document as IDocument;

        const uploadedFileResponse = await uploadFile({
            file: file,
            path: `clients/${(document?.client as IClient)._id}/receipts`,
        }).unwrap();

        if (!uploadedFileResponse?.data?.url) return false;

        const uploadedFileUrl = uploadedFileResponse.data.url;
        const currentCategoryReceipts = document.receipts[category as keyof IReceipts] || [];
        const updatedReceipts = [...currentCategoryReceipts, {
            originalName: file.name,
            url: uploadedFileUrl,
            name: name,
            forAdminOnly: forAdminOnly,
        }];

        await updateDocumentById({
            documentId: document._id,
            data: {
                receipts: updatedReceipts,
                category,
            },
        });

        return true;
    }, [data?.data.document, updateDocumentById, uploadFile]);

    const uploadQFile = useCallback(async (file: File, category: string, name: string) => {
        const document = data?.data.document as IDocument;

        const uploadedFileResponse = await uploadFile({
            file: file,
            path: `clients/${(document?.client as IClient)._id}/receipts`,
        }).unwrap();

        if (!uploadedFileResponse?.data?.url) return false;

        const uploadedFileUrl = uploadedFileResponse.data.url;
        const currentCategoryFiles = document.files[category]?.docs;
        const updatedFiles = [...currentCategoryFiles, {
            _id: uuidv4(),
            originalName: file.name,
            url: uploadedFileUrl,
            name: name,
            createdAt: new Date(),
        }];

        await updateDocumentById({
            documentId: document._id,
            data: {
                files: {
                    requiredDocuments: document.files[category].requiredDocuments,
                    docs: updatedFiles,
                    allowAdditionalFiles: document.files[category].allowAdditionalFiles,
                },
                category,
            },
        });

        return true;
    }, [data?.data.document, updateDocumentById, uploadFile]);

    const getRevenueLetterFile = useCallback(async (url: string, originalName: string) => {
        dispatch(getFile.initiate({
            url,
            fileName: originalName,
            triggerDownload: true,
        }));
    }, [dispatch]);

    const uploadRevenueLetter = useCallback(async (file: File) => {
        const client = data?.data.document.client as IClient;

        if (!client) return false;

        const uploadedFileResponse = await uploadFile({
            file: file,
            path: `clients/${client._id}/revenue`,
        }).unwrap();

        if (!uploadedFileResponse?.data?.url) return false;

        const uploadedFileUrl = uploadedFileResponse.data.url;

        await updateClientById({
            clientId: client._id,
            data: {
                revenueLetters: [...client.revenueLetters, {
                    name: "Revenue Letter",
                    url: uploadedFileUrl,
                    originalName: file.name,
                }],
            },
        });

        return true;
    }, [data?.data.document.client, updateClientById, uploadFile]);

    const deleteRevenueLetter = useCallback(async (revenueLetterId: string) => {
        const client = data?.data.document.client as IClient;

        if (!client) return false;

        await deleteRevenueLetterById({
            clientId: client._id,
            revenueLetterId,
        });

        return true;
    }, [data?.data.document.client, deleteRevenueLetterById]);

    const isLoading = useMemo(() => {
        return isLoadingDocument ||
            isFetchingDocument ||
            isLoadingDocumentStatusUpdate ||
            isLoadingClientUpdate ||
            isLoadingClientPaymentUpdate ||
            isLoadingSendErrorNotification ||
            isLoadingDocumentUpdate ||
            isLoadingConfirmPlanForDocument ||
            isLoadingUpdateDocumentRefund ||
            isFileLoading ||
            isLoadingDeleteDocumentReceipt ||
            isLoadingDeleteDocumentFile ||
            isLoadingRevenueLetterDelete ||
            isLoadingDocumentSubStatusUpdate;
    }, [
        isLoadingDocument,
        isFetchingDocument,
        isLoadingDocumentStatusUpdate,
        isLoadingClientUpdate,
        isLoadingClientPaymentUpdate,
        isLoadingSendErrorNotification,
        isLoadingDocumentUpdate,
        isLoadingConfirmPlanForDocument,
        isLoadingUpdateDocumentRefund,
        isFileLoading,
        isLoadingDeleteDocumentReceipt,
        isLoadingDeleteDocumentFile,
        isLoadingRevenueLetterDelete,
        isLoadingDocumentSubStatusUpdate,
    ]);

    const updateDocumentStatus = useCallback(async (statusData: IUpdateDocumentStatusByIdData) => {
        return await updateDocumentStatusById({
            documentId,
            data: statusData,
        }).unwrap();
    }, [documentId, updateDocumentStatusById]);

    const updateDocumentSubStatus = useCallback(async (statusData: IUpdateDocumentSubStatusByIdData) => {
        return await updateDocumentSubStatusById({
            documentId,
            data: statusData,
        });
    }, [documentId, updateDocumentSubStatusById]);

    const updateDocument = useCallback(async (documentData: IUpdateDocumentByIdData) => {
        return await updateDocumentById({
            documentId,
            data: documentData,
        });
    }, [documentId, updateDocumentById]);

    const confirmPlanForDocument = useCallback(async (documentData: IConfirmPlanForDocumentByIdData) => {
        return await confirmPlanForDocumentAsync({
            documentId,
            data: documentData,
        });
    }, [confirmPlanForDocumentAsync, documentId]);

    const updateDocumentRefund = useCallback(async (documentData: IUpdateDocumentRefundData) => {
        return await updateDocumentRefundAsync({
            documentId,
            data: documentData,
        });
    }, [updateDocumentRefundAsync, documentId]);

    const deleteReceipt = useCallback(async (id: string, category: string) => {
        const result = await deleteDocumentReceiptAsync({
            documentId,
            data: {
                receiptId: id,
                category,
            },
        }).unwrap();

        return !!result?.document;
    }, [deleteDocumentReceiptAsync, documentId]);

    const deleteQFile = useCallback(async (id: string, category: string) => {
        const result = await deleteDocumentFileAsync({
            documentId,
            data: {
                fileId: id,
                category,
            },
        }).unwrap();

        return !!result?.document;
    }, [deleteDocumentFileAsync, documentId]);

    const sendErrorNotification = useCallback(async (errorData: ISendErrorNotificationData) => {
        const client = data?.data.document.client as IClient;

        return await sendErrorNotificationAsync({
            clientId: client._id,
            data: errorData,
        });
    }, [data?.data.document.client, sendErrorNotificationAsync]);

    return {
        isLoading,
        document: data?.data.document,
        prevDocumentId: data?.data.prevDocumentId,
        nextDocumentId: data?.data.nextDocumentId,
        getAgreementFile,
        getInvoiceFile,
        uploadReceipt,
        deleteReceipt,
        uploadQFile,
        deleteQFile,
        getReceiptFile,
        getRevenueLetterFile,
        uploadRevenueLetter,
        deleteRevenueLetter,
        updateDocumentStatus,
        updateDocumentSubStatus,
        updateDocument,
        confirmPlanForDocument,
        updateClientById,
        updateClientPaymentDetailsById,
        updateDocumentRefund,
        sendErrorNotification,
    };
};
