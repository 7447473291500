import { useState } from "react";

import EyeInvisibleOutlined from "@ant-design/icons/lib/icons/EyeInvisibleOutlined";
import EyeOutlined from "@ant-design/icons/lib/icons/EyeOutlined";
import { Form, Input } from "antd";

import Label from "./../Labels/Label";
import Error from "../Labels/Error";

const ConfirmPasswordInput = ({
    required = false,
    name = "confirmPassword",
}: {
    required?: boolean;
    name?: string;
}) => {
    const [confirmPasswordInputType, setConfirmPasswordInputType] = useState("text");

    return <div>
        <Form.Item
            hasFeedback
            name="confirmPassword"
            dependencies={["password"]}
            label={<Label text={"Confirm password"}/>}
            required={required}
            labelAlign={"left"}
            rules={[{
                required,
                message: <Error text={"Please input the password!"}/>,
            }, ({ getFieldValue }) => ({
                validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                    }
                    return Promise.reject(<Error
                        text={"The two passwords that you entered do not match!"}/>);
                },
            })]}>
            <Input
                type={confirmPasswordInputType}
                placeholder={"Enter password..."}
                data-testid={name}
                className={"input-content dark-grey-color"}
                size="large"
                suffix={confirmPasswordInputType === "password"
                    ? <EyeInvisibleOutlined onClick={() => setConfirmPasswordInputType("text")}/>
                    : <EyeOutlined onClick={() => setConfirmPasswordInputType("password")}/>
                }
            />
        </Form.Item>
    </div>;
};

export default ConfirmPasswordInput;
