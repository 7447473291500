import React from "react";

import { Col, Input, Row, Select } from "antd";

import { SUBMISSION_METHODS } from "../../../../../../../constants/documents";
import { IRow } from "../../../../../../../types/common";

export interface ISubmissionRow extends IRow {
	type: string;
}

const SubmissionDetailsGroup = ({
    sizes,
    header,
    rows,
    onChange,
}: {
	header: IRow;
	sizes: {
		main: number;
		column: number;
	},
	rows: ISubmissionRow[];
	onChange: (_year: string, _type: string, _value: string) => void;
}) => {
    return <div>
        <Row wrap={false}>
            <Col flex={`${sizes.main}%`}>
                <div className={"fs16 bold"}>
                    {header.title}
                </div>
            </Col>

            {header.columns.map(column => {
                return <Col
                    key={column}
                    flex={`${sizes.column}%`}
                >
                    <div className={"fs16 primary-color bold horizontal-right"}>
                        {column}
                    </div>
                </Col>;
            })}
        </Row>

        {rows.map((row, i) => {
            return <Row
                key={i}
                className={"mt10"}
                gutter={8}
                wrap={false}
            >
                <Col flex={`${sizes.main}%`} className={"vertical-center"}>
                    <div className={"fs14 grey-6-color"}>
                        {row.title}
                    </div>
                </Col>

                {row.columns.map((column, j) => {
                    return <Col
                        key={j}
                        flex={`${sizes.column}%`}
                    >
                        <div>
	                        {row.type === "method" && <div>
                                <Select
                                    value={column.method}
                                    onSelect={(value: string) => onChange(column.year, row.type, value)}
                                    size={"large"}
                                    className={"select-content grey-7-color"}
                                    style={{ width: "100%" }}
                                    placeholder={<span className={"fs14"}>Select method...</span>}
                                >
			                        {SUBMISSION_METHODS.map(method => {
				                        return (
					                        <Select.Option
						                        key={method.id}
						                        value={method.id}
						                        className={"select-option"}
					                        >
						                        {method.title}
					                        </Select.Option>
				                        );
			                        })}
                                </Select>
	                        </div>}

	                        {row.type === "reference" && <div>
                                <Input
                                    className={"grey-7-color"}
                                    size={"large"}
                                    value={column.reference}
                                    disabled={column.method !== "manual"}
                                    placeholder={"Add text..."}
                                    onChange={e => onChange(column.year, row.type, e.target.value)}
                                />
                            </div>}
                        </div>
                    </Col>;
                })}
            </Row>;
        })}
    </div>;
};

export default SubmissionDetailsGroup;
