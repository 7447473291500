import { IDocument } from "../types/documents";

export function getTitleFromCategory(category: keyof IDocument["receipts"]): string {
    switch (category) {
    case "carer":
        return "Carer Receipts";
    case "medical":
        return "Medical Receipts";
    case "nursingHome":
        return "Nursing Home Receipts";
    case "thirdLevelEducation":
        return "Third Level Education Receipts";
    case "workingFromHome":
        return "Working From Home Receipts";
    default:
        return "";
    }
}

export const getRootPath = (pathname: string) => {
    return pathname.split("/")[1];
};

export const getUserInitials = (name: string) => {
    const [firstName, lastName] = name.split(" ");

    return `${firstName?.slice(0, 1)}${lastName?.slice(0, 1) || ""}`;
};

export const addSpaceEveryNthChar = (inputString: string, chunkSize: number): string => {
    const words = inputString.split(" ");
    let result = "";

    for (let i = 0; i < words.length; i++) {
        const word = words[i];

        if (word.length > chunkSize) {
            const chunks = [];
            for (let j = 0; j < word.length; j += chunkSize) {
                chunks.push(word.slice(j, j + chunkSize));
            }
            result += chunks.join(" ");
        } else {
            result += word;
        }

        if (i < words.length - 1) {
            result += " ";
        }
    }

    return result;
};
