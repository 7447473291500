import britainFlagImage from "./../assets/images/britain.png";
import irelandFlagImage from "./../assets/images/ireland.png";
import { convertArrayOfObjectsToDict } from "../utils/data";

export const DEFAULT_REGIONS = [{
    id: "IE",
    shortName: "IE",
    name: "Ireland",
    image: irelandFlagImage,
}, {
    id: "UK",
    shortName: "UK",
    name: "United Kingdom",
    image: britainFlagImage,
}];

export const POSSIBLE_REGIONS_IDS = DEFAULT_REGIONS.map(region => region.id);

export const DEFAULT_REGIONS_DICT = convertArrayOfObjectsToDict(POSSIBLE_REGIONS_IDS, DEFAULT_REGIONS);
