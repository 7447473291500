import { Form, Select } from "antd";
import { SizeType } from "antd/es/config-provider/SizeContext";

import { DEFAULT_ROLES } from "../../../../constants/roles";
import Label from "../Labels/Label";

const PermissionsSelector = ({
    size = "large",
    name = "role",
    label = "Permissions",
}: {
    size?: SizeType;
    name?: string;
    label?: string;
}) => {
    return <div>
        <Form.Item
            label={<Label text={label}/>}
            name={name}
            labelAlign={"left"}
        >
            <Select
                size={size}
                className={"select-content grey-color"}
                style={{ width: "100%" }}
            >
                {DEFAULT_ROLES.map(role => {
                    return <Select.Option
                        key={role.id}
                        value={role.id}
                        className={"select-option"}
                    >
                        {role.name}
                    </Select.Option>;
                })}
            </Select>
        </Form.Item>
    </div>;
};

export default PermissionsSelector;
