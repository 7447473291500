import { useCallback, useEffect, useMemo, useState } from "react";

import { useGetQuestionnaireByIdQuery, useUpdateQuestionnaireByIdMutation } from "../../services/questionnaires";
import { QuestionnaireFlowDataT } from "../../types/questionnaires";

export const useQuestionnaire = (questionnaireId?: string) => {
    const [isEditMode, setIsEditMode] = useState<boolean>(false);
    const [questionnaireFlowData, setQuestionnaireFlowData] = useState<QuestionnaireFlowDataT | undefined>(undefined);

    const {
        data: questionnaireData,
        isLoading,
        isFetching,
        refetch,
    } = useGetQuestionnaireByIdQuery({
        questionnaireId: questionnaireId!,
    }, {
        skip: !questionnaireId,
        refetchOnMountOrArgChange: true,
    });

    const [updateQuestionnaireById, {
        isLoading: isLoadingUpdate,
    }] = useUpdateQuestionnaireByIdMutation();

    useEffect(() => {
        if (questionnaireData?.questionnaire.questions) {
            setQuestionnaireFlowData({ ...questionnaireData.questionnaire.questions, timeStamp: new Date().getTime() });
        }
    }, [questionnaireData]);

    const updateQuestionnaire = useCallback(async () => {
        await updateQuestionnaireById({
            questionnaireId: questionnaireId!,
            data: {
                questions: questionnaireFlowData,
            },
        });
    }, [questionnaireFlowData, questionnaireId, updateQuestionnaireById]);

    const refetchQuestionnaire = useCallback(async () => {
        await refetch();
        if (questionnaireData?.questionnaire.questions) {
            setQuestionnaireFlowData({ ...questionnaireData.questionnaire.questions, timeStamp: new Date().getTime() });
        }
    }, [refetch, questionnaireData]);

    const isQuestionnaireLoading = useMemo(() => {
        return isFetching || isLoading || isLoadingUpdate;
    }, [isLoading, isFetching, isLoadingUpdate]);

    return {
        questionnaire: questionnaireData?.questionnaire,
        isLoading: isQuestionnaireLoading,
        isEditMode,
        setIsEditMode,
        questionnaireFlowData,
        setQuestionnaireFlowData,
        updateQuestionnaire,
        refetchQuestionnaire,
    };
};
