import { ColumnDef } from "@tanstack/react-table";

import Agent from "./components/Agent";
import Cell from "./components/Cell";
import Header from "./components/Header";
import { IOperationsStats } from "../../../../../../../types/stats";

export const getColumns = (): ColumnDef<IOperationsStats>[] => [
    {
        id: "agent",
        accessorKey: "agent.info.name",
        header: () => null,
        cell: ({ row }) => <Agent value={row.original.agent?.info?.name || ""}/>,
    },
    {
        id: "dailyClientsProcessed",
        accessorKey: "stats.dailyClientsProcessed",
        header: () => <Header label={"Daily Clients Processed"}/>,
        cell: info => <Cell value={info.getValue() as string}/>,
    },
    {
        id: "weeklyClientsProcessed",
        accessorKey: "stats.weeklyClientsProcessed",
        header: () => <Header label={"Weekly Clients Processed"}/>,
        cell: info => <Cell value={info.getValue() as string}/>,
    },
];
