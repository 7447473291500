import { FC, useCallback, useEffect, useMemo, useState } from "react";

import { EditOutlined } from "@ant-design/icons";
import { List, Button, Typography, Modal, Row } from "antd";
import { nanoid } from "nanoid";

import { ValidationType } from "./GroupItem";
import QuestionListItem from "./QuestionItem";
import closeIcon from "../../../../../../../assets/images/close.svg";
import removeIcon from "../../../../../../../assets/images/form/remove-circle.svg";
import { useAppSize } from "../../../../../../../hooks/helpers/useAppSize";
import { useSessionStorage } from "../../../../../../../hooks/helpers/useSessionStorage";
import { ChildrenT } from "../../../../../../../types/questionnaires";
import {
    checkDepend,
    getAnsweredRequiredQuestions,
    getTotalRequiredQuestions,
} from "../../../../../../../utils/builder";

type QuestionItem = Record<string, any>;

type ArrayComponentProps = {
    arrayData: ChildrenT;
    // eslint-disable-next-line no-unused-vars
    onAnswer: (answer: any) => void;
    answers?: QuestionItem;
    initialValue?: QuestionItem[];
};

const getQuestionAnswer = (
    item: QuestionItem,
    answerId?: string,
    isTitle?: boolean
) => {
    if (!answerId) {
        return null;
    }

    const qAnswer = item[answerId];

    if (!qAnswer) {
        return null;
    }
    return (
        <Typography.Text
            key={answerId}
            className={`${
                isTitle ? "builder__array-title" : "builder__array-text"
            }`}
        >
            {Array.isArray(qAnswer) ? qAnswer.join(", ") : qAnswer.toString()}
        </Typography.Text>
    );
};

const ArrayComponent: FC<ArrayComponentProps> = ({
    arrayData,
    onAnswer,
    answers,
    initialValue = [],
}) => {
    const { children, depend, andDepend, data, id } = arrayData;
    const { addItemLabel, label, helpText, itemTitle, itemSubTitle } = data;

    const isMobile = useAppSize();
    const [arrayItems, setArrayItems] = useState<QuestionItem[]>(initialValue);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [modalData, setModalData] = useState<QuestionItem>({});
    const [selectedIndex, setSelectedIndex] = useState<number>(-1);
    const [requiredGroupQuestions, setRequired] = useState<Record<string, any>>({});
    const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
    const [deleteIndex, setDeleteIndex] = useState<number>(-1);
    const [error, setError] = useState<string>("");
    const [skipValidation, setSkipValidation] = useState<boolean>(true);
    const isSaveDisabled = useMemo(() => {
        const totalRequiredQuestions = getTotalRequiredQuestions(
            requiredGroupQuestions
        );
        const answeredRequiredQuestions = getAnsweredRequiredQuestions(
            requiredGroupQuestions,
            modalData
        );

        return answeredRequiredQuestions < totalRequiredQuestions;
    }, [requiredGroupQuestions, modalData]);

    const [limitsReached, setLimitsReached] = useSessionStorage<Record<string, boolean>>(`limitsReached-${id}`, {});

    const disableAddButton = useMemo(() => Object.values(limitsReached).some(value => value === true), [limitsReached]);

    useEffect(() => {
        if (data.required && arrayItems.length < 1 && !skipValidation) {
            setError(`Required to ${addItemLabel}`);
        } else {
            setError("");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [arrayItems, skipValidation]);

    const handleAnswer = useCallback(
        (questionId: string, answer: any) => {
            if (modalData[questionId] === undefined && answer === undefined) {
                return;
            }

            setModalData(prevData => ({
                ...prevData,
                [questionId]: answer,
            }));
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [setModalData, arrayItems, showModal, modalData, answers]
    );

    const showArray = useMemo(() => {
        const show1 = checkDepend(depend, answers);
        const show2 = checkDepend(andDepend, answers);
        return show1 && show2;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [answers]);

    const handleAddItem = () => {
        setSelectedIndex(-1);
        setSkipValidation(false);
        setShowModal(true);
    };

    const handleEditItem = (index: number) => {
        setSelectedIndex(index);
        setModalData(arrayItems[index]);
        setShowModal(true);
    };

    const handleSave = () => {
        if (selectedIndex === -1) {
            setArrayItems(prevItems => [...prevItems, modalData]);
            onAnswer([...arrayItems, modalData]);
        } else {
            setArrayItems(prevItems => {
                const updatedItems = [...prevItems];
                updatedItems[selectedIndex] = modalData;
                onAnswer(updatedItems);
                return updatedItems;
            });
        }

        setModalData({});
        setSelectedIndex(-1);
        setShowModal(false);
    };

    const handleClose = () => {
        setModalData({});
        setSelectedIndex(-1);
        setShowModal(false);
    };

    const handleQuestionRequired = (questionId: string, required: boolean, validation?: ValidationType) => {
        setRequired(prevRequired => ({
            ...prevRequired,
            [questionId]: {
                required,
                validation,
            },
        }));
    };
    const handleSetLimitsReached = (questionId: string, reached: boolean) => {
        setLimitsReached(prevLimits => ({
            ...prevLimits,
            [questionId]: reached,
        }));
    };

    const deleteConfirm = () => {
        setArrayItems(prevItems => {
            const updatedItems = [...prevItems];
            updatedItems.splice(deleteIndex, 1);
            onAnswer(updatedItems);
            return updatedItems;
        });
        setLimitsReached({});
        setConfirmDelete(false);
    };

    const confirmDeleteItem = (index: number) => {
        setDeleteIndex(index);
        setConfirmDelete(true);
    };

    const handleDeleteItem = (index: number) => {
        confirmDeleteItem(index);
    };

    useEffect(() => {
        if (!showArray) {
            setArrayItems([]);
        }
    }, [showArray]);

    return (
        <div className="builder__array-question">
            <Typography.Title level={4}>{label}</Typography.Title>
            <div>
                {arrayItems.map(
                    (item, index) =>
                        children && (
                            <div key={nanoid()} style={{ padding: 0 }}>
                                <div className="custom-list-item">
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            width: "100%",
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                flexDirection: "column",
                                            }}
                                        >
                                            {getQuestionAnswer(
                                                item,
                                                itemTitle,
                                                true
                                            )}
                                            {getQuestionAnswer(
                                                item,
                                                itemSubTitle
                                            )}
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                flexDirection: "column",
                                            }}
                                        >
                                            <EditOutlined
                                                key="edit"
                                                style={{
                                                    fontSize: "24px",
                                                    color: "#555555",
                                                }}
                                                onClick={() =>
                                                    handleEditItem(index)
                                                }
                                            />
                                            <img
                                                src={removeIcon}
                                                alt="delete"
                                                style={{
                                                    width: "24px",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                    handleDeleteItem(index)
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                )}
            </div>
            {!disableAddButton &&
                <Button
                    className={"builder__add-array-item"}
                    onClick={handleAddItem}
                >
                    {addItemLabel}
                </Button>
            }
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    marginTop: "10px",
                }}
            >
                <Typography.Text className="builder__help-text">
                    {helpText}
                </Typography.Text>
                {error && (
                    <Typography.Text className="builder__form-error">
                        {error}
                    </Typography.Text>
                )}
            </div>
            {showModal && <Modal
                width={isMobile ? 375 : 385}
                open={showModal}
                footer={null}
                centered={true}
                closable={false}
                className="array-question-content"
            >
                <Row justify={"space-between"}>
                    <Typography className="builder__form-modal-title">{`${label} details`}</Typography>
                    <img src={closeIcon} className="builder__close-icon" onClick={handleClose} alt="close" />
                </Row>
                <List>
                    {children?.map(question => (
                        <QuestionListItem
                            setRequired={handleQuestionRequired}
                            key={question.id}
                            question={question}
                            onAnswer={handleAnswer}
                            answers={modalData}
                            arrayItems={arrayItems}
                            setLimitsReached={handleSetLimitsReached}
                        />
                    ))}
                </List>
                <Button
                    disabled={isSaveDisabled}
                    className={
                        "builder__boolean-button builder__boolean-button-selected"
                    }
                    style={{ width: "100%" }}
                    onClick={handleSave}
                >
                    {addItemLabel}
                </Button>
            </Modal>}
            <Modal
                width={355}
                footer={
                    <>
                        <Button
                            className={"builder__close-button"}
                            onClick={() => setConfirmDelete(false)}
                        >
                            Close
                        </Button>
                        <Button
                            className={"builder__delete-button"}
                            onClick={deleteConfirm}
                        >
                            Delete
                        </Button>
                    </>
                }
                centered={true}
                closable={false}
                open={confirmDelete}
            >
                <Typography
                    className="builder__form-title"
                    style={{
                        whiteSpace: "break-spaces",
                        marginBottom: "30px",
                    }}
                >
                    Delete {label}
                </Typography>
                <Typography
                    className="builder__form-question"
                    style={{
                        whiteSpace: "break-spaces",
                        marginBottom: "30px",
                    }}
                >
                    Are you sure you would like to delete this {label}’s
                    details?
                </Typography>
            </Modal>
        </div>
    );
};

export default ArrayComponent;
