import { useCallback, useEffect, useMemo, useState } from "react";

import {
    ColumnDef,
    getCoreRowModel,
    getSortedRowModel,
    PaginationState,
    SortingState,
    useReactTable,
} from "@tanstack/react-table";

import { PAGE_SIZES } from "../../constants/pagination";
import { useGetDocumentsQuery, useSetDocumentsAgentMutation } from "../../services/documents";
import { ISortBy } from "../../types/common";
import { IDocument } from "../../types/documents";
import { getPaginationProps, getSelectedRows } from "../../utils/table";

export const useUnassignedCasesTable = (columns: ColumnDef<IDocument>[]) => {
    const [rowSelection, setRowSelection] = useState({});
    const [sorting, setSorting] = useState<SortingState>([]);

    const [{
        pageIndex,
        pageSize,
    }, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: PAGE_SIZES[1],
    });

    const paginationData = useMemo(
        () => ({ pageIndex, pageSize }),
        [pageIndex, pageSize]
    );

    const {
        currentData,
        isLoading,
        isFetching,
    } = useGetDocumentsQuery({
        unassignedOnly: true,
        pageIndex,
        pageSize,
        sortBy: sorting as ISortBy[],
    });

    useEffect(() => {
        setPagination(prevState => ({
            ...prevState,
            pageIndex: 0,
        }));
    }, [
        sorting,
        pageSize,
    ]);

    const [
        setDocumentsAgent, {
            isLoading: isDocumentsAgentUpdating,
        },
    ] = useSetDocumentsAgentMutation();

    const table = useReactTable(
        {
            columns: useMemo(() => columns, [columns]),
            data: useMemo(() => currentData?.documents || [], [currentData?.documents]),
            pageCount: currentData?.pagination.totalPages,
            state: {
                pagination: paginationData,
                sorting,
                rowSelection,
            },
            onRowSelectionChange: setRowSelection,
            onSortingChange: setSorting,
            onPaginationChange: setPagination,
            getCoreRowModel: getCoreRowModel(),
            getSortedRowModel: getSortedRowModel(),
            enableRowSelection: true,
            manualPagination: true,
            manualSorting: true,
        }
    );

    const selectedCases = useMemo(() => {
        return getSelectedRows(table);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rowSelection]);

    const assignCasesToAgent = useCallback(async (agentId: string) => {
        await setDocumentsAgent({
            data: {
                managerId: agentId,
                documentIds: selectedCases.map(selectedCase => selectedCase.id),
            },
        });

        setRowSelection({});
    }, [selectedCases, setDocumentsAgent]);

    const isDataLoading = useMemo(() => {
        return isLoading || isFetching || isDocumentsAgentUpdating;
    }, [isLoading, isFetching, isDocumentsAgentUpdating]);

    return {
        isLoading: isDataLoading,
        table,
        selectedCases,
        assignCasesToAgent,
        pagination: useMemo(() => {
            return getPaginationProps(table, currentData?.pagination, "Unassigned Cases");
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [paginationData, currentData?.pagination]),
    };
};
