import { useCallback, useMemo } from "react";

import { useAppDispatch } from "../../redux/hooks";
import { useGetClientByIdQuery, useUpdateClientByIdMutation, useUpdateClientPaymentDetailsByIdMutation } from "../../services/clients";
import { getFile } from "../../services/files";
import { IClient } from "../../types/clients";

export const useClient = (clientId: string) => {
    const dispatch = useAppDispatch();

    const {
        data,
        isLoading: isLoadingDocument,
	    isFetching: isFetchingDocument,
    } = useGetClientByIdQuery({
	    clientId,
    });

    const [updateClientById, {
        isLoading: isLoadingClientUpdate,
    }] = useUpdateClientByIdMutation();

    const [updateClientPaymentDetailsById, {
        isLoading: isLoadingClientPaymentUpdate,
    }] = useUpdateClientPaymentDetailsByIdMutation();

    const isLoading = useMemo(() => {
        return isLoadingDocument ||
	        isFetchingDocument ||
	        isLoadingClientUpdate ||
            isLoadingClientPaymentUpdate;
    }, [
        isLoadingDocument,
	    isFetchingDocument,
	    isLoadingClientUpdate,
        isLoadingClientPaymentUpdate,
    ]);

    const getAgreementFile = useCallback(async () => {
        const client = data?.data.client as IClient;

        dispatch(getFile.initiate({
            url: client.agreementUrl,
            fileName: `${client.id}_agreement.pdf`,
            triggerDownload: true,
        }));
    }, [data?.data.client, dispatch]);

    return {
	    isLoading,
	    client: data?.data.client,
	    getAgreementFile,
	    updateClientById,
        updateClientPaymentDetailsById,
    };
};
