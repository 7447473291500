import {
    Questions,
    ValidationType,
} from "../components/pages/Questionnaire/tabs/QuestionnairePreview/components/builder/GroupItem";
import { ChildrenT, DependT, GroupT, QuestionnaireData, TabT } from "../types/questionnaires";

export type MessageT =
    | QuestionnaireData["forbiddenMessages"]
    | QuestionnaireData["allowMessages"];

export const checkDepend = (
    depend: DependT[],
    answers?: Record<string, any>
) => {
    if (!depend?.length || !answers) return true;

    for (const dependItem of depend) {
        const answerValue = answers[dependItem.id];

        if (Array.isArray(answerValue)) {
            if (answerValue.includes(dependItem.value)) {
                return true;
            }
        } else if (answerValue === dependItem.value) {
            return true;
        }
    }

    return false;
};

type CheckMessageDependencyResult = {
    message: string;
    affectTo: string;
} | null;

export const checkMessageDependency = (
    messages: MessageT,
    questionId: string,
    answer: any
): CheckMessageDependencyResult => {
    for (const message of messages) {
        const { depend, text, bindTo } = message;
        for (const dependItem of depend) {
            if (questionId === dependItem.id) {
                if (Array.isArray(answer)) {
                    if (answer.includes(dependItem.value)) {
                        return { message: text, affectTo: bindTo?.id || "" };
                    }
                } else if (answer === dependItem.value) {
                    return { message: text, affectTo: bindTo?.id || "" };
                }
            }
        }
    }
    return null;
};

export const getAllDependIds = (messages: MessageT): string[] => {
    const dependIds: string[] = [];
    messages.forEach(message => {
        message.depend.forEach(depend => {
            dependIds.push(depend.id);
        });
        message.andDepend.forEach(depend => {
            dependIds.push(depend.id);
        });
    });

    return dependIds;
};

export type BaseQuestion = {
    id: string;
    question: string;
    type: string | "arrayGroup" | "yearsGroup";
    answer: any;
};

export interface QuestionWithAnswer extends BaseQuestion {}

export interface ArrayQuestion {
    id: string;
    label: string;
    type: "arrayGroup";
    questions: QuestionWithAnswer[][];
}
export interface YearsQuestion {
    id: string;
    answer: Record<string, any>;
    type: "yearsGroup";
    question: string;
}

export type CombinedQuestion = BaseQuestion | ArrayQuestion | YearsQuestion

export type SubTabAnswers = {
    subTabLabel: string;
    questions: CombinedQuestion[];
};

export type TabAnswers = {
    tabLabel: string;
    subTabs: SubTabAnswers[];
};

const processChildren = (
    children: ChildrenT[] | undefined,
    answers: Record<string, any>
): any => {
    if (!children) return [];
    return children.map(child => {
        if (child.type === "arrayGroup") {
            const arrayAnswers: Record<string, any>[] = answers[child.id];

            const arrayGroupAnswers: Array<QuestionWithAnswer | null>[] =
                arrayAnswers?.map((answer: Record<string, any>) => {
                    return (
                        child.children
                            ?.map(child => {
                                const childAnswer = answer[child.id];

                                if (child.type === "infoBlock") {
                                    return {
                                        id: child.id,
                                        question: child.data.label || "",
                                        type: child.type,
                                        answer: "",
                                    };
                                }

                                if (typeof childAnswer === "undefined")
                                    return null;

                                return {
                                    id: child.id,
                                    question: child.data.question || "",
                                    type: child.type,
                                    answer: childAnswer,
                                };
                            })
                            .filter(a => a !== null) || []
                    );
                }) as Array<QuestionWithAnswer | null>[];

            if (!arrayGroupAnswers) return null;
            return {
                id: child.id,
                label: child.data.label || "",
                type: child.type,
                questions: arrayGroupAnswers,
            };
        }

        if (child.type === "infoBlock") {
            return {
                id: child.id,
                question: child.data.label || "",
                type: child.type,
                answer: "",
            };
        }
        const answer = answers[child.id];

        if (typeof answer === "undefined") return null;

        return {
            id: child.id,
            question: child.data.question || "",
            type: child.type,
            answer: answer,
        };
    });
};

export const createAnswersArray = (
    answers: Record<string, Record<string, any>>,
    tabs: TabT[]
): TabAnswers[] => {
    return tabs
        .map(tab => {
            const subTabsQuestions: QuestionWithAnswer[] = [];

            const subTabs: SubTabAnswers[] = (tab.children || []).map(subTab => {
                (subTab.children || []).forEach(group => {
                    const questions: QuestionWithAnswer[] = processChildren(
                        group.children,
                        answers[subTab.data.label!]
                    );
                    subTabsQuestions.push(...questions);
                    return {
                        groupLabel: group.data.label || "",
                        questions: questions,
                    };
                });

                return {
                    subTabLabel: subTab.data.label || "",
                    questions: subTabsQuestions.filter(Boolean),
                };
            });

            return {
                tabLabel: tab.data.label || "",
                subTabs: subTabs,
            };
        })
        .filter(tab => tab.subTabs.length);
};

export const getAnswerString = (answer: any, questionType?: string) => {
    if (typeof answer === "boolean") {
        return answer ? "yes" : "no";
    } else if (answer instanceof Date) {
        return answer.toLocaleString();
    } else if (Array.isArray(answer)) {
        if (questionType === "formulaNumberQuestion" || questionType === "yearsGroup") {
            if (answer.length === 2) {
                return `${answer[0]} (${answer[1]})`;
            } else {
                return answer.join(", ");
            }
        }
        return answer.join(", ");
    } else if (answer !== null && typeof answer === "object") {
        return Object.entries(answer)
            .filter(([, value]) => value)
            .map(([key, value]) => `${key} year: ${value}`)
            .join(", ");
    } else {
        return answer?.toString() || "-" ;
    }
};

export const getStepsCount = (tab: TabT, completedSubTabs: { [key: string]: boolean }) => {
    const totalSteps = tab.children?.length || 0;
    const completedSteps = tab.children?.filter(subTab => subTab.data.label && completedSubTabs[subTab.data.label]).length || 0;

    const stepLabel = totalSteps > 1 ? "steps" : "step";

    return completedSteps
        ? `${completedSteps} of ${totalSteps} ${stepLabel} complete`
        : `${totalSteps} ${stepLabel}`;
};

export const getTotalRequiredQuestions = (questions: Questions) => {
    return Object.values(questions).filter(q => q.required).length;
};

export const isAnswerValid = (answer?: string, regexString?: string): boolean => {
    if (!regexString || !answer) return true;
    const regex = new RegExp(regexString.slice(1, -1));
    return regex.test(answer);
};

const checkValidAnswer = (answer: any, validation?: ValidationType): boolean => {
    if (Array.isArray(answer)) {
        return answer.length > 0;
    } else if (typeof answer === "string") {
        const isValid = isAnswerValid(answer, validation?.regex) && answer.trim().length > 0;
        return isValid;
    } else {
        return answer !== undefined;
    }
};

export const getAnsweredRequiredQuestions = (
    questions: Questions,
    answers: Record<string, any>
) => {
    return Object.entries(questions).filter(([questionId, requirement]) => {
        if (requirement.required) {
            const answer = answers[questionId];
            const hasValidAnswer = checkValidAnswer(answer, requirement.validation);
            return hasValidAnswer;
        }
        return false;
    }).length;
};

export const isGroupShown = (group: GroupT, answers: Record<string, any>): boolean => {
    const show1 = checkDepend(group.depend, answers);
    const show2 = checkDepend(group.andDepend, answers);
    return show1 && show2;
};

export const shouldExpandSubTab = (subTab: SubTabAnswers) => {
    return subTab.questions.some(question => {
        if (question.type === "yearsGroup") {
            return Object.values(question.answer).some(answer =>
                answer && getAnswerString(answer) !== "no");
        } else if (question.type === "arrayGroup") {
            const typedQuestion = question as ArrayQuestion;
            return typedQuestion.questions.some(item =>
                item.some(q => q.answer && getAnswerString(q.answer) !== "no"));
        } else {
            const typedQuestion = question as BaseQuestion;
            return typedQuestion.answer && getAnswerString(typedQuestion.answer) !== "no";
        }
    });
};
