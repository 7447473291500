/* eslint-disable quotes */
import { useEffect, useState } from "react";

import { Col, Divider, Row } from "antd";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";

import { ArrayQuestion, BaseQuestion, TabAnswers, YearsQuestion, getAnswerString, shouldExpandSubTab } from "../../../../../../../utils/builder";

const SubmissionTable = ({
    document,
    sizes,
    years,
}: {
    document: any;
    years: string[];
    sizes: {
        main: number;
        column: number;
    };
}) => {
    const [expandedItems, setExpandedItems] = useState<string[]>([]);

    const toggleExpansion = (id: string) => {
        setExpandedItems(prevState => {
            if (prevState.includes(id)) {
                return prevState.filter(item => item !== id);
            } else {
                return [...prevState, id];
            }
        });
    };

    useEffect(() => {
        const newExpandedItems: string[] = [];
        document.answers?.convertedAnswers.forEach((tab: TabAnswers) => {
            tab.subTabs?.forEach((subTab, index) => {
                if (shouldExpandSubTab(subTab)) {
                    newExpandedItems.push(subTab.subTabLabel + "sub" + index);
                }
            });
        });
        setExpandedItems(newExpandedItems);
    }, [document.answers]);

    if (!document.answers) return null;
    if (!document.answers.constructorVersion) return <div>Prior submission is inaccessible for preview. Kindly initiate a new submission.</div>;
    if (document.answers.constructorVersion === 2) {
        return (
            <>
                {document.answers?.convertedAnswers.map(
                    (tab: TabAnswers, index: number) => {
                        return (
                            <div key={tab.tabLabel + index}>
                                <Row>
                                    <Col flex={`${sizes.main}%`}>
                                        <div className={"fs16 bold space-between"}>
                                            {tab.tabLabel}
                                        </div>
                                    </Col>
                                </Row>
                                {(tab.subTabs?.map((subTab, index) => {
                                    return (
                                        <div key={subTab.subTabLabel + "sub" + index}>
                                            <Row className="mt20">
                                                <Col flex={`${sizes.main}%`}>
                                                    <div
                                                        className={"fs14 bold pointer space-between p4 hover border-radius-4px"}
                                                        onClick={() => toggleExpansion(subTab.subTabLabel + "sub" + index)
                                                        }>
                                                        <div>
                                                            {subTab.subTabLabel}
                                                        </div>
                                                        <div className={"ml6 purple-color space-between"}>
                                                            {expandedItems.includes(subTab.subTabLabel + "sub" + index) ? <BsChevronUp/> : <BsChevronDown/>}
                                                        </div>
                                                    </div>
                                                </Col>
                                                {years.map(year =>
                                                    <Col key={year + "header"} flex={`${sizes.column}%`}>
                                                        <div className={"fs16 primary-color bold horizontal-right"}>
                                                            {year}
                                                        </div>
                                                    </Col>

                                                )}
                                            </Row>
                                            {expandedItems.includes(subTab.subTabLabel + "sub" + index) && (subTab?.questions.map(question => {
                                                if (question.type === "infoBlock") {
                                                    return null;
                                                } else if (question.type === "documentsUpload") {
                                                    return null;
                                                } else if (question.type === "yearsGroup") {
                                                    const typedQuestion = question as YearsQuestion;
                                                    return (
                                                        <Row
                                                            key={question.id}
                                                            className={"mt10"}
                                                        >
                                                            <Col flex={`${sizes.main}%`}>
                                                                <div className={"fs12 purple-color"}>
                                                                    {typedQuestion.question}
                                                                </div>
                                                            </Col>

                                                            {years.map((year, j) =>
                                                                <Col key={year + j} flex={`${sizes.column}%`}>
                                                                    <div className={"fs12 grey-7-color text-right bold horizontal-right"}>
                                                                        {typedQuestion.answer[year]
                                                                            ? getAnswerString(typedQuestion.answer[year], question.type)
                                                                            : "-"}
                                                                    </div>
                                                                </Col>
                                                            )}
                                                        </Row>
                                                    );
                                                } else if (question.type === "arrayGroup") {
                                                    const typedQuestion = question as ArrayQuestion;
                                                    return <div key={question.id}>
                                                        {typedQuestion.questions.map((item, i) => {
                                                            return (
                                                                <div key={question.id + i}>
                                                                    <Row className={"mt10"}>
                                                                        <Col flex={`${sizes.main}%`}>
                                                                            <div className={"fs12 purple-color"}>
                                                                                {typedQuestion.label + ': "' + item[0].answer + '" '}
                                                                                <span
                                                                                    className={"blue-color see-details"}
                                                                                    onClick={() => toggleExpansion(typedQuestion.id + i)}
                                                                                >
                                                                                    see details
                                                                                </span>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                    {expandedItems.includes(typedQuestion.id + i) && (
                                                                        <div>
                                                                            {item.map(question => {
                                                                                if (question.type === "yearsGroup") {
                                                                                    const typedQuestion = question as YearsQuestion;
                                                                                    return (
                                                                                        <Row
                                                                                            key={question.id}
                                                                                            className={"mt10"}
                                                                                        >
                                                                                            <Col flex={`${sizes.main}%`}>
                                                                                                <div className={"fs12 purple-color pl10"}>
                                                                                                    {typedQuestion.question}
                                                                                                </div>
                                                                                            </Col>

                                                                                            {years.map((year, j) =>
                                                                                                <Col key={year + j} flex={`${sizes.column}%`}>
                                                                                                    <div className={"fs12 grey-7-color text-right bold horizontal-right"}>
                                                                                                        {typedQuestion.answer[year]
                                                                                                            ? getAnswerString(typedQuestion.answer[year], question.type)
                                                                                                            : "-"}
                                                                                                    </div>
                                                                                                </Col>
                                                                                            )}
                                                                                        </Row>
                                                                                    );
                                                                                }
                                                                                return (
                                                                                    <Row
                                                                                        key={question.id + i}
                                                                                        className={"mt10"}
                                                                                    >
                                                                                        <Col flex={`${sizes.main}%`}>
                                                                                            <div className={"fs12 purple-color pl10"}>
                                                                                                {question.question}
                                                                                            </div>
                                                                                        </Col>

                                                                                        {years.map(
                                                                                            (year, j) => {
                                                                                                return (
                                                                                                    <Col
                                                                                                        key={year + j}
                                                                                                        flex={`${sizes.column}%`}
                                                                                                    >
                                                                                                        <div className={"fs12 grey-7-color text-right bold horizontal-right"}>
                                                                                                            {document.year === year
                                                                                                                ? getAnswerString(question.answer, question.type)
                                                                                                                : "-"}
                                                                                                        </div>
                                                                                                    </Col>
                                                                                                );
                                                                                            }
                                                                                        )}
                                                                                    </Row>
                                                                                );
                                                                            })}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            );
                                                        })
                                                        }
                                                    </div>;
                                                } else {
                                                    const typedQuestion = question as BaseQuestion;

                                                    return (
                                                        <Row
                                                            key={question.id}
                                                            className={"mt10"}
                                                        >
                                                            <Col flex={`${sizes.main}%`}>
                                                                <div className={`fs12 purple-color ${question.type === "infoBlock" ? "fw500" : ""}`}>
                                                                    {typedQuestion.question}
                                                                </div>
                                                            </Col>

                                                            {question.type !== "infoBlock" && years.map(
                                                                (year, j) => {
                                                                    return (
                                                                        <Col
                                                                            key={year + j}
                                                                            flex={`${sizes.column}%`}
                                                                        >
                                                                            <div className={"fs12 grey-7-color text-right bold horizontal-right"}>
                                                                                {document.year === year
                                                                                    ? getAnswerString(typedQuestion.answer, question.type)
                                                                                    : "-"}
                                                                            </div>
                                                                        </Col>
                                                                    );
                                                                }
                                                            )}
                                                        </Row>
                                                    );
                                                }
                                            }))}
                                        </div>
                                    );
                                }))}
                                <Divider type={"horizontal"} />
                            </div>
                        );
                    }
                )}
            </>
        );
    }
    return null;
};

export default SubmissionTable;
