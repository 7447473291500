import React, { useContext } from "react";

import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";

import { useModalOpener } from "../../../../../../hooks/helpers/useModalOpener";
import { ICreateClientLogData } from "../../../../../../types/client-logs";
import CreateClientLogModal from "../../../../../modals/Logs/CreateClientLogModal";
import { CaseContext } from "../../../Case";

const LogsHeader = ({
    createClientLog,
}: {
    createClientLog: (_data: ICreateClientLogData) => void;
}) => {
    const { documentId } = useContext(CaseContext);

    const {
        isOpened: createClientLogModalIsOpened,
        open: openCreateClientLogModal,
        close: closeCreateClientLogModal,
    } = useModalOpener();

    return <div className={"p10 space-between"}>
        <div className={"horizontal-left"}>
            <div className={"h2"}>
                Event Logs
            </div>
        </div>
        <div className={"horizontal-right"}>
            <div>
                <Button
                    disabled={!documentId}
                    type={"primary"}
                    className={"inverted-button"}
                    style={{ width: 200, height: 36 }}
                    icon={<PlusOutlined/>}
                    onClick={openCreateClientLogModal}
                >
                    Add Comment Event
                </Button>
            </div>
        </div>

        {createClientLogModalIsOpened && <CreateClientLogModal
            close={closeCreateClientLogModal}
            onCreate={(data: ICreateClientLogData) => {
                createClientLog({
                    ...data,
                    documentId: documentId!,
                });
            }}
        />}
    </div>;
};

export default LogsHeader;
