import React, { ReactElement } from "react";

interface IActionProps {
    action: () => void;
    icon: ReactElement;
    label: string;
}

const EditButtonLabel = ({
    icon,
    label,
}: {
    icon: ReactElement,
    label: string;
}) => {
    return <div className={"horizontal-left"}>
        <div className={"fs16 mt4"}>
            {icon}
        </div>
        <div className={"ml4"}>
            {label}
        </div>
    </div>;
};

const EditButton = ({
    isEditMode,
    permissionIsGranted = true,
    start,
    finish,
    cancel,
}: {
    isEditMode: boolean;
    permissionIsGranted?: boolean;
    start: IActionProps;
    finish: IActionProps;
    cancel?: IActionProps;
}) => {
    return <div className="horizontal-right">
        {isEditMode && cancel && <div
            data-testid={"cancel-edit-button"}
            className={permissionIsGranted ? "questionnaire-edit-button mr4" : "questionnaire-edit-button-disabled mr4"}
            onClick={() => cancel.action()}
        >
            <EditButtonLabel
                icon={cancel.icon}
                label={cancel.label}
            />
        </div>}
        <div
            data-testid={"edit-button"}
            className={permissionIsGranted ? "questionnaire-edit-button" : "questionnaire-edit-button-disabled"}
            onClick={() => {
                if (permissionIsGranted) {
                    if (isEditMode) {
                        finish.action();
                    } else {
                        start.action();
                    }
                }
            }}
        >
            {isEditMode
                ? <EditButtonLabel
                    icon={finish.icon}
                    label={finish.label}
                />
                : <EditButtonLabel
                    icon={start.icon}
                    label={start.label}
                />}
        </div>
    </div>;
};

export default EditButton;
