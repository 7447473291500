import React, { useContext } from "react";

import { LeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

import { ClientContext } from "../Client";

const VerticalDivider = () => {
    return <div style={{ height: 30 }} className={"ml20 border-left-1px"}/>;
};

const ClientHeader = () => {
    const {
        client,
    } = useContext(ClientContext);

    const navigate = useNavigate();

    return <div
        data-testid="header"
        className={"page-header"}
    >
        <div
            className={"page-header-block space-between white-background-color"}
        >
            {client && <div className={"horizontal-left"}>
                <div
                    className={"horizontal-left pointer bold"}
                    onClick={() => navigate("/clients/list")}
                >
                    <div className={"ml20 primary-color"}>
                        <LeftOutlined />
                    </div>
                    <div className={"ml10 primary-color"}>
                        Client Details
                    </div>
                </div>

                <div className={"ml40 horizontal-left"}>
                    <div className={"grey-7-color"}>
                        {client.info.firstName} {client.info.lastName}
                    </div>

                    <VerticalDivider/>

                    <div className={"ml20 grey-7-color"}>
                        {client.ppsn}
                    </div>

                    <VerticalDivider/>

                    <div className={"ml20 grey-7-color"}>
                        {client.info.phone || "-"}
                    </div>

                    <VerticalDivider/>

                    <div className={"ml20 grey-7-color"}>
                        {client.info.email || "-"}
                    </div>
                </div>
            </div>}
        </div>
    </div>;
};

export default ClientHeader;
