import {
    IAssignCaseToTransactionBody,
    IAssignCaseToTransactionResponse,
    IGetClientTransactionsBody,
    IGetClientTransactionsResponse,
} from "../../types/transactions";
import { getClientTransactionsQueryString } from "../../utils/queries";
import { api } from "../connection/api";

const PARENT_ENDPOINT_PREFIX = "clients";
const ENDPOINT_PREFIX = "transactions";

export const clientTransactionsApi = api.injectEndpoints({
    endpoints: build => ({
        getClientTransactions: build.query<IGetClientTransactionsResponse, IGetClientTransactionsBody>({
            query: data => ({
                url: `${PARENT_ENDPOINT_PREFIX}/${data.clientId}/${ENDPOINT_PREFIX}?${getClientTransactionsQueryString(data.query)}`,
            }),
            providesTags: ["ClientTransactions"],
        }),
        assignCaseToTransaction: build.mutation<IAssignCaseToTransactionResponse, IAssignCaseToTransactionBody>({
            query: data => ({
                url: `${PARENT_ENDPOINT_PREFIX}/${data.clientId}/${ENDPOINT_PREFIX}/${data.transactionId}/assign`,
                method: "PATCH",
                body: data.data,
            }),
            invalidatesTags: ["ClientTransactions", "ClientDocuments", "Document"],
        }),
    }),

});

export const {
    useGetClientTransactionsQuery,
    useAssignCaseToTransactionMutation,
} = clientTransactionsApi;
