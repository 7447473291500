import { api } from "./connection/api";
import {
    IGetFinancialStatsQuery,
    IGetFinancialStatsResponse,
    IGetOperationsStatsQuery,
    IGetOperationsStatsResponse,
} from "../types/stats";

const ENDPOINT_PREFIX = "stats";

export const statsApi = api.injectEndpoints({
    endpoints: build => ({
        getFinancialStats: build.query<IGetFinancialStatsResponse, IGetFinancialStatsQuery>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/financial-stats?year=${data.year}`,
            }),
            providesTags: [],
        }),
        getOperationsStats: build.query<IGetOperationsStatsResponse, IGetOperationsStatsQuery>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/operations-stats?day=${data.day}`,
            }),
            providesTags: [],
        }),
    }),

});

export const {
    useGetFinancialStatsQuery,
    useGetOperationsStatsQuery,
} = statsApi;
