import React, { useCallback, useMemo, useState } from "react";

import { CheckOutlined, CloseOutlined } from "@ant-design/icons/lib/icons";
import { Input, Typography } from "antd";
import { NavLink } from "react-router-dom";

import { IClient } from "../../../../../../../../types/clients";
import {
    ITransaction,
} from "../../../../../../../../types/transactions";

const TaxNumber = ({
    assignClientToTransaction,
    value,
}: {
    assignClientToTransaction: (_transactionId: string, _ppsn: string) => void;
	value: ITransaction;
}) => {
    const client = useMemo(() => (value.client as IClient), [value.client]);

    const [isEditing, setIsEditing] = useState(false);
    const [ppsn, setPpsn] = useState("");

    const reset = useCallback(() => {
        setIsEditing(false);
        setPpsn("");
    }, []);

    return <div className={"horizontal-center"}>
	    {client ? <NavLink
            className={"pointer"}
            to={{ pathname: `/client/${client.id}/details` }}
        >
            <Typography.Text
                className={"blue-color horizontal-center"}
                strong
                copyable
                underline
            >
                {client.ppsn}
            </Typography.Text>
        </NavLink> : <div>
            {!isEditing ? <div
                className={"blue-color pointer underline"}
                onClick={() => setIsEditing(true)}
            >
                Set PPSN...
            </div> : <div>
                <div className={"horizontal-center"}>
                    <div>
                        <Input
                            style={{ width: 100 }}
                            value={ppsn}
                            onChange={e => {
                                setPpsn(e.target.value);
                            }}
                        />
                    </div>
                    <div className={"ml6 center"}>
                        <CheckOutlined
                            className={"fs16 primary-color pointer"}
                            onClick={() => {
                                assignClientToTransaction(value._id, ppsn);
                                reset();
                            }}
                        />
                    </div>
                    <div className={"ml6 center"}>
                        <CloseOutlined
                            className={"fs16 red-color pointer"}
                            onClick={() => {
                                reset();
                            }}
                        />
                    </div>
                </div>
            </div>}
        </div>}
    </div>;
};

export default TaxNumber;
