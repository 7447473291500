import { FC } from "react";

import { Typography, Modal, Button } from "antd";

import { useModalOpener } from "../../../../../../../hooks/helpers/useModalOpener";

type InfoClickBlockProps = {
    data: {
        label?: string;
        title?: string;
        message?: string;
    };
};

const InfoClickBlock: FC<InfoClickBlockProps> = ({ data }) => {
    const {
        isOpened: open,
        open: handleOpenModal,
        close: handleCloseModal,
    } = useModalOpener();

    return (
        <div className="builder__info-click-block">
            <Typography
                onClick={handleOpenModal}
                style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    color: "#2EBB49",
                }}
            >
                {data.label}
            </Typography>

            <Modal
                open={open}
                width={"325px"}
                centered={true}
                closable={false}
                footer={
                    <Button
                        className="builder__done-button"
                        onClick={handleCloseModal}
                    >
                        Done
                    </Button>
                }
                onCancel={handleCloseModal}
            >
                <Typography
                    className="builder__form-title"
                    style={{
                        whiteSpace: "break-spaces",
                        marginBottom: "30px",
                    }}
                >
                    {data.title?.toString()}
                </Typography>
                <div style={{
                    maxHeight: "400px",
                    overflowY: "auto",
                }}>
                    <Typography
                        className="builder__form-question"
                        style={{
                            whiteSpace: "break-spaces",
                            marginBottom: "30px",
                        }}
                    >
                        {data.message?.toString()}
                    </Typography>
                </div>
            </Modal>
        </div>
    );
};

export default InfoClickBlock;
