import { useState } from "react";

import { CloseCircleOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Input } from "antd";
import { useDebouncedCallback } from "use-debounce";

import { usePermissionGranter } from "../../../../../../hooks/common/usePermissionGranter";
import { useModalOpener } from "../../../../../../hooks/helpers/useModalOpener";
import { IGlobalFilterProps } from "../../../../../../types/common";
import CreateAgentModal from "../../../../../modals/Agents/CreateAgentModal";

const UsersHeader = ({
    globalFilter,
}: {
    globalFilter: IGlobalFilterProps
}) => {
    const {
        open: openCreateAgentModal,
        isOpened: createAgentModalIsOpened,
        close: closeCreateAgentModal,
    } = useModalOpener();

    const createAgentPermissionIsGranted = usePermissionGranter("users:people", "create");

    const [globalFilterValue, setGlobalFilterValue] = useState(globalFilter.globalFilter);

    const handleSearchChange = useDebouncedCallback((value: string) => {
        return globalFilter.setGlobalFilter(value);
    }, 500);

    return <div className={"p10 space-between"}>
        <div>
            <Input
                value={globalFilterValue}
                size="large"
                placeholder="Search"
                allowClear={{
                    clearIcon: <div
                        onClick={() => {
                            setGlobalFilterValue("");
                            globalFilter.setGlobalFilter("");
                        }}
                    >
                        <CloseCircleOutlined />
                    </div>,
                }}
                onChange={e => {
                    setGlobalFilterValue(e.target.value);
                    handleSearchChange(e.target.value);
                }}
                prefix={<SearchOutlined />}
            />
        </div>

        <div>
            {createAgentPermissionIsGranted && <Button
                type={"primary"}
                className={"primary-button"}
                style={{ width: 140, height: 36 }}
                icon={<PlusOutlined/>}
                onClick={openCreateAgentModal}
            >
				Add agent
            </Button>}
        </div>

        {createAgentModalIsOpened && <CreateAgentModal
            close={closeCreateAgentModal}
        />}

    </div>;
};

export default UsersHeader;
