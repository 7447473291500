import { Table, flexRender } from "@tanstack/react-table";

import { IUser } from "../../../../../../../types/users";

const ROW_HEIGHT = 60;

const UsersTable = ({
    table,
}: {
	table: Table<IUser>;
}) => {
    return <div className={"p10"}>
        <table>
            <thead>
                {table.getHeaderGroups().map(headerGroup => (
                    <tr key={headerGroup.id} style={{ height: ROW_HEIGHT }}>
                        {headerGroup.headers.map(header => (
                            <th key={header.id}>
                                {header.isPlaceholder
                                    ? null
                                    : flexRender(
                                        header.column.columnDef.header,
                                        header.getContext()
                                    )}
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody>
                {table.getRowModel().rows.map(row => (
                    <tr key={row.id} className={"border-top-1px"} style={{ height: ROW_HEIGHT }}>
                        {row.getVisibleCells().map(cell => (
                            <td
                                key={cell.id}
                                className={"fs14 grey-7-color"}
                            >
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    </div>;
};

export default UsersTable;
