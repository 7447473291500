import React from "react";

import { Modal } from "antd";

import { IFile } from "../../../types/files";
import Spinner from "../../basic/Spinner/Spinner";
import CategoryFiles from "../../common/Files/CategoryFiles";

const RevenueLettersModal = ({
    isLoading,
    revenueLetters,
    getRevenueLetterFile,
    uploadRevenueLetter,
    deleteRevenueLetter,
    close,
}: {
    isLoading: boolean;
    revenueLetters: IFile[];
    getRevenueLetterFile: (_url: string, _originalName: string) => Promise<void>;
    uploadRevenueLetter: (_file: File) => Promise<boolean>;
    deleteRevenueLetter: (_revenueLetterId: string) => Promise<boolean>;
    close: () => void;
}) => {
    return <Modal
        open
        width={900}
        title={
            <div className={"p20 modal-header grey-7-color"}>
                Revenue letters
            </div>
        }
        onCancel={() => close()}
        footer={null}
    >
        <Spinner isLoading={isLoading}>
            <div className={"p20"}>
                <CategoryFiles
                    files={revenueLetters}
                    uploadFile={file => uploadRevenueLetter(file)}
                    deleteFile={id => deleteRevenueLetter(id)}
                    getFile={getRevenueLetterFile}
                />
            </div>
        </Spinner>
    </Modal>;
};

export default RevenueLettersModal;
