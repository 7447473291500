import { useContext } from "react";

import { Col, Row } from "antd";
import { Navigate } from "react-router-dom";

import AuthPicture from "./components/AuthPicture";
import SignIn from "./components/SignIn";
import { AppContext } from "../../../App";
import Logo from "../../../assets/icons/Logo";
import Spinner from "../../basic/Spinner/Spinner";

const Auth = () => {
    const { isAuth } = useContext(AppContext);

    if (isAuth)
        return <Navigate replace to={"/"}/>;

    if (!isAuth)
        return (
            <div>
                <Row style={{ height: "100vh" }}>
                    <Col span={12} className={"center primary-extra-light-background-color"}>
                        <div>
                            <AuthPicture/>
                        </div>
                    </Col>
                    <Col span={12} className={"horizontal-center"}>
                        <div>
                            <div
                                style={{ height: "15vh" }}
                                className={"center"}
                            >
                                <Logo/>
                            </div>
                            <div
                                style={{ height: "75vh" }}
                                className={"center"}
                            >
                                <SignIn/>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        );

    return <div style={{ width: "100vw" }}>
        <Spinner isLoading/>
    </div>;
};

export default Auth;
