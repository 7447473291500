import { Checkbox, Form } from "antd";

const ShouldChangePasswordCheckbox = ({
    disabled,
}: {
    disabled: boolean;
}) => {
    return <div>
        <Form.Item
            name="shouldChangePassword"
            valuePropName="checked"
            noStyle
        >
            <Checkbox
                className={"vertical-center"}
                disabled={disabled}
            >
                <div style={{ paddingTop: 6 }}>
                    Require password reset
                </div>
            </Checkbox>
        </Form.Item>
        <div className={"h6 grey-color mt10"}>
            * User must create a new password at next sign-in
        </div>
    </div>;
};

export default ShouldChangePasswordCheckbox;
