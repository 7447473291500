import { useState } from "react";

import EyeInvisibleOutlined from "@ant-design/icons/lib/icons/EyeInvisibleOutlined";
import EyeOutlined from "@ant-design/icons/lib/icons/EyeOutlined";
import { Form, Input } from "antd";

import Label from "./../Labels/Label";
import Error from "../Labels/Error";

const PasswordInput = ({
    required = false,
    name = "password",
    label = "Password",
    min = 2,
}: {
    required?: boolean;
    name?: string;
    label?: string;
    min?: number;
}) => {
    const [passwordInputType, setPasswordInputType] = useState("password");

    return <div>
        <Form.Item
            hasFeedback
            name={name}
            label={<Label text={label}/>}
            required={required}
            labelAlign={"left"}
            rules={[{
                required,
                message: <Error text={"Please input the password!"}/>,
            }, {
                min: min,
                message: <Error text={`Password must contain at least ${min} characters!`}/>,
            }]}>
            <Input
                type={passwordInputType}
                placeholder={"Enter password..."}
                data-testid={name}
                className={"input-content dark-grey-color"}
                size="large"
                suffix={passwordInputType === "password"
                    ? <EyeInvisibleOutlined onClick={() => setPasswordInputType("text")}/>
                    : <EyeOutlined onClick={() => setPasswordInputType("password")}/>
                }
            />
        </Form.Item>
    </div>;
};

export default PasswordInput;
