const Logo = ({
    height = 50,
    width = 186,
    withLabel = true,
}: {
    height?: number;
    width?: number;
    withLabel?: boolean;
}) => {
    return <div className={"horizontal-center"}>
        <div style={{ width }} className={"horizontal-center"}>
            {withLabel && <div>
                <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none">
                    <path
                        d="M9.78528 12.9998V24.3778C9.78528 25.1703 9.96925 25.7505 10.3372 26.1184C10.7334 26.4581 11.3844 26.6279 12.2901 26.6279H15.8564V32.2368H11.6109C6.60115 32.2368 3.79911 29.2035 3.79911 24.3353V12.9998H0.99707V8.11747H3.79911V0.395508H9.78528V8.11747H15.0497V12.9998H9.78528Z"
                        fill="#2EBB49"/>
                    <path
                        d="M16.5227 19.7926C16.5227 17.4151 16.9897 15.3065 17.9237 13.4668C18.886 11.6271 20.1738 10.2119 21.7871 9.2213C23.4287 8.23068 25.2543 7.73537 27.2639 7.73537C29.0187 7.73537 30.5471 8.08916 31.849 8.79675C33.1793 9.50434 34.2406 10.3959 35.0331 11.4714V8.11747H41.0193V32.2368H35.0331V28.1987C34.2689 29.3026 33.2076 30.2224 31.849 30.9583C30.5187 31.6659 29.2863 32.2368 27.5315 32.2368C25.5503 32.2368 23.4287 31.5102 21.7871 30.4913C20.1738 29.4724 18.886 28.0431 17.9237 26.2034C16.9897 24.3353 16.5227 22.1984 16.5227 19.7926ZM35.0331 19.8775C35.0331 18.4341 34.7501 17.2029 34.184 16.1839C33.618 15.1367 32.8538 14.3442 31.8915 13.8065C30.9291 13.2404 29.8961 12.9574 28.7922 12.9574C27.6884 12.9574 26.6695 13.2262 25.7355 13.764C24.8015 14.3018 24.0373 15.0943 23.4429 16.1415C22.8768 17.1604 22.5938 18.3775 22.5938 19.7926C22.5938 21.2078 22.8768 22.4531 23.4429 23.5287C24.0373 24.5759 24.8015 25.3826 25.7355 25.9486C26.6978 26.5147 27.7167 26.7977 28.7922 26.7977C29.8961 26.7977 30.9291 26.5288 31.8915 25.9911C32.8538 25.425 33.618 24.6325 34.184 23.6136C34.7501 22.5664 35.0331 21.321 35.0331 19.8775Z"
                        fill="#2EBB49"/>
                    <path
                        d="M58.3115 32.2368L53.7021 24.3353L48.7591 32.2368H42.3908L50.7303 19.8351L42.961 8.11747H49.6689L54.4663 15.3773L58.7967 8.11747H65.165L57.4382 19.8351L66.8818 32.2368H58.3115Z"
                        fill="#2EBB49"/>
                    <path
                        d="M73.7794 26.7553H84.9252V32.2368H66.8818L67.029 26.8402L77.3032 12.9998H67.0715V8.11747H84.1385V12.9149L73.7794 26.7553Z"
                        fill="#2EBB49"/>
                    <path
                        d="M84.9252 19.7926C84.9252 17.4151 85.3922 15.3065 86.3262 13.4668C87.2885 11.6271 88.5763 10.2119 90.1896 9.2213C91.8312 8.23068 93.6568 7.73537 95.6664 7.73537C97.4212 7.73537 98.9495 8.08916 100.251 8.79675C101.582 9.50434 102.643 10.3959 103.436 11.4714V8.11747H109.422V32.2368H103.436V28.1987C102.671 29.3026 101.61 30.2224 100.251 30.9583C98.9212 31.6659 97.2145 32.2368 95.4597 32.2368C93.4785 32.2368 91.8312 31.5102 90.1896 30.4913C88.5763 29.4724 87.2885 28.0431 86.3262 26.2034C85.3922 24.3353 84.9252 22.1984 84.9252 19.7926ZM103.436 19.8775C103.436 18.4341 103.153 17.2029 102.587 16.1839C102.02 15.1367 101.256 14.3442 100.294 13.8065C99.3316 13.2404 98.2986 12.9574 97.1947 12.9574C96.0909 12.9574 95.072 13.2262 94.138 13.764C93.2039 14.3018 92.4398 15.0943 91.8454 16.1415C91.2793 17.1604 90.9963 18.3775 90.9963 19.7926C90.9963 21.2078 91.2793 22.4531 91.8454 23.5287C92.4398 24.5759 93.2039 25.3826 94.138 25.9486C95.1003 26.5147 96.1192 26.7977 97.1947 26.7977C98.2986 26.7977 99.3316 26.5288 100.294 25.9911C101.256 25.425 102.02 24.6325 102.587 23.6136C103.153 22.5664 103.436 21.321 103.436 19.8775Z"
                        fill="#2EBB49"/>
                    <path
                        d="M119.897 11.5139C120.661 10.4383 121.708 9.54679 123.039 8.83921C124.397 8.10332 125.94 7.73537 127.666 7.73537C129.676 7.73537 131.487 8.23068 133.1 9.2213C134.742 10.2119 136.03 11.6271 136.964 13.4668C137.926 15.2782 138.554 17.0945 138.554 19.5003C138.554 21.9061 137.926 24.3353 136.964 26.2034C136.03 28.0431 134.742 29.4724 133.1 30.4913C131.487 31.5102 130.372 32.2368 128.362 32.2368C126.636 32.2368 124.411 31.6659 123.081 30.9583C121.779 30.2507 120.718 29.3592 119.897 28.2837L119.871 38.6051H113.953V8.11747H119.897V11.5139ZM132.336 19.7926C132.336 18.3775 132.039 17.1604 131.445 16.1415C130.879 15.0943 130.114 14.3018 129.152 13.764C128.218 13.2262 127.199 12.9574 126.095 12.9574C125.02 12.9574 124.001 13.2404 123.039 13.8065C122.105 14.3442 121.34 15.1367 120.746 16.1839C120.18 17.2312 119.897 18.4624 119.897 19.8775C119.897 21.2927 120.18 22.5239 120.746 23.5711C121.34 24.6184 122.105 25.425 123.039 25.9911C124.001 26.5288 125.02 26.7977 126.095 26.7977C127.199 26.7977 128.218 26.5147 129.152 25.9486C130.114 25.3826 130.879 24.5759 131.445 23.5287C132.039 22.4815 132.336 21.2361 132.336 19.7926Z"
                        fill="#2EBB49"/>
                </svg>
            </div>}

            <div style={{ marginLeft: withLabel ? -40 : 4 }}>
                <svg xmlns="http://www.w3.org/2000/svg" width={36} height={height} fill="none">
                    <path fillRule="evenodd" clipRule="evenodd"
                        d="M8.0064 22.3317C8.11569 22.4768 8.175 22.6534 8.17541 22.835L8.20932 37.766C8.21089 38.4578 9.00136 38.8509 9.55372 38.4346L23.7292 27.7511C24.0994 27.472 24.1734 26.9457 23.8944 26.5754L16.4375 16.6783C16.3282 16.5332 16.2688 16.3566 16.2684 16.175L16.2345 1.23459C16.2329 0.542841 15.4425 0.149756 14.8901 0.566052L8.13694 5.65569C8.1366 5.65595 8.13694 5.65527 8.13694 5.65569L8.13475 5.65856L0.710232 11.253C0.340023 11.532 0.266064 12.0584 0.545042 12.4287L8.0064 22.3317Z"
                        fill="#2EBB49"/>
                    <path fillRule="evenodd" clipRule="evenodd"
                        d="M15.8423 22.3317C15.9516 22.4768 16.0109 22.6534 16.0113 22.835L16.0453 37.766C16.0468 38.4578 16.8373 38.8509 17.3897 38.4346L31.5651 27.7511C31.9353 27.472 32.0093 26.9457 31.7303 26.5754L24.2734 16.6783C24.1641 16.5332 24.1048 16.3566 24.1044 16.175L24.0704 1.23459C24.0689 0.542841 23.2784 0.149756 22.726 0.566052L15.9729 5.65569C15.9725 5.65595 15.9729 5.65527 15.9729 5.65569L15.9707 5.65856L8.54617 11.253C8.17596 11.532 8.102 12.0584 8.38098 12.4287L15.8423 22.3317Z"
                        fill="#7F43FF"/>
                    <path fillRule="evenodd" clipRule="evenodd"
                        d="M15.9977 22.8182C15.9973 22.6366 15.938 22.4601 15.8288 22.315L8.36727 12.4118C8.08834 12.0416 8.1623 11.5153 8.53249 11.2364L15.9558 5.64165C15.9562 5.64134 15.9568 5.64143 15.9571 5.64183C15.9576 5.64254 15.9587 5.64216 15.9587 5.64128L15.9587 5.64003C15.9587 5.63961 15.9589 5.63921 15.9593 5.63896L16.2305 5.43457L16.2542 15.8785C16.255 16.2417 16.3736 16.5949 16.5921 16.8849L23.3758 25.8885C23.9337 26.6289 23.7858 27.6814 23.0454 28.2394L16.2916 33.3295L16.2916 33.3295L16.2915 33.3295L16.0221 33.5326L15.9977 22.8182Z"
                        fill="#165724"/>
                </svg>
            </div>
        </div>

    </div>;
};

export default Logo;
