import { Row } from "@tanstack/react-table";
import { GoDotFill } from "react-icons/go";

import { IClient } from "../../../../../../types/clients";
import { IDocument } from "../../../../../../types/documents";

const StatusCell = ({
    value,
    row,
}: {
    value: string;
    row: Row<IDocument>
}) => {
    const { status, files } = row.original;
    const client = row.original.client as IClient;
    const infoProvided =
        (status.id === "ppsn-error" && client.additionalInfo?.ppsn) ||
        (status.id === "payment-ppsn" && client.additionalInfo?.ppsn) ||
        (status.id === "payment-iban" && client.additionalInfo?.iban) ||
        (status.id === "name-error" && client.additionalInfo?.name) ||
        (status.id === "awaiting-mortgage" && files?.Mortgage?.docs.length);

    const statusComponent = infoProvided
        ? <GoDotFill style={{ color: "red" }}/>
        : null;
    return (
        <>
            {statusComponent
                ? <div className={"space-between"}>
                    {statusComponent}
                    <div className={"horizontal-center"}>
                        {value}
                    </div>
                    <span style={{ width: "14px" }}/>
                </div>
                : <div className={"horizontal-center"}>
                    {value}
                </div>
            }
        </>
    );
};

export default StatusCell;
