import { useMemo, useState } from "react";

import { Button, message, Modal, Select } from "antd";

import { GROUPED_SUB_STATUSES } from "../../../constants/documents";
import {
    ISubStatuses,
    IUpdateDocumentSubStatusByIdData,
    SubStatusType,
} from "../../../types/documents";

const SetCaseSubStatusModal = ({
    subStatuses,
    subStatusId,
    close,
    updateDocumentSubStatus,
}: {
    subStatuses: ISubStatuses;
    subStatusId: SubStatusType;
    close: () => void;
    updateDocumentSubStatus: (_data: IUpdateDocumentSubStatusByIdData) => void;
}) => {
    const subStatusCurrentValue = useMemo(() => {
        return subStatuses[subStatusId as keyof ISubStatuses];
    }, [subStatusId, subStatuses]);

    const [subStatusValue, setSubStatusValue] = useState<string>(subStatusCurrentValue);

    return <Modal
        open
        width={500}
        title={
            <div className={"p20 modal-header grey-7-color"}>
                Change Sub-status
            </div>
        }
        destroyOnClose
        onCancel={() => close()}
        footer={
            <div
                style={{ height: 40 }} className={"space-between"}>
                <Button
                    type={"link"}
                    onClick={() => close()}
                    className={"default-button"}
                >
                    Cancel
                </Button>

                <Button
                    disabled={!subStatusValue}
                    htmlType="submit"
                    onClick={async () => {
                        if (subStatusValue === subStatusCurrentValue) {
                            return message.error("You cannot set current sub-status.");
                        }

                        if (subStatusValue) {
                            updateDocumentSubStatus({
                                subStatusId: subStatusId as keyof ISubStatuses,
                                subStatusValue,
                            });
                        }

                        close();
                    }}
                    type={"primary"}
                    className={"primary-button"}
                >
                    Save
                </Button>
            </div>
        }
    >
        <div className={"p20"}>
            <div>
                <div className={"grey-7-color"}>
                    Sub status
                </div>

                <div className={"mt10"}>
                    <Select
                        value={subStatusValue}
                        onSelect={(value: string) => setSubStatusValue(value)}
                        size={"large"}
                        className={"select-content grey-color"}
                        style={{ width: "100%" }}
                        placeholder={<span className={"fs14"}>Select sub-status...</span>}
                    >
                        {GROUPED_SUB_STATUSES[subStatusId]
                            .map(status => {
                                return (
                                    <Select.Option
                                        key={status.id}
                                        value={status.id}
                                        className={"select-option"}
                                    >
                                        {status.title}
                                    </Select.Option>
                                );
                            })}
                    </Select>
                </div>
            </div>
        </div>
    </Modal>;
};

export default SetCaseSubStatusModal;
