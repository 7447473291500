import { useMemo } from "react";

import { GoDotFill } from "react-icons/go";

import { LOG_VARIATIONS_DICT } from "../../../../../../../../../constants/client-logs";
import { ClientLogVariation } from "../../../../../../../../../types/client-logs";

const Level = ({
    value,
}: {
	value: ClientLogVariation;
}) => {
    const levelData = useMemo(() => LOG_VARIATIONS_DICT[value], [value]);

    return <div className={"horizontal-left"}>
	    <div
		    className={levelData.colorClassName}
		    style={{ paddingTop: 8 }}
	    >
		    <GoDotFill className={"fs24"}/>
	    </div>
	    <div className={"ml6 mt2 grey-5-color bold"}>
		    {levelData.title}
	    </div>
    </div>;
};

export default Level;
