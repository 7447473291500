import "../../../../../../../styles/form-builder.less";

import { useState, FC, useMemo, useEffect, useCallback, Fragment } from "react";

import { Modal, Button, Typography, ConfigProvider, Divider } from "antd";

import AnswerItemPreview from "./AnswerItemPreview";
import GroupItem from "./GroupItem";
import SelectableMenu from "./SelectableMenu";
import useBaseUrl from "../../../../../../../hooks/helpers/useBaseUrl";
import { useModalOpener } from "../../../../../../../hooks/helpers/useModalOpener";
import {
    GroupT,
    QuestionnaireData,
    SubTabT,
} from "../../../../../../../types/questionnaires";
import {
    getAllDependIds,
    checkMessageDependency,
    createAnswersArray,
    isGroupShown,
    TabAnswers,
} from "../../../../../../../utils/builder";
import FormPageWrapper from "../FormWrapper/FormWrapper";

type FormBuilderPropsT = {
    builderData: QuestionnaireData;
    title: string;
};

export type ErrorsT = {
    [key: string]: string;
};

export type GroupProgressT = {
    [key: string]: {
        value: number;
        toCalculation: boolean;
    }
}

type SubTabProgressT = {
    groups: GroupProgressT;
};

type ProgressT = {
    [subTabLabel: string]: SubTabProgressT;
};

const FormBuilder: FC<FormBuilderPropsT> = ({ builderData, title }) => {
    const baseUrl = useBaseUrl();

    const {
        isOpened: showPreview,
        open: openAnswersPreviewModal,
        close: closeAnswersPreviewModal,
    } = useModalOpener();

    const [currentSubTab, setCurrentSubTab] = useState<string>(
        builderData?.tabs?.[0]?.children?.[0]?.data?.label || ""
    );
    const [currentIcon, setCurrentIcon] = useState<string>("");
    const [currentGroupId, setCurrentGroupId] = useState<string>(
        builderData?.tabs?.[0]?.children?.[0]?.children?.[0]?.id || ""
    );
    const [currentSubTabGroups, setCurrentSubTabGroups] = useState<GroupT[]>([]);

    const [progress, setProgress] = useState<ProgressT>(() => {
        return builderData.tabs.reduce((acc, tab) => {
            const subTabProgress = tab.children?.reduce((subTabAcc, subTab) => {
                const groupProgress = subTab.children?.reduce((groupAcc, group) => {
                    groupAcc[group.id] = {
                        value: 0,
                        toCalculation: !(group.depend && group.depend.length > 0),
                    };
                    return groupAcc;
                }, {} as GroupProgressT);

                subTabAcc[subTab.data.label!] = { groups: groupProgress! };
                return subTabAcc;
            }, {} as ProgressT);

            return { ...acc, ...subTabProgress };
        }, {} as ProgressT);
    });

    const [overallProgress, setOverallProgress] = useState<number>(0);
    const [completedSubTabs, setCompletedSubTabs] = useState<{[key: string]: boolean}>({});

    const [returnForbidden, setReturnForbidden] = useState<Record<string, string>>({});
    const [answers, setAnswers] = useState<Record<string, Record<string, any>>>({});

    const [message, setMessage] = useState<string | null>(null);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [buttonLabel, setButtonLabel] = useState("Next");
    const [previewData, setPreviewData] = useState<TabAnswers[]>([]);

    const forbiddenMDepIds = useMemo(
        () => getAllDependIds(builderData.forbiddenMessages),
        [builderData]
    );

    const allowMDepIds = useMemo(
        () => getAllDependIds(builderData.allowMessages),
        [builderData]
    );

    const handleQuestionAnswer = useCallback(
        (questionId: string, answer: any) => {
            setAnswers(prevAnswers => ({
                ...prevAnswers,
                [currentSubTab]: {
                    ...(prevAnswers[currentSubTab] || {}),
                    [questionId]: answer,
                },
            }));

            if (forbiddenMDepIds.includes(questionId)) {
                const result = checkMessageDependency(
                    builderData.forbiddenMessages,
                    questionId,
                    answer
                );
                if (result) {
                    setMessage(result.message);
                    setReturnForbidden(prev => ({
                        ...prev,
                        [questionId]: result.affectTo,
                    }));
                } else {
                    setReturnForbidden(prev => ({
                        ...prev,
                        [questionId]: "",
                    }));
                }
            }

            if (allowMDepIds.includes(questionId)) {
                const result = checkMessageDependency(
                    builderData.allowMessages,
                    questionId,
                    answer
                );
                if (result) {
                    setMessage(result.message);
                }
            }
        },
        [
            currentSubTab,
            forbiddenMDepIds,
            builderData,
            allowMDepIds,
            setMessage,
            setReturnForbidden,
            setAnswers,
        ]
    );

    const updateGroupToCalculation = (subTab: string, groupId: string, value: boolean) => {
        setProgress(prevProgress => ({
            ...prevProgress,
            [subTab]: {
                ...prevProgress[subTab],
                groups: {
                    ...prevProgress[subTab].groups,
                    [groupId]: {
                        ...prevProgress[subTab].groups[groupId],
                        toCalculation: value,
                    },
                },
            },
        }));
    };

    const getSubTabNextGroup = useCallback(
        (groupId: string): GroupT | null => {
            const currentIndex = currentSubTabGroups.findIndex(
                group => group.id === groupId
            );
            if (
                currentIndex !== -1 &&
                currentIndex < currentSubTabGroups.length - 1
            ) {
                const nextGroup = currentSubTabGroups[currentIndex + 1];

                const currentSubTabAnswers = answers[currentSubTab] || {};
                const showGroup = isGroupShown(
                    nextGroup,
                    currentSubTabAnswers
                );
                if (showGroup) {
                    return nextGroup;
                } else {
                    nextGroup?.children?.forEach(question => {
                        handleQuestionAnswer(question.id, undefined);
                        currentSubTabAnswers[question.id] = undefined;
                    });
                    updateGroupToCalculation(currentSubTab, nextGroup.id, false);
                    return getSubTabNextGroup(nextGroup.id);
                }
            }
            return null;
        },
        [answers, currentSubTab, currentSubTabGroups, handleQuestionAnswer]
    );

    const getSubTabPreviousGroup = useCallback(
        (groupId: string): GroupT | null => {
            const currentIndex = currentSubTabGroups.findIndex(
                group => group.id === groupId
            );

            if (currentIndex > 0) {
                const previousGroup = currentSubTabGroups[currentIndex - 1];
                const showGroup = isGroupShown(
                    previousGroup,
                    answers[currentSubTab] || {}
                );
                if (showGroup) {
                    return previousGroup;
                } else {
                    return getSubTabPreviousGroup(previousGroup.id);
                }
            }
            return null;
        },
        [answers, currentSubTab, currentSubTabGroups]
    );

    const getSubTabLastGroup = useCallback(
        (subTabLabel?: string): GroupT | null => {
            if (!subTabLabel) return null;
            let groups: GroupT[] = [];

            for (const tab of builderData.tabs) {
                const foundSubTab = tab.children?.find(
                    subTab => subTab.data.label === subTabLabel
                );
                if (foundSubTab) {
                    groups = foundSubTab.children || [];
                    break;
                }
            }

            for (let i = groups.length - 1; i >= 0; i--) {
                const group = groups[i];
                const showGroup = isGroupShown(
                    group,
                    answers[subTabLabel] || {}
                );
                if (showGroup) return group;
            }
            return null;
        },
        [builderData.tabs, answers]
    );

    const getNextSubTab = useCallback((): SubTabT | null => {
        const currentTab = builderData.tabs.find(tab =>
            tab.children?.some(subTab => subTab.data.label === currentSubTab)
        );

        if (!currentTab) return null;

        const currentSubTabIndex = currentTab.children!.findIndex(
            subTab => subTab.data.label === currentSubTab
        );

        if (currentSubTabIndex < currentTab.children!.length - 1) {
            return currentTab.children![currentSubTabIndex + 1];
        }

        const nextTabs = builderData.tabs.slice(
            builderData.tabs.indexOf(currentTab) + 1
        );

        for (const tab of nextTabs) {
            if (tab.children && tab.children.length > 0) {
                return tab.children[0];
            }
        }

        return null;
    }, [builderData.tabs, currentSubTab]);

    const getPreviousSubTab = useCallback((): SubTabT | null => {
        const currentTab = builderData.tabs.find(tab =>
            tab.children?.some(subTab => subTab.data.label === currentSubTab)
        );

        if (!currentTab || !currentTab.children) return null;

        const currentSubTabIndex = currentTab.children.findIndex(
            subTab => subTab.data.label === currentSubTab
        );

        if (currentSubTabIndex > 0) {
            return currentTab.children[currentSubTabIndex - 1];
        }

        const previousTabs = builderData.tabs.slice(
            0,
            builderData.tabs.indexOf(currentTab)
        );
        const previousTabWithChildren = previousTabs
            .reverse()
            .find(tab => tab.children && tab.children.length > 0);

        if (previousTabWithChildren && previousTabWithChildren.children) {
            return previousTabWithChildren.children[
                previousTabWithChildren.children.length - 1
            ];
        }

        return null;
    }, [builderData.tabs, currentSubTab]);

    const handleNextButton = () => {
        const nextGroup = getSubTabNextGroup(currentGroupId);

        if (nextGroup) return setCurrentGroupId(nextGroup.id);

        const nextSubTab = getNextSubTab();

        if (nextSubTab) {
            setCurrentSubTab(nextSubTab.data.label || "");
            setCurrentGroupId(nextSubTab.children?.[0]?.id || "");
        } else {
            const answersArray = createAnswersArray(answers, builderData.tabs);
            setPreviewData(answersArray);
            openAnswersPreviewModal();
            console.log("submit answersArray", answersArray);
        }
    };

    const handleGroupProgressUpdate = useCallback((groupId: string, progress: number) => {
        setProgress(prevProgress => {
            const currentSubTabProgress = prevProgress[currentSubTab];
            const currentGroupProgress = currentSubTabProgress?.groups[groupId];

            if (currentGroupProgress && currentGroupProgress?.value === progress) {
                return prevProgress;
            }

            if (currentGroupProgress) {
                return {
                    ...prevProgress,
                    [currentSubTab]: {
                        ...currentSubTabProgress,
                        groups: {
                            ...currentSubTabProgress.groups,
                            [groupId]: {
                                value: progress,
                                toCalculation: true,
                            },
                        },
                    },
                };
            }

            return {
                ...prevProgress,
                [currentSubTab]: {
                    groups: {
                        [groupId]: {
                            value: progress,
                            toCalculation: true,
                        },
                    },
                },
            };
        });
    }, [currentSubTab, setProgress]);

    const handleBackButton = () => {
        const prevGroup = getSubTabPreviousGroup(currentGroupId);
        if (prevGroup) {
            setCurrentGroupId(prevGroup.id);
            return;
        }

        const previousSubTab = getPreviousSubTab();
        if (previousSubTab) {
            setCurrentSubTab(previousSubTab.data.label || "");
            const lastShownGroup = getSubTabLastGroup(
                previousSubTab.data.label
            );
            setCurrentGroupId(lastShownGroup?.id || "");
        } else {
            console.log("back");
        }
    };

    useEffect(() => {
        const allToCalculationGroups = Object.values(progress).flatMap(subTabProgress =>
            Object.values(subTabProgress.groups).filter(group => group.toCalculation)
        );
        const totalProgress = allToCalculationGroups.reduce((acc, group) => acc + group.value, 0);
        const averageProgress = allToCalculationGroups.length ? Math.floor(totalProgress / allToCalculationGroups.length) : 0;
        setOverallProgress(averageProgress);

        const updatedCompletedTabs = Object.fromEntries(
            Object.entries(progress).map(([tab, tabProgress]) => {
                if (tab === currentSubTab && averageProgress !== 100) {
                    return [tab, false];
                }
                const isCompleted = Object.values(tabProgress.groups).every(group => !group.toCalculation || group.value === 100);
                return [tab, isCompleted];
            })
        );
        setCompletedSubTabs(updatedCompletedTabs);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [progress, currentSubTab]);

    useEffect(() => {
        const isLastSubTab = builderData.tabs?.at(-1)?.children?.at(-1)?.data?.label === currentSubTab;
        if (isLastSubTab) {
            const noNextGroup = !getSubTabNextGroup(currentGroupId);
            setButtonLabel(noNextGroup ? "See Answers" : "Next");
            setIsButtonDisabled(noNextGroup && overallProgress !== 100);
        } else {
            const currentGroupProgress = progress[currentSubTab]?.groups[currentGroupId];
            setButtonLabel("Next");
            setIsButtonDisabled(!currentGroupProgress || currentGroupProgress.value !== 100);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSubTab, progress, currentGroupId, overallProgress]);

    useEffect(() => {
        let groups: GroupT[] = [];

        for (const tab of builderData.tabs) {
            const foundSubTab = tab.children?.find(
                subTab => subTab.data.label === currentSubTab
            );
            if (foundSubTab) {
                groups = foundSubTab.children || [];
                setCurrentIcon(baseUrl + tab.data.iconUrl);
                break;
            }
        }

        setCurrentSubTabGroups(groups);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [builderData.tabs, currentSubTab]);

    if (!builderData.tabs.length) return null;

    return (
        <>
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimary: "green",
                    },
                }}
            >
                <FormPageWrapper
                    title={title}
                    stepTitle={currentSubTab}
                    complete={overallProgress || 0}
                    completeTitle={undefined}
                    progressMenu={
                        <SelectableMenu
                            currentSubTab={currentSubTab}
                            setCurrentSubTab={setCurrentSubTab}
                            setCurrentIcon={setCurrentIcon}
                            setCurrentGroupId={setCurrentGroupId}
                            completedSubTabs={completedSubTabs}
                            tabs={builderData.tabs}
                            answers={answers}
                            isQuestionnaire={true}
                        />
                    }
                    formInputs={
                        <div className="form-builder__content">
                            {currentSubTabGroups.map(group => (
                                <div key={group.id}>
                                    {currentGroupId === group.id && (
                                        <GroupItem
                                            group={group}
                                            returnForbidden={returnForbidden}
                                            onAnswer={handleQuestionAnswer}
                                            answers={
                                                answers[currentSubTab] || {}
                                            }
                                            onGroupProgressUpdate={
                                                handleGroupProgressUpdate
                                            }
                                        />
                                    )}
                                </div>
                            ))}
                        </div>
                    }
                    sectionIcon={currentIcon}
                    isButtonDisabled={isButtonDisabled}
                    handleNextButton={handleNextButton}
                    handleBackButton={handleBackButton}
                    buttonLabel={buttonLabel}
                />
                <Modal
                    width={"325px"}
                    centered={true}
                    closable={false}
                    open={!!message}
                    onCancel={() => setMessage(null)}
                    footer={
                        <Button
                            className="builder__done-button"
                            onClick={() => setMessage(null)}
                        >
                            Done
                        </Button>
                    }
                >
                    <div
                        style={{
                            maxHeight: "400px",
                            overflowY: "auto",
                        }}
                    >
                        <Typography
                            className="builder__form-question"
                            style={{
                                whiteSpace: "break-spaces",
                                marginBottom: "30px",
                            }}
                        >
                            {message}
                        </Typography>
                    </div>
                </Modal>
                <Modal
                    width={"650px"}
                    centered={true}
                    closable={false}
                    open={showPreview}
                    onCancel={closeAnswersPreviewModal}
                    footer={[
                        <Button
                            className="builder__done-button"
                            onClick={closeAnswersPreviewModal}
                        >
                            Close
                        </Button>,
                    ]}
                >
                    <Typography
                        className="builder__form-title"
                        style={{
                            whiteSpace: "break-spaces",
                            marginBottom: "20px",
                        }}
                    >
                        Answers Preview
                    </Typography>
                    <div style={{ maxWidth: "100%" }}>
                        {previewData.map((tab, index) => (
                            <Fragment key={tab.tabLabel + index}>
                                {index > 0 && <Divider />}
                                <div
                                    style={{
                                        display: "block",
                                        padding: "4px",
                                    }}
                                >
                                    <Typography
                                        className="builder__sub-title"
                                        style={{
                                            whiteSpace: "break-spaces",
                                            marginBottom: "20px",
                                        }}
                                    >
                                        {tab.tabLabel}
                                    </Typography>
                                    <div
                                        style={{
                                            padding: "16px",
                                            border: "1px solid #ccc",
                                            background: "rgba(177, 255, 171, 0.115)",
                                        }}
                                    >
                                        {tab.subTabs?.map((subTab, index) => (
                                            <Fragment key={subTab.subTabLabel + index}>
                                                {subTab.questions.map(question => (
                                                    <AnswerItemPreview
                                                        item={question}
                                                        key={question.id}
                                                    />
                                                ))}
                                            </Fragment>
                                        ))}
                                    </div>
                                </div>
                            </Fragment>
                        ))}
                    </div>
                </Modal>
            </ConfigProvider>
        </>
    );
};

export default FormBuilder;
