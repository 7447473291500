import React, { useContext } from "react";

import { Col, Row, Radio } from "antd";

import { getColumns } from "./components/TransactionsTable/columns";
import TransactionsTable from "./components/TransactionsTable/TransactionsTable";
import { useClientTransactionsTable } from "../../../../../hooks/clients/useClientTransactionsTable";
import Spinner from "../../../../basic/Spinner/Spinner";
import Pagination from "../../../../common/Table/components/Pagination";
import { ClientContext } from "../../Client";

const Transactions = () => {
    const {
        client,
	    isLoading: isLoadingOutside,
    } = useContext(ClientContext);

    const {
        table,
	    isLoading,
	    variation,
	    setVariation,
	    pagination,
    } = useClientTransactionsTable(
	    getColumns,
	    client?._id
    );

    return <div>
	    <Spinner isLoading={isLoading || isLoadingOutside}>
		    <div className={"p20"}>
			    <Row>
				    <Col span={24}>
                        <div>
                            <div
                                style={{ height: 50 }}
                                className={"space-between"}
                            >
                                <div className={"h3"}>
                                    Transactions Details
                                </div>

	                            <div>
		                            <Radio.Group
			                            options={[{
				                            value: "outgoing",
				                            label: "TaxZap",
			                            }, {
				                            value: "incoming",
				                            label: "Revenue",
			                            }]}
			                            onChange={({ target: { value } }) => setVariation(value)}
			                            value={variation}
			                            optionType="button"
			                            buttonStyle="solid"
		                            />
	                            </div>
                            </div>

                            <div className={"mt10"}>
                                <TransactionsTable
                                    table={table}
                                />
                            </div>

	                        <div className={"mt10"}>
		                        <Pagination
			                        pagination={pagination}
		                        />
	                        </div>
                        </div>
				    </Col>
			    </Row>
		    </div>
	    </Spinner>
    </div>;
};

export default Transactions;
