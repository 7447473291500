import { flexRender } from "@tanstack/react-table";
import { Select } from "antd";
import { SizeType } from "antd/es/config-provider/SizeContext";

import { columns } from "./columns";
import { useAgentSelector } from "../../../../hooks/agents/useAgentSelector";

export const AgentsFilter = ({
    selectedAgentId,
    onAgentSelect,
    width = 240,
    size = "large",
    name = "users",
}: {
	selectedAgentId?: string;
	onAgentSelect: (_agentIds: string) => void;
	width?: number;
	size?: SizeType;
	name?: string;
}) => {
    const {
        isLoading,
	    table,
	    pagination,
    } = useAgentSelector(columns);

    return <div data-testid={name}>
        <Select
	        value={selectedAgentId}
	        onSelect={(value: string) => onAgentSelect(value)}
            size={size}
            className={"select-content grey-color"}
            style={{ width }}
	        loading={isLoading}
	        placeholder={<span className={"fs14"}>Select agent...</span>}
	        onPopupScroll={e => {
		        e.persist();
		        const target = e.target as HTMLElement;
		        if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
			        pagination.canNextPage && pagination.nextPage();
		        }
	        }}
        >
	        <Select.Option
		        value={""}
		        className={"select-option"}
	        >
		        <div
			        className={"vertical-center"}
			        data-testid={`select-option-${name}-all`}
		        >
			        <div className={"fs14"}>
				        All Agents
			        </div>
		        </div>
	        </Select.Option>
            {table.getRowModel().rows.map(row => {
                return <Select.Option
                    key={row.id}
                    value={row.original.id}
                    className={"select-option"}
                >
                    <div
	                    className={"vertical-center"}
	                    data-testid={`select-option-${name}-${row.original.info.email}`}
                    >
	                    {row.getVisibleCells().map(cell => (
		                    <div
			                    key={cell.id}
			                    className={"fs14 grey-7-color"}
		                    >
			                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
		                    </div>
	                    ))}
                    </div>
                </Select.Option>;
            })}
        </Select>
    </div>;
};

export default AgentsFilter;
