import "../../../../../../../styles/float-input.less";

import { useState, ChangeEvent, CSSProperties, useMemo } from "react";

import { Input } from "antd";

import { useAppSize } from "../../../../../../../hooks/helpers/useAppSize";

interface FloatInputProps {
    label: string;
    value?: string;
    placeholder?: string;
    staticPlaceholder?: string;
    type?: string;
    error?: string;
    style?: CSSProperties;
    onChange?: (_event: ChangeEvent<HTMLInputElement>) => void;
    setSkipValidation?: (_value: boolean) => void;
}

const FloatInput = ({
    label,
    value,
    placeholder,
    staticPlaceholder,
    type,
    error,
    style,
    onChange,
    setSkipValidation,
}: FloatInputProps) => {
    const isMobile = useAppSize();

    const [focus, setFocus] = useState(false);

    const isOccupied = focus || value;

    const labelClass = useMemo(() => {
        const baseClass = "label";
        const isLongLabel = label.length > 40;
        const isVeryLongLabel = label.length > 65;

        let computedLabelClass = isOccupied
            ? `${baseClass} as-label`
            : `${baseClass} as-placeholder`;

        if (isOccupied && error) {
            computedLabelClass += " text-error";
        }

        if (isMobile) {
            if (!isOccupied && (isLongLabel || isVeryLongLabel)) {
                computedLabelClass += " two-line-placeholder";
            }

            if (isOccupied && (isLongLabel || isVeryLongLabel)) {
                computedLabelClass += " two-line-label";
            }
        } else {
            if (!isOccupied && isVeryLongLabel) {
                computedLabelClass += " two-line-placeholder";
            }

            if (isOccupied && isVeryLongLabel) {
                computedLabelClass += " two-line-label";
            }
        }

        return computedLabelClass;
    }, [isOccupied, error, label.length, isMobile]);

    const handleBlur = () => {
        setFocus(false);
    };

    const handleFocus = () => {
        setSkipValidation && setSkipValidation(false);
        setFocus(true);
    };

    return (
        <div
            className="float-label"
            onBlur={handleBlur}
            onFocus={handleFocus}
            style={style}
        >
            <Input
                className="input-field"
                onChange={onChange}
                type={type}
                defaultValue={value}
                placeholder={isOccupied ? placeholder : ""}
            />
            <label className={labelClass}>{label}</label>
            {staticPlaceholder && <div className="static-placeholder">{staticPlaceholder}</div>}
        </div>
    );
};

export default FloatInput;
