import { ColumnDef } from "@tanstack/react-table";

import Actions from "./components/Actions";
import Agent from "./components/Agent";
import CaseId from "./components/CaseId";
import Cell from "./components/Cell";
import Date from "./components/Date";
import Header from "./components/Header";
import IDVerificationStatus from "./components/IDVerificationStatus";
import Log from "./components/Log";
import StatusCell from "./components/StatusCell";
import SubStatus from "./components/SubStatus";
import TaxNumber from "./components/TaxNumber";
import { IClient } from "../../../../../types/clients";
import { IDeleteClientDocumentByIdBody, IDocument, ISetDocumentsAgentBody } from "../../../../../types/documents";
import Status from "../../../../common/Status/Status";
import IndeterminateCheckbox from "../../../../common/Table/components/IndeterminateCheckbox";

export const getColumns = ({
    deleteClientDocumentById,
    setDocumentsAgent,
}: {
    deleteClientDocumentById: (_data: IDeleteClientDocumentByIdBody) => void;
    setDocumentsAgent: (_data: ISetDocumentsAgentBody) => void;
}): ColumnDef<IDocument>[] => [
    {
        id: "select",
        header: ({ table }) => (
            <IndeterminateCheckbox
                {...{
                    checked: table.getIsAllRowsSelected(),
                    indeterminate: table.getIsSomeRowsSelected(),
                    onChange: table.getToggleAllRowsSelectedHandler(),
                }}
                className={"horizontal-left"}
            />
        ),
        cell: ({ row }) => (
            <div className="px-1">
                <IndeterminateCheckbox
                    {...{
                        checked: row.getIsSelected(),
                        disabled: !row.getCanSelect(),
                        indeterminate: row.getIsSomeSelected(),
                        onChange: row.getToggleSelectedHandler(),
                    }}
                />
            </div>
        ),
    },
    {
        id: "name",
        enableSorting: false,
        accessorFn: row => {
            const client = row.client as IClient;
            return `${client.info.firstName} ${client.info.lastName}`;
        },
        header: () => <Header label={"Name"}/>,
        cell: info => <StatusCell value={info.getValue() as string} row={info.row}/>,
    },
    {
        id: "email",
        accessorKey: "client.info.email",
        enableSorting: false,
        header: () => <Header label={"Email"}/>,
        cell: info => <Cell value={info.getValue() as string}/>,
    },
    {
        id: "ppsn",
        accessorKey: "ppsn",
        enableSorting: false,
        header: () => <Header label={"PPSN"}/>,
        cell: ({ row }) => <TaxNumber value={row.original.ppsn}/>,
    },
    {
        id: "id",
        accessorKey: "id",
        enableSorting: false,
        header: () => <Header label={"Case ID"}/>,
        cell: ({ row }) => <CaseId
            value={row.original}
        />,
    },
    {
        id: "status",
        accessorKey: "status.id",
        enableSorting: false,
        header: () => <Header label={"Status"}/>,
        cell: ({ row }) => <Status value={row.original.status.id}/>,
    },
    {
        id: "subStatus",
        accessorKey: "subStatuses.mortgage",
        enableSorting: false,
        header: () => <Header label={"Sub-status"}/>,
        cell: ({ row }) => <SubStatus value={row.original.subStatuses.mortgage}/>,
    },
    {
        id: "updatedAt",
        accessorKey: "updatedAt",
        header: () => <Header label={"Last Updated"}/>,
        cell: ({ row }) => <Date value={row.original.updatedAt}/>,
    },
    {
        id: "createdAt",
        accessorKey: "createdAt",
        header: () => <Header label={"Date Added"}/>,
        cell: ({ row }) => <Date value={row.original.createdAt}/>,
    },
    {
        id: "idpalVerified",
        accessorKey: "idpalVerified",
        header: () => <Header label={"ID"}/>,
        cell: ({ row }) => <IDVerificationStatus value={(row.original.client as IClient).idpalVerified}/>,
    },
    {
        id: "manager",
        accessorKey: "manager",
        enableSorting: false,
        header: () => <Header label={"Agent"}/>,
        cell: ({ row }) => <Agent value={row.original.manager}/>,
    },
    {
        id: "error",
        accessorKey: "error",
        enableSorting: false,
        header: () => <Header label={"Logs"}/>,
        cell: ({ row }) => <Log logs={row.original.logs}/>,
    },
    {
        id: "actions",
        cell: ({ row }) => <Actions
            value={row.original}
            deleteClientDocumentById={deleteClientDocumentById}
            setDocumentsAgent={setDocumentsAgent}
        />,
    },
];
