import React from "react";

import { Form } from "antd";

interface CustomFormItemProps {
    helpText?: string;
    error: string;
    children: React.ReactNode;
    // labelLength: number;
}

const CustomFormItem: React.FC<CustomFormItemProps> = ({
    helpText,
    error,
    children,
    // labelLength
}) => (
    <Form.Item
        validateStatus={error ? "error" : "success"}
        help={error ? error : helpText ? helpText : ""}
        style={{
            width: "100%",
            // width: `${labelLength > 32 ? "600px" : "100%"}`,
            paddingBottom: "8px",
        }}
    >
        {children}
    </Form.Item>
);

export default CustomFormItem;
