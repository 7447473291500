import { memo, useCallback, useState } from "react";

import {
    Divider,
    Typography,
    Box,
    Button,
    Modal,
    TextareaAutosize,
} from "@mui/material";
import { Handle, Position } from "reactflow";

import { useModalOpener } from "../../../../../../../hooks/helpers/useModalOpener";
import { createChangeHandler } from "../../../../../../../utils/constructor";
import useStore from "../store";

const WarningBlockNode = (props: any) => {
    const updateNodeData = useStore(state => state.updateNodeData);

    const {
        isOpened: isEditing,
        open: openEditModal,
        close: closeModal,
    } = useModalOpener();

    const [editedInfo, setEditedInfo] = useState(props.data.label);

    const saveChanges = useCallback(() => {
        updateNodeData(props.id, { label: editedInfo });
        closeModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateNodeData, editedInfo]);

    const handleInfoChange = createChangeHandler(setEditedInfo);

    return (
        <div className="question-node">
            <Handle
                type="target"
                position={Position.Top}
                className="handle-depend"
                style={{ left: 10 }}
                id="depend"
            />
            <Handle
                type="target"
                position={Position.Top}
                className="handle-depend"
                style={{ left: 30 }}
                id="depend_and"
            />
            <Handle type="target" position={Position.Top} id="to" />
            <Typography ml={2} component="div" sx={{ fontSize: "8px" }}>
                Warning Block
            </Typography>
            <Divider />
            <Box className="question-label-wrapper">
                <Typography
                    ml={1}
                    component="div"
                    sx={{
                        fontSize: "10px",
                        maxWidth: "250px",
                    }}
                >
                    {props.data.label}
                </Typography>
                <Button
                    variant="outlined"
                    onClick={openEditModal}
                    className="button-edit"
                >
                    Edit
                </Button>
            </Box>
            <Handle type="source" position={Position.Bottom} id="from" />
            {isEditing && (
                <Modal
                    open={isEditing}
                    onClose={saveChanges}
                    className="modal-wrapper"
                    slotProps={{
                        backdrop: {
                            sx: {
                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                            },
                        },
                    }}
                >
                    <Box className="box-wrapper">
                        <Box
                            sx={{
                                width: "100%",
                            }}
                        >
                            <Typography
                                component="div"
                                className="select-one-options-label"
                            >
                                Warning Block Text
                            </Typography>
                            <TextareaAutosize
                                id="message"
                                value={editedInfo}
                                onChange={handleInfoChange}
                                minRows={10}
                                className="select-one-textarea"
                            />
                        </Box>
                        <Box className="button-group">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={saveChanges}
                            >
                                Save
                            </Button>
                        </Box>
                    </Box>
                </Modal>
            )}
        </div>
    );
};

export default memo(WarningBlockNode);
