import { useEffect, useState } from "react";

import { BsBriefcase, BsFileText, BsGrid, BsPeople, BsPerson, BsPersonVcard } from "react-icons/bs";
import { MdOutlineKeyboardDoubleArrowLeft, MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import { useLocation } from "react-router-dom";

import NavigationHeader from "./components/NavigationHeader";
import NavigationItem from "./components/NavigationItem";
import { NavigationContext } from "./contexts/NavigationContext";
import Logo from "../../../assets/icons/Logo";
import { usePermissionGranter } from "../../../hooks/common/usePermissionGranter";

const Navigation = ({
    navbarWidth,
    navbarIsCollapsed,
    setNavbarIsCollapsed,
}: {
    navbarWidth: number;
    navbarIsCollapsed: boolean;
    setNavbarIsCollapsed: (_navbarIsCollapsed: boolean) => void;
}) => {
    const location = useLocation();

    const [selectedPath, setSelectedPath] = useState(location.pathname);

    useEffect(() => {
        setSelectedPath(location.pathname);
    }, [location.pathname]);

    const questionnairesPermissionIsGranted = usePermissionGranter("questionnaires", "read");
    const agentsPermissionIsGranted = usePermissionGranter("users:people", "read");
    const dashboardPermissionIsGranted = usePermissionGranter("dashboard", "read");

    return (
        <NavigationContext.Provider
            value={{
                navbarIsCollapsed,
                selectedPath,
                onPathSelect: setSelectedPath,
            }}
        >
            <div style={{ width: navbarWidth }}>
                <div
                    style={{ height: "10vh" }}
                    className={"mt40 horizontal-center"}
                >
                    <Logo
                        width={navbarIsCollapsed ? 40 : 190}
                        height={48}
                        withLabel={!navbarIsCollapsed}
                    />
                </div>

                <div
                    style={{ height: "80vh" }}
                    className={navbarIsCollapsed ? "ph12" : "ph20"}
                >
                    {dashboardPermissionIsGranted && <>
                        <div>
                            <NavigationHeader label={"Homepage"}/>
                        </div>

                        <div className={"mt10"}>
                            <NavigationItem
                                icon={<BsGrid/>}
                                label={"Dashboard"}
                                path={"/home"}
                            />
                        </div>
                    </>}

                    {agentsPermissionIsGranted && <>
                        <div className={"mt20"}>
                            <NavigationHeader label={"Management"}/>
                        </div>

                        <div className={"mt10"}>
                            <NavigationItem
                                icon={<BsPersonVcard/>}
                                label={"Agents"}
                                path={"/agents/list"}
                            />
                        </div>
                    </>}

                    <div className={"mt20"}>
                        <NavigationHeader label={"Product"}/>
                    </div>

                    <div className={"mt10"}>
                        <NavigationItem
                            icon={<BsBriefcase/>}
                            label={"Cases"}
                            path={"/cases"}
                        />
                    </div>

                    <div>
                        <NavigationItem
                            icon={<BsPeople/>}
                            label={"Clients"}
                            path={"/clients/list"}
                        />
                    </div>

                    {questionnairesPermissionIsGranted && <div>
                        <NavigationItem
                            icon={<BsFileText/>}
                            label={"Questionnaire"}
                            path={"/questionnaire/constructor"}
                        />
                    </div>}

                    <div className={"mt20"}>
                        <NavigationHeader label={"Other"}/>
                    </div>

                    <div className={"mt10"}>
                        <NavigationItem
                            icon={<BsPerson/>}
                            label={"Profile"}
                            path={"/profile"}
                        />
                    </div>
                </div>

                <div
                    style={{ height: "10vh" }}
                    className={navbarIsCollapsed ? "ph12" : "ph20"}
                >
                    <div className={navbarIsCollapsed ? "horizontal-center" : "horizontal-right"}>
                        {navbarIsCollapsed ? <MdOutlineKeyboardDoubleArrowRight
                            className={"grey-4-color pointer fs20"}
                            style={{ marginTop: -20 }}
                            onClick={() => {
                                setNavbarIsCollapsed(false);
                                localStorage.setItem("navbarIsCollapsed", "false");
                            }}
                        /> : <MdOutlineKeyboardDoubleArrowLeft
                            className={"grey-4-color pointer fs26"}
                            onClick={() => {
                                setNavbarIsCollapsed(true);
                                localStorage.setItem("navbarIsCollapsed", "true");
                            }}
                        />}
                    </div>
                </div>
            </div>

        </NavigationContext.Provider>
    );
};

export default Navigation;
