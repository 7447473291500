import qs from "qs";

import { DEFAULT_LAST_SUBMITTED_RANGE, DEFAULT_PAID_RANGE } from "../constants/documents";
import { IGetClientLogsQuery } from "../types/client-logs";
import { IGetClientsQuery } from "../types/clients";
import { IFilter, ISortBy } from "../types/common";
import { IGetDocumentsQuery } from "../types/documents";
import { IGetQuestionnairesQuery } from "../types/questionnaires";
import { IGetClientTransactionsQuery, IGetTransactionsQuery } from "../types/transactions";
import { IGetUsersQuery } from "../types/users";

export const withPagination = (pageIndex: number, pageSize: number) => {
    return {
        page: pageIndex,
        limit: pageSize,
    };
};

export const withGlobalFilter = (global: string) => {
    return {
        global: global,
    };
};

export const withFilters = (filters?: IFilter[]) => {
    return {
        filters,
    };
};

export const withSortBy = (sortBy?: ISortBy[]) => {
    return {
        ...sortBy?.[0] && {
            sort: sortBy[0].id,
            desc: sortBy[0].desc ? "desc" : "asc",
        },
    };
};

export const getUsersQueryString = ({
    pageIndex = 1,
    pageSize = 20,
    globalFilter = "",
    enabled = undefined,
    withStats = true,
}: IGetUsersQuery) => {
    return qs.stringify({
        ...withPagination(pageIndex, pageSize),
        ...withGlobalFilter(globalFilter),
        ...enabled !== undefined && {
            enabled,
        },
        withStats,
    }, { encode: false });
};

export const getClientLogsQueryString = ({
    pageIndex = 1,
    pageSize = 20,
    sortBy = [],
}: IGetClientLogsQuery) => {
    return qs.stringify({
        ...withPagination(pageIndex, pageSize),
        ...withSortBy(sortBy),
    }, { encode: false });
};

export const getClientTransactionsQueryString = ({
    variation,
    pageIndex = 1,
    pageSize = 20,
    sortBy = [],
}: IGetClientTransactionsQuery) => {
    return qs.stringify({
        variation,
        ...withPagination(pageIndex, pageSize),
        ...withSortBy(sortBy),
    }, { encode: false });
};

export const getTransactionsQueryString = ({
    pageIndex = 1,
    pageSize = 20,
    sortBy = [],
    globalFilter = "",
    variation,
}: IGetTransactionsQuery) => {
    return qs.stringify({
        ...withPagination(pageIndex, pageSize),
        ...withSortBy(sortBy),
        ...withGlobalFilter(globalFilter),
        variation,
    }, { encode: false });
};

export const getQuestionnairesQueryString = ({
    pageIndex = 1,
    pageSize = 10,
}: IGetQuestionnairesQuery) => {
    return qs.stringify({
        ...withPagination(pageIndex, pageSize),
    }, { encode: false });
};

export const getDocumentsQueryString = ({
    pageIndex = 1,
    pageSize = 20,
    filters = [],
    sortBy = [],
    globalFilter = "",
    unassignedOnly = undefined,
    managerId = undefined,
    mainStatusId = undefined,
    statusIds = [],
    paidRange = DEFAULT_PAID_RANGE,
    lastSubmittedRange = DEFAULT_LAST_SUBMITTED_RANGE,
    planIds = [],
}: IGetDocumentsQuery) => {
    return qs.stringify({
        ...withPagination(pageIndex, pageSize),
        ...withFilters(filters),
        ...withSortBy(sortBy),
        ...withGlobalFilter(globalFilter),
        ...unassignedOnly !== undefined && {
            unassignedOnly,
        },
        ...managerId !== undefined && {
            managerId,
        },
        ...mainStatusId !== undefined && {
            mainStatusId,
        },
        planIds,
        statusIds,
        paidRange,
        lastSubmittedRange,
    }, { encode: false });
};

export const getClientsQueryString = ({
    pageIndex = 1,
    pageSize = 20,
    filters = [],
    sortBy = [],
    globalFilter = "",
    shouldUpdateAgreement,
}: IGetClientsQuery) => {
    return qs.stringify({
        ...withPagination(pageIndex, pageSize),
        ...withFilters(filters),
        ...withSortBy(sortBy),
        ...withGlobalFilter(globalFilter),
        ...shouldUpdateAgreement !== undefined && {
            shouldUpdateAgreement,
        },
    }, { encode: false });
};
