import React, { useMemo } from "react";

import { Route, Routes } from "react-router-dom";

import Financial from "./tabs/Financial/Financial";
import Operations from "./tabs/Operations/Operations";
import PageHeader from "../../basic/PageHeader/PageHeader";
import PageTab from "../../common/PageTab/PageTab";

const MENU_ITEMS = [{
    id: "",
    title: "Financial",
}, {
    id: "operations",
    title: "Operations",
}];

const Dashboard = () => {
    const menu = useMemo(() =>
        MENU_ITEMS.map(item =>
            <PageTab
                key={item.id}
                item={item}
            />),
    []
    );

    return <div>
        <div>
            <PageHeader menu={menu}/>
        </div>

        <div className={"common-page scroll-block"}>
            <div className={"common-block"}>
                <Routes>
                    <Route path={MENU_ITEMS[0].id} element={<Financial/>}/>
                    <Route path={MENU_ITEMS[1].id} element={<Operations/>}/>
                </Routes>
            </div>
        </div>
    </div>;
};

export default Dashboard;
