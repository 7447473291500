const TaxNumber = ({
    value,
}: {
	value: string;
}) => {
    return <div className={"purple-color bold horizontal-center"}>
        {value}
    </div>;
};

export default TaxNumber;
