import { CheckOutlined, CloseOutlined } from "@ant-design/icons/lib/icons";

const IDVerificationStatus = ({
    value,
}: {
	value: string | undefined;
}) => {
    return <div className={"horizontal-center"}>
	    {!value && <div className={"horizontal-left"}>
		    -
        </div>}

	    {value === "success" && <div className={"horizontal-left"}>
            <CheckOutlined className={"fs16 primary-color"}/>
        </div>}

	    {value === "failed" && <div className={"horizontal-left"}>
            <CloseOutlined className={"fs16 red-color"}/>
        </div>}
    </div>;
};

export default IDVerificationStatus;
