import { useMemo } from "react";

import { EditOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import moment from "moment";

import { POSSIBLE_SUB_STATUSES } from "../../../../../../constants/documents";
import { useModalDataOpener } from "../../../../../../hooks/helpers/useModalDataOpener";
import { useModalOpener } from "../../../../../../hooks/helpers/useModalOpener";
import {
    IConfirmPlanForDocumentByIdData,
    IDocument, ISubStatuses,
    IUpdateDocumentStatusByIdData,
    IUpdateDocumentStatusByIdResponse,
    IUpdateDocumentSubStatusByIdData,
    SubStatusType,
} from "../../../../../../types/documents";
import Status from "../../../../../common/Status/Status";
import SubStatus from "../../../../../common/Status/SubStatus";
import SetCasePlanModal from "../../../../../modals/Cases/SetCasePlanModal";
import SetCaseStatusModal from "../../../../../modals/Cases/SetCaseStatusModal";
import SetCaseSubStatusModal from "../../../../../modals/Cases/SetCaseSubStatusModal";

const CaseDetails = ({
    document,
    updateDocumentStatus,
    confirmPlanForDocument,
	 updateDocumentSubStatus,
}: {
	document: IDocument,
	updateDocumentStatus: (_statusData: IUpdateDocumentStatusByIdData) => Promise<IUpdateDocumentStatusByIdResponse>;
	confirmPlanForDocument: (_documentData: IConfirmPlanForDocumentByIdData) => void;
	updateDocumentSubStatus: (_data: IUpdateDocumentSubStatusByIdData) => void;
}) => {
    const {
        isOpened: setCaseStatusModalIsOpened,
	    open: openSetCaseStatusModal,
	    close: closeSetCaseStatusModal,
    } = useModalOpener();

    const {
        isOpened: setCasePlanModalIsOpened,
        open: openSetCasePlanModal,
        close: closeSetCasePlanModal,
    } = useModalOpener();

    const {
        modalData: setCaseSubStatusModalData,
        open: openSetCaseSubStatusModal,
        close: closeSetCaseSubStatusModal,
    } = useModalDataOpener();

    const planName = useMemo(() => {
        if (!document.plan) return "-";

        return document.plan.name;
    }, [document.plan]);

    return <div>
	    <div
		    style={{ height: 50 }}
		    className={"horizontal-left"}
	    >
		    <div className={"h3"}>
			    Case Details
		    </div>
	    </div>

	    <div className={"mt20"}>
		    <div className={"horizontal-left"}>
			    <Row
				    className={"vertical-center"}
				    style={{ width: 500 }}
			    >
				    <Col span={8}>
					    <div className={"primary-color"}>
						    Status:
					    </div>
				    </Col>
				    <Col span={16}>
					    <div className={"horizontal-left"}>
						    <Status value={document.status.id}/>

						    <div className={"ml10"}>
							    <EditOutlined
								    data-testid={"open-case-status-selector"}
								    onClick={() => openSetCaseStatusModal()}
								    className={"primary-color pointer fs18"}
							    />
						    </div>
					    </div>
				    </Col>
			    </Row>
		    </div>
	    </div>

	    {POSSIBLE_SUB_STATUSES.map(subStatusId => {
		    return <div className={"mt20"} key={subStatusId}>
			    <div className={"horizontal-left"}>
				    <Row
					    className={"vertical-center"}
					    style={{ width: 500 }}
				    >
					    <Col span={8}>
						    <div className={"primary-color"}>
							    Sub-status:
						    </div>
					    </Col>
					    <Col span={16}>
						    <div className={"horizontal-left"}>
							    <SubStatus
								    value={document.subStatuses[subStatusId as keyof ISubStatuses]}
								    subStatusId={subStatusId}
							    />

							    <div className={"ml10"}>
								    <EditOutlined
									    data-testid={"open-case-status-selector"}
									    onClick={() => openSetCaseSubStatusModal(subStatusId)}
									    className={"primary-color pointer fs18"}
								    />
							    </div>
						    </div>
					    </Col>
				    </Row>
			    </div>
		    </div>;
	    })}

	    <div className={"mt20"}>
		    <div className={"horizontal-left"}>
			    <Row
				    className={"vertical-center"}
				    style={{ width: 500 }}
			    >
				    <Col span={8}>
					    <div className={"primary-color"}>
						    Plan:
					    </div>
				    </Col>
				    <Col span={16}>
					    <div className={"horizontal-left"}>
						    <div>
							    {planName}
						    </div>
						    <div className={"ml10"}>
							    <EditOutlined
								    onClick={() => openSetCasePlanModal()}
								    className={"primary-color pointer fs18"}
							    />
						    </div>
					    </div>
				    </Col>
			    </Row>
		    </div>
	    </div>

	    <div className={"mt20"}>
		    <div className={"horizontal-left"}>
			    <Row
				    className={"vertical-center"}
				    style={{ width: 500 }}
			    >
				    <Col span={8}>
					    <div className={"primary-color"}>
						    Submission Date:
					    </div>
				    </Col>
				    <Col span={16}>
					    <div className={"grey-7-color"}>
						    {moment(document.createdAt).format("DD MMM YYYY [at] HH:mm")}
					    </div>
				    </Col>
			    </Row>
		    </div>
	    </div>

	    {setCaseStatusModalIsOpened && <SetCaseStatusModal
            close={closeSetCaseStatusModal}
            currentStatusId={document.status.id}
            setDocumentStatus={updateDocumentStatus}
        />}

	    {setCasePlanModalIsOpened && <SetCasePlanModal
            availablePlans={document.refund.availablePlans}
            close={closeSetCasePlanModal}
            currentPlanId={document.plan?._id}
            confirmPlanForDocument={confirmPlanForDocument}
        />}

	    {setCaseSubStatusModalData && <SetCaseSubStatusModal
            subStatusId={setCaseSubStatusModalData as SubStatusType}
            subStatuses={document.subStatuses}
            close={closeSetCaseSubStatusModal}
            updateDocumentSubStatus={updateDocumentSubStatus}
        />}
    </div>;
};

export default CaseDetails;
