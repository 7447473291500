import { useMemo } from "react";

import { ITransaction } from "../../../../../../../../types/transactions";

const Amount = ({
    value,
}: {
	value: ITransaction;
}) => {
    const isOutgoing = useMemo(() => value.variation === "outgoing", [value.variation]);

    return <div className={`bold ${isOutgoing ? "red-color" : "primary-color"} horizontal-center`}>
        {isOutgoing ? `-${value.amount}` : `+${value.amount}`}
    </div>;
};

export default Amount;
