import { useCallback, useState } from "react";

type Value = string | object | null;

export const useModalDataOpener = () => {
    const defaultValue: Value = null;

    const [modalData, setModalData] = useState<Value>(defaultValue);

    const open = useCallback((value: Value) => {
        setModalData(value);
    }, []);

    const close = useCallback(() => {
        setModalData(defaultValue);
    }, [defaultValue]);

    return {
        modalData,
        open,
        close,
    };
};
