import React, { useContext, useMemo } from "react";

import { NavLink } from "react-router-dom";

import NavigationTooltip from "./NavigationTooltip";
import { getRootPath } from "../../../../utils/strings";
import { NavigationContext } from "../contexts/NavigationContext";

const NavigationItem = ({
    label,
    icon,
    path,
}: {
	label: string;
	path: string;
	icon: React.ReactNode;
}) => {
    const {
        navbarIsCollapsed,
	    selectedPath,
	    onPathSelect,
    } = useContext(NavigationContext);

    const itemClassName = useMemo(() => {
        return getRootPath(selectedPath) === getRootPath(path) ? "selected-navigation-item" : "navigation-item";
    }, [path, selectedPath]);

    return <div>
		    <NavLink
			    to={path}
			    data-testid={path}
			    onClick={() => onPathSelect(path)}
		    >
			    {navbarIsCollapsed
				    ? <div className={itemClassName}>
					    <NavigationTooltip title={label}>
					    <div
						    style={{
							    marginRight: 12,
						    }}
						    className={"center fs20 bold"}
					    >
					        {icon}
				        </div>
                    </NavigationTooltip>
				    </div>
				    : <div className={`${itemClassName} vertical-center`}>
				    <div
					    className={"fs20 bold"}
				    >
					    {icon}
                    </div>

				    <div
					    style={{
                            marginTop: -4,
					    }}
					    className={"ml10 fs15"}
				    >
					    {label}
                    </div>
		        </div>}
        </NavLink>
    </div>;
};

export default NavigationItem;
