import React, { useContext } from "react";

import { Col, Row } from "antd";

import { IClient, IUpdateClientByIdBody } from "../../../../../types/clients";
import Spinner from "../../../../basic/Spinner/Spinner";
import PersonalDetails from "../../../../common/Clients/PersonalDetails";
import { ClientContext } from "../../Client";

const Details = ({
    updateClientById,
}: {
	updateClientById: (_data: IUpdateClientByIdBody) => void;
}) => {
    const {
        client,
	    isLoading,
    } = useContext(ClientContext);

    return <div>
	    <Spinner isLoading={isLoading}>
		    <div className={"p20"}>
			    {client && <Row>
				    <Col span={24}>
                        <PersonalDetails
                            client={client as IClient}
                            updateClientById={updateClientById}
                        />
				    </Col>
			    </Row>}
		    </div>
	    </Spinner>
    </div>;
};

export default Details;
