import { useState, FC, useEffect } from "react";

import { Input, Typography } from "antd";

import { TEXT_TYPE } from "../../../../../../../constants/constructor";
import useDebounce from "../../../../../../../hooks/helpers/useDebounce";
import { isAnswerValid } from "../../../../../../../utils/builder";
import CustomFormItem from "../FormWrapper/FormItemWrapper";
import FloatInput from "../inputs/FloatInput";

const { TextArea } = Input;

type TextQuestionProps = {
    data: {
        question: string;
        helpText?: string;
        selectType?: string;
        keyboardType?: string;
        validation?: {
            regex: string;
            message: string;
        };
        warning?: {
            regex: string;
            message: string;
        };
        required?: boolean;
    };
    // eslint-disable-next-line no-unused-vars
    onAnswer: (answer: string) => void;
    initialValue?: string;
};

const TextQuestion: FC<TextQuestionProps> = ({
    data,
    onAnswer,
    initialValue = "",
}) => {
    const [answer, setAnswer] = useState<string>(initialValue);
    const [error, setError] = useState<string>("");
    const [warning, setWarning] = useState<string>("");
    const [skipValidation, setSkipValidation] = useState<boolean>(true);

    useEffect(() => {
        if (data.required && answer === "" && !skipValidation) {
            setError("This field is required");
        } else if (data.validation?.regex && !skipValidation) {
            const valid = isAnswerValid(answer, data.validation?.regex);
            !valid ? setError(data.validation.message) : setError("");
        } else {
            setError("");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [answer, skipValidation]);

    const debouncedAnswer = useDebounce(answer, 500);

    const handleAnswerChange = (value: string) => {
        setSkipValidation(false);
        setAnswer(value);
    };

    useEffect(() => {
        if (initialValue === debouncedAnswer) return;
        onAnswer(debouncedAnswer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedAnswer]);

    useEffect(() => {
        if (initialValue === debouncedAnswer) return;
        onAnswer(debouncedAnswer);
        const valid = isAnswerValid(answer, data.warning?.regex);
        !valid ? setWarning(data.warning?.message || "") : setWarning("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedAnswer]);

    switch (data.selectType) {
    case TEXT_TYPE.TEXT_AREA:
        return (
            <div className="builder__text-question">
                <Typography className="builder__form-question" style={{ marginBottom: "5px" }}>
                    {data.question}
                </Typography>
                <CustomFormItem
                    key={data.question}
                    // labelLength={data.question?.length || 1}
                    helpText={data.helpText}
                    error={error}
                >
                    <TextArea
                        rows={4}
                        value={answer}
                        onFocus={() => setSkipValidation(false)}
                        onChange={event => {
                            handleAnswerChange(event.target.value);
                        }}
                    />
                </CustomFormItem>
                {error && <div className="error-text">{error}</div>}
            </div>
        );
    case TEXT_TYPE.INPUT:
    default:
        return (
            <div className="builder__text-question">
                <CustomFormItem
                    key={data.question}
                    // labelLength={data.question?.length || 1}
                    helpText={data.helpText}
                    error={error || warning}
                >
                    <FloatInput
                        style={{
                            marginBottom: "5px",
                        }}
                        label={data.question}
                        value={answer}
                        error={error || warning}
                        {...(data?.keyboardType ? { type: data.keyboardType } : {})}
                        setSkipValidation={setSkipValidation}
                        onChange={(event: any) => {
                            handleAnswerChange(event.target.value);
                        }}
                    />
                </CustomFormItem>
            </div>
        );
    }
};

export default TextQuestion;
