import React, { createContext } from "react";

import { DownloadOutlined, FrownOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { NavLink, Route, Routes, useParams } from "react-router-dom";

import CaseHeader from "./components/CaseHeader";
import Details from "./tabs/Details/Details";
import Documents from "./tabs/Documents/Documents";
import Logs from "./tabs/Logs/Logs";
import Payment from "./tabs/Payment/Payment";
import Submission from "./tabs/Submission/Submission";
import { useCase } from "../../../hooks/cases/useCase";
import { useModalOpener } from "../../../hooks/helpers/useModalOpener";
import { IDocument } from "../../../types/documents";
import ErrorHandlingModal from "../../modals/Cases/ErrorHandlingModal";

export const CaseContext = createContext<{
    documentId: string | undefined;
    document: IDocument | undefined;
    isLoading: boolean;
    prevDocumentId: string | undefined;
    nextDocumentId: string | undefined;
}>({
    documentId: undefined,
    document: undefined,
    isLoading: false,
    prevDocumentId: undefined,
    nextDocumentId: undefined,
});

const getTabClassName = ({ isActive }: { isActive: boolean }) => "tab grey-color" + (isActive ? " tab-active" : "");

const Case = () => {
    const {
        documentId,
    } = useParams() as {
        documentId: string
    };

    const {
        isOpened: errorHandlingModalIsOpened,
        open: openErrorHandlingModal,
        close: closeErrorHandlingModal,
    } = useModalOpener();

    const {
        document,
        prevDocumentId,
        nextDocumentId,
        isLoading,
        getAgreementFile,
        getInvoiceFile,
        uploadReceipt,
        deleteReceipt,
        uploadQFile,
        deleteQFile,
        getReceiptFile,
        getRevenueLetterFile,
        uploadRevenueLetter,
        deleteRevenueLetter,
        updateDocumentStatus,
        updateClientById,
        updateClientPaymentDetailsById,
        confirmPlanForDocument,
        updateDocumentRefund,
        updateDocumentSubStatus,
        sendErrorNotification,
    } = useCase(documentId);

    return <div>
        <CaseContext.Provider
            value={{
                documentId,
                isLoading,
                document,
                prevDocumentId,
                nextDocumentId,
            }}
        >
            <div>
                <CaseHeader/>
            </div>

            <div className={"common-page scroll-block"}>
                <div className={"common-block-header"}>
                    <div className={"space-between"}>
                        <div
                            className={"case-tabs"}
                        >
                            <NavLink
                                data-testid={"case-details-tab"}
                                to={`/cases/${documentId}/details`}
                                className={getTabClassName}
                            >
                                Personal & Case Details
                            </NavLink>
                            <NavLink
                                data-testid={"case-submission-tab"}
                                to={`/cases/${documentId}/submission`}
                                className={getTabClassName}
                            >
                                Submission & Revenue Details
                            </NavLink>
                            <NavLink
                                data-testid={"case-payment-tab"}
                                to={`/cases/${documentId}/payment`}
                                className={getTabClassName}
                            >
                                Payment Details
                            </NavLink>
                            <NavLink
                                data-testid={"case-logs-tab"}
                                to={`/cases/${documentId}/logs`}
                                className={getTabClassName}
                            >
                                Logs
                            </NavLink>
                            <NavLink
                                data-testid={"case-documents-tab"}
                                to={`/cases/${documentId}/documents`}
                                className={getTabClassName}
                            >
                                Documents
                            </NavLink>
                        </div>
                        <div className={"horizontal-right"}>
                            <div className={"mr20"}>
                                <Button
                                    type={"primary"}
                                    className={"danger-button"}
                                    style={{ width: 180, height: 36 }}
                                    icon={<FrownOutlined />}
                                    onClick={openErrorHandlingModal}
                                >
                                    Error Handling
                                </Button>
                            </div>
                            <div className={"mr10"}>
                                <Button
                                    type={"primary"}
                                    className={"primary-button"}
                                    style={{ width: 280, height: 36 }}
                                    icon={<DownloadOutlined />}
                                    onClick={getAgreementFile}
                                >
                                    Download Client Agreement
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"common-block"}>
                    <Routes>
                        <Route
                            path={"details"}
                            element={<Details
                                updateDocumentStatus={updateDocumentStatus}
                                updateClientById={updateClientById}
                                confirmPlanForDocument={confirmPlanForDocument}
                                updateDocumentSubStatus={updateDocumentSubStatus}
                            />}
                        />

                        <Route
                            path={"submission"}
                            element={<Submission
                                updateDocumentStatus={updateDocumentStatus}
                            />}
                        />

                        <Route
                            path={"payment"}
                            element={<Payment
                                updateClientPaymentDetailsById={updateClientPaymentDetailsById}
                                updateDocumentRefund={updateDocumentRefund}
                            />}
                        />

                        <Route
                            path={"logs"}
                            element={<Logs/>}
                        />

                        <Route
                            path={"documents"}
                            element={<Documents
                                getInvoiceFile={getInvoiceFile}
                                uploadReceipt={uploadReceipt}
                                deleteReceipt={deleteReceipt}
                                uploadQFile={uploadQFile}
                                deleteQFile={deleteQFile}
                                getReceiptFile={getReceiptFile}
                                getRevenueLetterFile={getRevenueLetterFile}
                                uploadRevenueLetter={uploadRevenueLetter}
                                deleteRevenueLetter={deleteRevenueLetter}
                            />}
                        />
                    </Routes>
                </div>
            </div>

            {errorHandlingModalIsOpened && <ErrorHandlingModal
                close={closeErrorHandlingModal}
                sendErrorNotification={sendErrorNotification}
            />}
        </CaseContext.Provider>
    </div>;
};

export default Case;
