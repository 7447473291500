import { useMemo, useState } from "react";

import { ColumnDef, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import moment from "moment";

import { useGetOperationsStatsQuery } from "../../services/stats";
import { IOperationsStats } from "../../types/stats";

export const useOperationsStats = (getColumns: () => ColumnDef<IOperationsStats>[]) => {
    const [day, setDay] = useState<string>(moment().format("DD-MM-YYYY"));

    const {
        data,
        isLoading,
    } = useGetOperationsStatsQuery({
        day,
    });

    const table = useReactTable(
        {
            columns: useMemo(() => getColumns(), [getColumns]),
            data: useMemo(() => data?.data.stats || [], [data]),
            getCoreRowModel: getCoreRowModel(),
        }
    );

    return {
        day,
        setDay,
        table,
        newClientsCount: data?.data.newClientsCount || 0,
        isLoading,
    };
};
