import { useEffect, useMemo, useState } from "react";

import { ColumnDef, getCoreRowModel, getFilteredRowModel, PaginationState, useReactTable } from "@tanstack/react-table";

import { PAGE_SIZES } from "../../constants/pagination";
import { useDeleteUserMutation, useGetUsersQuery } from "../../services/users";
import { IDeleteUserBody, IUser } from "../../types/users";
import { getGlobalFilterProps, getPaginationProps } from "../../utils/table";

export const useAgentsTable = (getColumns: ({
    // eslint-disable-next-line no-unused-vars
    deleteUser,
}: {
    deleteUser: (_data: IDeleteUserBody) => void
}) => ColumnDef<IUser>[]) => {
    const [globalFilter, setGlobalFilter] = useState("");

    const [{
        pageIndex,
        pageSize,
    }, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: PAGE_SIZES[0],
    });

    const paginationData = useMemo(
        () => ({ pageIndex, pageSize }),
        [pageIndex, pageSize]
    );

    const {
        currentData,
        isLoading: isLoadingGetUsers,
        isFetching: isFetchingGetUsers,
    } = useGetUsersQuery({
        pageIndex,
        pageSize,
        globalFilter,
        withStats: true,
    });

    console.log(currentData);

    const [deleteUser, {
        isLoading: isLoadingDeleteUser,
    }] = useDeleteUserMutation();

    useEffect(() => {
        setPagination(prevState => ({
            ...prevState,
            pageIndex: 0,
        }));
    }, [
        globalFilter,
        pageSize,
    ]);

    const table = useReactTable(
        {
            columns: useMemo(() => getColumns({
                deleteUser,
            }), [deleteUser, getColumns]),
            data: useMemo(() => currentData?.users || [], [currentData?.users]),
            pageCount: currentData?.pagination.totalPages,
            state: {
                pagination: paginationData,
                globalFilter,
            },
            onPaginationChange: setPagination,
            onGlobalFilterChange: setGlobalFilter,
            getCoreRowModel: getCoreRowModel(),
            getFilteredRowModel: getFilteredRowModel(),
            manualPagination: true,
            manualFiltering: true,
        }
    );

    const isLoading = useMemo(() => {
        return isLoadingGetUsers || isFetchingGetUsers || isLoadingDeleteUser;
    }, [
        isLoadingGetUsers,
        isFetchingGetUsers,
        isLoadingDeleteUser,
    ]);

    return {
        isLoading,
        table,
        globalFilter: useMemo(() => {
            return getGlobalFilterProps(table);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [globalFilter]),
        pagination: useMemo(() => {
            return getPaginationProps(table, currentData?.pagination, "Agents");
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [paginationData, currentData?.pagination]),
    };
};
