import { Button, Col, Form, message, Modal, Row } from "antd";

import { useForgotPasswordMutation } from "../../../services/auth";
import Spinner from "../../basic/Spinner/Spinner";
import UserEmailInput from "../../inputs/Form/Inputs/EmailInput";

const ForgotPasswordModal = ({
    close,
}: {
    close: () => void;
}) => {
    const [form] = Form.useForm();

    const [forgotPassword, {
        isLoading,
    }] = useForgotPasswordMutation();

    return <Modal
        open
        width={500}
        title={
            <div style={{ color: "white" }} className={"modal-header"}>
                Reset password
            </div>
        }
        afterClose={() => form.resetFields()}
        destroyOnClose
        onCancel={() => close()}
        footer={
            <div
                style={{ height: 40 }} className={"space-between"}>
                <Button
                    shape="round"
                    type={"link"}
                    onClick={() => close()}
                    className={"default-button button-label"}
                >
                    Cancel
                </Button>

                <Button
                    shape="round"
                    htmlType="submit"
                    onClick={() => form.submit()}
                    type={"primary"}
                    className={"primary-button button-label"}
                >
                    Reset password
                </Button>
            </div>
        }
    >
        <div className={"p30"}>
            <Spinner isLoading={isLoading}>
                <Form
                    form={form}
                    layout={"vertical"}
                    onFinish={values => {
                        forgotPassword({
                            email: values.email,
                        })
                            .unwrap()
                            .then(async result => {
                                close();
                                if (result.success) await message.success(result.message);
                                else await message.error(result.message);
                            }).catch(() => null);
                    }}
                >
                    <Row>
                        <Col span={24}>
                            <div className={"modal-description grey-color"}>
                                Enter your email address and we'll send you an email with instructions to reset your
                                password.
                            </div>
                        </Col>
                    </Row>

                    <Row gutter={16} className={"mt20"}>
                        <Col span={24}>
                            <UserEmailInput required/>
                        </Col>
                    </Row>
                </Form>
            </Spinner>
        </div>
    </Modal>;
};

export default ForgotPasswordModal;
